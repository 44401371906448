import React, { useState, useEffect } from "react";

import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

import * as UsuarioServices from "../../services/usuario/usuario";
import * as RolesServices from "../../services/usuario/roles";

import { useUserState } from "../../context/UserContext";

import UsuarioForm from "./UsuarioForm";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import PageHeader from "../../components/tablas/PageHeader";
import Popup from "../../components/tablas/Popup";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";

import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import BlockIcon from '@material-ui/icons/Block';
import { Search } from "@material-ui/icons";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "telefono", label: "Teléfono" },
  { id: "email", label: "Email" },
  { id: "RolId", label: "Rol" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function Usuario() {

  const { token } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => { return items; }, });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", });

  const [registros, setRegistros] = useState({});
  const [registroRoles, setRegistroRoles] = useState({});
  const [bloqueo, setBloqueo] = useState({});

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  const bloquear = async (item) => {
    setBloqueo({
      id: item.id,
      bloqueado: true,
    });
    setConfirmDialog({
      isOpen: true,
      title: 'Está seguro de bloquear a',
      subTitle: item.nombre + ' ' + item.apellido,
      ok: false
    });
  };  

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      UsuarioServices.activos(token).then((res) => {
        console.log('Usuarios: ', res);
        setRegistros(res);
      });
      RolesServices.getAllItems(token).then((res) => {
        setRegistroRoles(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (confirmDialog.ok) {
      UsuarioServices.bloquear(bloqueo, token).then((res) => {
        console.log('Usuarios: ', res);
        setCargando(false);
        setNotify({
          isOpen: true,
          message: res.data.message,
          type: "success",    
        });
      }).catch((err) => {
        setNotify({
          isOpen: true,
          message: err.data.message,
          type: "error",    
        });
      });
    }
  }, [confirmDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();

    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) => {
            if (x.nombre)
              if (x.nombre.toLowerCase().includes(target.value)) return x;

            if (x.apellido)
              if (x.apellido.toLowerCase().includes(target.value)) return x;

            if (x.telefono)
              if (x.telefono.toLowerCase().includes(target.value)) return x;
            return false; // Línea Modificada
          });
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm) => {
    if (registroItem.id === 0) {
      try {
        await UsuarioServices.insert(registroItem, token);
      } catch (err) {
        setNotify({
          isOpen: true,
          message: "No se pudo completar la operación, revise los datos e intente nuevamente",
          type: "error",
        });
        return false;
      }
    } else {
      try {
        await UsuarioServices.update(registroItem, token);
      } catch (err) {
        setNotify({
          isOpen: true,
          message: "No se pudo completar la operación, revise los datos e intente nuevamente",
          type: "error",
        });
        return false;
      }
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
  };

  return (
    <>
      <PageHeader
        title="USUARIOS"
        subTitle="Gestión de usuarios"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text="Agregar"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <>
                  {item.nombre ? (
                    <TableRow key={item.id}>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.apellido} {item.nombre}{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.telefono}{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.email}{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.Rol.nombre}{" "}
                      </TableCell>
                      <TableCell>
                        <Controls.ActionButton
                          color="primary"
                          onClick={() => {
                            openInPopup(item);
                          }}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            bloquear(item)
                          }}
                        >
                          <BlockIcon fontSize="small" style={{ color: "white" }} />
                        </Controls.ActionButton>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="Gestión usuarios"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <UsuarioForm
          token={token}
          listaRoles={registroRoles}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        setCargando={setCargando}
      />
    </>
  );
}
