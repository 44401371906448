import GestorAxios from "../../config/axios";
import Permisos from "../../config/permisos";

export async function getAllItems(token, rolCodigo) {
  let data = {};
  if (Permisos(rolCodigo, "CLIENTES.VERTODOS")) {
    await GestorAxios.get("/cliente", {
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      data = res.data;
    });
  } else {
    if (rolCodigo === "MKT") {
      await GestorAxios.get("/cliente/vercreados", {
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        data = res.data;
      });
    } else {
      await GestorAxios.get("/cliente/verpropios", {
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        data = res.data;
      });
    }
  }

  return data;
}

export async function getAllItemsSinAsignar(token) {
  let data = {};

  await GestorAxios.get("/cliente/sinvendedorasignado", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function getAllItemsSinContactar(token) {
  let data = {};

  await GestorAxios.get("/cliente/sinprimercontacto", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function getItemsRevision(token) {
  let data = {};

  await GestorAxios.get("/cliente/revision", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function filtrarSimilares(token, dataBuscar) {
  let data = {};

  await GestorAxios.post("/cliente/filtrarSimilares", dataBuscar, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function filtrar(token, dataBuscar) {
  let data = {};

  await GestorAxios.get(`/cliente/filtrar/${dataBuscar.key}/${dataBuscar.value}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function setClienteContactado(registroItem, token) {
  let data = {};

  await GestorAxios.put("/cliente/setclientecontactado", registroItem, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function insert(data, token) {
  let dataReturn = {};

  await GestorAxios.post("/cliente/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/cliente/actualizar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function updateConError(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/cliente/actualizar/error/${data.ClienteId}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function updateAsignaVendedor(data, token) {
  let dataReturn = {};

  await GestorAxios.put(
    `/cliente/actualizar/asignavendedor/${data.id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function pertenecenVendedor(token, UsuarioId) {
  let data = {};

  await GestorAxios.get(`/cliente/pertenecenavendedor/UsuarioId/${UsuarioId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
