import React, { useEffect, useState } from "react";

import { Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";
import Popup from "../../../components/tablas/Popup";

import * as FormaPagoServices from "../../../services/venta/formaPago";
import * as MonedaServices from "../../../services/venta/moneda";
import * as LocalidadServices from "../../../services/ubicacion/localidad";
import * as PaisServices from "../../../services/ubicacion/pais";
import * as ProvinciaServices from "../../../services/ubicacion/provincia";
import * as ClienteServices from "../../../services/cliente/cliente";
import * as EstadoServices from "../../../services/producto/estado";
import * as ReservaPropiedadVentaServices from "../../../services/venta/reservaPropiedadVenta";
import * as PropiedadVentaServices from "../../../services/producto/propiedadVenta";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import ForwardIcon from "@material-ui/icons/Forward";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import Calculadora from "../../utiles/CalculadoraDeCuotas";

import { useUserState } from "../../../context/UserContext";

import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    backgroundColor: "lightgray",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  //propiedad
  id: 0,
  importe1: "",
  MonedaId1: "",
  FormaPagoId1: "",
  importe2: "",
  MonedaId2: "",
  FormaPagoId2: "",
  importe3: "",
  MonedaId3: "",
  FormaPagoId3: "",
  importe4: "",
  MonedaId4: "",
  FormaPagoId4: "",
  cantidadCuotas: 1,
  importeAnio1: 0,
  importeAnio2: 0,
  importeAnio3: 0,
  importeAnio4: 0,
  ClienteId: "",
  vendedorId: "",
  comisionGastos: 0,
  fechaEstimadaFirma: fc,
  //cliente
  nombre: "",
  apellido: "",
  DNI: "",
  telefono: "",
  email: "",
  //busqueda
  b_nombre: "",
  b_apellido: "",
  b_DNI: "",
  b_telefono: "",
  b_email: "",
};

export default function PropiedadVentaReserva(props) {

  const { token, userId } = useUserState();

  const { productoSeleccionado, onSave } = props;

  const classes = useStyles();

  const [cargando, setCargando] = useState(false);
  const [registro, setRegistro] = useState({});
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [confirma, setConfirma] = React.useState(false);
  const [pagoParcial, setPagoParcial] = useState("true");
  const [registroFormaPago, setRegistroFormaPago] = useState({});
  const [registroMoneda, setRegistroMoneda] = useState({});
  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});
  const [registroLocalidades, setRegistroLocalidades] = useState({});

  const [openPopup, setOpenPopup] = useState(false);
  const [registroEstados, setRegistroEstados] = useState({});

  const [visible_FPago2, setVisibleFPago2] = useState(false);
  const [visible_FPago3, setVisibleFPago3] = useState(false);
  const [visible_FPago4, setVisibleFPago4] = useState(false);

  const [seleccionado, setSeleccionado] = useState(false);
  const [guardando, setGuardando] = useState(false);

  const addOrEdit = async (registroItem, resetForm) => {
    console.log('Registro en addOrEdit PropiedadesVentaReserva: ', registroItem);
    let propVenta = {
      vendedorId: userId,
      PropiedadesVentumId: productoSeleccionado.id,
      observaciones: registroItem.observacionesReserva,

      FormaPagoId1: registroItem.FormaPagoId1,
      MonedaId1: registroItem.MonedaId1,
      importe1: registroItem.importe1,

      ClienteId: registroItem.ClienteId,
      fechaEstimadaFirma: selectedDate,
      linkComprobantePago: registroItem.linkComprobantePago,

      comisionGastos: registroItem.comisionGastos,
    };

    if (visible_FPago2) {
      propVenta.FormaPagoId2 = registroItem.FormaPagoId2;
      propVenta.MonedaId2 = registroItem.MonedaId2;
      propVenta.importe2 = registroItem.importe2;
    }
    if (visible_FPago3) {
      propVenta.FormaPagoId3 = registroItem.FormaPagoId3;
      propVenta.MonedaId3 = registroItem.MonedaId3;
      propVenta.importe3 = registroItem.importe3;
    }
    if (visible_FPago4) {
      propVenta.FormaPagoId4 = registroItem.FormaPagoId4;
      propVenta.MonedaId4 = registroItem.MonedaId4;
      propVenta.importe4 = registroItem.importe4;
    }

    try {
      registroItem.cantidadCuotas = parseInt(registroItem.cantidadCuotas);
    } catch (err) {}

    if (registroItem.cantidadCuotas > 1) {
      propVenta.cantidadCuotas = registroItem.cantidadCuotas;
      propVenta.entrega = registroItem.entrega;
      propVenta.importeAnio1 = registroItem.importeAnio1;
      propVenta.importeAnio2 = registroItem.importeAnio2;
      propVenta.importeAnio3 = registroItem.importeAnio3;
      propVenta.importeAnio4 = registroItem.importeAnio4;
    }

    let cliente = {
      id: registroItem.id,
      nombre: registroItem.nombre,
      apellido: registroItem.apellido,
      DNI: registroItem.DNI,
      telefono: registroItem.telefono,
      email: registroItem.email,
      direccion: registroItem.direccion,
      telefonoAlternativo: registroItem.telefonoAlternativo,
      observaciones: registroItem.observaciones,
      LocalidadId: registroItem.LocalidadId,
      asociadoNombre: registroItem.asociadoNombre,
      asociadoDNI: registroItem.asociadoDNI,
      asociadoTelefono: registroItem.asociadoTelefono,
    };

    try {
      await ClienteServices.update(cliente, token);
      await ReservaPropiedadVentaServices.insert(propVenta, token);
      let EstadoId = registroEstados.find(
        (estado) => estado.esReservado === true
      ).id;
      const modificarItem = {
        id: productoSeleccionado.id,
        EstadoId: EstadoId,
      };
      await PropiedadVentaServices.updateReserva(modificarItem, modificarItem.id, token);

      onSave();
    } catch (err) {
      alert("Error al intentar crear la reserva");
      return false;
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fechaConsulta =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleRadioChange = (event) => {
    setPagoParcial(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.importe1 || !values.FormaPagoId1 || !values.MonedaId1) {
      alert("Por favor complete los datos de pago antes de crear la reserva");
      return false;
    }
    if (pagoParcial === "true") {
      if (values.cantidadCuotas < 1 || values.cantidadCuotas === undefined) {
        alert("Por favor complete la cantidad de cuotas");
        return false;
      } else {
        if (values.cantidadCuotas > 48) {
          alert("La cantidad de cuotas ingresada no puede ser mayor a 48");
          return false;
        }
      }
    } else {
      values.cantidadCuotas = 1;
    }
    if (validate()) {
      setGuardando(true);
      values.vendedorId = userId;
      values.ClienteId = values.id;
      values.PropiedadesVentumId = productoSeleccionado.id;
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  const handleInputSoloNumerosChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      e.target.value = onlyNums;
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      e.target.value = number;
    }
    handleInputChange(e);
  };

  const telefonoCoincide = (telefono) => {
    let siNo = false;
    if (telefono) {
      if (values.b_telefono) {
        if (values.b_telefono.length > 0) {
          if (telefono.includes(values.b_telefono)) {
            siNo = true;
          }
        }
      }
    }
    return siNo;
  };

  const buscarSimilares = () => {
    const datosBuscar = {
      nombre: values.b_nombre,
      apellido: values.b_apellido,
      DNI: values.b_DNI,
      telefono: values.b_telefono,
      email: values.b_email,
    };

    ClienteServices.filtrarSimilares(token, datosBuscar).then((res) => {
      setRegistro(res);
    });
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      FormaPagoServices.getAllItems(token).then((res) => {
        setRegistroFormaPago(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setRegistroMoneda(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidades(res);
      });
      EstadoServices.getAllItems(token).then((res) => {
        setRegistroEstados(res);
      });
    }
  }, [cargando]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {registroFormaPago.length && registroMoneda.length ? (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {!seleccionado ? (
                  <>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={12}
                    >
                      <h2 style={{ paddingLeft: 10 }}>
                        {" "}
                        BUSQUE Y SELECCIONE EL CLIENTE
                      </h2>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="b_nombre"
                        label="Nombre / Alias"
                        value={values.b_nombre}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="b_apellido"
                        label="Apellido"
                        value={values.b_apellido}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="b_DNI"
                        label="DNI"
                        value={values.b_DNI}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="b_telefono"
                        label="Teléfono"
                        value={values.b_telefono}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="b_email"
                        label="Email"
                        type="email"
                        value={values.b_email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginTop: 25, textAlign: "right" }}>
                        <Controls.Button
                          onClick={buscarSimilares}
                          text="Buscar"
                        />
                      </div>
                    </Grid>
                    {!confirma ? (
                      <>
                        <div>
                          <h4>CLIENTES ENCONTRADOS</h4>
                        </div>
                        <Grid item xs={12} sm={12}></Grid>
                        {registro.length ? (
                          <>
                            {registro.map((item) => (
                              <Grid item xs={12} sm={4}>
                                <Card className={classes.root}>
                                  <CardContent>
                                    <Typography
                                      style={{ textAlign: "center" }}
                                      className={classes.title}
                                      color="textSecondary"
                                      gutterBottom
                                    >
                                      <AccountCircleIcon
                                        style={{ fontSize: 40, color: "white" }}
                                      />
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                      {item.apellido} {item.nombre}
                                    </Typography>
                                    <Typography
                                      className={classes.pos}
                                      color="textSecondary"
                                    >
                                      DNI: {item.DNI}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                      {telefonoCoincide(item.telefono) && (
                                        <>
                                          <PhoneAndroidIcon
                                            style={{ marginBottom: -5 }}
                                          />{" "}
                                          Coincide con TELEFONO PRINCIPAL
                                        </>
                                      )}
                                      {telefonoCoincide(
                                        item.asociadoTelefono
                                      ) && (
                                        <>
                                          <PhoneAndroidIcon
                                            style={{ marginBottom: -5 }}
                                          />{" "}
                                          Coincide con TELEFONO DEL ASOCIADO
                                        </>
                                      )}
                                      {telefonoCoincide(
                                        item.telefonoAlternativo
                                      ) && (
                                        <>
                                          <PhoneAndroidIcon
                                            style={{ marginBottom: -5 }}
                                          />{" "}
                                          Coincide con TELEFONO ALTERNATIVO
                                        </>
                                      )}
                                      <br />
                                      <EmailIcon
                                        style={{ marginBottom: -5 }}
                                      />{" "}
                                      {item.email}
                                      <br />
                                      {item.Usuario ? (
                                        <>
                                          VENDEDOR:{" "}
                                          <b>
                                            {item.Usuario.nombre}{" "}
                                            {item.Usuario.apellido}
                                          </b>
                                        </>
                                      ) : (
                                        <>NO HAY VENDEDOR ASIGNADO</>
                                      )}
                                    </Typography>
                                    <Typography
                                      className={classes.pos}
                                      color="textSecondary"
                                    >
                                      <Controls.ActionButton
                                        color="primary"
                                        onClick={() => {
                                          setSeleccionado(true);
                                          //setValor(true);
                                          setConfirma(true);
                                          let paisId = 0;
                                          let provinciaId = 0;
                                          try {
                                            const prov =
                                              registroLocalidades.find(
                                                (itemLoc) =>
                                                  itemLoc.id ===
                                                  item.LocalidadId
                                              );
                                            const pais =
                                              registroProvincias.find(
                                                (itemProv) =>
                                                  itemProv.id ===
                                                  prov.ProvinciumId
                                              );
                                            if (pais) {
                                              paisId = pais.PaiId;
                                            }
                                            if (prov) {
                                              provinciaId = prov.ProvinciumId;
                                            }
                                          } catch (e) {
                                          }
                                          item.PaiId = paisId;
                                          item.ProvinciumId = provinciaId;
                                          item.b_nombre = values.b_nombre;
                                          item.b_apellido = values.b_apellido;
                                          item.b_DNI = values.b_DNI;
                                          item.b_telefono = values.b_telefono;
                                          item.b_email = values.b_email;
                                          item.DNI = item.DNI ? item.DNI : "";
                                          setValues(item);
                                        }}
                                      >
                                        <ForwardIcon fontSize="small" />
                                      </Controls.ActionButton>
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </>
                        ) : (
                          <> No se encontraron registros</>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={9}
                    >
                      <h2 style={{ paddingLeft: 10 }}>CLIENTE SELECCIONADO</h2>
                    </Grid>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <Controls.ActionButton
                        style={{ marginTop: 15 }}
                        color="primary"
                        onClick={() => {
                          setSeleccionado(false);
                          //setValor(false);
                          setConfirma(false);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" /> CAMBIAR CLIENTE
                      </Controls.ActionButton>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="nombre"
                        label="Nombre / Alias"
                        value={values.nombre}
                        onChange={handleInputChange}
                        error={errors.nombre}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="apellido"
                        label="Apellido"
                        value={values.apellido}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="DNI"
                        label="DNI"
                        value={values.DNI}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="telefono"
                        label="Teléfono"
                        value={values.telefono}
                        onChange={handleInputChange}
                        error={errors.telefono}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="email"
                        label="email"
                        type="email"
                        value={values.email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="direccion"
                        label="Dirección"
                        value={values.direccion}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="telefonoAlternativo"
                        label="Teléfono Alternativo"
                        value={values.telefonoAlternativo}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Select
                        name="PaiId"
                        label="País"
                        value={values.PaiId}
                        onChange={handleInputChange}
                        options={registroPaises.map((item) => {
                          return { id: item.id, title: item.nombre };
                        })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      {values.PaiId ? (
                        <div>
                          {registroProvincias.find(
                            (item) => item.PaiId === values.PaiId
                          ) ? (
                            <Controls.Select
                              name="ProvinciumId"
                              label="Provincia"
                              value={values.ProvinciumId}
                              onChange={handleInputChange}
                              options={registroProvincias.map((item) => {
                                if (item.PaiId === values.PaiId) {
                                  return { id: item.id, title: item.nombre };
                                } else {
                                  return false; //Línea Modificada
                                }
                              })}
                            />
                          ) : (
                            <>
                              {" "}
                              No hay provincias creadas en este pais. Por favor
                              completar antes de continuar.
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      {values.ProvinciumId ? (
                        <div>
                          {registroLocalidades.find(
                            (item) => item.ProvinciumId === values.ProvinciumId
                          ) ? (
                            <>
                              <Controls.Select
                                name="LocalidadId"
                                label="Localidad"
                                value={values.LocalidadId}
                                onChange={handleInputChange}
                                options={registroLocalidades.map((item) => {
                                  if (
                                    item.ProvinciumId === values.ProvinciumId
                                  ) {
                                    return { id: item.id, title: item.nombre };
                                  } else {
                                    return false; // Línea Modificada
                                  }
                                })}
                              />
                            </>
                          ) : (
                            <>
                              {" "}
                              No hay localidades creadas en esta provincia. Por
                              favor completar antes de continuar.
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Controls.Input
                        name="observaciones"
                        label="Observaciones"
                        value={values.observaciones}
                        onChange={handleInputChange}
                        multiline
                        rowsMax={4}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="asociadoNombre"
                        label="Nombre asociado"
                        value={values.asociadoNombre}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="asociadoDNI"
                        label="DNI asociado"
                        value={values.asociadoDNI}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="asociadoTelefono"
                        label="Teléfono asociado"
                        value={values.asociadoTelefono}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={12}
                    >
                      <hr />
                      <h2 style={{ paddingLeft: 10 }}>DATOS DE PAGO </h2>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={pagoParcial}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Pago Total"
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Pago Parcial"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={12}></Grid>
                    {pagoParcial === "true" ? (
                      <>
                        <Grid item xs={12} sm={3}>
                          <Controls.Input
                            name="entrega"
                            style={{ backgroundColor: "lightblue" }}
                            label="Entrega"
                            value={values.entrega}
                            onChange={handleInputSoloNumerosChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                          <Controls.Button
                            text="Calculadora de Cuotas"
                            onClick={() => setOpenPopup(true)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Input
                            name="cantidadCuotas"
                            label="Cantidad de Cuotas"
                            value={values.cantidadCuotas}
                            onChange={handleInputSoloNumerosChange}
                          />
                        </Grid>
                        {values.cantidadCuotas > 48 ||
                        values.cantidadCuotas < 1 ? (
                          <>
                            {() =>
                              alert(
                                "Número de cuotas inválido. No pueden superar 48"
                              )
                            }
                          </>
                        ) : (
                          <>
                            {values.cantidadCuotas > 1 && (
                              <>
                                <Grid item xs={12} sm={2}>
                                  <Controls.Input
                                    name="importeAnio1"
                                    label="Primer Año"
                                    value={values.importeAnio1}
                                    onChange={handleInputSoloNumerosChange}
                                  />
                                </Grid>
                              </>
                            )}
                            {values.cantidadCuotas > 12 && (
                              <>
                                <Grid item xs={12} sm={2}>
                                  <Controls.Input
                                    name="importeAnio2"
                                    label="Segundo Año"
                                    value={values.importeAnio2}
                                    onChange={handleInputSoloNumerosChange}
                                  />
                                </Grid>
                              </>
                            )}
                            {values.cantidadCuotas > 24 && (
                              <>
                                <Grid item xs={12} sm={2}>
                                  <Controls.Input
                                    name="importeAnio3"
                                    label="Tercer Año"
                                    value={values.importeAnio3}
                                    onChange={handleInputSoloNumerosChange}
                                  />
                                </Grid>
                              </>
                            )}
                            {values.cantidadCuotas > 36 && (
                              <>
                                <Grid item xs={12} sm={2}>
                                  <Controls.Input
                                    name="importeAnio4"
                                    label="Cuarto Año"
                                    value={values.importeAnio4}
                                    onChange={handleInputSoloNumerosChange}
                                  />
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12} sm={12}>
                      {" "}
                      <hr />{" "}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controls.Select
                        name="FormaPagoId1"
                        label="Forma de Pago"
                        value={values.FormaPagoId1 || ""}
                        onChange={handleInputChange}
                        options={registroFormaPago.map((item) => {
                          return { id: item.id, title: item.nombre };
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controls.Select
                        name="MonedaId1"
                        label="Moneda"
                        value={values.MonedaId1 || ""}
                        onChange={handleInputChange}
                        options={registroMoneda.map((item) => {
                          return { id: item.id, title: item.nombre };
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controls.Input
                        name="importe1"
                        label="Importe"
                        value={values.importe1}
                        onChange={handleInputSoloNumerosChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controls.ActionButton
                        style={{ marginTop: 15 }}
                        color="primary"
                        onClick={() => {
                          setVisibleFPago2(true);
                        }}
                      >
                        <AddCircleIcon fontSize="small" />
                      </Controls.ActionButton>
                    </Grid>
                    {visible_FPago2 && (
                      <>
                        <Grid item xs={12} sm={3}>
                          <Controls.Select
                            name="FormaPagoId2"
                            label="Forma de Pago"
                            value={values.FormaPagoId2 || ""}
                            onChange={handleInputChange}
                            options={registroFormaPago.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Select
                            name="MonedaId2"
                            label="Moneda"
                            value={values.MonedaId2 || ""}
                            onChange={handleInputChange}
                            options={registroMoneda.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Input
                            name="importe2"
                            label="Importe"
                            value={values.importe2}
                            onChange={handleInputSoloNumerosChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.ActionButton
                            style={{ marginTop: 15 }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago3(true);
                            }}
                          >
                            <AddCircleIcon fontSize="small" />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            style={{ marginTop: 15, backgroundColor: "red" }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago2(false);
                            }}
                          >
                            <CancelRoundedIcon fontSize="small" />
                          </Controls.ActionButton>
                        </Grid>
                      </>
                    )}
                    {visible_FPago3 && (
                      <>
                        <Grid item xs={12} sm={3}>
                          <Controls.Select
                            name="FormaPagoId3"
                            label="Forma de Pago"
                            value={values.FormaPagoId3 || ""}
                            onChange={handleInputChange}
                            options={registroFormaPago.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Select
                            name="MonedaId3"
                            label="Moneda"
                            value={values.MonedaId3 || ""}
                            onChange={handleInputChange}
                            options={registroMoneda.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Input
                            name="importe3"
                            label="Importe"
                            value={values.importe3}
                            onChange={handleInputSoloNumerosChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.ActionButton
                            style={{ marginTop: 15 }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago4(true);
                            }}
                          >
                            <AddCircleIcon fontSize="small" />
                          </Controls.ActionButton>

                          <Controls.ActionButton
                            style={{ marginTop: 15, backgroundColor: "red" }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago3(false);
                            }}
                          >
                            <CancelRoundedIcon fontSize="small" />
                          </Controls.ActionButton>
                        </Grid>
                      </>
                    )}
                    {visible_FPago4 && (
                      <>
                        <Grid item xs={12} sm={3}>
                          <Controls.Select
                            name="FormaPagoId4"
                            label="Forma de Pago"
                            value={values.FormaPagoId4 || ""}
                            onChange={handleInputChange}
                            options={registroFormaPago.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Select
                            name="MonedaId4"
                            label="Moneda"
                            value={values.MonedaId4 || ""}
                            onChange={handleInputChange}
                            options={registroMoneda.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Input
                            name="importe4"
                            label="Importe"
                            value={values.importe4}
                            onChange={handleInputSoloNumerosChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.ActionButton
                            style={{ marginTop: 15, backgroundColor: "red" }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago4(false);
                            }}
                          >
                            <CancelRoundedIcon fontSize="small" />
                          </Controls.ActionButton>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="selectedDate"
                          disablePast={true}
                          format="dd/MM/yyyy"
                          value={selectedDate}
                          onChange={handleDateChange}
                          helperText="Fecha estimada de firma:"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controls.Input
                        name="linkComprobantePago"
                        label="Link al comprobante de Pago"
                        value={values.linkComprobantePago}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controls.Input
                        name="comisionGastos"
                        label="Comision y Gastos Administrativos"
                        value={values.comisionGastos}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controls.Input
                        name="observacionesReserva"
                        label="Observaciones de la reserva"
                        value={values.observacionesReserva}
                        onChange={handleInputChange}
                        multiline
                        rowsMax={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {!guardando ? (
                        <div style={{ marginTop: 25, textAlign: "right" }}>
                          <Controls.Button type="submit" text="Guardar" />
                        </div>
                      ) : (
                        <div style={{ margin: 25, textAlign: "right" }}>
                          <CircularProgress size={26} />
                        </div>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Popup
            title="Calculadora de Cuotas"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <Calculadora />
          </Popup>
        </Form>
      ) : (
        <>Cargando...</>
      )}
    </>
  );
}
