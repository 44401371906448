import React, { useEffect, useState } from "react";

import { Grid, CircularProgress, RadioGroup, FormControlLabel, Radio, } from "@material-ui/core";

import * as ReservaLotesXLoteoServices from "../../../services/venta/reservaLotesXLoteo";
import * as HistorialReservaServices from "../../../services/venta/historialReserva";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";
import Popup from "../../../components/tablas/Popup";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import Calculadora from "../../utiles/CalculadoraDeCuotas";

import { useUserState } from "../../../context/UserContext";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  //propiedad
  id: 0,
  importe1: "",
  MonedaId1: "",
  FormaPagoId1: "",
  importe2: "",
  MonedaId2: "",
  FormaPagoId2: "",
  importe3: "",
  MonedaId3: "",
  FormaPagoId3: "",
  importe4: "",
  MonedaId4: "",
  FormaPagoId4: "",
  cantidadCuotas: 1,
  importeAnio1: 0,
  importeAnio2: 0,
  importeAnio3: 0,
  importeAnio4: 0,
  ClienteId: "",
  vendedorId: "",
  comisionGastos: 0,
  fechaEstimadaFirma: fc,
};

export default function LotexLoteoReserva(props) {
  const { token, userId } = useUserState();

  const { recordForEdit, recordFormaPago, recordMoneda, setOpenPopupEditar, listaUsuarios, setCargandoGeneral } = props;

  console.log("Reserva a editar: ", recordForEdit);

  const loteo = recordForEdit.Lotesxloteo.Loteo;
  const manzanas = loteo.cantManzanas.split(",");
  const cliente = recordForEdit.Cliente;
  const vendedor = recordForEdit.Usuario;
  const listaLotes = recordForEdit.ReservasItemComprados;
  //const fechaFirma = recordForEdit.fechaEstimadaFirma;
  
  const [cargando, setCargando] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  
  const [openPopup, setOpenPopup] = useState(false);
  
  const [pagoParcial, setPagoParcial] = useState("true");
  
  const [visible_FPago2, setVisibleFPago2] = useState(false);
  const [visible_FPago3, setVisibleFPago3] = useState(false);
  const [visible_FPago4, setVisibleFPago4] = useState(false);
  
  const [guardando, setGuardando] = useState(false);
  
  const addOrEdit = async (registroItem, resetForm) => {
    let loteAReservar = {
      id: registroItem.id,
      //vendedorId: userId,
      LotesxloteoId: recordForEdit.LotesxloteoId,
      observaciones: registroItem.observacionesReserva,
      
      FormaPagoId1: registroItem.FormaPagoId1,
      MonedaId1: registroItem.MonedaId1,
      importe1: registroItem.importe1,
      
      ClienteId: registroItem.ClienteId,
      fechaEstimadaFirma: registroItem.fechaEstimadaFirma,
      linkComprobantePago: registroItem.linkComprobantePago,
      
      comisionGastos: registroItem.comisionGastos,
    };
    console.log('Fecha de firma: ', registroItem.fechaEstimadaFirma);

    
    if (visible_FPago2) {
      loteAReservar.FormaPagoId2 = registroItem.FormaPagoId2;
      loteAReservar.MonedaId2 = registroItem.MonedaId2;
      loteAReservar.importe2 = registroItem.importe2;
    } else {
      loteAReservar.FormaPagoId2 = null;
      loteAReservar.MonedaId2 = null;
      loteAReservar.importe2 = null;
    }
    if (visible_FPago3) {
      loteAReservar.FormaPagoId3 = registroItem.FormaPagoId3;
      loteAReservar.MonedaId3 = registroItem.MonedaId3;
      loteAReservar.importe3 = registroItem.importe3;
    } else {
      loteAReservar.FormaPagoId3 = null;
      loteAReservar.MonedaId3 = null;
      loteAReservar.importe3 = null;
    }
    if (visible_FPago4) {
      loteAReservar.FormaPagoId4 = registroItem.FormaPagoId4;
      loteAReservar.MonedaId4 = registroItem.MonedaId4;
      loteAReservar.importe4 = registroItem.importe4;
    } else {
      loteAReservar.FormaPagoId4 = null;
      loteAReservar.MonedaId4 = null;
      loteAReservar.importe4 = null;
    }

    try {
      registroItem.cantidadCuotas = parseInt(registroItem.cantidadCuotas);
    } catch (err) {}
    
    if (pagoParcial === "false") {
      loteAReservar.cantidadCuotas = 1;
    } else {
      switch (registroItem.cantidadCuotas) {
        case 12:
          loteAReservar.importeAnio1 = registroItem.importeAnio1;
          loteAReservar.importeAnio2 = null;
          loteAReservar.importeAnio3 = null;
          loteAReservar.importeAnio4 = null;
          break;
        case 24:
          loteAReservar.importeAnio1 = registroItem.importeAnio1;
          loteAReservar.importeAnio2 = registroItem.importeAnio2;
          loteAReservar.importeAnio3 = null;
          loteAReservar.importeAnio4 = null;
          break;
        case 36:
          loteAReservar.importeAnio1 = registroItem.importeAnio1;
          loteAReservar.importeAnio2 = registroItem.importeAnio2;
          loteAReservar.importeAnio3 = registroItem.importeAnio3;
          loteAReservar.importeAnio4 = null;
          break;
        case 48:
          loteAReservar.importeAnio1 = registroItem.importeAnio1;
          loteAReservar.importeAnio2 = registroItem.importeAnio2;
          loteAReservar.importeAnio3 = registroItem.importeAnio3;
          loteAReservar.importeAnio4 = registroItem.importeAnio4;
          break;
        default: break;
      }
    }
    if (registroItem.cantidadCuotas > 1) {
      loteAReservar.cantidadCuotas = registroItem.cantidadCuotas;
      loteAReservar.entrega = registroItem.entrega;
      if (registroItem.cantidadCuotas === 12) {
      }
    } else {
      loteAReservar.entrega = null;
      loteAReservar.importeAnio1 = null;
      loteAReservar.importeAnio2 = null;
      loteAReservar.importeAnio3 = null;
      loteAReservar.importeAnio4 = null;
    }
    console.log('Lote a Reservar: ', loteAReservar);
    try {
      await ReservaLotesXLoteoServices.update(loteAReservar, token);
      let fecha = new Date();
      const usuario = listaUsuarios.find(usuario => userId === usuario.id)
      const tipoProducto = "lote";
      const historial = {
        nota: `Modificó el usuario ${usuario.nombre} ${usuario.apellido}, el día ${fecha.getDate()}/${fecha.getMonth()}/${fecha.getFullYear()} a las ${fecha.getHours()}:${fecha.getMinutes()}:${fecha.getSeconds()}`,
        reservaId: registroItem.id,
        UsuarioId: userId,
      }
      console.log('Historial: ', historial);
      await HistorialReservaServices.insert(historial, token, tipoProducto);
      setCargandoGeneral(false);
    } catch (err) {
      alert("Error al intentar crear la reserva");
      return false;
    }
  };

  const handleRadioChange = (event) => {
    setPagoParcial(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fechaEstimadaFirma =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.importe1 || !values.FormaPagoId1 || !values.MonedaId1) {
      alert("Por favor complete los datos de pago antes de crear la reserva");
      return false;
    }
    if (pagoParcial === "true") {
      if (values.cantidadCuotas < 1 || values.cantidadCuotas === undefined) {
        alert("Por favor complete la cantidad de cuotas");
        return false;
      } else {
        if (values.cantidadCuotas > 48) {
          alert("La cantidad de cuotas ingresada no puede ser mayor a 48");
          return false;
        }
      }
    } else {
      values.cantidadCuotas = 1;
    }
    if (validate()) {
      setGuardando(true);
      //values.vendedorId = userId;
      values.ClienteId = values.id;
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        } else {
          setOpenPopupEditar(false);
        }
      });
    }
  };

  // const handleInputSoloNumerosChange = (e) => {
  //   const onlyNums = e.target.value.replace(/[^0-9]/g, "");
  //   if (onlyNums.length < 10) {
  //     e.target.value = onlyNums;
  //   } else if (onlyNums.length === 10) {
  //     const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  //     e.target.value = number;
  //   }
  //   handleInputChange(e);
  // };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      setValues(recordForEdit);
      if (recordForEdit.cantidadCuotas === 1) {
        setPagoParcial("false");
      } else {
        setPagoParcial("true");
      }
      if (recordForEdit.fechaEstimadaFirma !== null) {
        setSelectedDate(recordForEdit.fechaEstimadaFirma);
      }
      if (recordForEdit.FormaPagoId2 !== null) {
        setVisibleFPago2(true);
      }
      if (recordForEdit.FormaPagoId3 !== null) {
        setVisibleFPago3(true);
      }
      if (recordForEdit.FormaPagoId4 !== null) {
        setVisibleFPago4(true);
      }
    }
  }, [cargando]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {recordFormaPago.length && recordMoneda.length ? (
        <Form onSubmit={handleSubmit}>
          <Grid
            container
            style={{
              borderRadius: 20,
              backgroundColor: "#FFA433",
              textAlign: "center",
            }}
          >
            <Grid item xs={12} sm={12}>
              <div>
                <h1>{loteo.nombre}</h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ width: "80%", marginLeft: "10%" }}>
                <hr />
              </div>
            </Grid>
            {/*------------------------------------Fecha------------------------------------*/}
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={4}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingRight: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <h2>Fecha:</h2>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingRight: "10px",
                    }}
                  >
                    <h2>{fc}</h2>
                  </div>
                </Grid>
                {/*------------------------------------Productos------------------------------------*/}
                <Grid item xs={4}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingRight: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <h2>Productos:</h2>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingRight: "10px",
                    }}
                  >
                    <Grid container spacing={3}>
                      {listaLotes.length ? (
                        <>
                          {listaLotes.map((lote) => {
                            return (
                              <Grid xs={3}>
                                <div
                                  style={{
                                    backgroundColor: "#dddddd",
                                    borderRadius: 10,
                                    marginLeft: 5,
                                    //padding: "10%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h3>
                                    {manzanas[lote.Lotesxloteo.manzana]} -{" "}
                                    {lote.Lotesxloteo.nombre}
                                  </h3>
                                </div>
                              </Grid>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            {/*------------------------------------Cliente------------------------------------*/}
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={4}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingRight: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <h2>Cliente:</h2>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    <h2>
                      {cliente.nombre} {cliente.apellido}
                    </h2>
                  </div>
                </Grid>

                {/*------------------------------------Vendedor------------------------------------*/}
                <Grid item xs={4}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingRight: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <h2>Vendedor:</h2>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div
                    style={{
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    <h2>
                      {vendedor.nombre} {vendedor.apellido}
                    </h2>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={pagoParcial}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Pago Total"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Pago Parcial"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
            {pagoParcial === "true" ? (
              <>
                <Grid item xs={12} sm={3}>
                  <Controls.InputMask
                    name="entrega"
                    style={{ backgroundColor: "lightblue" }}
                    label="Entrega"
                    value={values.entrega}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                  <Controls.Button
                    text="Calculadora de Cuotas"
                    onClick={() => setOpenPopup(true)}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.InputMask
                    name="cantidadCuotas"
                    label="Cantidad de Cuotas"
                    value={values.cantidadCuotas}
                    onChange={handleInputChange}
                  />
                </Grid>
                {values.cantidadCuotas > 48 || values.cantidadCuotas < 1 ? (
                  <>
                    {() =>
                      alert("Número de cuotas inválido. No pueden superar 48")
                    }
                  </>
                ) : (
                  <>
                    {values.cantidadCuotas > 1 && (
                      <>
                        <Grid item xs={12} sm={2}>
                          <Controls.InputMask
                            name="importeAnio1"
                            label="Primer Año"
                            value={values.importeAnio1}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    )}
                    {values.cantidadCuotas > 12 && (
                      <>
                        <Grid item xs={12} sm={2}>
                          <Controls.InputMask
                            name="importeAnio2"
                            label="Segundo Año"
                            value={values.importeAnio2}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    )}
                    {values.cantidadCuotas > 24 && (
                      <>
                        <Grid item xs={12} sm={2}>
                          <Controls.InputMask
                            name="importeAnio3"
                            label="Tercer Año"
                            value={values.importeAnio3}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    )}
                    {values.cantidadCuotas > 36 && (
                      <>
                        <Grid item xs={12} sm={2}>
                          <Controls.InputMask
                            name="importeAnio4"
                            label="Cuarto Año"
                            value={values.importeAnio4}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {pagoParcial === "true" && (
              <>
                <Grid item xs={12} sm={12}>
                  {" "}
                  <hr />{" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <h2>Reserva:</h2>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={3}>
              <Controls.Select
                name="FormaPagoId1"
                label="Forma de Pago"
                value={values.FormaPagoId1 || ""}
                onChange={handleInputChange}
                options={recordFormaPago.map((item) => {
                  return { id: item.id, title: item.nombre };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controls.Select
                name="MonedaId1"
                label="Moneda"
                value={values.MonedaId1 || ""}
                onChange={handleInputChange}
                options={recordMoneda.map((item) => {
                  return { id: item.id, title: item.nombre };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controls.InputMask
                name="importe1"
                label="Importe"
                value={values.importe1}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controls.ActionButton
                style={{ marginTop: 15 }}
                color="primary"
                onClick={() => {
                  setVisibleFPago2(true);
                }}
              >
                <AddCircleIcon fontSize="small" />
              </Controls.ActionButton>
            </Grid>
            {visible_FPago2 && (
              <>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="FormaPagoId2"
                    label="Forma de Pago"
                    value={values.FormaPagoId2 || ""}
                    onChange={handleInputChange}
                    options={recordFormaPago.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="MonedaId2"
                    label="Moneda"
                    value={values.MonedaId2 || ""}
                    onChange={handleInputChange}
                    options={recordMoneda.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.InputMask
                    name="importe2"
                    label="Importe"
                    value={values.importe2}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controls.ActionButton
                    style={{ marginTop: 15 }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago3(true);
                    }}
                  >
                    <AddCircleIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    style={{ marginTop: 15, backgroundColor: "red" }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago2(false);
                    }}
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </Controls.ActionButton>
                </Grid>
              </>
            )}
            {visible_FPago3 && (
              <>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="FormaPagoId3"
                    label="Forma de Pago"
                    value={values.FormaPagoId3 || ""}
                    onChange={handleInputChange}
                    options={recordFormaPago.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="MonedaId3"
                    label="Moneda"
                    value={values.MonedaId3 || ""}
                    onChange={handleInputChange}
                    options={recordMoneda.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.InputMask
                    name="importe3"
                    label="Importe"
                    value={values.importe3}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.ActionButton
                    style={{ marginTop: 15 }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago4(true);
                    }}
                  >
                    <AddCircleIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    style={{ marginTop: 15, backgroundColor: "red" }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago3(false);
                    }}
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </Controls.ActionButton>
                </Grid>
              </>
            )}
            {visible_FPago4 && (
              <>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="FormaPagoId4"
                    label="Forma de Pago"
                    value={values.FormaPagoId4 || ""}
                    onChange={handleInputChange}
                    options={recordFormaPago.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="MonedaId4"
                    label="Moneda"
                    value={values.MonedaId4 || ""}
                    onChange={handleInputChange}
                    options={recordMoneda.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.InputMask
                    name="importe4"
                    label="Importe"
                    value={values.importe4}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.ActionButton
                    style={{ marginTop: 15, backgroundColor: "red" }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago4(false);
                    }}
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </Controls.ActionButton>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  //disablePast={true}  Cambiado 29/10/21 para carga de documentación anterior al sistema
                  id="selectedDate"
                  format="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  helperText="Fecha estimada de firma:"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="linkComprobantePago"
                label="Link al comprobante de Pago"
                value={values.linkComprobantePago}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.InputMask
                name="comisionGastos"
                label="Comisión y Gastos Administrativos"
                value={values.comisionGastos}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                name="observacionesReserva"
                label="Observaciones de la reserva"
                value={values.observacionesReserva}
                onChange={handleInputChange}
                multiline
                rowsMax={4}
              />
            </Grid>
            <Grid item xs={12}>
              {!guardando ? (
                <div style={{ marginTop: 25, textAlign: "right" }}>
                  <Controls.Button type="submit" text="Guardar" />
                </div>
              ) : (
                <div style={{ margin: 25, textAlign: "right" }}>
                  <CircularProgress size={26} />
                </div>
              )}
            </Grid>
          </Grid>
          <Popup
            title="Calculadora de Cuotas"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <Calculadora />
          </Popup>
        </Form>
      ) : (
        <>Cargando...</>
      )}
    </>
  );
}
