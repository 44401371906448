import React, { useEffect } from "react";

import { Grid } from "@material-ui/core";

import { useForm, Form } from "../../../components/tablas/useForm";

import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import GestorUrls from "../../../config/urls";

const initialFValues = {
  observaciones: "",
};

export default function ReservasPropiedadesVisor(props) {

  const { addOrEdit, recordForEdit, recordFormaPago, recordMoneda } = props;

  const urlDrive = GestorUrls.drive;

  const obtenerFormaDePago = (id) => {
    const fp = recordFormaPago.find((record) => record.id === id);
    if (fp) {
      return fp.nombre;
    }
    return "No definido";
  };

  const obtenerModeda = (id) => {
    const fp = recordMoneda.find((record) => record.id === id);
    if (fp) {
      return fp.nombre;
    }
    return "No definido";
  };

  const validate = (fieldValues = values) => {

    let temp = { ...errors };

    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      {recordForEdit && (
        <Grid container>
          <Grid
            style={{
              backgroundColor: "lightblue",
              padding: 10,
              borderRadius: 10,
            }}
            item
            xs={12}
            sm={6}
          >
            <h2>DATOS DE LA PROPIEDAD</h2>
            <img
              src={
                urlDrive +
                "productos/propsventa/" +
                recordForEdit.PropiedadesVentum.id +
                "/" +
                recordForEdit.PropiedadesVentum.fotoPrincipal
              }
              alt=""
              style={{ width: 400, borderRadius: 10 }}
            />
            <br />
            <b>{recordForEdit.PropiedadesVentum.nombre}</b> <br />
            {recordForEdit.PropiedadesVentum.direccion} <br />
            {recordForEdit.PropiedadesVentum.Localidad ? (
              <>
                {recordForEdit.PropiedadesVentum.Localidad.nombre}-{" "}
                {recordForEdit.PropiedadesVentum.Localidad.Provincium.nombre}-{" "}
                {
                  recordForEdit.PropiedadesVentum.Localidad.Provincium.Pai
                    .nombre
                }
              </>
            ) : (
              <>Localización no definida</>
            )}
          </Grid>
          <Grid
            style={{
              backgroundColor: "lightblue",
              padding: 10,
              borderRadius: 10,
            }}
            item
            xs={12}
            sm={6}
          >
            <h3 style={{ marginTop: 60 }}>CARACTERÍSTICAS</h3>
            {recordForEdit.PropiedadesVentum.superficieTotal ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Superficie total :{" "}
                <b> {recordForEdit.PropiedadesVentum.superficieTotal}</b>{" "}
              </div>
            ) : (
              <div>
                <CancelIcon /> Superficie Total : No definido
              </div>
            )}
            {recordForEdit.PropiedadesVentum.superficieCubierta ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Superficie cubierta :{" "}
                <b>{recordForEdit.PropiedadesVentum.superficieCubierta}</b>{" "}
              </div>
            ) : (
              <div>
                <CancelIcon /> Superficie cubierta : No definido
              </div>
            )}
            {recordForEdit.PropiedadesVentum.cantHabitaciones ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Cantidad habitaciones :{" "}
                <b>{recordForEdit.PropiedadesVentum.cantHabitaciones}</b>{" "}
              </div>
            ) : (
              <div>
                <CancelIcon /> Cantidad habitaciones : No definido
              </div>
            )}
            {recordForEdit.PropiedadesVentum.cantBanios ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Cantidad baños :{" "}
                <b> {recordForEdit.PropiedadesVentum.cantBanios}</b>{" "}
              </div>
            ) : (
              <div>
                <CancelIcon /> Cantidad habitaciones : No definido
              </div>
            )}
            {recordForEdit.PropiedadesVentum.cantCocheras ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Cantidad cocheras :{" "}
                <b> {recordForEdit.PropiedadesVentum.cantCocheras}</b>{" "}
              </div>
            ) : (
              <div>
                <CancelIcon /> Cantidad cocheras : No definido
              </div>
            )}
            {recordForEdit.PropiedadesVentum.linkPlano && (
              <Link
                href={recordForEdit.PropiedadesVentum.linkPlano}
                target="_blank"
                color="inherit"
              >
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: "blue" }}
                >
                  <AspectRatioIcon style={{ marginRight: 5 }} /> VER PLANO
                </Button>
                <br />
              </Link>
            )}
            {recordForEdit.PropiedadesVentum.linkDocumentacion && (
              <Link
                href={recordForEdit.PropiedadesVentum.linkDocumentacion}
                target="_blank"
                color="inherit"
              >
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: "green" }}
                >
                  <FolderSpecialIcon style={{ marginRight: 5 }} /> VER DOCS
                </Button>
              </Link>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <hr />
          </Grid>
          <Grid
            style={{
              backgroundColor: "lightgreen",
              padding: 10,
              borderRadius: 10,
            }}
            item
            xs={12}
            sm={6}
          >
            <h2> PROPIETARIO</h2>
            <b>
              {recordForEdit.PropiedadesVentum.Propietario.apellido}{" "}
              {recordForEdit.PropiedadesVentum.Propietario.nombre}
              <br />
              DNI: {recordForEdit.PropiedadesVentum.Propietario.DNI}
            </b>{" "}
            <br />
            <b> TEL: </b>
            {recordForEdit.PropiedadesVentum.Propietario.telefono} <br />
            <b> OBS: </b>
            {recordForEdit.PropiedadesVentum.Propietario.observaciones}
          </Grid>
          <Grid
            style={{
              backgroundColor: "lightgreen",
              padding: 10,
              borderRadius: 10,
            }}
            item
            xs={12}
            sm={6}
          >
            <h2>DATOS DEL CLIENTE</h2>
            <b>
              {recordForEdit.Cliente.apellido} {recordForEdit.Cliente.nombre}
              <br />
              DNI: {recordForEdit.Cliente.DNI}
            </b>{" "}
            <br />
            <b> TEL: </b>
            {recordForEdit.Cliente.telefono} <br />
            <b> OBS: </b>
            {recordForEdit.Cliente.observaciones}
          </Grid>
          <Grid item xs={12} sm={12}>
            <hr style={{ marginTop: 40 }} />
            <h2
              style={{
                backgroundColor: "red",
                padding: 10,
                borderRadius: 10,
                color: "white",
              }}
            >
              DATOS DE LA RESERVA
            </h2>
          </Grid>
          <Grid style={{ padding: 20 }} item xs={12} sm={6}>
            <h3>PAGO</h3>

            <div
              style={{
                backgroundColor: "red",
                color: "white",
                padding: 10,
                borderRadius: 10,
                fontSize: 16,
              }}
            >
              <h4>
                {recordForEdit.FormaPagoId1 && (
                  <>
                    <CheckCircleIcon style={{ fontSize: 20 }} /> IMPORTE :{" "}
                    {recordForEdit.importe1} -{" "}
                    {obtenerModeda(recordForEdit.MonedaId1)} <ArrowForwardIcon /> ({" "}
                    {obtenerFormaDePago(recordForEdit.FormaPagoId1)} )
                  </>
                )}
                {recordForEdit.FormaPagoId2 && (
                  <>
                    <br />
                    <CheckCircleIcon style={{ fontSize: 20 }} /> IMPORTE :{" "}
                    {recordForEdit.importe2} -{" "}
                    {obtenerModeda(recordForEdit.MonedaId2)} <ArrowForwardIcon /> ({" "}
                    {obtenerFormaDePago(recordForEdit.FormaPagoId2)} )
                  </>
                )}
                {recordForEdit.FormaPagoId3 && (
                  <>
                    <br />
                    <CheckCircleIcon style={{ fontSize: 20 }} /> IMPORTE :{" "}
                    {recordForEdit.importe3} -{" "}
                    {obtenerModeda(recordForEdit.MonedaId3)} <ArrowForwardIcon /> ({" "}
                    {obtenerFormaDePago(recordForEdit.FormaPagoId3)} )
                  </>
                )}
                {recordForEdit.FormaPagoId4 && (
                  <>
                    <br />
                    <CheckCircleIcon style={{ fontSize: 20 }} /> IMPORTE :{" "}
                    {recordForEdit.importe4} -{" "}
                    {obtenerModeda(recordForEdit.MonedaId4)} <ArrowForwardIcon /> ({" "}
                    {obtenerFormaDePago(recordForEdit.FormaPagoId4)} )
                  </>
                )}
                <br />
                <br />
                {recordForEdit.observaciones && (
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      padding: 10,
                      borderRadius: 10,
                      paddingTop: 1,
                    }}
                  >
                    <h3>NOTA DE LA RESERVA:</h3> {recordForEdit.observaciones}
                  </div>
                )}
              </h4>
            </div>
          </Grid>
          <Grid style={{ padding: 20 }} item xs={12} sm={6}>
            <h3>INFORMACIÓN</h3>
            {recordForEdit.obsGerente && (
              <div
                style={{
                  backgroundColor: "yellow",
                  padding: 10,
                  borderRadius: 10,
                  paddingTop: 1,
                  marginBottom: 5,
                }}
              >
                {" "}
                <h3>NOTA GERENTE:</h3>
                {recordForEdit.obsGerente}
              </div>
            )}
            {recordForEdit.obsSecretaria && (
              <div
                style={{
                  backgroundColor: "yellow",
                  padding: 10,
                  borderRadius: 10,
                  paddingTop: 1,
                }}
              >
                {" "}
                <h3>NOTA SECRETARIA:</h3>
                {recordForEdit.obsSecretaria}
              </div>
            )}
            {recordForEdit.linkComprobantePago && (
              <>
                <Link
                  href={recordForEdit.linkComprobantePago}
                  target="_blank"
                  color="inherit"
                >
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ backgroundColor: "green" }}
                  >
                    <FolderSpecialIcon style={{ marginRight: 5 }} /> VER
                    COMPROBANTE PAGO
                  </Button>
                </Link>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Form>
  );
}
