import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, } from "@material-ui/core";

import { useUserState } from "../../../context/UserContext";

import PropiedadVentaForm from "./PropiedadVentaForm";
import PropiedadVentaVisor from "./PropiedadVentaVisor";
import Galeria from "../../utiles/Galeria";

import * as PropiedadVentaServices from "../../../services/producto/propiedadVenta";
import * as LocalidadServices from "../../../services/ubicacion/localidad";
import * as PaisServices from "../../../services/ubicacion/pais";
import * as ProvinciaServices from "../../../services/ubicacion/provincia";
import * as PropietariosServices from "../../../services/producto/propietario";
import * as EstadosServices from "../../../services/producto/estado";
import * as MonedaServices from "../../../services/venta/moneda";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";
import PageHeader from "../../../components/tablas/PageHeader";
import Popup from "../../../components/tablas/Popup";
import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";

import GestorUrls from "../../../config/urls";
import Permisos from "../../../config/permisos";

import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Search } from "@material-ui/icons";

const cabecera = [
  { id: "nombre", label: "nombre" },
  { id: "foto", label: "Foto" },
  { id: "Estado", label: "Estado" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function PropiedadesVenta() {

  const { token, rolCodigo } = useUserState();

  const urlDrive = GestorUrls.drive;

  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});
  const [registroLocalidad, setRegistroLocalidad] = useState({});
  const [registroPropietario, setRegistroPropietario] = useState({});
  const [registroEstados, setRegistroEstados] = useState({});
  const [registroMoneda, setRegistroMoneda] = useState({});

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => { return items; }, });
  const [registros, setRegistros] = useState({});

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupVer, setOpenPopupVer] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForVer, setRecordForVer] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", });
  
  const [recordForGaleria, setRecordForGaleria] = useState(null);
  const [openPopupGaleria, setOpenPopupGaleria] = useState(false);

  const [nuevoPropietario, setNuevoPropietario] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  const reservaGeneradaOk = () => {
    setOpenPopupVer(false);
    setNotify({
      isOpen: true,
      message: "Reserva generada correctamente",
      type: "success",
    });
    setCargando(false);
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      PropiedadVentaServices.getAllItems(token).then((res) => {
        setRegistros(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidad(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
      PropietariosServices.getAllItems(token).then((res) => {
        setRegistroPropietario(res);
      });
      EstadosServices.getAllItems(token).then((res) => {
        setRegistroEstados(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setRegistroMoneda(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //console.log('En useEffect nuevopropietario: ', nuevoPropietario)
    if (!nuevoPropietario) {
      setNuevoPropietario(true);
      PropietariosServices.getAllItems(token).then((res) => {
        setRegistroPropietario(res);
        //console.log('En la lista de propietarios: ', res);
        //console.log('PopUp: ', openPopup);
      });
    }
  }, [nuevoPropietario]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopupVer = (item) => {
    try {
      const prov = registroLocalidad.find(
        (itemLoc) => itemLoc.id === item.LocalidadId
      );
      const pais = registroProvincias.find(
        (itemProv) => itemProv.id === prov.ProvinciumId
      );
      if (pais) {
        item.PaiId = pais.PaiId;
        item.Pais_nombre = pais.nombre;
      }
      if (prov) {
        item.ProvinciumId = prov.ProvinciumId;
        item.Provincia_nombre = prov.nombre;
      }
      if (item.latitud) {
        item.urlMapa =
          "https://www.google.com/maps/search/?api=1&query=" +
          item.latitud +
          "," +
          item.longitud;
      }
    } catch (e) {
    }
    setRecordForVer(item);
    setOpenPopupVer(true);
  };

  const openInPopup = (item) => {
    let paisId = 0;
    let provinciaId = 0;
    try {
      const prov = registroLocalidad.find(
        (itemLoc) => itemLoc.id === item.LocalidadId
      );
      const pais = registroProvincias.find(
        (itemProv) => itemProv.id === prov.ProvinciumId
      );
      if (pais) {
        paisId = pais.PaiId;
      }
      if (prov) {
        provinciaId = prov.ProvinciumId;
      }
    } catch (e) {
    }
    item.PaiId = paisId;
    item.ProvinciumId = provinciaId;
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInPopupGaleria = (id) => {
    setRecordForGaleria("productos/propsventa/" + id + "/galeria/");
    setOpenPopupGaleria(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm, id) => {
    if (id === 0) {
      try {
        await PropiedadVentaServices.insert(registroItem, token);
      } catch (err) {
        return false;
      }
    } else {
      try {
        await PropiedadVentaServices.update(registroItem, id, token);
      } catch (err) {
        return false;
      }
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    setRegistros(PropiedadVentaServices.getAllItems(token));
  };

  return (
    <>
      <PageHeader
        title="PROPIEDADES EN VENTA"
        subTitle="Gestión de propiedes"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          {Permisos(rolCodigo, "PRODUCTOS.PROP_VENTA_AGREGA_EDITA") && (
            <Controls.Button
              text="Agregar"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          )}
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <>
                  {item.nombre ? (
                    <TableRow key={item.id}>
                      <TableCell key={"nombre" + item.id} style={{ fontWeight: "500" }}>
                        {item.nombre}{" "}
                      </TableCell>
                      <TableCell key={"fotoprincipal" + item.nombre}>
                        {item.fotoPrincipal ? (
                          <img
                            style={{ width: 200, borderRadius: 10 }}
                            alt=""
                            src={
                              urlDrive +
                              "productos/propsventa/" +
                              item.id +
                              "/" +
                              item.fotoPrincipal
                            }
                          />
                        ) : (
                          <> </>
                        )}
                      </TableCell>
                      {item.Estado ? (
                        <TableCell key={"estado" + item.id} style={{ fontWeight: "500" }}>
                          <div
                            style={{
                              backgroundColor: item.Estado.color,
                              padding: 10,
                              borderRadius: 10,
                              textAlign: "center",
                              color: "white",
                            }}
                          >
                            {item.Estado.nombre}{" "}
                          </div>{" "}
                        </TableCell>
                      ) : (
                        <TableCell key={"estado" + item.id} style={{ fontWeight: "500" }}>
                          <div
                            style={{
                              backgroundColor: "lightgray",
                              padding: 10,
                              borderRadius: 10,
                              textAlign: "center",
                              color: "black",
                            }}
                          >
                            No asignado
                          </div>{" "}
                        </TableCell>
                      )}
                      <TableCell key={"botones" + item.id}>
                        {Permisos(
                          rolCodigo,
                          "PRODUCTOS.PROP_VENTA_AGREGA_EDITA"
                        ) && (
                          <Controls.ActionButton
                            key={"editar" + item.id}
                            color="primary"
                            onClick={() => {
                              openInPopup(item);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </Controls.ActionButton>
                        )}
                        <Controls.ActionButton
                          key={"ver" + item.id}
                          color="primary"
                          style={{ backgroundColor: "#1F6D14" }}
                          onClick={() => {
                            openInPopupVer(item);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </Controls.ActionButton>
                        {Permisos(rolCodigo, "GALERIA.CRUD"
                        ) && 
                          <Controls.ActionButton
                            key={"galeria" + item.id}
                            color="primary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              openInPopupGaleria(item.id);
                            }}
                          >
                            <PhotoCameraIcon fontSize="small" />
                          </Controls.ActionButton>
                        }
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="Formulario propiedad en venta"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <PropiedadVentaForm
          listaLocalidades={registroLocalidad}
          listaProvincias={registroProvincias}
          listaPaises={registroPaises}
          listaPropietarios={registroPropietario}
          listaEstados={registroEstados}
          listaModendas={registroMoneda}
          recordForEdit={recordForEdit}
          setRecordForEdit={setRecordForEdit}
          addOrEdit={addOrEdit}
          setRegistroPropietario={setRegistroPropietario}
          setNuevoPropietario={setNuevoPropietario}
          nuevoPropietario={nuevoPropietario}
        />
      </Popup>
      <Popup
        title="Vista de propiedad en venta"
        openPopup={openPopupVer}
        setOpenPopup={setOpenPopupVer}
      >
        <PropiedadVentaVisor
          onSave={reservaGeneradaOk}
          recordForVer={recordForVer}
        />
      </Popup>
      <Popup
        title="GALERIA DE FOTOS"
        openPopup={openPopupGaleria}
        setOpenPopup={setOpenPopupGaleria}
      >
        <Galeria
          token={token}
          path={recordForGaleria}
          setOpenPopup={setOpenPopupGaleria}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
