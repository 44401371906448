import React, { useState, useEffect } from "react";

import { Grid, } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, } from "@material-ui/core";

import Controls from "../../components/tablas/controls/Controls";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import { useUserState } from "../../context/UserContext";
import Permisos from "../../config/permisos";

import * as ConfigDataServices from "../../services/utiles/configData";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  verde1: {
    backgroundColor: "#7DDA82",
    color: "black",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  verde2: {
    backgroundColor: "#3EA644",
    color: "white",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  verde3: {
    backgroundColor: "#338D38",
    color: "white",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  verde4: {
    backgroundColor: "#29762D",
    color: "white",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  verde5: {
    backgroundColor: "#17551A",
    color: "white",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  gris1: {
    backgroundColor: "#E9E9E9",
    color: "black",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  gris2: {
    backgroundColor: "#D6D6D6",
    color: "black",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  gris3: {
    backgroundColor: "#B0B0B0",
    color: "black",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  gris4: {
    backgroundColor: "#8C8D8C",
    color: "black",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  gris5: {
    backgroundColor: "#707070",
    color: "black",
    padding: theme.spacing(1),
    textAlign: "center",
  },
}));

export default function PropiedadVentaVisor(props) {

  const { rolCodigo, token } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [restoFinanciar, setRestoFinanciar] = useState(0);
  const [entrega, setEntrega] = useState(0);
  const [precioContado, setPrecioContado] = useState(0);
  const [interes, setInteres] = useState(40);
  const [config, setConfig] = useState({});

  const classes = useStyles();

  let calculos = {
    cuotasx12: {
      financiar: 0,
      cuotas: 12,
      cuotasPromedio: 0,
      total: 0,
      detallexanio: {
        anio1: 0,
      },
    },
    cuotasx24: {
      financiar: 0,
      cuotas: 24,
      cuotasPromedio: 0,
      total: 0,
      detallexanio: {
        anio1: 0,
        anio2: 0,
      },
    },
    cuotasx36: {
      financiar: 0,
      cuotas: 36,
      cuotasPromedio: 0,
      total: 0,
      detallexanio: {
        anio1: 0,
        anio2: 0,
        anio3: 0,
      },
    },
    cuotasx48: {
      financiar: 0,
      cuotas: 48,
      cuotasPromedio: 0,
      total: 0,
      detallexanio: {
        anio1: 0,
        anio2: 0,
        anio3: 0,
        anio4: 0,
      },
    },
  };

  function FormRow(props) {
    let cantidad = parseInt(props.cant);
    return (
      <React.Fragment>
        {cantidad === 1 && (
          <>
            <Grid item xs={2}>
              <Paper className={classes.gris1}>{props.anio}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris1}>
                ${numberWithCommas(props.item1)}
              </Paper>
            </Grid>
          </>
        )}
        {cantidad === 2 && (
          <>
            <Grid item xs={2}>
              <Paper className={classes.gris2}>{props.anio}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris2}>
                ${numberWithCommas(props.item1)}
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris2}>
                ${numberWithCommas(props.item2)}
              </Paper>
            </Grid>
          </>
        )}
        {cantidad === 3 && (
          <>
            <Grid item xs={2}>
              <Paper className={classes.gris3}>{props.anio}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris3}>
                ${numberWithCommas(props.item1)}
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris3}>
                ${numberWithCommas(props.item2)}
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris3}>
                ${numberWithCommas(props.item3)}
              </Paper>
            </Grid>
          </>
        )}
        {cantidad === 4 && (
          <>
            <Grid item xs={2}>
              <Paper className={classes.gris4}>{props.anio}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris4}>
                ${numberWithCommas(props.item1)}
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris4}>
                ${numberWithCommas(props.item2)}
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris4}>
                ${numberWithCommas(props.item3)}
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.gris4}>
                ${numberWithCommas(props.item4)}
              </Paper>
            </Grid>
          </>
        )}
        {cantidad === 0 && (
          <>
            <Grid item xs={2}>
              <Paper className={classes.verde1}>{props.anio}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.verde2}>{props.item1}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.verde3}>{props.item2}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.verde4}>{props.item3}</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper className={classes.verde5}>{props.item4}</Paper>
            </Grid>
          </>
        )}
        {cantidad === 5 && (
          <>
            <Grid item xs={3}>
              <Paper className={classes.verde3}>{props.item1}</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.verde3}>{props.item2}</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.verde3}>{props.item3}</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.verde3}>{props.item4}</Paper>
            </Grid>
          </>
        )}
        {cantidad === 6 && (
          <>
            <Grid item xs={3}>
              <Paper className={classes.gris1}>
                ${numberWithCommas(props.item1)}
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.gris2}>{props.item2}</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.gris3}>
                ${numberWithCommas(props.item3)}
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.gris4}>
                ${numberWithCommas(props.item4)}
              </Paper>
            </Grid>
          </>
        )}
      </React.Fragment>
    );
  }

  const cuotas = (valor, porcentaje) => {
    return valor + (valor / 100) * porcentaje;
  };

  const Cargar = () => {
    calculos.cuotasx12.financiar = Math.ceil(
      cuotas(restoFinanciar, interes * 1)
    );
    calculos.cuotasx24.financiar = Math.ceil(
      cuotas(restoFinanciar, interes * 2)
    );
    calculos.cuotasx36.financiar = Math.ceil(
      cuotas(restoFinanciar, interes * 3)
    );
    calculos.cuotasx48.financiar = Math.ceil(
      cuotas(restoFinanciar, interes * 4)
    );
    calculos.cuotasx12.cuotasPromedio = Math.ceil(
      calculos.cuotasx12.financiar / 12
    );
    calculos.cuotasx24.cuotasPromedio = Math.ceil(
      calculos.cuotasx24.financiar / 24
    );
    calculos.cuotasx36.cuotasPromedio = Math.ceil(
      calculos.cuotasx36.financiar / 36
    );
    calculos.cuotasx48.cuotasPromedio = Math.ceil(
      calculos.cuotasx48.financiar / 48
    );
    calculos.cuotasx12.total =
      parseFloat(entrega) + calculos.cuotasx12.financiar;
    calculos.cuotasx24.total =
      parseFloat(entrega) + calculos.cuotasx24.financiar;
    calculos.cuotasx36.total =
      parseFloat(entrega) + calculos.cuotasx36.financiar;
    calculos.cuotasx48.total =
      parseFloat(entrega) + calculos.cuotasx48.financiar;
    calculos.cuotasx12.detallexanio.anio1 = cuotas(
      calculos.cuotasx12.cuotasPromedio,
      0
    );
    calculos.cuotasx24.detallexanio.anio1 = cuotas(
      calculos.cuotasx24.cuotasPromedio,
      -30
    );
    calculos.cuotasx24.detallexanio.anio2 = cuotas(
      calculos.cuotasx24.cuotasPromedio,
      30
    );
    calculos.cuotasx36.detallexanio.anio1 = cuotas(
      calculos.cuotasx36.cuotasPromedio,
      -50
    );
    calculos.cuotasx36.detallexanio.anio2 = cuotas(
      calculos.cuotasx36.cuotasPromedio,
      0
    );
    calculos.cuotasx36.detallexanio.anio3 = cuotas(
      calculos.cuotasx36.cuotasPromedio,
      50
    );
    calculos.cuotasx48.detallexanio.anio1 = cuotas(
      calculos.cuotasx48.cuotasPromedio,
      -50
    );
    calculos.cuotasx48.detallexanio.anio2 = cuotas(
      calculos.cuotasx48.cuotasPromedio,
      -15
    );
    calculos.cuotasx48.detallexanio.anio3 = cuotas(
      calculos.cuotasx48.cuotasPromedio,
      15
    );
    calculos.cuotasx48.detallexanio.anio4 = cuotas(
      calculos.cuotasx48.cuotasPromedio,
      50
    );
  };

  const cambiarPorcentaje = async () => {
    try {
      let data = {
        calculadoraPorcentajeAnual: interes,
      }
      if (config && config.length) {
        console.log('Actualizar...............................................................', interes);
        data.id = config[0].id;
        await ConfigDataServices.update(data, token);
      } else {
        console.log('Nuevo....................................................................', interes);
        await ConfigDataServices.insert(data, token);
      }
    } catch (error) {
      console.log('Error: ', error.message);
      return false
    }
  }

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ConfigDataServices.getAllItems(token).then((res) => {
        setConfig(res);
        console.log('config: ', res)
        if (res && res.length) {
          setInteres(res[0].calculadoraPorcentajeAnual);
        };
      })
    }
    setRestoFinanciar(precioContado - entrega);
  }, [cargando, entrega, precioContado, restoFinanciar]); // eslint-disable-line

  function numberWithCommas(x) {
    x = parseInt(x);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  let resto = 0;

  return (
        <>
          <Paper className={classes.pageContent}>
            <div style={{ marginBottom: -33, marginLeft: 20 }}>
              <h2>
                {" "}
                <CreditCardIcon
                  style={{ marginBottom: -8, fontSize: 33 }}
                />{" "}
                CALCULADORA DE CUOTAS
              </h2>
            </div>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Grid item xs={12} sm={10}>
                    <Controls.Input
                      id="precio-contado"
                      label="Precio Contado"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={precioContado}
                      onChange={(e) => setPrecioContado(e.target.value)}
                    />
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid item xs={12} sm={10}>
                    <Controls.Input
                      id="entrega"
                      label="Entrega"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={entrega}
                      onChange={(e) => {
                        setEntrega(e.target.value);
                        resto = precioContado - entrega;
                        setRestoFinanciar(resto);
                      }}
                    />
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid item xs={12} sm={12}>
                    <div style={{ textAlign: "center" }}>
                      <h4 style={{ paddingTop: 0 }}>Total a Financiar</h4>
                      <h2 style={{ paddingTop: 0 }}>
                        ${numberWithCommas(restoFinanciar)}
                      </h2>
                    </div>
                  </Grid>
                </TableCell>
                {Permisos(rolCodigo, "UTILES.CALCULADORA.INTERES") && (
                  <>
                    <TableCell>
                      <Grid item xs={12} sm={8}>
                        <Controls.Input
                          id="interes"
                          label="Interés Anual"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={interes}
                          onChange={(e) => {
                            setInteres(e.target.value);
                          }}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item xs={12} sm={8}>
                        <Controls.Button
                          text="Cambiar"
                          onClick={() => cambiarPorcentaje()}
                        />
                      </Grid>
                    </TableCell>
                  </>
                )}
                {Cargar()}
              </TableRow>
            </TableBody>
          </Paper>
          {Permisos(rolCodigo, "UTILES.CALCULADORA.CENTRO") && (
            <Paper className={classes.pageContent}>
              <TableBody>
                <div className={classes.root}>
                  <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1="A FINANCIAR"
                        item2="CUOTAS"
                        item3="CUOTA PROMEDIO"
                        item4="PRECIO FINAL"
                        cant="5"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1={calculos.cuotasx12.financiar}
                        item2="12 CUOTAS"
                        item3={calculos.cuotasx12.cuotasPromedio}
                        item4={calculos.cuotasx12.total}
                        cant="6"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1={calculos.cuotasx24.financiar}
                        item2="24 CUOTAS"
                        item3={calculos.cuotasx24.cuotasPromedio}
                        item4={calculos.cuotasx24.total}
                        cant="6"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1={calculos.cuotasx36.financiar}
                        item2="36 CUOTAS"
                        item3={calculos.cuotasx36.cuotasPromedio}
                        item4={calculos.cuotasx36.total}
                        cant="6"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1={calculos.cuotasx48.financiar}
                        item2="48 CUOTAS"
                        item3={calculos.cuotasx48.cuotasPromedio}
                        item4={calculos.cuotasx48.total}
                        cant="6"
                      />
                    </Grid>
                  </Grid>
                </div>
              </TableBody>
            </Paper>
          )}
          <Paper className={classes.pageContent}>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <div className={classes.root}>
                  <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1="PRIMER AÑO"
                        item2="SEGUNDO AÑO"
                        item3="TERCER AÑO"
                        item4="CUARTO AÑO"
                        cant="0"
                        anio="CUOTAS/AÑO"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1={calculos.cuotasx12.detallexanio.anio1}
                        cant="1"
                        anio="12 CUOTAS"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1={calculos.cuotasx24.detallexanio.anio1}
                        item2={calculos.cuotasx24.detallexanio.anio2}
                        cant="2"
                        anio="24 CUOTAS"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1={calculos.cuotasx36.detallexanio.anio1}
                        item2={calculos.cuotasx36.detallexanio.anio2}
                        item3={calculos.cuotasx36.detallexanio.anio3}
                        cant="3"
                        anio="36 CUOTAS"
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      <FormRow
                        item1={calculos.cuotasx48.detallexanio.anio1}
                        item2={calculos.cuotasx48.detallexanio.anio2}
                        item3={calculos.cuotasx48.detallexanio.anio3}
                        item4={calculos.cuotasx48.detallexanio.anio4}
                        cant="4"
                        anio="48 CUOTAS"
                      />
                    </Grid>
                  </Grid>
                </div>
              </TableRow>
            </TableBody>
          </Paper>
        </>
  );
}
