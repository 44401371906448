import React, { useState, useEffect } from "react";

import * as ClienteServices from "../../services/cliente/cliente";
import * as LocalidadServices from "../../services/ubicacion/localidad";
import * as PaisServices from "../../services/ubicacion/pais";
import * as ProvinciaServices from "../../services/ubicacion/provincia";
import * as FuenteServices from "../../services/cliente/fuente";
import * as HistorialServices from "../../services/cliente/historial";

import HistorialLista from "./HistorialLista";

import VisibilityIcon from "@material-ui/icons/Visibility";

import ClienteForm from "./ClienteForm";
import ClienteConErrorForm from "./ClienteConErrorForm";
import Tarjeta from "../utiles/Tarjeta";

import { useUserState } from "../../context/UserContext";
import Permisos from "../../config/permisos";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
} from "@material-ui/core";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import Popup from "../../components/tablas/Popup";

import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "observacion", label: "Observaciones" },
  { id: "telefono", label: "Teléfono" },
  { id: "email", label: "Email" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#DFE3FC",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function ClienteSinContactar() {
  const { token, rolCodigo, userId } = useUserState();
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({  // eslint-disable-line
    fn: (items) => {
      return items;
    },
  });

  const [openPopupLista, setOpenPopupLista] = useState(false);

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupError, setOpenPopupError] = useState(false);

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [registros, setRegistros] = useState({});
  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});
  const [registroLocalidad, setRegistroLocalidad] = useState({});
  const [registroFuente, setRegistroFuente] = useState({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ClienteServices.getAllItemsSinContactar(token).then((res) => {
        setRegistros(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidad(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
      FuenteServices.getAllItems(token).then((res) => {
        setRegistroFuente(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  const addOrEditError = async (registroItem, resetForm) => {
    try {
      await ClienteServices.updateConError(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopupError(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    ClienteServices.getAllItemsSinContactar(token).then((res) => {
      setRegistros(res);
    });
  };

  const addOrEdit = async (registroItem, resetForm) => {
    try {
      await ClienteServices.update(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    ClienteServices.getAllItemsSinContactar(token).then((res) => {
      setRegistros(res);
    });
  };

  const addOrEditLista = async (registroItem, resetForm) => {
    try {
      await HistorialServices.insert(registroItem, token);
      // console.log("GUARDA HISTORIAL: ", registroItem);
      await ClienteServices.setClienteContactado(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setOpenPopupLista(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    ClienteServices.getAllItemsSinContactar(token).then((res) => {
      setRegistros(res);
    });
  };

  const openInPopupLista = (item) => {
    setRecordForEdit(item);
    setOpenPopupLista(true);
  };

  const openInPopup = (item) => {
    let paisId = 0;
    let provinciaId = 0;
    try {
      const prov = registroLocalidad.find(
        (itemLoc) => itemLoc.id === item.LocalidadId
      );
      const pais = registroProvincias.find(
        (itemProv) => itemProv.id === prov.ProvinciumId
      );
      if (pais) {
        paisId = pais.PaiId;
      }
      if (prov) {
        provinciaId = prov.ProvinciumId;
      }
    } catch (e) {
      //console.log("ERROR:", e.message)
    }
    item.PaiId = paisId;
    item.ProvinciumId = provinciaId;
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInPopupError = (item) => {
    setRecordForEdit(item);
    setOpenPopupError(true);
  };

  return (
    <>
      {registros.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="CLIENTES SIN CONTACTAR"
                icon={
                  <DoubleArrowIcon
                    style={{ fontSize: 40, color: "blue", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={registros}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#DFE3FC" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <h2>
                        <DoubleArrowIcon
                          style={{
                            fontSize: 40,
                            color: "blue",
                            marginBottom: -10,
                          }}
                        />{" "}
                        CLIENTES SIN CONTACTAR{" "}
                        <DoubleArrowIcon
                          style={{
                            fontSize: 40,
                            color: "blue",
                            marginBottom: -10,
                          }}
                        />{" "}
                      </h2>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: 10 }}>
                      <Controls.Button
                        text="Ocultar"
                        onClick={() => setVisible(!visible)}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.apellido} - {item.nombre}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.observaciones}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.telefono}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.email}{" "}
                        </TableCell>
                        <TableCell>
                          {Permisos(rolCodigo, "CLIENTES.CLIENTES.EDITAR") && (
                            <Controls.ActionButton
                              color="primary"
                              onClick={() => {
                                openInPopup(item);
                              }}
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </Controls.ActionButton>
                          )}
                          {userId !== item.usuarioCreadorId &&
                            !item.fechaPrimerContacto && (
                              <>
                                {Permisos(
                                  rolCodigo,
                                  "CLIENTES.CLIENTES.REPORTAR"
                                ) && (
                                  <Controls.ActionButton
                                    style={{ backgroundColor: "#E3E703" }}
                                    onClick={() => {
                                      openInPopupError(item);
                                    }}
                                  >
                                    <ErrorOutlineIcon fontSize="small" />
                                  </Controls.ActionButton>
                                )}
                              </>
                            )}
                          <Controls.ActionButton
                            color="primary"
                            style={{ backgroundColor: "blue" }}
                            onClick={() => {
                              openInPopupLista(item);
                            }}
                          >
                            <VisibilityIcon fontSize="small" />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Popup
        title="Gestión clientes"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ClienteForm
          token={token}
          listaFuentes={registroFuente}
          listaLocalidades={registroLocalidad}
          listaProvincias={registroProvincias}
          listaPaises={registroPaises}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Popup
        title="Cliente con error en datos"
        openPopup={openPopupError}
        setOpenPopup={setOpenPopupError}
      >
        <ClienteConErrorForm
          token={token}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditError}
        />
      </Popup>
      <Popup
        title="Historial de seguimiento"
        openPopup={openPopupLista}
        setOpenPopup={setOpenPopupLista}
      >
        <HistorialLista
          token={token}
          puedeAgregar={Permisos(rolCodigo, "CLIENTES.HISTORIAL.AGREGAR")}
          puedeVerGestion={Permisos(
            rolCodigo,
            "CLIENTES.HISTORIAL.VERPESTAÑAGESTION"
          )}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditLista}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
