import GestorAxios from "../../config/axios";

export async function getAllItems(id, token) {
  let data = {};
  console.log('Services: ', id);
  await GestorAxios.get(`/contables/comisionesusuarios/${id}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    console.log(res.data);
    data = res.data;
  });

  return data;
}

export async function insert(data, token) {
  let dataReturn = {};
  console.log('Llega a services: ', data);

  await GestorAxios.post("/contables/comisionesusuarios/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/contables/comisionesusuarios/actualizar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function comisionesLotes(id, token) {
  let data = {};
  //console.log('Services: ', id);
  await GestorAxios.get(`/contables/comisionesusuarios/lotes/${id}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    //console.log(res.data);
    data = res.data;
  });

  return data;
}

export async function comisionesPropiedades(id, token) {
  let data = {};
  console.log('Services: ', id);
  await GestorAxios.get(`/contables/comisionesusuarios/propiedades/${id}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    console.log(res.data);
    data = res.data;
  });

  return data;
}
