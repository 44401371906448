import GestorAxios from "../../config/axios";

export async function getAllItems(token) {
  let data = {};

  await GestorAxios.get("/rol", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
