import GestorAxios from "../../config/axios";

export async function getAllItems(token) {
  let data = {};

  await GestorAxios.get("/caja/cajageneral", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function insert(data, token) {
  let dataReturn = {};
  console.log('Desde setvices: ', data);
  await GestorAxios.post("/caja/cajageneral/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/caja/cajageneral/actualizar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function filtrar(data, token) {
  let dataReturn = {};

  await GestorAxios.get(`/caja/cajageneral/actualizar/${data.campo}/${data.id}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function getNoProcesado(token) {
  let data = {};

  await GestorAxios.get("/caja/cajageneral/noprocesado", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function getProcesado(token) {
  let data = {};

  await GestorAxios.get("/caja/cajageneral/procesado", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function procesar(data, id, token) {
  let dataReturn = {};

  await GestorAxios.put(`/caja/cajageneral/procesar/${id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}