import GestorAxios from "../../config/axios";


export async function getAllItems(token) {
  let data = {};
  await GestorAxios.get("/web/consulta", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });
  return data;
}


export async function consulta(token) {
  let data = {};
  await GestorAxios.get("/web/consulta/consulta", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });
  return data;
}


export async function update(data, token) {
  let dataReturn = {};
  //console.log(data);
  await GestorAxios.put(`/web/consulta/actualizar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}


export async function boletin(token) {
  let data = {};
  await GestorAxios.get("/web/consulta/boletin", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });
  return data;
}
