import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";

import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import * as LotesXLoteoServices from "../../../services/producto/lotesXLoteo";
import * as LoteoServices from "../../../services/producto/loteos";

export default function DetalleVenta(props) {
  const {
    token,
    addOrEdit,
    recordForEdit,
    lotesListaCompleta,
    setLotesListaCompleta,
  } = props;

  //console.log("Lista de lotes: ", lotesListaCompleta);

  const loteo = recordForEdit.Lotesxloteo.Loteo;
  const manzanas = loteo.cantManzanas.split(",");
  const cliente = recordForEdit.Cliente;
  const vendedor = recordForEdit.Usuario;
  const productoSeleccionado = recordForEdit.Lotesxloteo;

  const [cargando, setCargando] = useState(false);
  const [listaLotes, setListaLotes] = useState({});
  const [cambiarLote, setCambiarLote] = useState(false);
  const [guardando, setGuardando] = useState(false);
  const [lotesCompradosActual, setLotesCompradosActual] = useState([]);
  const [loteos, setLoteos] = useState([]);
  const [loteoActual, setLoteoActual] = useState(loteo.id);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const cambiarLoteo = async (e) => {
    await LotesXLoteoServices.getAllItems(token, e.target.value).then((res) => {
      //console.log('Nuevos elementos: ', res, e.target.value);
      setLoteoActual(e.target.value);
      setLotesListaCompleta(res);
    });
  };

  const obtenerNombreLote = (item) => {
    const nom = manzanas[item.manzana] + " - " + item.nombre;
    return nom;
  };

  const eliminarLoteComprado = (item) => {
    console.log("Item: ", item);
    let lc = lotesCompradosActual.slice().filter((lc) => lc.id !== item.id);
    setLotesCompradosActual(lc);
  };

  const agregarLoteACompra = (item) => {
    if (productoSeleccionado.id === item.target.value) {
      alert("El lote ya esta en la lista de compra");
      return;
    }
    console.log("lotes comprados: ", lotesCompradosActual);
    const lote = lotesListaCompleta.find((l) => l.id === item.target.value);
    console.log("Lote seleccionado: ", lote);
    let lc = lotesCompradosActual.slice();
    const existe = lc.find((l) => l.id === item.target.value);
    console.log("Existe: ", existe);
    if (existe) {
      alert("El lote ya esta en la lista de compra");
      return;
    }
    lc.push(lote);
    setLotesCompradosActual(lc);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Lotes: ", lotesCompradosActual);

    if (lotesCompradosActual.length > 0) {
      const vectorItemsComprados = listaLotes.map((item) => item.id);
      const vectorItemsCompradosActual = lotesCompradosActual.map(
        (item) => item.id
      );

      console.log(vectorItemsComprados, vectorItemsCompradosActual);
      let vectorEliminar = vectorItemsComprados.filter(
        (item) => !vectorItemsCompradosActual.includes(item)
      );
      let vectorGuardar = vectorItemsCompradosActual.filter(
        (item) => !vectorItemsComprados.includes(item)
      );
      //console.log('Eliminar: ', vectorEliminar, 'Guardar: ', vectorGuardar);

      let eliminar = [];
      let guardar = [];

      if (loteo.id === loteoActual) {
        for (let i = 0; i < lotesListaCompleta.length; i++) {
          if (vectorEliminar.includes(lotesListaCompleta[i].id))
            eliminar.push(lotesListaCompleta[i]);
          if (vectorGuardar.includes(lotesListaCompleta[i].id))
            guardar.push(lotesListaCompleta[i]);
        }
      } else {
        eliminar = listaLotes.slice();
        guardar = lotesCompradosActual.slice();
      }

      console.log("Eliminar: ", eliminar, "Guardar: ", guardar);

      let datos = {
        id: recordForEdit.id,
        lotesEliminar: eliminar,
        lotesGuardar: guardar,
        vendido: true,
        lotesPrimeros: recordForEdit.ReservasItemComprados,
      };

      setGuardando(true);
      addOrEdit(datos, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    } else {
      alert("No hay lotes en la reserva");
      return;
    }
  };

  const DiaMes = (diames) => {
    if (diames < 10) {
      return "0" + diames;
    } else {
      return diames;
    }
  };

  const fa = new Date();
  const fc =
    DiaMes(fa.getDate()) +
    "/" +
    DiaMes(fa.getMonth() + 1) +
    "/" +
    fa.getFullYear();

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      LoteoServices.getAllItems(token).then((res) => {
        console.log("Loteos: ", res);
        setLoteos(res);
      });
      if (recordForEdit != null) {
        setListaLotes(recordForEdit.ReservasItemComprados);
        let lc = [];
        for (let i = 0; i < recordForEdit.ReservasItemComprados.length; i++) {
          lc.push(
            lotesListaCompleta.find(
              (lote) =>
                recordForEdit.ReservasItemComprados[i].LotesxloteoId === lote.id
            )
          );
        }
        console.log(
          "anterior: ",
          recordForEdit.ReservasItemComprados,
          "actual: ",
          lc
        );
        setListaLotes(lc);
        setLotesCompradosActual(lc);
        setValues({
          ...recordForEdit,
        });
      }
    }
  }, [recordForEdit, lotesCompradosActual]); // eslint-disable-line react-hooks/exhaustive-deps

  const initialFValues = {
    id: recordForEdit.id,
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =   // eslint-disable-line
    useForm(initialFValues, true, validate);

  return (
    <Form onSubmit={handleSubmit}>
      {/*------------------------------------ENCABEZADO------------------------------------*/}
      <Grid
        container
        style={{
          borderRadius: 20,
          backgroundColor: "#E6E0FF",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} sm={12}>
          <div>
            <h1>{loteo.nombre}</h1>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ width: "80%", marginLeft: "10%" }}>
            <hr />
          </div>
        </Grid>
        {/*------------------------------------Fecha------------------------------------*/}
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h3>Fecha:</h3>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                }}
              >
                <h3>{fc}</h3>
              </div>
            </Grid>
            {/*------------------------------------Cliente------------------------------------*/}
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h3>Cliente:</h3>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                <h3>
                  {cliente.nombre} {cliente.apellido}
                </h3>
              </div>
            </Grid>

            {/*------------------------------------Vendedor------------------------------------*/}
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h3>Vendedor:</h3>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                <h3>
                  {vendedor.nombre} {vendedor.apellido}
                </h3>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/*------------------------------------Productos------------------------------------*/}
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h3>{lotesCompradosActual.length > 1 ? "Lotes" : "Lote"}:</h3>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                }}
              >
                <Grid container spacing={3}>
                  {lotesCompradosActual.length ? (
                    <>
                      {lotesCompradosActual.map((lote) => {
                        return (
                          <>
                            <Grid
                              xs={3}
                              key={lote.id}
                              style={{ marginTop: 15 }}
                            >
                              <Controls.Button
                                style={{
                                  backgroundColor: "#dddddd",
                                  color: "#222222",
                                  borderRadius: 10,
                                  marginLeft: 5,
                                  //padding: "10%",
                                  textAlign: "right",
                                }}
                                text={`${manzanas[parseInt(lote.manzana)]}-${
                                  lote.nombre
                                }`}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Controls.ActionButton
                                style={{
                                  marginTop: 7,
                                  backgroundColor: "red",
                                  textAlign: "left"
                                }}
                                color="primary"
                                onClick={() => {
                                  eliminarLoteComprado(lote);
                                }}
                              >
                                <CancelRoundedIcon fontSize="small" />
                              </Controls.ActionButton>
                            </Grid>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              {!cambiarLote && (
                <Controls.Button
                  text="Cambiar Lote"
                  color="secondary"
                  onClick={() => setCambiarLote(!cambiarLote)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/*------------------------------------Cambio Lote------------------------------------*/}
      <Grid container>
        {cambiarLote ? (
          <>
            <Grid item xs={6}>
              {lotesCompradosActual.length === 0 && (
                <Grid container>
                  <Grid item xs={12}>
                    <Controls.Select
                      name="loteos"
                      label="Seleccionar Loteo"
                      //value={values.loteAgregadoId || ""}
                      onChange={cambiarLoteo}
                      options={loteos.map((item) => {
                        if (item.Estado)
                          if (item.Estado.esDisponible) {
                            return {
                              id: item.id,
                              title: item.nombre,
                            };
                          } else {
                            return false; // Línea Modificada
                          }
                        return false; // Línea Modificada
                      })}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Controls.Select
                    name="lotesCompradosActual"
                    label="Agregar lote a la compra"
                    //value={values.loteAgregadoId || ""}
                    onChange={agregarLoteACompra}
                    options={lotesListaCompleta.map((item) => {
                      if (item.Estado)
                        if (item.Estado.esDisponible) {
                          return {
                            id: item.id,
                            title: obtenerNombreLote(item),
                          };
                        } else {
                          return false; // Línea Modificada
                        }
                      return false; // Línea Modificada
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Controls.Button
                text="Ocultar"
                color="secondary"
                onClick={() => setCambiarLote(!cambiarLote)}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12}>
        {!guardando ? (
          <div style={{ marginTop: 25, textAlign: "right" }}>
            <Controls.Button type="submit" text="Confirmar" />
          </div>
        ) : (
          <div style={{ margin: 25, textAlign: "right" }}>
            <CircularProgress size={26} />
          </div>
        )}
      </Grid>
    </Form>
  );
}
