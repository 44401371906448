import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";

export default function DetalleReserva(props) {

  const {
    addOrEdit,
    recordForEdit,
    recordFormaPago,
    recordMoneda,
  } = props;

  const initialFValues = {
    obsFinalReserva: "",
    importeDevolucion: 0,
  };

  const propiedad = recordForEdit.PropiedadesVentum;
  const cliente = recordForEdit.Cliente;
  const vendedor = recordForEdit.Usuario;
  const propietario = recordForEdit.PropiedadesVentum.Propietario;
  const comisionGastos = recordForEdit.comisionGastos || 0;
  const reservaId = recordForEdit.id;
  const [guardando, setGuardando] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleInputSoloNumerosChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      e.target.value = onlyNums;
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      e.target.value = number;
    }
    handleInputChange(e);
  };

  //Calcula el importe de reserva
  const importeReserva = () => {
    let res = 0;
    if (recordForEdit.importe1) {
      res = recordForEdit.importe1;
    }
    if (recordForEdit.importe2) {
      res = res + recordForEdit.importe2;
    }
    if (recordForEdit.importe3) {
      res = res + recordForEdit.importe3;
    }
    if (recordForEdit.importe4) {
      res = res + recordForEdit.importe4;
    }
    return res;
  };

  const DiaMes = (diames) => {
    if (diames < 10) {
      return "0" + diames;
    } else {
      return diames;
    }
  };

  const fa = new Date();
  const fc =
    DiaMes(fa.getDate()) +
    "/" +
    DiaMes(fa.getMonth() + 1) +
    "/" +
    fa.getFullYear();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const objData = {
        id: reservaId,
        PropiedadId: recordForEdit.PropiedadesVentumId,
        importeDevolucion: values.importeDevolucion,
        obsFinalReserva: values.obsFinalReserva,
        fechaCancelado: fa,
        cancelado: true,
      }  
      setGuardando(true);
      addOrEdit(objData, resetForm, reservaId).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );  
        }  
      });
      setGuardando(false);
    }  
  };  

    useEffect(() => {
        if (recordForEdit != null) {
          setValues({
            ...recordForEdit,
          });
        }
    }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <Form onSubmit={handleSubmit}>
      {/*------------------------------------ENCABEZADO------------------------------------*/}
      <Grid container style={{ borderRadius: 20, backgroundColor: "#FFA433", textAlign: "center" }} >
        <Grid item xs={12} sm={12} >
          <div>
            <h1>{propiedad.nombre}</h1>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ width: '80%', marginLeft: '10%'}} >
            <hr />
          </div>
        </Grid>
        {/*------------------------------------Fecha------------------------------------*/}
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4} >
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h2>Fecha:</h2>
              </div>
            </Grid>
            <Grid item xs={8} >
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                }}
              >
                <h2>{fc}</h2>
              </div>
            </Grid>
            {/*------------------------------------Vendedor------------------------------------*/}
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h2>Vendedor:</h2>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                <h2>
                  {vendedor.nombre} {vendedor.apellido}
                </h2>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/*------------------------------------Cliente------------------------------------*/}
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h2>Cliente:</h2>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                <h2>
                  {cliente.nombre} {cliente.apellido}
                </h2>
              </div>
            </Grid>
            {/*------------------------------------Propietario------------------------------------*/}
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
                >
                <h2>Propietario:</h2>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
                >
                <h2>
                  {propietario.nombre} {propietario.apellido}
                </h2>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {/*------------------------------------Entrega------------------------------------*/}
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                backgroundColor: "lightblue",
                padding: "10px 20px",
                marginTop: "20px",
                marginBottom: "20px",
                marginRight: "10px",
                border: "1px solid",
                borderRadius: 10,
              }}
            >
              <h2>Entrega: $ {recordForEdit.entrega}</h2>
              <h2>Reserva:</h2>
              {recordForEdit.importe1 ? 
                <h4 style={{ marginLeft: "15%" }}>
                  -{" "}
                  {
                    recordFormaPago.find(
                      (pago) => pago.id === recordForEdit.FormaPagoId1
                    ).nombre
                  }
                  ,{" "}
                  {
                    recordMoneda.find(
                      (moneda) => moneda.id === recordForEdit.MonedaId1
                    ).nombre
                  }
                  : ${recordForEdit.importe1}
                </h4>
                :
                <></>
              }
              {recordForEdit.importe2 ?
                <h4 style={{ marginLeft: "15%" }}>
                  -{" "}
                  {
                    recordFormaPago.find(
                      (pago) => pago.id === recordForEdit.FormaPagoId2
                    ).nombre
                  }
                  ,{" "}
                  {
                    recordMoneda.find(
                      (moneda) => moneda.id === recordForEdit.MonedaId2
                    ).nombre
                  }
                  : ${recordForEdit.importe2}
                </h4>
                :
                <></>
              }
              {recordForEdit.importe3 ?
                <h4 style={{ marginLeft: "15%" }}>
                  -{" "}
                  {
                    recordFormaPago.find(
                      (pago) => pago.id === recordForEdit.FormaPagoId3
                    ).nombre
                  }
                  ,{" "}
                  {
                    recordMoneda.find(
                      (moneda) => moneda.id === recordForEdit.MonedaId3
                    ).nombre
                  }
                  : ${recordForEdit.importe3}
                </h4>
              :
              <></>
              }
              {recordForEdit.importe4 ?
                <h4 style={{ marginLeft: "15%" }}>
                  -{" "}
                  {
                    recordFormaPago.find(
                      (pago) => pago.id === recordForEdit.FormaPagoId4
                    ).nombre
                  }
                  ,{" "}
                  {
                    recordMoneda.find(
                      (moneda) => moneda.id === recordForEdit.MonedaId4
                    ).nombre
                  }
                  : ${recordForEdit.importe4}
                </h4>
                :
                <></>
              }
              {recordForEdit.totalSeniaFinalReserva ? 
                <h4 style={{ marginLeft: "15%" }}>
                -{" "}
                  TOTAL ENTREGADO POR RESERVA: $ {recordForEdit.totalSeniaFinalReserva}
              </h4>
              :
              <></>            
              }
              {recordForEdit.totalEntregadoFinalReserva ?
                <h4 style={{ marginLeft: "15%" }}>
                -{" "}
                  ENTREGA TOTAL: $ {recordForEdit.totalEntregadoFinalReserva}
              </h4>            
              :
              <></>
              }
              {comisionGastos ? 
                <h4 style={{ marginLeft: "15%" }}>
                -{" "}
                  COMISION Y GASTOS ADMINISTRATIVOS: $ {comisionGastos}
              </h4>         
              :
              <></>   
              }
              <Controls.Input
                name="importeDevolucion"
                label="Importe de devolución"
                value={values.importeDevolucion}
                onChange={handleInputSoloNumerosChange}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                width: "100%",
                border: "1px solid",
                borderRadius: 10,
                padding: "10px 20px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <h3>Observaciones: </h3>
              <p>{recordForEdit.observaciones}</p>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <hr />
        </Grid>
        {/*----------------------------------------Detalles de pago--------------------------------------*/}
        <Grid container>
          <>
            <Grid item xs={6} sm={6} style={{ borderRadius: 20, backgroundColor: "lightblue", marginBottom: 10 }} >
              <div style={{ paddingRight: "10px", marginTop: "10px", }} >
                {recordForEdit.cantidadCuotas === 1 ? (
                  <>
                    <h3 style={{ textAlign: "left", paddingLeft: "10px" }}>
                      Operación realizada de Contado
                    </h3>
                    <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                    - Precio de la Propiedad: ${recordForEdit.PropiedadesVentum.precio}
                    </h4>
                    <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                      - Entrega: ${importeReserva()}
                    </h4>
                    <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                      - Total: ${importeReserva()}
                    </h4>
                  </>
                ) : (
                  <>
                    <h3 style={{ textAlign: "left", paddingLeft: "10px" }}>
                      Operación realizada en {recordForEdit.cantidadCuotas} cuotas
                    </h3>
                    <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                      - Precio de la Propiedad: ${recordForEdit.PropiedadesVentum.precio}
                    </h4>
                    <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                      - Entrega: ${importeReserva()}
                    </h4>
                    {recordForEdit.cantidadCuotas === 12 && (
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 1-12: ${recordForEdit.importeAnio1}
                      </h4>
                    )}
                    {recordForEdit.cantidadCuotas === 24 && (
                      <>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 1-12: ${recordForEdit.importeAnio1}
                        </h4>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 13-24: ${recordForEdit.importeAnio2}
                        </h4>
                      </>
                    )}
                    {recordForEdit.cantidadCuotas === 36 && (
                      <>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 1-12: ${recordForEdit.importeAnio1}
                        </h4>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 13-24: ${recordForEdit.importeAnio2}
                        </h4>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 24-36: ${recordForEdit.importeAnio3}
                        </h4>
                      </>
                    )}
                    {recordForEdit.cantidadCuotas === 48 && (
                      <>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 1-12: ${recordForEdit.importeAnio1}
                        </h4>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 13-24: ${recordForEdit.importeAnio2}
                        </h4>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 25-36: ${recordForEdit.importeAnio3}
                        </h4>
                        <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                          - Cuotas 37-48: ${recordForEdit.importeAnio4}
                        </h4>
                      </>
                    )}
                  </>
                )}
              </div>
            </Grid>
          </>
        <Grid item xs={6} sm={6}>
          <Controls.Input
            name="obsFinalReserva"
            label="Observaciones Finales"
            value={values.obsFinalReserva}
            onChange={handleInputChange}
            variant="outlined"
            multiline
          />
        </Grid>
      </Grid>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginTop: 25, textAlign: "right" }}>
              <Controls.Button type="submit" text="Confirmar" />
              <Controls.Button
                text="Cancelar"
                color="default"
              />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
