import React from "react";

import {
  Grid,
  Paper,
} from "@material-ui/core";

export default function ChequeForm(props) {
  const {
    recordForEdit,
  } = props;
  console.log('En chequeVisor: ', recordForEdit)
  return (
    <Paper elevation={0}>
      <Grid container>
        {recordForEdit && recordForEdit.id ? (
          <Grid item xs={4}>
            <h3 style={{ marginLeft: 10 }}>
              CLIENTE: {recordForEdit.Cliente.nombre} {recordForEdit.Cliente.apellido}
            </h3>
          </Grid>
        ) : (
          <>
          </>
        )}
        <Grid item xs={4}>
          <h3>Librador: {recordForEdit.librador}</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Destinatario: {recordForEdit.destinatario}</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Número de Cheque: {recordForEdit.numeroCheque}</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Banco: {recordForEdit.BancoSucursal.Banco.nombre}</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Sucursal: {recordForEdit.BancoSucursal.nombre}</h3>
        </Grid>
        <Grid item xs={3}>
          <h3>Emisión: {recordForEdit.fechaEmision}</h3>
        </Grid>
        <Grid item xs={3}>
          <h3>Pago: {recordForEdit.fechaPago}</h3>
        </Grid>
        <Grid item xs={3}>
          <h3>Importe: {recordForEdit.importe}</h3>
        </Grid>
        <Grid item xs={3}>
          <h3>Moneda: {recordForEdit.Moneda.nombre}</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Tipo de Cheque: {recordForEdit.tipoCheque}</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Endosado: {recordForEdit.endosado ? "SI" : "NO"}</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Comprobante de Pago: {recordForEdit.comprobante}</h3>
        </Grid>
        <Grid item xs={12}>
          <h3>Observaciones: {recordForEdit.observaciones}</h3>
        </Grid>
      </Grid>
    </Paper>
  );
}
