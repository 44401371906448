import React, { useState, useEffect } from "react";

import * as LocalidadServices from "../../services/ubicacion/localidad";
import * as PaisServices from "../../services/ubicacion/pais";
import * as ProvinciaServices from "../../services/ubicacion/provincia";

import { useUserState } from "../../context/UserContext";

import LocalidadForm from "./LocalidadForm";

import { CircularProgress } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, } from "@material-ui/core";

import Notification from "../../components/tablas/Notification";
import PageHeader from "../../components/tablas/PageHeader";
import Popup from "../../components/tablas/Popup";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";

import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Search } from "@material-ui/icons";

const cabecera = [
  { id: "nombre", label: "Localidad" },
  { id: "codigoPostal", label: "CP" },
  { id: "ProvinciumId", label: "Provincia" },
  { id: "Pais", label: "País" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function Localidad() {

  const { token } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => { return items; }, });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });

  const [registros, setRegistros] = useState({});
  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});

  const classes = useStyles();
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistros(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
    }
  }, [cargando, registros, registroPaises]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopup = (item) => {
    item.PaiId = item.Provincium.PaiId;
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();

    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm) => {
    let existe = registros.find((item) => item.nombre === registroItem.nombre.toUpperCase());
    if (registroItem.id === 0) {
      if (existe === undefined || !(existe.nombre === registroItem.nombre.toUpperCase() && existe.ProvinciumId === registroItem.ProvinciumId)) {
        try {
          await LocalidadServices.insert(registroItem, token);
        } catch (err) {
          setNotify({
            isOpen: true,
            message: "No se pudo completar la operación, revise los datos e intente nuevamente",
            type: "error",
          });
          return false;
        }
      } else {
        setNotify({
          isOpen: true,
          message: "No se pudo completar la operación, la localidad ya existe en esta provincia",
          type: "error",
        });
        return false;
      }
    } else {
      if (!(existe.nombre === registroItem.nombre.toUpperCase() && existe.ProvinciumId === registroItem.ProvinciumId) || registroItem.codigoPostal !== existe.codigoPostal) {
        try {
          await LocalidadServices.update(registroItem, token);
        } catch (err) {
          setNotify({
            isOpen: true,
            message: "No se pudo completar la operación, revise los datos e intente nuevamente",
            type: "error",
          });
          return false;
        }
      } else {
        setNotify({
          isOpen: true,
          message: "No se pudo completar la operación, la localidad ya existe en esta provincia",
          type: "error",
        });
        return false;
      }
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    setRegistros(LocalidadServices.getAllItems(token));
  };

  return (
    <>
      <PageHeader
        title="LOCALIDADES"
        subTitle="Gestión de localidades"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text="Agregar"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <>
                  {item.nombre ? (
                    <TableRow key={item.id}>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.nombre}{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.codigoPostal}{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.Provincium.nombre}{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {registroPaises.length ? (
                          <>
                            {
                              registroPaises.find(
                                (itemPais) =>
                                  itemPais.id === item.Provincium.PaiId
                              ).nombre
                            }{" "}
                          </>
                        ) : (
                          <> No cargado</>
                        )}
                      </TableCell>
                      <TableCell>
                        <Controls.ActionButton
                          color="primary"
                          onClick={() => {
                            openInPopup(item);
                          }}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </Controls.ActionButton>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="Gestión localidades"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <LocalidadForm
          listaProvincias={registroProvincias}
          listaPaises={registroPaises}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
