import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
} from "@material-ui/core";

import { useUserState } from "../../context/UserContext";

import * as FormaPagoServices from "../../services/venta/formaPago";
import * as MonedaServices from "../../services/venta/moneda";
import * as BancoSucursalServices from "../../services/contable/bancoSucursal";
import * as BancoServices from "../../services/contable/banco";
import * as ChequeServices from "../../services/contable/cheque";
import * as TransferenciaServices from "../../services/contable/transferencia";
import * as TarjetaCreditoServices from "../../services/contable/tarjetaCredito";
import * as CajaGeneralServices from "../../services/caja/cajaGeneral";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import Popup from "../../components/tablas/Popup";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";

import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ListIcon from "@material-ui/icons/List";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Tarjeta from "../utiles/Tarjeta";
import ChequeVisor from "../contable/ChequeVisor";
import TransferenciaVisor from "../contable/TransferenciaVisor";
import TarjetaVisor from "../contable/TarjetaVisor";
import EfectivoVisor from "../contable/EfectivoVisor";
import IngresoEgresoVisor from "../contable/IngresoEgresoVisor";

const cabecera = [
  { id: "persona", label: "Persona" },
  { id: "ingreso", label: "Ingreso" },
  { id: "egreso", label: "Egreso" },
  { id: "fecha", label: "Fecha" },
  { id: "transaccion", label: "Transacción" },
  { id: "origenId", label: "Origen" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#FFFEFC",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function NotificacionIngresoEgreso() {
  const { token } = useUserState();
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ // eslint-disable-line
    fn: (items) => {
      return items;
    },
  });
  const [openPopupCheque, setOpenPopupCheque] = useState(false);
  const [openPopupTransferencia, setOpenPopupTransferencia] = useState(false);
  const [openPopupTarjeta, setOpenPopupTarjeta] = useState(false);
  const [openPopupEfectivo, setOpenPopupEfectivo] = useState(false);
  const [openPopupIngresoEgreso, setOpenPopupIngresoEgreso] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [formasPago, setFormasPago] = useState({});
  const [monedas, setMonedas] = useState({});
  const [bancos, setBancos] = useState({});
  const [sucursales, setSucursales] = useState({});
  const [registros, setRegistros] = useState({});
  const [cheques, setCheques] = useState({});
  const [transferencias, setTransferencias] = useState({});
  const [tarjetas, setTarjetas] = useState({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  let fecha = new Date();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      FormaPagoServices.getAllItems(token).then((res) => {
        setFormasPago(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setMonedas(res);
      });
      TarjetaCreditoServices.getAllItems(token).then((res) => {
        setTarjetas(res);
      });
      BancoSucursalServices.getAllItems(token).then((res) => {
        setSucursales(res);
      });
      BancoServices.getAllItems(token).then((res) => {
        setBancos(res);
      });
      ChequeServices.noProcesado(token).then((res) => {
        setCheques(res);
      });
      TransferenciaServices.noProcesado(token).then((res) => {
        setTransferencias(res);
      });
      CajaGeneralServices.getNoProcesado(token).then((res) => {
        console.log("Caja General: ", res);
        setRegistros(res);
      });
    }
  }, [cargando]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopupVisor = (item) => {
    console.log('Item en visor: ', item);
    if (item.ChequeId) {
      setOpenPopupCheque(true);
      setRecordForEdit(cheques.find((cheque) => item.ChequeId === cheque.id));
    } else if (item.TransferenciumId) {
      setOpenPopupTransferencia(true);
      setRecordForEdit(transferencias.find((transferencia) => item.TransferenciumId === transferencia.id));
    } else if (item.FormaPago && item.FormaPago.esTarjeta) {
      setOpenPopupTarjeta(true);
      setRecordForEdit(item);
    } else if (item.ingreso) {
      console.log('Ingreso: ', item.Origen)
      setOpenPopupEfectivo(true);
      setRecordForEdit(item);
    } else {
      console.log('Egreso: ', item.Origen)
      setOpenPopupIngresoEgreso(true);
      setRecordForEdit(item);
    }
  };

  const procesar = async (id) => {
    let data = {
      procesado: true,
      fechaProcesado: fecha,
    };
    console.log('Para procesar: ', data);
    await CajaGeneralServices.procesar(data, id, token);
    await CajaGeneralServices.getNoProcesado(token).then((res) => {
      setRegistros(res);
    });
  };

  const addOrEdit = async (registroItem, resetForm) => {
    if (registroItem.id === 0) {
      try {
        await BancoSucursalServices.insert(registroItem, token);
      } catch (err) {
        return false;
      }
    } else {
      try {
        await BancoSucursalServices.update(registroItem, token);
      } catch (err) {
        return false;
      }
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopupCheque(false);
    setOpenPopupTransferencia(false);
    setOpenPopupTarjeta(false);
    setOpenPopupEfectivo(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    setSucursales(BancoSucursalServices.getAllItems(token));
  };

  return (
    <>
      {registros && registros.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="NOTIFICACIÓN CONTABLE"
                icon={
                  <ListIcon
                    style={{ fontSize: 40, color: "green", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={registros}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#FFFEFC" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <h2>
                        <ListIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                        NOTIFICACIÓN CONTABLE{" "}
                        <ListIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                      </h2>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: 10 }}>
                      <Controls.Button
                        text="Ocultar"
                        onClick={() => setVisible(!visible)}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.ClienteId
                            ? "CLIENTE: " +
                              item.Cliente.nombre +
                              " " +
                              item.Cliente.apellido
                            : item.AsociadoId
                            ? "ASOCIADO: " +
                              item.Asociado.nombre +
                              " " +
                              item.Asociado.apellido
                            : item.UsuarioId
                            ? "USUARIO: " +
                              item.Usuario.nombre +
                              " " +
                              item.Usuario.apellido
                            : item.PropietarioId
                            ? "PROPIETARIO: " +
                              item.Propietario.nombre +
                              " " +
                              item.Propietario.apellido
                            : ""}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          ${new Intl.NumberFormat().format(item.ingreso)}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          ${new Intl.NumberFormat().format(item.egreso)}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.createdAt.toString().split("T")[0] + " " + item.createdAt.toString().split("T")[1].split(".")[0]}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.FormaPagoId ? item.FormaPago.nombre + " " + item.Moneda.nombre : ""}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.OrigenId ? item.Origen.nombre : ""}
                        </TableCell>
                        <TableCell>
                          <Controls.ActionButton
                            color="primary"
                            style={{ backgroundColor: "#FFA114" }}
                            onClick={() => {
                              openInPopupVisor(item);
                            }}>
                            <VisibilityIcon fontSize="small" />
                          </Controls.ActionButton>
                          <Controls.ActionButton
                            color="primary"
                            style={{ backgroundColor: "#121ADE" }}
                            onClick={() => {
                              procesar(item.id);
                            }}
                          >
                            <CheckIcon fontSize="small" />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Popup
        title="Visor de Cheques"
        openPopup={openPopupCheque}
        setOpenPopup={setOpenPopupCheque}
      >
        <ChequeVisor
          recordForEdit={recordForEdit}
        />
      </Popup>
      <Popup
        title="Visor de Transacciones Bancarias"
        openPopup={openPopupTransferencia}
        setOpenPopup={setOpenPopupTransferencia}
      >
        <TransferenciaVisor
          recordForEdit={recordForEdit}
        />
      </Popup>
      <Popup
        title="Visor de Pago con Tarjeta"
        openPopup={openPopupTarjeta}
        setOpenPopup={setOpenPopupTarjeta}
      >
        <TarjetaVisor
          listaTarjetas={tarjetas}
          recordForEdit={recordForEdit}
        />
      </Popup>
      <Popup
        title="Visor de Pago Efectivo"
        openPopup={openPopupEfectivo}
        setOpenPopup={setOpenPopupEfectivo}
      >
        <EfectivoVisor
          recordForEdit={recordForEdit}
        />
      </Popup>
      <Popup
        title="Visor de Ingreso - Egreso General"
        openPopup={openPopupIngresoEgreso}
        setOpenPopup={setOpenPopupIngresoEgreso}
      >
        <IngresoEgresoVisor
          recordForEdit={recordForEdit}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
