import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import * as AsociadosXLote from "../../services/producto/asociadosXLote";
import * as UsuarioServices from "../../services/usuario/usuario";

export default function DetalleReserva(props) {
  const {
    token,
    addOrEdit,
    loteo,
    recordForEdit,
    closeForm,
    listaUsuarios,
  } = props;

  const initialFValues = {
    id: 0,
    nombre: "",
    linkDocumentacion: "",
    precio: 0,
    precioWeb: 0,
    superficieTotal: 0,
    observaciones: "",
    EstadoId: 0,
    manzana: 0,
    amojonado: false,
  };

  const [guardando, setGuardando] = React.useState(false);
  const [registroUsuarios, setRegistroUsuarios] = React.useState({});
  const [cargando, setCargando] = useState(false);
  const [registros, setRegistros] = useState({});
  const [soloSocios, setSoloSocios] = useState({});
  const [cantidad, setCantidad] = useState(0);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [comisiones, setComisiones] = React.useState([]);
  const [aporteSocios, setAporteSocios] = useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleInputComisionSoloNumerosChange = (e, usuarioId) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      e.target.value = onlyNums;
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      e.target.value = number;
    }
    let c = comisiones;
    c[usuarioId] = e.target.value;
    setComisiones(c);
    cambiaComisionUsuario(e.target.value, usuarioId);
  };

  const agregarUsuario = () => {
    if (usuarioSeleccionado) {
      let tempUsuarios = usuarios;
      const existe = tempUsuarios.find((s) => s.id === usuarioSeleccionado);
      if (!existe) {
        tempUsuarios.push({
          id: usuarioSeleccionado,
          comision: 0,
        });
      } else {
        alert("Ya esta ingresado el usuario seleccionado");
      }
      setUsuarios(tempUsuarios);
      validate();
    } else {
      alert("Seleccione un socio");
    }
  };

  const eliminarUsuario = (id) => {
    let tempUsuarios = usuarios;
    const ss = tempUsuarios.filter((s) => s.id !== id);
    setUsuarios(ss);
  };

  const obtenerUsuario = (id) => {
    let tempUsuarios = listaUsuarios;
    const ss = tempUsuarios.find((s) => s.id === id);
    if (ss) {
      return ss.apellido + " " + ss.nombre;
    }
  };

  const cambiaComisionUsuario = (value, usuarioId) => {
    let tempUsuarios = usuarios;
    tempUsuarios.forEach((s) => {
      if (s.id === usuarioId) {
        s.comision = value;
      }
    });
    setUsuarios(tempUsuarios);
    validate();
  };

  let entrega = 0;
  let totalComisiones = 0;
  let ganancias = 0;

  const sumaAporteSocios = (socios) => {
    let resultado = 0;
    let i = 0;
    for (i = 0; i < socios.length; i++) {
      resultado = resultado + parseInt(socios[i]);
    }
    return resultado;
  };

  const handleInputChangeAporte = (e) => {
    let aportes = aporteSocios.slice();
    aportes[e.target.name] = e.target.value;
    setAporteSocios(aportes);
  }

  const indiceGrid = (cantidad) => {
    return 12 / cantidad;
  };

  //Calcula el importe de reserva
  const importeReserva = () => {
    let res = 0;
    if (recordForEdit.importe1) {
      res = recordForEdit.importe1;
    }
    if (recordForEdit.importe2) {
      res = res + recordForEdit.importe2;
    }
    if (recordForEdit.importe3) {
      res = res + recordForEdit.importe3;
    }
    if (recordForEdit.importe4) {
      res = res + recordForEdit.importe4;
    }
    return res;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.nombre) {
      alert("Por favor complete el NOMBRE del lote");
      return false;
    }
    if (!values.EstadoId) {
      alert("Por favor seleccione el estado de la propiedad");
      return false;
    }
    if (values.manzana === undefined) {
      alert("Por favor seleccione la manzana");
      return false;
    }
    if (validate()) {
      values.LoteoId = loteo.id;
      setGuardando(true);
      addOrEdit(values, resetForm, values.id).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
      setGuardando(false);
    }
  };

  const DiaMes = (diames) => {
    if (diames < 10) {
      return "0" + diames;
    }
  };

  const fa = new Date();
  const fc =
    DiaMes(fa.getDate()) +
    "/" +
    DiaMes(fa.getMonth() + 1) +
    "/" +
    fa.getFullYear();

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      AsociadosXLote.getAllItems(token).then((res) => {
        setRegistros(res);
        setSoloSocios(
          res.filter(
            (socio) => socio.Lotesxloteo.id === recordForEdit.Lotesxloteo.id
          )
        );
        setCantidad(
          res.filter(
            (socio) => socio.Lotesxloteo.id === recordForEdit.Lotesxloteo.id
          ).length
        );
      });
      UsuarioServices.getAllItems(token).then((res) => {
        setRegistroUsuarios(res);
      });
      if (recordForEdit != null) {
        setValues({
          ...recordForEdit,
        });
      }
    }
  }, [recordForEdit, soloSocios, registros, cantidad, registroUsuarios, totalComisiones, ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      {/*------------------------------------ENCABEZADO------------------------------------*/}
      <Grid container>
        <Grid item xs={12} sm={12}>
          <h1>{recordForEdit.Lotesxloteo.Loteo.nombre}</h1>
        </Grid>

        {/*------------------------------------Fecha------------------------------------*/}
        <Grid item xs={12} sm={2}>
          <div
            style={{
              border: "1px solid",
              textAlign: "right",
              paddingRight: "10px",
              marginLeft: "20px",
              marginBottom: "5px",
            }}
          >
            <h3>Fecha:</h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div
            style={{
              border: "1px solid",
              textAlign: "right",
              paddingRight: "10px",
            }}
          >
            <h3>{fc}</h3>
          </div>
        </Grid>

        {/*------------------------------------Cliente------------------------------------*/}
        <Grid item xs={12} sm={3}>
          <div
            style={{
              border: "1px solid",
              textAlign: "right",
              paddingRight: "10px",
              marginLeft: "100px",
            }}
          >
            <h3>Cliente:</h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <div
            style={{
              border: "1px solid",
              textAlign: "left",
              paddingLeft: "10px",
            }}
          >
            <h3>
              {recordForEdit.Cliente.nombre} {recordForEdit.Cliente.apellido}
            </h3>
          </div>
        </Grid>

        {/*------------------------------------Lote------------------------------------*/}
        <Grid item xs={12} sm={2}>
          <div
            style={{
              border: "1px solid",
              textAlign: "right",
              paddingRight: "10px",
              marginLeft: "20px",
              marginBottom: "5px",
            }}
          >
            <h3>Lote:</h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div
            style={{
              border: "1px solid",
              textAlign: "right",
              paddingRight: "10px",
            }}
          >
            <h3>{recordForEdit.Lotesxloteo.nombre}</h3>
          </div>
        </Grid>

        {/*------------------------------------Vendedor------------------------------------*/}
        <Grid item xs={12} sm={3}>
          <div
            style={{
              border: "1px solid",
              textAlign: "right",
              paddingRight: "10px",
              marginLeft: "100px",
            }}
          >
            <h3>Vendedor:</h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <div
            style={{
              border: "1px solid",
              textAlign: "left",
              paddingLeft: "10px",
            }}
          >
            <h3>
              {recordForEdit.Usuario.nombre} {recordForEdit.Usuario.apellido}
            </h3>
          </div>
        </Grid>

        {/*------------------------------------Manzana------------------------------------*/}
        <Grid item xs={12} sm={2}>
          <div
            style={{
              border: "1px solid",
              textAlign: "right",
              paddingRight: "10px",
              marginLeft: "20px",
              marginBottom: "5px",
            }}
          >
            <h3>Manzana:</h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div
            style={{
              border: "1px solid",
              textAlign: "right",
              paddingRight: "10px",
            }}
          >
            <h3>{recordForEdit.Lotesxloteo.manzana}</h3>
          </div>
        </Grid>

        {/*------------------------------------Entrega------------------------------------*/}
        <Grid item xs={12} sm={12}>
          <div
            style={{
              marginLeft: "40%",
              backgroundColor: "lightblue",
              padding: "10px 20px",
            }}
          >
            <h2>Entrega: {recordForEdit.entrega}</h2>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ height: "200%" }}></div>
        </Grid>

        {/*------------------------------------Socios------------------------------------*/}
        {soloSocios.length > 0 ? (
          <>
            {soloSocios.map((socio, index) => (
              <>
                <div style={{ color: "transparent", width: "0" }}>
                  {index === 0 &&
                    (entrega =
                      (recordForEdit.entrega / 100) * socio.porcentaje)}
                </div>
                <Grid item xs={12} sm={indiceGrid(soloSocios.length) - 1}>
                  <div style={{ textAlign: "center" }}>
                    <h2>{socio.Asociado.nombre}</h2>
                    {/*<h4>{socio.porcentaje}%</h4>*/}
                    <h3>{(recordForEdit.entrega / 100) * socio.porcentaje}</h3>
                    <Controls.Input
                      name={index}
                      label="Aporte Comisión"
                       value={values.aporteComision}
                      onChange={handleInputChangeAporte}
                      variant="outlined"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
              </>
            ))}
          </>
        ) : (
          <div>No hay socios asignados a este lote</div>
        )}
        <Grid item xs={12} sm={12}>
          <hr />
        </Grid>

        {/*--------------------------------------DETALLE------------------------------------------- */}
        <Grid
          item
          xs={12}
          sm={5}
          style={{
            marginRight: "5%",
            marginLeft: "5%",
          }}
        >
          <Grid item xs={12} sm={12}>
            <div
              style={{
                border: "1px solid",
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <h2>Detalle</h2>
            </div>
          </Grid>

          {/*------------------------------------Entrega del Lote------------------------------------*/}
          <Grid container>
            <Grid item xs={6} sm={5}>
              <div
                style={{
                  border: "1px solid",
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>${entrega}</h3>
              </div>
            </Grid>
            <Grid item xs={6} sm={7}>
              <div
                style={{
                  border: "1px solid",
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>Entrega del Lote</h3>
              </div>
            </Grid>
          </Grid>

          {/*------------------------------------Comisión y Gastos Administrativos------------------------------------*/}
          <Grid container>
            <Grid item xs={6} sm={5}>
              <div
                style={{
                  border: "1px solid",
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>${parseInt(recordForEdit.comisionGastos)}</h3>
              </div>
            </Grid>
            <Grid item xs={6} sm={7}>
              <div
                style={{
                  border: "1px solid",
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>Comision y Gastos</h3>
              </div>
            </Grid>
          </Grid>

          {/*------------------------------------Aporte de Socios------------------------------------*/}
          <Grid container>
            <Grid item xs={6} sm={5}>
              <div
                style={{
                  border: "1px solid",
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>${sumaAporteSocios(aporteSocios)}</h3>
              </div>
            </Grid>
            <Grid item xs={6} sm={7}>
              <div
                style={{
                  border: "1px solid",
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>Aporte de Socios</h3>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/*----------------------------------------------------COMISIONES-------------------------------------*/}
        <Grid item xs={12} sm={5}>
          <Grid item xs={12} sm={12}>
            <div
              style={{
                border: "1px solid",
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <h2>Comisiones</h2>
            </div>
          </Grid>

          {/*------------------------------------Selector de Usuarios------------------------------------*/}
          <Grid container>
            <Grid item xs={12} sm={10}>
              <Controls.Select
                name="UsuarioId"
                label="Usuario"
                value={usuarioSeleccionado}
                onChange={(item) => {
                  setUsuarioSeleccionado(item.target.value);
                }}
                options={listaUsuarios.map((item) => {
                  return {
                    id: item.id,
                    title: item.apellido + " " + item.nombre,
                  };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.Button
                text="+"
                color="default"
                onClick={() => agregarUsuario()}
              />
            </Grid>
          </Grid>

          {/*------------------------------------Seleccionados------------------------------------*/}
          {usuarios.length > 0 ? (
          <>
            <div style={{height:"5%"}}></div>
            {usuarios.map((user) => {
              return (
                <>
                <Grid container>
                  <Grid item xs={12} sm={5}>
                    <h3
                      style={{
                        background: "lightGray",
                        padding: 5,
                        borderRadius: 10,
                        marginBottom: 13,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {obtenerUsuario(user.id)}
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Controls.Input
                      label="Comision"
                      value={comisiones[user.id]}
                      name={user.id}
                      variant="outlined"
                      onChange={(e) => {
                        handleInputComisionSoloNumerosChange(e, user.id);
                      }}
                    />
                    <div style={{color: "transparent"}}>
                    {totalComisiones = totalComisiones + parseInt(comisiones[user.id])}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Controls.Button
                      text="-"
                      color="secondary"
                      onClick={() => eliminarUsuario(user.id)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}></Grid>
                  </Grid>
                </>
              );
            })}
          </>
        ) : (
          <></>
        )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{padding: 20}}>
            <hr />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          
          {/*------------------------------------Total Comisiones------------------------------------*/}
          <Grid container>
          <Grid item xs={12} sm={5}>
            <div
              style={{
                border: "1px solid",
                textAlign: "right",
                paddingRight: "10px",
                marginBottom: "5px"
              }}
            >
              <h3>Total Comisiones</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div
              style={{
                border: "1px solid",
                textAlign: "right",
                paddingRight: "10px",
                marginBottom: "5px"
               }}
            >
              <h3>{totalComisiones}</h3>
            </div>
          </Grid>
        </Grid>

        {/*----------------------------------------------------GANANCIAS-------------------------------------*/}
        <Grid container>
          <Grid item xs={12} sm={5}>
            <div
              style={{
                border: "1px solid",
                textAlign: "right",
                paddingRight: "10px",
                marginBottom: "5px"
               }}
            >
              <h3>Ganancias</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div
              style={{
                border: "1px solid",
                textAlign: "right",
                paddingRight: "10px",
                marginBottom: "5px"
               }}
            >
              <h3>
                {
                  (ganancias = parseInt(entrega) + parseInt(recordForEdit.comisionGastos) + sumaAporteSocios(aporteSocios) - parseInt(totalComisiones))
                }
              </h3>
            </div>
          </Grid>
        </Grid>

        {/*----------------------------------------------------Reserva-------------------------------------*/}
        <Grid container>
          <Grid item xs={12} sm={5}>
            <div
              style={{
                border: "1px solid",
                textAlign: "right",
                paddingRight: "10px",
                marginBottom:"5px"
              }}
            >
              <h3>Reserva</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div
              style={{
                border: "1px solid",
                textAlign: "right",
                paddingRight: "10px",
                marginBottom:"5px"
              }}
            >
              <h3>{importeReserva()}</h3>
            </div>
          </Grid>
        </Grid>

        {/*----------------------------------------------------Dinero Disponible-------------------------------------*/}
        <Grid container>
          <Grid item xs={12} sm={5}>
            <div
              style={{
                border: "1px solid",
                textAlign: "right",
                paddingRight: "10px",
                marginBottom:"5px"
              }}
            >
              <h3>Dinero Disponible</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div
              style={{
                border: "1px solid",
                textAlign: "right",
                paddingRight: "10px",
                marginBottom:"5px"
              }}
            >
              <h3>{ganancias - importeReserva()}</h3>
            </div>
          </Grid>
        </Grid>
        </Grid>

        {/*----------------------------------------Detalles de pago--------------------------------------*/}
        {recordForEdit.cantidadCuotas === 1 ? (
          <>
            <Grid item xs={6} sm={6}>
              <div
                style={{
                  border: "1px solid",
                  paddingRight: "10px",
                }}
              >
                <h3 style={{textAlign:"left", paddingLeft:"10px"}}>Operación realizada de Contado</h3>
                <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Precio del Lote: ${recordForEdit.Lotesxloteo.precio}</h4>
                <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Entrega: ${importeReserva()}</h4>
                <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Total: ${importeReserva()}</h4>
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6} sm={6}>
              <div
                style={{
                  border: "1px solid",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                <h3 style={{textAlign:"left", paddingLeft:"10px"}}>
                  Operación realizada en {recordForEdit.cantidadCuotas} cuotas
                </h3>
                <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Precio del Lote: ${recordForEdit.Lotesxloteo.precio}</h4>
                <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Entrega: ${importeReserva()}</h4>
                {recordForEdit.cantidadCuotas === 12 && (
                  <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 1-12: ${recordForEdit.importeAnio1}</h4>
                )}
                {recordForEdit.cantidadCuotas === 24 && (
                  <>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 1-12: ${recordForEdit.importeAnio1}</h4>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 13-24: ${recordForEdit.importeAnio2}</h4>
                  </>
                )}
                {recordForEdit.cantidadCuotas === 36 && (
                  <>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 1-12: ${recordForEdit.importeAnio1}</h4>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 13-24: ${recordForEdit.importeAnio2}</h4>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 24-36: ${recordForEdit.importeAnio3}</h4>
                  </>
                )}
                {recordForEdit.cantidadCuotas === 48 && (
                  <>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 1-12: ${recordForEdit.importeAnio1}</h4>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 13-24: ${recordForEdit.importeAnio2}</h4>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 25-36: ${recordForEdit.importeAnio3}</h4>
                    <h4 style={{textAlign:"left", paddingLeft:"30%"}}>- Cuotas 37-48: ${recordForEdit.importeAnio4}</h4>
                  </>
                )}
              </div>
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={12} style={{marginTop:"20px"}}>
          <Controls.Input
            name="observaciones"
            helperText="Observaciones"
            value={values.observaciones}
            onChange={handleInputChange}
            multiline
            rows={4}
            rowsMax={4}
            error={errors.descripcion}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <hr />
        </Grid>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginTop: 25, textAlign: "right" }}>
              <Controls.Button type="submit" text="Guardar" />
              <Controls.Button
                text="Cancelar"
                color="default"
                onClick={closeForm}
              />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
