import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';

export default function Barra(props) {

  const { vendedores, asignados, propios } = props;
  //console.log('Datos a tabla: ', vendedores, asignados, propios);
  const data = {
    labels: vendedores,
    datasets: [
      {
        label: 'Asignados',
        data: asignados,
        borderColor: "green",
        backgroundColor: "red",
      },
      {
        label: 'Propios',
        data: propios,
        borderColor: "red",
        backgroundColor: "green",
      }
    ]
  };

  return (
    <Grid container >
      <Grid item xs={12} >
        <Bar
          data={data}
          width={50}
          height={25}
        />
      </Grid>
    </Grid>
  );
}
