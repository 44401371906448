import React, { useEffect, useState } from "react";

import {
  Grid,
} from "@material-ui/core";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import { useUserState } from "../../context/UserContext";

export default function ChequeForm(props) {
  const {
    addOrEdit,
    recordForEdit,
    listaTarjetas,
    listaMonedas,
    cliente,
    numero,
  } = props;

  console.log('Props: ', props);
  const { userId } = useUserState();

  const [guardando, setGuardando] = useState(false);

  const initialFValues = {
    id: 0,
    importe: 0,
    linkComprobante: "",
    ClienteId: "",
    MonedaId: "",
    TarjetaCreditoId: "",
    numero: 0,
};
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("importe" in fieldValues)
    temp.importe = fieldValues.importe ? "" : "Dato requerido";
    
    if ("MonedaId" in fieldValues)
    temp.MonedaId = fieldValues.MonedaId ? "" : "Dato requerido";
    
    if ("TarjetaCreditoId" in fieldValues)
      temp.TarjetaCreditoId = fieldValues.TarjetaCreditoId ? "" : "Dato requerido";
    
    setErrors({
      ...temp,
    });
    
    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const Guardar = () => {
    //e.preventDefault();
    values.ClienteId = cliente.id; 
    values.numero = numero;
    values.UsuarioId = userId
    values.importe = parseFloat(values.importe)
    console.log('En efectivo contenido: ', values);
    if (validate()) {
      setGuardando(true);
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    values.ClienteId = cliente.id; 
    values.numero = numero;
    values.UsuarioId = userId
    console.log('En chequeform: ', values);
    if (validate()) {
      setGuardando(true);
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={3}>
          <Controls.Input
            name="importe"
            label="Importe"
            value={values.importe}
            onChange={handleInputChange}
            error={errors.importe}
          />
        </Grid>
        <Grid item xs={3}>
          <Controls.Select
            name="MonedaId"
            label="Moneda"
            value={values.MonedaId}
            onChange={handleInputChange}
            options={listaMonedas.map((item) => {
              return { id: item.id, title: item.nombre };
            })}
            error={errors.BancoId}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Select
            name="TarjetaCreditoId"
            label="Tarjeta"
            value={values.TarjetaCreditoId}
            onChange={handleInputChange}
            options={listaTarjetas.map((item) => {
              return { id: item.id, title: item.Banco ? item.nombre + ' - ' + item.Banco.nombre : item.nombre };
            })}
            error={errors.TarjetaCreditoId}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Input
            name="linkComprobante"
            label="Comprobante"
            value={values.linkComprobante}
            onChange={handleInputChange}
            error={errors.linkComprobante}
          />
        </Grid>
        <Grid item xs={12}>
            <>
              {!guardando ? (
                <div style={{ marginTop: 25, textAlign: "right" }}>
                  <Controls.Button 
                    text="Guardar"
                    onClick={() => Guardar()}
                  />
                  <Controls.Button
                    text="Limpiar"
                    color="default"
                    onClick={resetForm}
                  />
                </div>
              ) : (
                  <></>
              )}
            </>
        </Grid>
      </Grid>
    </Form>
  );
}
