import { Card, CardContent } from '@material-ui/core';
import Controls from "../../components/tablas/controls/Controls";

export default function Tarjeta(props) {

    const { label, icon, estilos, registros, setVisible, visible } = props;

    return (
        <Card className={estilos}>
            <CardContent>
                <div style={{ textAlign: 'center' }}>
                    <h2>
                        {label}
                        {icon}
                    </h2>
                </div>
                <div style={{ fontSize: 30, textAlign: 'center'}}>
                    <h2>{registros.length}</h2>
                </div>
                <Controls.Button
                    text="Mostrar"
                    onClick={() => setVisible(!visible)}
                />
            </CardContent>
        </Card>
    );
}


