import React, { useState, useEffect, Fragment } from "react";

import { CircularProgress, Box, Grid, Collapse } from "@material-ui/core";

import { useUserState } from "../../context/UserContext";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import PageHeader from "../../components/tablas/PageHeader";
import useTable from "../../components/tablas/useTable";
//import Controls from "../../components/tablas/controls/Controls";

import * as ReservaLotesXLoteoServices from "../../services/venta/reservaLotesXLoteo";
import * as CuotasxVentaService from "../../services/contable/cuotasxVentasLote";

import RoomIcon from "@material-ui/icons/Room";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
//import { Search } from "@material-ui/icons";

const cabecera = [
  { id: "cuota", label: "Cuota" },
  { id: "importe", label: "Importe" },
  { id: "fechaVencimiento", label: "Vencimiento" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

const convertirFechaToStr = (fechaTurno) => {
  const texto =
    fechaTurno.substr(8, 2) +
    "/" +
    fechaTurno.substr(5, 2) +
    "/" +
    fechaTurno.substr(0, 4);
  return texto;
};

function Filas(props) {
  const { cuota, listaFormaPago, listaMoneda } = props;

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      {console.log("Cuota: ", cuota)}
      <TableRow key={cuota.id}>
        <TableCell
          style={{
            fontWeight: "500",
            color: cuota.pagado
              ? "red"
              : cuota.importeParcial !== 0
              ? "blue"
              : "black",
          }}
        >
          {cuota.cuota}
        </TableCell>
        <TableCell
          style={{
            fontWeight: "500",
            color: cuota.pagado
              ? "red"
              : cuota.importeParcial !== 0
              ? "blue"
              : "black",
          }}
        >
          {cuota.importe}
        </TableCell>
        <TableCell
          style={{
            fontWeight: "500",
            color: cuota.pagado
              ? "red"
              : cuota.importeParcial !== 0
              ? "blue"
              : "black",
          }}
        >
          {cuota.fechaVencimiento
            ? convertirFechaToStr(cuota.fechaVencimiento)
            : "S/D"}
        </TableCell>
        <TableCell>
          {cuota.pagado || cuota.importeParcial ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container style={{ borderRadius: 10 }}>
                {cuota.importeParcial ? (
                  <>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <h3>Detalle de Pago Parcial: </h3>
                        </Grid>
                        <Grid item xs={6}>
                          <h3>
                            {listaFormaPago &&
                            listaFormaPago.length &&
                            cuota.FormaPagoParcialId
                              ? listaFormaPago.find(
                                  (pago) => pago.id === cuota.FormaPagoParcialId
                                ).nombre
                              : "S/D"}
                            :{" "}
                            {listaMoneda &&
                            listaMoneda.length &&
                            cuota.MonedaParcialId
                              ? listaMoneda.find(
                                  (moneda) =>
                                    moneda.id === cuota.MonedaParcialId
                                ).simbolo
                              : "$"}
                            {new Intl.NumberFormat().format(
                              cuota.importeParcial
                            )}
                          </h3>
                        </Grid>
                        <Grid item xs={6}>
                          <h3>
                            Fecha:{" "}
                            {cuota.fechaPagoParcial
                              ? convertirFechaToStr(cuota.fechaPagoParcial)
                              : "S/D"}
                          </h3>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {cuota.fechaPago ? (
                  <>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <h3>Detalle de Pago Total:</h3>
                        </Grid>
                        <Grid item xs={6}>
                          <h3>
                            {listaFormaPago &&
                            listaFormaPago.length &&
                            cuota.FormaPagoId
                              ? listaFormaPago.find(
                                  (pago) => pago.id === cuota.FormaPagoId
                                ).nombre
                              : "S/D"}
                            :{" "}
                            {listaMoneda && listaMoneda.length && cuota.MonedaId
                              ? listaMoneda.find(
                                  (moneda) => moneda.id === cuota.MonedaId
                                ).simbolo
                              : "$"}
                            {new Intl.NumberFormat().format(
                              cuota.importe - cuota.importeParcial
                            )}
                          </h3>
                        </Grid>
                        <Grid item xs={4}>
                          <h3>
                            Fecha:{" "}
                            {cuota.fechaPago
                              ? convertirFechaToStr(cuota.fechaPago)
                              : "S/D"}
                          </h3>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function ListadoCuotas(props) {
  const { venta, listaFormaPago, listaMoneda } = props;

  const { token, rolCodigo } = useUserState();

  const [registros, setRegistros] = useState({});
  const [verPendientes, setVerPendientes] = useState(true);
  const [textoBuscar, setTextoBuscar] = useState("");
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [cuotas, setCuotas] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  //const [filtroPagado, setFiltroPagado] = useState(3);
  //const [cuotasFiltradas, setCuotasFiltradas] = useState({});

  const classes = useStyles();

  /*  const vectorFiltroPagado = [
    {
      id: 1,
      texto: "Cuotas Pagadas",
    },
    {
      id: 2,
      texto: "Cuotas Impagas",
    },
    {
      id: 3,
      texto: "Ver Todas",
    }
  ]
*/
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(cuotas, cabecera, filterFn);

  useEffect(() => {
    handleSearch();
  }, [textoBuscar]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      CuotasxVentaService.cuotasxVenta(venta.id, token).then((res) => {
        console.log(res.data);
        setCuotas(res.data);
      });
    }

    if (verPendientes) {
      ReservaLotesXLoteoServices.getAllActivos(token, rolCodigo).then((res) => {
        setRegistros(res);
        handleSearch();
      });
    } else {
    }
  }, [cargando, verPendientes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = () => {
    let target = textoBuscar ? textoBuscar : "";
    target = target.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.length === 0) return items;
        //console.log('items: ', items);
        else
          return items.filter(
            (x) => console.log(x) //x.cuota.includes(target)
          );
      },
    });
  };

  /*  const filtro = (id) => {
    switch (id) {
      case 1: 
        setCuotasFiltradas(cuotas.filter((cuota) => cuota.pagado === true));
        break;
      case 2:
        setCuotasFiltradas(cuotas.filter((cuota) => cuota.pagado === true));
        break;
      case 3:
        setCuotasFiltradas(cuotas);
        break;
      default: break;
    }
    setFiltroPagado(id);
  }*/

  return (
    <>
      <PageHeader
        title="RESERVAS DE LOTES EN VENTA"
        subTitle="Gestión de propiedes"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        {/*<Toolbar>
          <Controls.Checkbox
            name="verPendientes"
            label="Ver pendientes"
            value={verPendientes}
            onChange={() => {
              setVerPendientes(!verPendientes);
              setCargando(false);
              handleSearch();
            }}
          />
        </Toolbar>*/}
        {cuotas.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((cuota) => (
                <Filas
                  key={cuota.id}
                  cuota={cuota}
                  listaFormaPago={listaFormaPago}
                  listaMoneda={listaMoneda}
                />
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            <h3>No hay cuotas declaradas</h3>
          </div>
        )}
        <TblPagination />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
