import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";

import * as CuotasxVentaService from "../../../services/contable/cuotasxVentasLote";

import { useUserState } from "../../../context/UserContext";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  id: 0,
  codigo: 0,
  fechaVencimiento: fc,
};

export default function CuotasForm(props) {
  const { addOrEdit, recordForEdit } = props;

  const { token } = useUserState();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [guardando, setGuardando] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [primerCuota, setPrimerCuota] = useState({});

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fechaVencimiento =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault(); 
    values.fechaVencimiento = selectedDate;
    console.log('Values: ', values);
    addOrEdit(values, resetForm).then((e) => {
      if (e === false) {
        setGuardando(false);
        alert(
          "No se pudo completar la operación, revise los datos e intente nuevamente"
        );
      }
    });
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      CuotasxVentaService.cuotasxVenta(recordForEdit.id, token).then((res) => {
        setSelectedDate(res.data[0].fechaVencimiento);
        setPrimerCuota(res.data[0]);
      });
    }
  }, [ recordForEdit, cargando, primerCuota ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Controls.Input
            name="codigo"
            label="Codigo"
            value={values.codigo}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="fechaVencimiento"
              maxDate={new Date()}
              format="dd/MM/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              helperText="Fecha de Primer Vencimiento"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginTop: 25, textAlign: "right" }}>
              <Controls.Button type="submit" text="Guardar" />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>  
      </Grid>
    </Form>
  );
}
