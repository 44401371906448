import GestorAxios from "../../config/axios";

export async function getAllItems(token) {
  let data = {};

  await GestorAxios.get("/productos/asociadosxlote", {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      data = res.data;
    })
    .catch((error) => console.log(error.message));

  return data;
}

/*
export async function filtrarSimilares(token, dataBuscar) {

    let data = {};

    await GestorAxios.post('/productos/asociado/filtrarSimilares', dataBuscar,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            data = res.data;
        })

    return data;

}


export async function insert(data, token) {
    let dataReturn = {};

    await GestorAxios.post('/productos/asociado/nuevo/', data,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            dataReturn = res;
        })

    return dataReturn;
}
*/
export async function update(data, token) {
    let dataReturn = {};
    //console.log('Llega a services: ', data);
    await GestorAxios.put(`/productos/asociadosxlote/actualizar/${data.LotesxloteoId}/${data.AsociadoId}`, data,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            dataReturn = res;
        })

    return dataReturn;
}

export async function filtrar(token, id) {
  let data = {};

  await GestorAxios.get("/productos/asociadosxlote/filtrar/LotesxloteoId/" + id + "/", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
