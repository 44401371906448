import React, { useState } from "react";
import { Grid, CircularProgress, Card, CardContent, Typography, } from "@material-ui/core";
import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";
import { makeStyles } from "@material-ui/core/styles";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    backgroundColor: "lightgray",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function ClienteForm(props) {

  const { addOrEdit, setOpenPopup, advertencia } = props;

  const classes = useStyles();

  const telefono = advertencia.telefono;
  const email = advertencia.email;

  //console.log('Record for edit: ', recordForEdit);

  const [guardando, setGuardando] = useState(false);

  const {resetForm } = useForm();

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit(resetForm).then((e) => {
      if (e === false) {
        setGuardando(false);
        alert(
          "No se pudo completar la operación, revise los datos e intente nuevamente"
        );
      }
    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        {telefono ?
          <>
            <Grid item xs={12} style={{ backgroundColor: 'yellow', textAlign: 'center' }}>
              <h2 style={{ color: 'red' }}>Coincidencia de número de telefono encontrada:</h2>
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={12} sm={4} style={{ alignItems: 'center', marginTop: 30 }}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    style={{ textAlign: "center" }}
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    <AccountCircleIcon
                      style={{ fontSize: 40, color: "white" }}
                    />
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {telefono.apellido} {telefono.nombre}
                  </Typography>
                  <Typography
                    className={classes.pos}
                    color="textSecondary"
                  >
                    DNI: {telefono.DNI}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <PhoneAndroidIcon
                      style={{ marginBottom: -5 }}
                    />{" "}
                    {telefono.telefono}
                    <br />
                    <EmailIcon
                      style={{ marginBottom: -5 }}
                    />{" "}
                    {telefono.email}
                  </Typography>
                  <Typography
                    className={classes.pos}
                    color="textSecondary"
                  >
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <h2>Por favor, corroborar existencia</h2>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <div  style={{ backgroundColor: 'green', textAlign: 'center' }}>
                <hr />
              </div>
            </Grid>
          </>
        :
          <></>
        }
        {email ?
          <>
            <Grid item xs={12} style={{ backgroundColor: 'yellow', textAlign: 'center' }}>
              <h2 style={{ color: 'red' }}>Coincidencia de email encontradas:</h2>
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={12} sm={4} style={{ alignItems: 'center', marginTop: 30 }}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    style={{ textAlign: "center" }}
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    <AccountCircleIcon
                      style={{ fontSize: 40, color: "white" }}
                    />
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {email.apellido} {email.nombre}
                  </Typography>
                  <Typography
                    className={classes.pos}
                    color="textSecondary"
                  >
                    DNI: {email.DNI}
                  </Typography>
                  <Typography variant="body2" component="p">
                    <PhoneAndroidIcon
                      style={{ marginBottom: -5 }}
                    />{" "}
                    {email.telefono}
                    <br />
                    <EmailIcon
                      style={{ marginBottom: -5 }}
                    />{" "}
                    {email.email}
                  </Typography>
                  <Typography
                    className={classes.pos}
                    color="textSecondary"
                  >
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <h2>Por favor, corroborar existencia</h2>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <div  style={{ backgroundColor: 'green', textAlign: 'center' }}>
                <hr />
              </div>
            </Grid>
          </>
        :
          <></>
        }
        <div style={{ marginTop: 25, textAlign: "right" }}>
          {!guardando ? (
            <>
              <Controls.Button type="submit" text="Eliminar" />
              <Controls.Button
                text="Cerrar"
                color="default"
                onClick={() => setOpenPopup(false)}
              />
              </>
          ) : (
            <CircularProgress size={26} />
          )}
        </div>
      </Grid>
    </Form>
  );
}
