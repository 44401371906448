import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import * as CuotasxVentaService from "../../services/contable/cuotasxVentasLote";
import * as ReservaLotesXLoteoServices from "../../services/venta/reservaLotesXLoteo";
import * as AsociadosxCuotasxVentasLote from "../../services/contable/asociadosxCuotasxVentasLote";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useUserState } from "../../context/UserContext";

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  id: 0,
  importe: 0,
  fechaPago: fc,
  FormaPagoId: undefined,
  MonedaId: undefined,
  linkComprobantePago: "",
  observaciones: ""
};

export default function CuotasForm(props) {
  const {
    addOrEdit,
    recordForEdit,
    setOpenPopup,
    listaAsociados,
    listaAsociadosxLote,
    listaFormaPago,
    listaMoneda,
  } = props;

  const { token } = useUserState();

  console.log("Record en cuotas: ", recordForEdit);

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [guardando, setGuardando] = React.useState(false);
  const [cuotas, setCuotas] = useState({});
  const [cargando, setCargando] = useState(false);
  const [itemsComprados, setItemsComprados] = useState({});
  const [filtro, setFiltro] = useState([]);
  const [socioSeleccionado, setSocioSeleccionado] = useState(0);
  const [socios, setSocios] = useState([]);
  const [importes, setImportes] = React.useState([]);
  const [cuotaPagar, setCuotaPagar] = useState({});
  const [obs, setObs] = useState(cuotaPagar.observaciones || "");
  const [linkComprobante, setLinkComprobante] = useState(cuotaPagar.linkComprobantePago || cuotaPagar.linkComprobantePagoParcial || "");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fechaConsulta =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    if ("telefono" in fieldValues)
      temp.telefono = fieldValues.telefono ? "" : "Dato requerido";
    if ("FuenteId" in fieldValues)
      temp.FuenteId =
        fieldValues.FuenteId !== undefined ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =  // eslint-disable-line
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit({}, resetForm);
    if (validate()) {
      setGuardando(true);
    }
  };

  const agregarSocio = () => {
    console.log("Existe? ", parseInt(socioSeleccionado));
    if (socioSeleccionado) {
      let tempSocios = socios;
      const existe = tempSocios.find((s) => s.id === socioSeleccionado);
      if (!existe) {
        tempSocios.push({
          id: socioSeleccionado,
          importe: 0,
        });
      } else {
        alert("Ya esta ingresado el socio seleccionado");
      }
      setSocios(tempSocios);
      validate();
    } else {
      alert("Seleccione un socio");
    }
  };

  const handleInputPorcentajeSoloNumerosChange = (e, socioId) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      e.target.value = onlyNums;
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      e.target.value = number;
    }
    let p = importes;
    p[socioId] = e.target.value;
    setImportes(p);
    cambiaImporteSocio(e.target.value, socioId);
  };

  const cambiaImporteSocio = (value, socioId) => {
    let tempSocios = socios;
    tempSocios.forEach((s) => {
      if (s.id === socioId) {
        s.importe = value;
      }
    });
    setSocios(tempSocios);
    validate();
  };

  const eliminarSocio = (id) => {
    let tempSocios = socios;
    const ss = tempSocios.filter((s) => s.id !== id);
    setSocios(ss);
  };

  const obtenerSocio = (id) => {
    let tempSocios = listaAsociados;
    const ss = tempSocios.find((s) => s.id === id);
    if (ss) {
      return ss.apellido + " " + ss.nombre;
    }
  };

  const manzanaLote = (item) => {
    let manzanas = item.Lotesxloteo.Loteo.cantManzanas.split(",");
    let lote = item.Lotesxloteo.nombre;
    let ml = `Manzana: ${
      manzanas[parseInt(item.Lotesxloteo.manzana)]
    } - Lote: ${lote}`;
    return ml;
  };

  const FiltroSociosxLote = (lotes, socios) => {
    let vector = [];
    for (let i = 0; i < lotes.length; i++) {
      let v = socios.filter(
        (socio) => socio.LotesxloteoId === lotes[i].LotesxloteoId
      );
      if (v !== []) vector.push(v);
    }
    return vector;
  };

  const sociosFiltrados = (socios) => {
    let sociosTemp = socios.map((socioArray) =>
      socioArray.map((socio) => {
        return socio.Asociado.id;
      })
    );
    //console.log('SociosTemp', sociosTemp);
    let arrayTemp = [];
    for (let i = 0; i < sociosTemp.length; i++) {
      arrayTemp = arrayTemp.concat(sociosTemp[i]);
    }
    let filtrados = arrayTemp.filter((socio, index) => {
      return arrayTemp.indexOf(socio) === index;
    });
    return filtrados;
  };

  const abonarCuota = async (cuota, importe, formaPago, moneda, link) => {
    try {
      let datos = {
        id: cuota.id,
        observaciones: obs,
      };
      let importeTemp = parseInt(cuota.importe) - parseInt(cuota.importeParcial);
      console.log("importeTemp: ", importeTemp, importe);
      if (importe) {
        if (importeTemp === parseInt(importe)) {
          datos.pagado = true;
          datos.FormaPagoId = formaPago;
          datos.MonedaId = moneda;
          datos.fechaPago = selectedDate;
          datos.linkComprobantePago = link;
        } else {
          if (importeTemp > parseInt(importe)) {
            datos.importeParcial = importe;
            datos.FormaPagoParcialId = formaPago;
            datos.MonedaParcialId = moneda;
            datos.fechaPagoParcial = selectedDate;
            datos.linkComprobantePagoParcial = link;
            console.log('Importe menor a cuota', datos);
          } else {
            alert(
              "El monto es mayor al importe de la cuota. Por favor realice más de una operación"
            );
            return false;
          }
        }
      } else {
        alert("Ingrese un importe");
        return false;
      }
      if (formaPago) {
        datos.FormaPagoId = formaPago;
      } else {
        alert("Seleccione Forma de Pago");
        return false;
      }
      let impSocios = 0;
      for (let i = 0; i < socios.length; i++) {
        impSocios += parseInt(socios[i].importe);
      }
      if (impSocios !== parseInt(importe)) {
        console.log(importe, impSocios);
        let diferencia = impSocios - parseInt(importe);
        alert(`Importes de socios no coincidentes. Diferencia: ${diferencia}`);
        return false
      }
      if (moneda) {
        datos.MonedaId = moneda;
      } else {
        alert("Seleccione Moneda");
        return false;
      }
  
      await CuotasxVentaService.update(datos, token);
      //let cuotasTemp = cuotas.filter((c) => c.pagado === false);
      //setCuotaPagar(cuotas.filter((c) => c.pagado === false));
      
      for (let i = 0; i < socios.length; i++) {
        let datos = {
          CuotasxVentasLoteId: cuotaPagar.id,
          AsociadoId: socios[i].id,
          importe: socios[i].importe,
        };
        console.log('Datos - ', i, datos);
        await AsociadosxCuotasxVentasLote.insert(datos, token);
      }
      setCargando(false);
    } catch (error) {
      console.log('Error: ', error.message);
    }
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      CuotasxVentaService.cuotasxVenta(recordForEdit.id, token).then((res) => {
        //console.log('Cuota siguiente: ', res.data.filter((cuota) => cuota.pagado === false)[0]);
        let fcuotas = [];
        fcuotas = res.data.filter((cuota) => cuota.pagado === false);
        console.log(fcuotas, cuotaPagar, fcuotas.length);
        if (fcuotas.length > 0) {
          console.log('Entra en if...');
          setObs(fcuotas[0].observaciones);
          setCuotaPagar(fcuotas[0]);
        }
        setCuotas(fcuotas);
      });
      ReservaLotesXLoteoServices.itemsComprados(
        recordForEdit.ReservasLoteId,
        token
      ).then((res) => {
        setItemsComprados(res);
      });
    }
  }, [ recordForEdit, cargando, itemsComprados, listaAsociadosxLote, listaAsociados ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      itemsComprados &&
      itemsComprados.length &&
      listaAsociadosxLote &&
      listaAsociadosxLote.length
    ) {
      setFiltro(FiltroSociosxLote(itemsComprados, listaAsociadosxLote));
    }
    if (filtro && filtro.length) {
      let sociosTemp = sociosFiltrados(filtro);
      for (let i = 0; i < sociosTemp.length; i++) {
        let s = listaAsociados.find((socio) => socio.id === sociosTemp[i]);
        if (s && s.id) {
          sociosTemp.push(s);
        }
      }
    }
  }, [itemsComprados, listaAsociadosxLote, cuotaPagar]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <h3>Cuota</h3>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <h3>Importe</h3>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <h3>Vencimiento</h3>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid container>
            {cuotaPagar && cuotaPagar.id ? (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#F5E8E8",
                      textAlign: "center",
                    }}
                  >
                    <h3>
                      {cuotaPagar.cuota}/{recordForEdit.ReservasLote.cantidadCuotas}
                    </h3>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#F5E8E8",
                      textAlign: "center",
                    }}
                  >
                    <h3>${new Intl.NumberFormat().format(cuotaPagar.importe)}</h3>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#F5E8E8",
                      textAlign: "center",
                    }}
                  >
                    <h3>{cuotaPagar.fechaVencimiento || "06/10/21"}</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    <h3>Pago parcial en cuota: ${cuotaPagar.importeParcial}</h3>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    <h3>
                      Saldo Cuota Actual: $
                      {cuotaPagar.importe - cuotaPagar.importeParcial}
                    </h3>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ textAlign: "center", marginBottom: 20 }}
                  >
                    <Controls.Select
                      name="FormaPagoId"
                      label="Forma de Pago"
                      value={values.FormaPagoId || ""}
                      onChange={handleInputChange}
                      options={listaFormaPago.map((item) => {
                        return { id: item.id, title: item.nombre };
                      })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ textAlign: "center", marginBottom: 20 }}
                  >
                    <Controls.Select
                      name="MonedaId"
                      label="Moneda"
                      value={values.MonedaId || ""}
                      onChange={handleInputChange}
                      options={listaMoneda.map((item) => {
                        return { id: item.id, title: item.nombre };
                      })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    <Controls.Input
                      name="importeAbonar"
                      label="Importe a Abonar"
                      value={values.importeAbonar}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="fechaPago"
                        maxDate={new Date()}
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        helperText="Fecha de Pago de Cuota"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    <Controls.Input
                      name="linkcomprobante"
                      label="Link a Comprobante"
                      value={linkComprobante}
                      onChange={(e) => setLinkComprobante(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    <Controls.Input
                      name="observaciones"
                      label="Observaciones"
                      value={obs}
                      onChange={(e) => setObs(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} style={ { textAlign: "center" }}>
                <h3>No hay mas cuotas</h3>
              </Grid>
            )}
          </Grid>
          <Grid container style={{ marginTop: 40 }}>
            {listaAsociados && listaAsociados.length && (
              <>
                <Grid item xs={12} sm={12}>
                  <h2>Socios </h2>
                  <hr />
                </Grid>
                <Grid item xs={9}>
                  <Controls.Select
                    name="AsociadoId"
                    label="Socio"
                    value={socioSeleccionado}
                    onChange={(item) => {
                      setSocioSeleccionado(item.target.value);
                    }}
                    options={listaAsociados.map((item) => {
                      return {
                        id: item.id,
                        title: item.apellido + " " + item.nombre,
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controls.Button
                    text="+"
                    color="default"
                    onClick={() => agregarSocio()}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {socios.length > 0 ? (
            <>
              <Grid container>
                {socios.map((socio) => {
                  return (
                    <>
                      <Grid item xs={5}>
                        <h3
                          style={{
                            background: "lightGray",
                            padding: 5,
                            borderRadius: 10,
                            marginBottom: 13,
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {obtenerSocio(socio.id)}
                        </h3>
                      </Grid>
                      <Grid item xs={4}>
                        <Controls.Input
                          label="Importe"
                          value={importes[socio.id]}
                          name={socio.id}
                          onChange={(e) => {
                            handleInputPorcentajeSoloNumerosChange(e, socio.id);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Controls.Button
                          text="-"
                          color="secondary"
                          onClick={() => eliminarSocio(socio.id)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1}></Grid>
                    </>
                  );
                })}
              </Grid>
            </>
          ) : (
            <div></div>
          )}
          <Grid
            item
            xs={12}
            style={{ fontWeight: "bold", textAlign: "center", marginTop: 40 }}
          >
            <Controls.Button
              text="Abonar"
              color="secondary"
              onClick={() =>
                abonarCuota(
                  cuotaPagar,
                  values.importeAbonar,
                  values.FormaPagoId,
                  values.MonedaId,
                  linkComprobante
                )
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          {itemsComprados && itemsComprados.length && (
            <>
              <Grid
                container
                style={{ backgroundColor: "#E2C5EB", borderRadius: 10 }}
              >
                {itemsComprados.map((item, index) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: "center",
                        backgroundColor: "#CECFF5",
                      }}
                    >
                      <h3>{manzanaLote(item)}</h3>
                    </Grid>
                    {filtro && filtro.length ? (
                      <>
                        {filtro[index].map((socio) => (
                          <Grid item xs={6} style={{ textAlign: "center" }}>
                            <h3>
                              {socio.Asociado.nombre} {socio.Asociado.apellido}
                            </h3>
                            <h3>Porcentaje: {socio.porcentaje} %</h3>
                          </Grid>
                        ))}
                      </>
                    ) : (
                      <>
                        <CircularProgress size={26} />
                      </>
                    )}
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right", marginTop: 50 }}>
          <Controls.Button
            //type="submit"
            text="Cerrar"
            onClick={() => setOpenPopup(false)}
          />
        </Grid>
      </Grid>
    </Form>
  );
}
