import React, { useState, useEffect } from "react";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";

import { useUserState } from "../../context/UserContext";

import * as FormaPagoServices from "../../services/venta/formaPago";
import * as MonedaServices from "../../services/venta/moneda";
import * as BancoSucursalServices from "../../services/contable/bancoSucursal";
import * as BancoServices from "../../services/contable/banco";
import * as ChequeServices from "../../services/contable/cheque";
import * as TransferenciaServices from "../../services/contable/transferencia";
import * as TarjetaCreditoServices from "../../services/contable/tarjetaCredito";
import * as CajaGeneralServices from "../../services/caja/cajaGeneral";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import Popup from "../../components/tablas/Popup";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";
import PageHeader from "../../components/tablas/PageHeader";

import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ListIcon from "@material-ui/icons/List";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Search } from "@material-ui/icons";

import Tarjeta from "../utiles/Tarjeta";
import ChequeVisor from "../contable/ChequeVisor";
import TransferenciaVisor from "../contable/TransferenciaVisor";
import TarjetaVisor from "../contable/TarjetaVisor";
import EfectivoVisor from "../contable/EfectivoVisor";
import IngresoEgresoVisor from "../contable/IngresoEgresoVisor";

const cabecera = [
  { id: "persona", label: "Persona" },
  { id: "ingreso", label: "Ingreso" },
  { id: "egreso", label: "Egreso" },
  { id: "fecha", label: "Fecha" },
  { id: "transaccion", label: "Transacción" },
  { id: "origenId", label: "Origen" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#FFFEFC",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function CajaDiaria() {
  const { token } = useUserState();
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({
    // eslint-disable-line
    fn: (items) => {
      return items;
    },
  });
  const [openPopupCheque, setOpenPopupCheque] = useState(false);
  const [openPopupTransferencia, setOpenPopupTransferencia] = useState(false);
  const [openPopupTarjeta, setOpenPopupTarjeta] = useState(false);
  const [openPopupEfectivo, setOpenPopupEfectivo] = useState(false);
  const [openPopupIngresoEgreso, setOpenPopupIngresoEgreso] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [formasPago, setFormasPago] = useState({});
  const [monedas, setMonedas] = useState({});
  const [bancos, setBancos] = useState({});
  const [sucursales, setSucursales] = useState({});
  const [registros, setRegistros] = useState({});
  const [cheques, setCheques] = useState({});
  const [transferencias, setTransferencias] = useState({});
  const [tarjetas, setTarjetas] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [ingreso, setIngreso] = useState(0);
  const [egreso, setEgreso] = useState(0);
 
  const classes = useStyles();

  let f = new Date();
  let fecha = f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate();

  const handleSearch = (e) => {
    let target = e.target;
    // setFilterFn({
    //   fn: (items) => {
    //     if (target.value === "") return items;
    //     else
    //       return items.filter((x) =>
    //         x.nombre.toLowerCase().includes(target.value)
    //       );
    //   },
    // });
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      FormaPagoServices.getAllItems(token).then((res) => {
        setFormasPago(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setMonedas(res);
      });
      TarjetaCreditoServices.getAllItems(token).then((res) => {
        setTarjetas(res);
      });
      BancoSucursalServices.getAllItems(token).then((res) => {
        setSucursales(res);
      });
      BancoServices.getAllItems(token).then((res) => {
        setBancos(res);
      });
      ChequeServices.noProcesado(token).then((res) => {
        setCheques(res);
      });
      TransferenciaServices.noProcesado(token).then((res) => {
        setTransferencias(res);
      });
      CajaGeneralServices.getProcesado(token).then((res) => {
        let respuesta = res.filter((item) => item.fechaProcesado == fecha);
        console.log("Fechas??? ", fecha, typeof fecha, respuesta);
        let ingresos = 0;
        let egresos = 0;
        for (let i = 0; i < respuesta.length; i++) {
          if (respuesta[i].ingreso) ingresos += respuesta[i].ingreso;
          else egresos += respuesta[i].egreso;
        }
        setRegistros(respuesta);
        setIngreso(ingresos);
        setEgreso(egresos);
      });
    }
  }, [cargando]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopupVisor = (item) => {
    console.log("Item en visor: ", item);
    if (item.ChequeId) {
      setOpenPopupCheque(true);
      setRecordForEdit(cheques.find((cheque) => item.ChequeId === cheque.id));
    } else if (item.TransferenciumId) {
      setOpenPopupTransferencia(true);
      setRecordForEdit(
        transferencias.find(
          (transferencia) => item.TransferenciumId === transferencia.id
        )
      );
    } else if (item.FormaPago && item.FormaPago.esTarjeta) {
      setOpenPopupTarjeta(true);
      setRecordForEdit(item);
    } else if (item.ingreso) {
      console.log("Ingreso: ", item.Origen);
      setOpenPopupEfectivo(true);
      setRecordForEdit(item);
    } else {
      console.log("Egreso: ", item.Origen);
      setOpenPopupIngresoEgreso(true);
      setRecordForEdit(item);
    }
  };

  const procesar = async (id) => {
    let data = {
      procesado: true,
    };
    await CajaGeneralServices.procesar(data, id, token);
    await CajaGeneralServices.getNoProcesado(token).then((res) => {
      setRegistros(res);
    });
  };

  return (
    <>
      <PageHeader
        title="CAJA DIARIA"
        subTitle="Gestión de ingresos y esgresos"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Controls.Button
            text="Agregar"
            startIcon={<AddIcon />}
            className={classes.newButton}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
            }}
          />
        </Toolbar>
        {registros && registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {item.ClienteId
                      ? "CLIENTE: " +
                        item.Cliente.nombre +
                        " " +
                        item.Cliente.apellido
                      : item.AsociadoId
                      ? "ASOCIADO: " +
                        item.Asociado.nombre +
                        " " +
                        item.Asociado.apellido
                      : item.UsuarioId
                      ? "USUARIO: " +
                        item.Usuario.nombre +
                        " " +
                        item.Usuario.apellido
                      : item.PropietarioId
                      ? "PROPIETARIO: " +
                        item.Propietario.nombre +
                        " " +
                        item.Propietario.apellido
                      : ""}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    ${new Intl.NumberFormat().format(item.ingreso)}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    ${new Intl.NumberFormat().format(item.egreso)}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {item.createdAt.toString().split("T")[0] +
                      " " +
                      item.createdAt.toString().split("T")[1].split(".")[0]}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {item.FormaPagoId
                      ? item.FormaPago.nombre + " " + item.Moneda.nombre
                      : ""}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {item.OrigenId ? item.Origen.nombre : ""}
                  </TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      style={{ backgroundColor: "#FFA114" }}
                      onClick={() => {
                        openInPopupVisor(item);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <></>
        )}
        <TblPagination />
        <Grid container>
          <Grid item xs={4} style={{ textAlign: 'center', color: "green" }}>
            <h3>Ingreso: $ {new Intl.NumberFormat().format(ingreso)}</h3>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', color: "red" }}>
            <h3>Egreso: $ {new Intl.NumberFormat().format(egreso)}</h3>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', color: "blue" }}>
            <h3>Diferencia: $ {new Intl.NumberFormat().format(ingreso - egreso)}</h3>
          </Grid>
        </Grid>
      </Paper>
      <Popup
        title="Visor de Cheques"
        openPopup={openPopupCheque}
        setOpenPopup={setOpenPopupCheque}
      >
        <ChequeVisor recordForEdit={recordForEdit} />
      </Popup>
      <Popup
        title="Visor de Transacciones Bancarias"
        openPopup={openPopupTransferencia}
        setOpenPopup={setOpenPopupTransferencia}
      >
        <TransferenciaVisor recordForEdit={recordForEdit} />
      </Popup>
      <Popup
        title="Visor de Pago con Tarjeta"
        openPopup={openPopupTarjeta}
        setOpenPopup={setOpenPopupTarjeta}
      >
        <TarjetaVisor listaTarjetas={tarjetas} recordForEdit={recordForEdit} />
      </Popup>
      <Popup
        title="Visor de Pago Efectivo"
        openPopup={openPopupEfectivo}
        setOpenPopup={setOpenPopupEfectivo}
      >
        <EfectivoVisor recordForEdit={recordForEdit} />
      </Popup>
      <Popup
        title="Visor de Ingreso - Egreso General"
        openPopup={openPopupIngresoEgreso}
        setOpenPopup={setOpenPopupIngresoEgreso}
      >
        <IngresoEgresoVisor recordForEdit={recordForEdit} />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
