import GestorAxios from "../../config/axios";

export async function getAllItems(token, rolCodigo) {
  let data = {};

  await GestorAxios.get("/productos/propietario", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function filtrarSimilares(token, dataBuscar) {
  let data = {};

  await GestorAxios.post(
    "/productos/propietario/filtrarSimilares",
    dataBuscar,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    data = res.data;
  });

  return data;
}

export async function insert(data, token) {
  let dataReturn = {};

  await GestorAxios.post("/productos/propietario/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/productos/propietario/actualizar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function updateConError(data, token) {
  let dataReturn = {};

  await GestorAxios.put(
    `/productos/propietario/actualizar/error/${data.propietarioId}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}
