import GestorAxios from "../../config/axios";

export async function getAllItems(token) {
  let data = {};

  await GestorAxios.get("/productos/propiedadesventa", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

// multipart upload - envia archivo
export async function insert(data, token) {
  let dataReturn = {};

  await GestorAxios.post("/productos/propiedadesventa/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, id, token) {
  let dataReturn = {};

  await GestorAxios.put(`/productos/propiedadesventa/actualizar/${id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function updateReserva(data, id, token) {
  let dataReturn = {};

  await GestorAxios.put(
    `/productos/propiedadesventa/actualizarreserva/${id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => {
      dataReturn = res;
    })
    .catch((error) => console.log("Error: ", error.message));

  return dataReturn;
}
