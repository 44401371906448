import React, { useState, useEffect } from "react";

import * as ReservaLotesxLoteoService from "../../../services/venta/reservaLotesXLoteo";
import * as FormaPagoService from "../../../services/venta/formaPago";
import * as MonedaService from "../../../services/venta/moneda";
import * as UsuarioService from "../../../services/usuario/usuario";
import * as ComisionesUsuariosServices from "../../../services/contable/comisionesUsuarios";
import * as AsociadosxloteServices from "../../../services/producto/asociadosXLote";
import * as VentasLotesServices from "../../../services/venta/ventasLote";
import * as ConfigDataServices from "../../../services/utiles/configData";
import * as CajaGeneralServices from "../../../services/caja/cajaGeneral";

import { useUserState } from "../../../context/UserContext";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import Popup from "../../../components/tablas/Popup";

import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
} from "@material-ui/core";

import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";

import AvisoVentasLoteForm from "./AvisoVentaLoteForm";
import Tarjeta from "../../utiles/Tarjeta";
//import { procesar } from "../../../services/venta/reservaPropiedadVenta";

const cabecera = [
  { id: "loteo", label: "Loteo" },
  { id: "cliente", label: "Cliente" },
  { id: "vendedor", label: "Vendedor" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#DBCFDE",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function AvisoVentaLote() {
  const { token } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ // eslint-disable-line
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [reservas, setReservas] = useState({});
  const [formaPago, setFormaPago] = useState({});
  const [moneda, setMoneda] = useState({});
  const [usuario, setUsuario] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [comisiones, setComisiones] = useState({});
  const [asociadosXLote, setAsociadosXLote] = useState({});
  const [lotes, setLotes] = useState({});
  //const [venta, setVenta] = useState({});
  const [ultimoCodigo, setUltimoCodigo] = useState(0);
  const [visible, setVisible] = useState(false);
  const [itemsComprados, setItemsComprados] = useState(false);
  const [sociosFiltrados, setSociosFiltrados] = useState([]);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(reservas, cabecera, filterFn);

  const openInPopup = (item) => {
    //console.log("Item", item);
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const obtenerComisiones = async (id) => {
    await ComisionesUsuariosServices.comisionesLotes(id, token).then((res) => {
      //console.log("Comisiones: ", res);
      setComisiones(res);
    });
  };

  const obtenerLotes = async (item) => {
    let manzanas = item.Lotesxloteo.Loteo.cantManzanas.split(",");
    let lotesLocal = [];
    let datos = {};
    let sociosLocal = [];
    await ReservaLotesxLoteoService.itemsComprados(item.id, token).then(
      (res) => {
        setItemsComprados(res);
        for (let i = 0; i < res.length; i++) {
          datos = {
            manzana: manzanas[parseInt(res[i].Lotesxloteo.manzana)],
            lote: res[i].Lotesxloteo.nombre,
          };
          lotesLocal.push(datos);
          sociosLocal.push(asociadosXLote.filter((socio) => socio.Lotesxloteo.id === res[i].Lotesxloteo.id));
        }
        //console.log("Lotes---------------------------", lotes);
        setSociosFiltrados(sociosLocal);
        setLotes(lotesLocal);
      }
    );
  };

  const obtenerAsociados = async (id) => {
    await AsociadosxloteServices.getAllItems(token).then((res) => {
      //console.log("AsociadosxLote: ", res);
      setAsociadosXLote(res);
    });
  };
  
  const procesar = async (item, resetForm) => {
    console.log("En procesar: ", item);
    const id = item.id;
    const usuarios = item.usuarios;
    const socios = item.socios;
    // const lotes = item.lotes;
    // let data = {
    //   id: item.id,
    //   procesado: true,
    // };
    
    // let datos = {
    //   ReservaLoteId: item.id,
    //   UsuarioId: item.UsuarioId,
    //   ClienteId: item.ClienteId,
    // };
    let procesar = {
      vendido: item.vendido,
      comisionGastos: item.comisionGastos,
      totalSeniaFinalReserva: item.totalSeniaFinalReserva,
      totalEntregadoFinalReserva: item.totalEntregadoFinalReserva,
      obsFinalReserva: item.obsFinalReserva,
      totalComisionesFinalReserva: item.totalComisionesFinalReserva,
      gananciaFinalReserva: item.gananciaFinalReserva,
      dineroDisponibleFinalReserva: item.dineroDisponibleFinalReserva,
      fechaVenta: item.fechaVenta,
      LotesxloteoId: item.LotesxloteoId,
      importeReserva1: item.importeReserva1,
      importeReserva2: item.importeReserva2,
      importeReserva3: item.importeReserva3,
      importeReserva4: item.importeReserva4,
      fechaPactada1: item.fechaPactada1,
      fechaPactada2: item.fechaPactada2,
      fechaPactada3: item.fechaPactada3,
      fechaPactada4: item.fechaPactada4,
      FormaPagoReservaId1: item.FormaPagoReservaId1,
      FormaPagoReservaId2: item.FormaPagoReservaId2,
      FormaPagoReservaId3: item.FormaPagoReservaId3,
      FormaPagoReservaId4: item.FormaPagoReservaId4,
      procesado: item.procesado,
    }
    console.log('confirma: ', procesar);
    let venta = {
      codigo: item.codigo,
      observacionesVenta: item.observaciones,
      ReservasLoteId: id,
      ClienteId: item.ClienteId,
      vendedorId: item.Usuario.id,
      cantidadCuotas: item.cantidadCuotas,
      importeAnio1: item.importeAnio1,
      importeAnio2: item.importeAnio2,
      importeAnio3: item.importeAnio3,
      importeAnio4: item.importeAnio4,
      fechaVencimiento: item.fechaVencimiento,
    }
    console.log('venta: ', venta);
    let ucv = item.codigo.split("-");
    let config = {
      id: 1,
    };
    if (parseInt(ucv[0]) === ultimoCodigo + 1) {
      config.ultimoCodigoVenta = ultimoCodigo + 1;
    }

    try {
      let ventasLoteId = 0;
      await ReservaLotesxLoteoService.procesar(procesar, id, token);
      await VentasLotesServices.insert(venta, token).then((res) => {
        ventasLoteId = res.data.id
      });
      await ConfigDataServices.update(config, token);
      for (let i = 0; i < socios.length; i++){
        if (socios[i] && socios[i].length > 0) {
          for (let j = 0; j < socios[i].length; j++) {
            console.log('Socio Update: ', socios[i][j]);
            let caja1 = {
              VentasLoteId: ventasLoteId,
              AsociadoId: socios[i][j].AsociadoId,
              ingreso: socios[i][j].aporteComision,
              OrigenId: 5          // aporte por comision de socio
            }
            await CajaGeneralServices.insert(caja1, token);
            let caja2 = {
              VentasLoteId: ventasLoteId,
              AsociadoId: socios[i][j].AsociadoId,
              egreso: socios[i][j].pagoPorPorcentaje,
              OrigenId: 6            // pago por porcentaje
            }
            await CajaGeneralServices.insert(caja2, token);
            await AsociadosxloteServices.update(socios[i][j], token);
          }
        }
      }
      if (usuarios && usuarios.length > 0) {
        for (let i = 0; i < usuarios.length; i++) {
          let comision = {
            ReservasLoteId: item.id,
            UsuarioId: usuarios[i].id,
            importe: usuarios[i].comision,
          };
          console.log('Comisiones: ', comision);
          let caja = {
            VentasLoteId: ventasLoteId,
            UsuarioId: usuarios[i].id,
            egreso: usuarios[i].comision,
            OrigenId: 7                     // comision por venta
          }
          await ComisionesUsuariosServices.insert(comision, token).then((res) => {
            caja.ComisionesUsuarioId = res.data.id;
          });
          await CajaGeneralServices.insert(caja, token);
          }
      }
      await ReservaLotesxLoteoService.getNoProcesadoVenta(token).then((res) => {
        setReservas(res);
      });
    } catch (err) {
      return false;
    }
    resetForm();
    //setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
  };

  useEffect(() => {
    if (!cargando) {
      FormaPagoService.getAllItems(token).then((res) => {
        setFormaPago(res);
      });
      MonedaService.getAllItems(token).then((res) => {
        setMoneda(res);
      });
      ConfigDataServices.getAllItems(token).then((res) => {
        //console.log(res);
        setUltimoCodigo(res[0].ultimoCodigoVenta);
      });
      UsuarioService.getAllItems(token).then((res) => {
        setUsuario(res);
      });
      AsociadosxloteServices.getAllItems(token).then((res) => {
        //console.log("AsociadosxLote: ", res);
        setAsociadosXLote(res);
      });
      }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ReservaLotesxLoteoService.getNoProcesadoVenta(token).then((res) => {
        //console.log("Reservas Venta: ", res);
        setReservas(res);
      });
    }
  }, [cargando, reservas]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {reservas && reservas.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="VENTA DE LOTE/S"
                icon={
                  <ThumbUpIcon
                    style={{ fontSize: 40, color: "green", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={reservas}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#DBCFDE" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <h2>
                        <ThumbUpIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                        VENTA DE LOTE/S{" "}
                        <ThumbUpIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                      </h2>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: 10 }}>
                      <Controls.Button
                        text="Ocultar"
                        onClick={() => setVisible(!visible)}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <TblContainer>
                  <Table aria-label="collapsible table">
                    <TblHead />
                    <TableBody>
                      {recordsAfterPagingAndSorting().map((item) => (
                        <TableRow key={item.id}>
                          <TableCell style={{ fontWeight: "bold" }}>
                            {item.Lotesxloteo.Loteo.nombre}
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            {item.Cliente.nombre} {item.Cliente.apellido}
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            {item.Usuario.nombre} {item.Usuario.apellido}
                          </TableCell>
                          <TableCell>
                            <Controls.ActionButton
                              color="primary"
                              style={{ backgroundColor: "#FFA114" }}
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                obtenerComisiones(item.id);
                                obtenerLotes(item);
                                obtenerAsociados(item.id);
                                openInPopup(item);
                              }}
                            >
                              <AssignmentTurnedInIcon />
                            </Controls.ActionButton>
                            {/*<Controls.ActionButton
                      color="primary"
                      style={{ backgroundColor: "#121ADE" }}
                      onClick={() => {
                        procesar(item);
                      }}
                    >
                      <CheckIcon fontSize="small" />
                    </Controls.ActionButton>*/}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Popup
        title="Venta Confirmada"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AvisoVentasLoteForm
          token={token}
          listaFormaPago={formaPago}
          listaMoneda={moneda}
          recordForEdit={recordForEdit}
          procesar={procesar}
          lotes={lotes}
          asociadosXLote={sociosFiltrados}
          comisiones={comisiones}
          ultimoCodigo={ultimoCodigo}
          listaUsuarios={usuario}
          itemsComprados={itemsComprados}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
