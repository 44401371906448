import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";
import Notification from "../../components/tablas/Notification";

import EditIcon from "@material-ui/icons/Edit";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

import * as EventosServices from "../../services/venta/eventosReserva";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";

const moment = require("moment");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  id: 0,
  fecha: fc,
  observaciones: "",
};

export default function EventosForm(props) {

  const { recordForEdit, puedeAgregar, token, tipoEvento } = props;

  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });
  const [guardando, setGuardando] = React.useState(false);
  const [registros, setRegistros] = useState({});
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [cargando, setCargando] = useState(false);
  const [nuevo, setNuevo] = useState(false);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fecha =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const addOrEdit = async (registroItem, resetForm) => {
    try {
      if (!modoEdicion) {
        await EventosServices.insert(registroItem, token, tipoEvento);
      } else {
        await EventosServices.update(registroItem, token, tipoEvento);
      }
      resetForm();
      setGuardando(false);
      setRegistros({});
      setCargando(false);
      setNuevo(false);
    } catch (err) {
      return false;
    }

    resetForm();
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
  };

  const validate = (fieldValues = values) => {

    let temp = { ...errors };

    if ("observaciones" in fieldValues)
      temp.observaciones = fieldValues.observaciones ? "" : "Dato requerido";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const nuevoForm = () => {
    setModoEdicion(false);
    setNuevo(!nuevo);
  };

  const editForm = (item) => {
    setModoEdicion(true);
    setSelectedDate(moment(item.fecha));
    values.eventoId = item.id;
    values.observaciones = item.observaciones;
    setNuevo(true);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.observaciones) {
      if (values.observaciones.length > 4) {
        if (validate()) {

          values.fecha = selectedDate;

          setGuardando(true);
          addOrEdit(values, resetForm).then((e) => {
            if (e === false) {
              setGuardando(false);
              alert(
                "No se pudo completar la operación, revise los datos e intente nuevamente"
              );
            }
          });
        }
      } else {
        alert("La descripción debe contener un mínimo de 5 letras");
      }
    } else {
      alert("Complete una descripción válida");
    }
  };

  const convertirFechaToStr = (fechaTurno) => {
    const texto =
      fechaTurno.substr(8, 2) +
      "/" +
      fechaTurno.substr(5, 2) +
      "/" +
      fechaTurno.substr(0, 4);
    return texto;
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        id: recordForEdit.id,
      });
    }

    if (!cargando) {
      setCargando(true);
      EventosServices.getAllItems(
        props.token,
        recordForEdit.id,
        tipoEvento
      ).then((res) => {
        setRegistros(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  const obtenerNombreManzana = (manzana, id) => {
    const manzanas = manzana.split(",");
    return manzanas[id];
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {tipoEvento === "pventa" ? (
            <h3>{recordForEdit.PropiedadesVentum.nombre}</h3>
          ) : (
            <h3>
              {recordForEdit.Lotesxloteo.Loteo.nombre} -{" "}
              {obtenerNombreManzana(
                recordForEdit.Lotesxloteo.Loteo.cantManzanas,
                recordForEdit.Lotesxloteo.manzana
              )}{" "}
              - {recordForEdit.Lotesxloteo.nombre}
            </h3>
          )}
        </Grid>
        {puedeAgregar && (
          <Grid style={{ textAlign: "right" }} item xs={6}>
            <Controls.Button
              text={nuevo ? "-" : "+"}
              color="primary"
              onClick={nuevoForm}
            />
          </Grid>
        )}
      </Grid>
      {nuevo && (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fecha"
                      minDate={new Date()}
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      helperText="Fecha"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    name="observaciones"
                    helperText="Descripción de la acción realizada"
                    value={values.observaciones}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 254);
                    }}
                    rowsMax={4}
                    error={errors.descripcion}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: 25, textAlign: "right" }}>
                {!guardando ? (
                  <Controls.Button type="submit" text="Guardar" />
                ) : (
                  <CircularProgress size={26} />
                )}
                <br /> <br />
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
      {registros ? (
        <>
          {registros.length ? (
            <>
              <AppBar position="static">
                <Tabs
                  value={value}
                  indicatorColor="secondary"
                  textColor="white"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                >
                  <Tab label="PRÓXIMOS EVENTOS" />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {registros.map((item, index) => (
                  <div
                    style={{
                      backgroundColor: "lightblue",
                      padding: 10,
                      borderRadius: 10,
                      margin: 5,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        float: "right",
                        marginRight: 10,
                        marginTop: 5,
                      }}
                    >
                      {" "}
                      {item.Usuario.apellido} {item.Usuario.nombre}
                      <Controls.Button
                        text={
                          nuevo ? (
                            <EditIcon style={{ fontSize: 14 }} />
                          ) : (
                            <EditIcon style={{ fontSize: 14 }} />
                          )
                        }
                        style={{
                          background: "blue",
                          padding: 5,
                          paddingRight: 0,
                        }}
                        onClick={() => editForm(item)}
                      />
                    </div>
                    <AccessAlarmIcon
                      style={{ marginBottom: -5, marginRight: 5 }}
                    />
                    <b> {convertirFechaToStr(item.fecha)} </b>
                    <br />
                    {item.observaciones} <br />
                  </div>
                ))}
              </TabPanel>
            </>
          ) : (
            <> Sin eventos registrados</>
          )}
        </>
      ) : (
        <>Sin eventos registrados</>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
