import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';

export default function Select(props) {

    const { name, label, value,error=null, onChange, options } = props;

    return (
        <FormControl  size="small" variant="outlined"
        {...(error && {error:true})}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                label={label}
               
                name={name}
                value={value}
                onChange={onChange}>
                <MenuItem value="">None</MenuItem>
                { options.length &&

                    options.map(
                        item => {
                            if(item)
                            {
                            return (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                            } else {                //Línea Modificada
                                return (false);     //Línea Modificada
                            }
                            
                        }
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
