import React, { useState, useEffect } from "react";

import * as ClienteServices from "../../services/cliente/cliente";
import * as ConsultaWebServices from "../../services/web/consultaWeb";

import ConsultaWebForm from "./ConsultaWebForm";
import ConsultaWebAdvertencia from "./ConsultaWebAdvertencia";

import { useUserState } from "../../context/UserContext";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";
import Popup from "../../components/tablas/Popup";
import Tarjeta from "../utiles/Tarjeta";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
} from "@material-ui/core";
//import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
//import AnnouncementIcon from "@material-ui/icons/Announcement";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "telefono", label: "Telefono" },
  { id: "email", label: "Email" },
  { id: "Consulta", label: "Consulta" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#F0EEC2",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function ClienteConError() {
  const { token } = useUserState();
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({  // eslint-disable-line
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupAdvertencia, setOpenPopupAdvertencia] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [advertencia, setAdvertencia] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [registros, setRegistros] = useState({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros.consultas, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ConsultaWebServices.consulta(token).then((res) => {
        console.log('Consulta desde Web: ', res);
        setRegistros(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInPopupAdvertencia = (id, index) => {
    setAdvertencia({id: id, telefono: registros.telefono[index], email: registros.email[index]})
    //setRecordForEdit(item);
    setOpenPopupAdvertencia(true);
  };

  const addOrEditAdvertencia = async (resetForm) => {
    //console.log('Llega a add or edit: ', registroItem);
    //console.log('record: ', recordForEdit);

    let data = {
      id: advertencia.id,
      procesado: true,
    };

    try {
      await ConsultaWebServices.update(data, token);
    } catch (err) {
      return false;
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopupAdvertencia(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    setRegistros(ConsultaWebServices.consulta(token));
  };

  const addOrEdit = async (registroItem, resetForm) => {
    //console.log('Llega a add or edit: ', registroItem);
    //console.log('record: ', recordForEdit);

    try {
      await ClienteServices.insert(registroItem, token);
    } catch (err) {
      return false;
    }

    let data = {
      id: recordForEdit.id,
      procesado: true,
    };

    try {
      await ConsultaWebServices.update(data, token);
    } catch (err) {
      return false;
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    setRegistros(ConsultaWebServices.consulta(token));
  };

  return (
    <>
      {registros && registros.consultas && registros.consultas.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="CONSULTAS DESDE LA WEB"
                icon={
                  <DoubleArrowIcon
                    style={{ fontSize: 40, color: "green", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={registros.consultas}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#F0EEC2" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <h2>
                        <DoubleArrowIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                        CONSULTAS DESDE LA WEB{" "}
                        <DoubleArrowIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                      </h2>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: 10 }}>
                      <Controls.Button
                        text="Ocultar"
                        onClick={() => setVisible(!visible)}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.nombre}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.telefono}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.email}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.LoteoId !== null
                            ? item.Loteo.nombre + " - " + item.consulta
                            : item.PropiedadesVentumId !== null
                            ? item.PropiedadesVentum.nombre +
                              " - " +
                              item.consulta
                            : item.consulta}
                        </TableCell>
                        <TableCell>
                          {registros.telefono[index] || registros.email[index] ?
                            <Controls.ActionButton
                              color="secondary"
                              onClick={() => {
                                openInPopupAdvertencia(item.id, index);
                              }}
                            >
                              <PriorityHighIcon fontSize="small" style={{ color: "white" }}/>
                            </Controls.ActionButton>
                            :
                            <></>
                          }
                          <Controls.ActionButton
                            color="primary"
                            onClick={() => {
                              openInPopup(item);
                            }}
                          >
                            <DoubleArrowIcon fontSize="small" />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Popup
        title="Potenciales clientes"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ConsultaWebForm
          token={token}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Popup
        title="Advertencia de coincidencias"
        openPopup={openPopupAdvertencia}
        setOpenPopup={setOpenPopupAdvertencia}
      >
        <ConsultaWebAdvertencia
          token={token}
          advertencia={advertencia}
          setOpenPopup={setOpenPopupAdvertencia}
          addOrEdit={addOrEditAdvertencia}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
