import GestorAxios from "../../config/axios";

export async function getAllItems(token, id, tipoEvento) {
  let data = {};

  await GestorAxios.get(
    "/ventas/eventosReservas/ver/" + id + "/" + tipoEvento,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    data = res.data;
  });

  return data;
}

export async function getProximosEventos(token, id, tipoEvento) {
  let data = {};

  await GestorAxios.get("/ventas/eventosReservas/proximos", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function insert(data, token, tipoEvento) {
  let dataReturn = {};

  if (tipoEvento === "pventa") {
    data.ReservasPropiedadVentumId = data.id;
  } else {
    data.ReservasLoteId = data.id;
  }

  await GestorAxios.post("/ventas/eventosReservas/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token, tipoEvento) {
  let dataReturn = {};

  if (tipoEvento === "pventa") {
    data.ReservasPropiedadVentumId = data.id;
  } else {
    data.ReservasLoteId = data.id;
  }

  await GestorAxios.put(
    `/ventas/eventosReservas/actualizar/${data.eventoId}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}
