import React from "react";

import { Grid, Paper } from "@material-ui/core";

export default function TransferenciaForm(props) {
  const { recordForEdit } = props;

  return (
    <Paper elevation={0}>
      <Grid container>
        {recordForEdit && recordForEdit.id ? (
          <Grid item xs={4} style={{ backgroundColor: "#E1FCE9", textAlign: "center", marginLeft: -5, marginRight: 5, borderRadius: 10 }}>
            <h3 style={{ marginLeft: 10 }}>
              {recordForEdit.ClienteId
                ? "CLIENTE: " +
                  recordForEdit.Cliente.nombre +
                  " " +
                  recordForEdit.Cliente.apellido
                : recordForEdit.AsociadoId
                ? "ASOCIADO: " +
                  recordForEdit.Asociado.nombre +
                  " " +
                  recordForEdit.Asociado.apellido
                : recordForEdit.UsuarioId
                ? "USUARIO: " +
                  recordForEdit.Usuario.nombre +
                  " " +
                  recordForEdit.Usuario.apellido
                : recordForEdit.PropietarioId
                ? "PROPIETARIO: " +
                  recordForEdit.Propietario.nombre +
                  " " +
                  recordForEdit.Propietario.apellido
                : ""}
            </h3>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={4} style={{ backgroundColor:"#EAF2D8", textAlign: "center", marginLeft: -5, marginRight: 5, borderRadius: 10 }}>
          <h3>
            {recordForEdit.ingreso
              ? "Ingreso: $ " + new Intl.NumberFormat().format(recordForEdit.ingreso)
              : "Egreso: $ " + new Intl.NumberFormat().format(recordForEdit.egreso)}
          </h3>
        </Grid>
        <Grid item xs={4} style={{ backgroundColor: "#E1FCE9", textAlign: "center", marginLeft: -5, marginRight: 5, borderRadius: 10 }}>
          {console.log(recordForEdit.Origen)}
          <h3>Origen: {recordForEdit.Origen.nombre}</h3>
        </Grid>
      </Grid>
    </Paper>
  );
}
