import React, { useState, useEffect } from "react";

import * as ConsultaWebServices from "../../services/web/consultaWeb";

import { useUserState } from "../../context/UserContext";

import useTable from "../../components/tablas/useTable";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
} from "@material-ui/core";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "telefono", label: "Telefono" },
  { id: "email", label: "Email" },
  { id: "Consulta", label: "Consulta" },
  { id: "origen", label: "Origen" },
  //{ id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function ClienteConError() {
  const { token } = useUserState();
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({  // eslint-disable-line
    fn: (items) => {
      return items;
    },
  });

  const [registros, setRegistros] = useState({});

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ConsultaWebServices.getAllItems(token).then((res) => {
        //console.log('Consulta desde Web: ', res);
        setRegistros(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {registros.length ? (
        <Paper className={classes.pageContent}>
          <Toolbar>
            <h2>
              {" "}
              CONSULTAS DESDE LA WEB{" "}
              {/*<KeyboardArrowDownIcon style={{ fontSize: 40, color: "blue" }} />{" "}*/}
            </h2>
          </Toolbar>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {" "}
                    {item.nombre}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {" "}
                    {item.telefono}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {" "}
                    {item.email}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {" "}
                    {item.LoteoId !== null
                      ? item.Loteo.nombre + " - " + item.consulta
                      : item.PropiedadesVentumId !== null
                      ? item.PropiedadesVentum.nombre + " - " + item.consulta
                      : item.consulta}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {" "}
                    {item.origen}{" "}
                  </TableCell>
                  <TableCell>
                    {/*<Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                      }}
                    >
                      <KeyboardArrowDownIcon fontSize="small" />
                    </Controls.ActionButton>*/}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Paper>
      ) : (
        <></>
      )}
      {/*<Popup
        title="Potenciales clientes"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ConsultaWebForm
          token={token}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />*/}
    </>
  );
}
