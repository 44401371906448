import GestorAxios from "../../config/axios";

export async function getAllItems(token, id) {
  let data = {};

  await GestorAxios.get("/productos/lotesxloteo/filtrar/LoteoId/" + id + "/", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

// multipart upload - envia archivo
export async function insert(data, token) {
  let dataReturn = {};

  await GestorAxios.post("/productos/lotesxloteo/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

// borrar
export async function borrar(id, token) {
  let dataReturn = false;

  await GestorAxios.delete(`/productos/lotesxloteo/borrar/${id}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = true;
  });

  return dataReturn;
}

export async function update(data, id, token) {
  let dataReturn = {};

  await GestorAxios.put(`/productos/lotesxloteo/actualizar/${id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function updateReserva(data, id, token) {
  let dataReturn = {};
  console.log('Llega a service: ', id, data);

  await GestorAxios.put(
    `/productos/lotesxloteo/actualizarreserva/${id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => {
      dataReturn = res;
    })
    .catch((error) => console.log("Error: ", error.message));

  return dataReturn;
}

export async function updatePreciosLoteo(data, id, token) {
  let dataReturn = {};
  console.log('Data en services: ', data);
  await GestorAxios.put(
    `/productos/lotesxloteo/actualizarpreciosloteo/${id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => {
      dataReturn = res;
    })
    .catch((error) => console.log("Error: ", error.message));

  return dataReturn;
}

export async function updatePreciosManzana(data, token) {
  let dataReturn = {};
  console.log('Data en services: ', data, data.LoteoId, data.manzana);
  await GestorAxios.put(
    `/productos/lotesxloteo/actualizarpreciosmanzana/${data.LoteoId}/${data.manzana}`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => {
      dataReturn = res;
    })
    .catch((error) => console.log("Error: ", error.message));

  return dataReturn;
}

export async function encontrar(token, datos) {
  let data = {};
  console.log('En services: ', token, datos);
  await GestorAxios.post("/productos/lotesxloteo/encontrar/", datos, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
