import React, { useState, useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";

import { Grid, CircularProgress, Paper } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PermScanWifiIcon from "@material-ui/icons/PermScanWifi";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import Panel from "./Panel";

const defaultTheme = createMuiTheme();

Object.assign(defaultTheme, {
  overrides: {
    RichTextEditor: {
      root: {
        marginTop: 20,
        width: "80%",
      },
      editor: {
        border: "1px solid gray",
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function VentasLotesForm(props) {
  const {
    procesar,
    recordForEdit,
    listaFormaPago,
    lotes,
    asociadosXLote,
    listaMoneda,
    ultimoCodigo,
    //token,
    listaUsuarios,
    itemsComprados,
  } = props;

  console.log("RecordForEdit: ", recordForEdit);
  console.log("Usuarios: ", listaUsuarios);

  const loteo = recordForEdit.Lotesxloteo.Loteo;
  const cliente = `${recordForEdit.Cliente.nombre} ${recordForEdit.Cliente.apellido}`;
  const vendedor = `${recordForEdit.Usuario.nombre} ${recordForEdit.Usuario.apellido}`;
  const comisionGastos = recordForEdit.comisionGastos || 0;

  const crearCodigo = () => {
    let cod = "";
    if (ultimoCodigo >= 0) {
      cod = cod + (parseInt(ultimoCodigo) + 1).toString();
    }
    if (recordForEdit.Lotesxloteo.Loteo.codigo) {
      cod = cod + "-" + recordForEdit.Lotesxloteo.Loteo.codigo;
    }
    if (recordForEdit.Lotesxloteo) {
      let manzana =
        recordForEdit.Lotesxloteo.Loteo.cantManzanas.split(",")[
          parseInt(recordForEdit.Lotesxloteo.manzana)
        ];
      cod = cod + "-" + manzana + "-" + recordForEdit.Lotesxloteo.nombre;
    }
    return cod;
  };

  const [cargando, setCargando] = useState(false);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [aporteSocios, setAporteSocios] = useState([]);
  const [pagoSocios, setPagoSocios] = useState([]);
  const [value, setValue] = useState(0);
  const [entrega, setEntrega] = useState(0);
  const [totalPagoSociosSumaTabletas, setTotalPagoSociosSumaTabletas] = useState(0);
  const [totalAporteSociosSumaTabletas, setTotalAporteSociosSumaTabletas] = useState(0);
  const [totalPagoPrimerSocio, setTotalPagoPrimerSocio] = useState(0);
  const [guardando, setGuardando] = useState(false);
  const [comisiones, setComisiones] = React.useState([]);
  const [visible_FPago2, setVisibleFPago2] = useState(false);
  const [visible_FPago3, setVisibleFPago3] = useState(false);
  const [visible_FPago4, setVisibleFPago4] = useState(false);

  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [selectedDate3, setSelectedDate3] = useState(new Date());
  const [selectedDate4, setSelectedDate4] = useState(new Date());
  const [selectedDateVencimiento, setSelectedDateVencimiento] = useState(new Date());
  const [codigo, setCodigo] = useState(crearCodigo);

  const handleDateChange1 = (date) => {
    //console.log("Dia seleccionado: ", date);
    setSelectedDate1(date);
    const f = new Date(date);
    values.fechaPactada1 =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const handleDateChange2 = (date) => {
    //console.log("Dia seleccionado: ", date);
    setSelectedDate2(date);
    const f = new Date(date);
    values.fechaPactada2 =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const handleDateChange3 = (date) => {
    //console.log("Dia seleccionado: ", date);
    setSelectedDate3(date);
    const f = new Date(date);
    values.fechaPactada3 =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const handleDateChange4 = (date) => {
    //console.log("Dia seleccionado: ", date);
    setSelectedDate4(date);
    const f = new Date(date);
    values.fechaPactada4 =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const handleDateChangeVencimiento = (date) => {
    //console.log("Dia seleccionado: ", date);
    setSelectedDateVencimiento(date);
    const f = new Date(date);
    values.fechaVencimiento =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const fa = new Date();
  const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

  const importeReserva = () => {
    let res = 0;
    if (recordForEdit.importe1) {
      res = recordForEdit.importe1;
    }
    if (recordForEdit.importe2) {
      res = res + recordForEdit.importe2;
    }
    if (recordForEdit.importe3) {
      res = res + recordForEdit.importe3;
    }
    if (recordForEdit.importe4) {
      res = res + recordForEdit.importe4;
    }
    return res;
  };

  const listaSociosUnica = (lista, pagos) => {
    let auxSocios = [];
    let auxPagos = [];
    let filtrados = [];
    let pagosTodos = [];
    let resultado = [];
    for (let i = 0; i < lista.length; i++) {
      auxSocios = auxSocios.concat(lista[i]);
      auxPagos = auxPagos.concat(pagos[i])
    }
    let socios = auxSocios.map((item) => {return {id: item.AsociadoId, nombre: item.Asociado.nombre, apellido: item.Asociado.apellido, pago: 0}});
    let ids = socios.map((item) => item.id)
    let idsFiltrados = ids.filter((id, index) => {return ids.indexOf(id) === index;})
    for (let i = 0; i < idsFiltrados.length; i++) {
      filtrados.push(socios.find((socio) => {return socio.id === idsFiltrados[i]}));
      let contador = 0;
      for (let j = 0; j < auxPagos.length; j++) {
        if (idsFiltrados[i] === ids[j]) {
          contador += parseInt(auxPagos[j]);
        }
      }
      pagosTodos.push(contador);
    }
    for (let i = 0; i < filtrados.length; i++) {
      resultado.push({id: filtrados[i].id, nombre: filtrados[i].nombre, apellido: filtrados[i].apellido, pago: pagosTodos[i]});
    }
    console.log('Lista Socios Unica: ', filtrados);
    console.log('--------------Pagos: ', pagosTodos);
    return resultado;
  }

  const diferenciaEntrega = (entrega, items) => {
    let diferencia = 0;
    if (entrega) {
      diferencia = entrega;
      if (items.importeReserva1) diferencia -= items.importeReserva1;
      if (items.importeReserva2 && visible_FPago2) diferencia -= items.importeReserva2;
      if (items.importeReserva3 && visible_FPago3) diferencia -= items.importeReserva3;
      if (items.importeReserva4 && visible_FPago4) diferencia -= items.importeReserva4;
    } 
    return diferencia;
  }

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const setearArrayPagosSocios = (indice, valor) => {
    let vp = pagoSocios.slice();
    vp[indice] = valor;
    setPagoSocios(vp);
    setTotalPagoSociosSumaTabletas(sumaPagoSocios(vp));
  };

  const setearArrayAportesSocios = (indice, valor) => {
    let vp = aporteSocios.slice();
    vp[indice] = valor;
    setAporteSocios(vp);
    setTotalAporteSociosSumaTabletas(sumaAporteSocios(vp));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputComisionChange = (e, usuarioId) => {
    let c = comisiones;
    c[usuarioId] = e.target.value;
    setComisiones(c);
    cambiaComisionUsuario(e.target.value, usuarioId);
  };

  const handleInputChangeEntrega = (e) => {
    setEntrega(e.target.value);
    handleInputChange(e);
  };

  const agregarUsuario = () => {
    if (usuarioSeleccionado) {
      let tempUsuarios = usuarios;
      const existe = tempUsuarios.find((s) => s.id === usuarioSeleccionado);
      if (!existe) {
        tempUsuarios.push({
          id: usuarioSeleccionado,
          comision: 0,
        });
      } else {
        alert("Ya esta ingresado el usuario seleccionado");
      }
      setUsuarios(tempUsuarios);
      validate();
    } else {
      alert("Seleccione un socio");
    }
  };

  const eliminarUsuario = (id) => {
    let tempUsuarios = usuarios;
    const ss = tempUsuarios.filter((s) => s.id !== id);
    setUsuarios(ss);
  };

  const obtenerUsuario = (id) => {
    let tempUsuarios = listaUsuarios;
    const ss = tempUsuarios.find((s) => s.id === id);
    if (ss) {
      return ss.apellido + " " + ss.nombre;
    }
  };

  const buscarObjeto = (id, datos) => {
    let ret = {};
    if (datos.length) {
      if (id === 0) {
        ret = undefined;
      } else {
        const itemEncontrado = datos.find((item) => item.id === id);
        if (itemEncontrado) {
          ret = itemEncontrado;
        }
      }
    }
    return ret;
  };

  const cambiaComisionUsuario = (value, usuarioId) => {
    let tempUsuarios = usuarios;
    tempUsuarios.forEach((s) => {
      if (s.id === usuarioId) {
        s.comision = value;
      }
    });
    setUsuarios(tempUsuarios);
    validate();
  };

  let totalComisiones = 0;

  const sumaComisiones = (comision) => {
    let resultado = 0;
    for (let i = 0; i < comision.length; i++) {
      if (comision[i]) {
        resultado = parseInt(resultado) + parseInt(comision[i]);
      }
    }
    return resultado;
  };

  const sumaAporteSocios = (aporte) => {
    let resultado = 0;

    let i = 0;
    let j = 0;
    for (i = 0; i < aporte.length; i++) {
      for (j = 0; j < aporte[i].length; j++) {
        resultado = resultado + parseInt(aporte[i][j]);
      }
    }

    return resultado;
  };

  const sumaPagoSocios = (pago) => {
    let resultado = 0;
    let auxiliar = 0;

    for (let i = 0; i < pago.length; i++) {
      for (let j = 0; j < pago[i].length; j++) {
        if (j === 0) {
          auxiliar = parseInt(auxiliar) + parseInt(pago[i][j]);
        }
        resultado = resultado + parseInt(pago[i][j]);
      }
    }
    setTotalPagoPrimerSocio(parseInt(auxiliar));
    return resultado;
  };

  const unirDatosSocios = (socios, aportes, pagos) => {
    //console.log("Datos en funcion socios: ", socios);
    //console.log("Datos en funcion aportes: ", aportes);
    //console.log("Datos en funcion pagos: ", pagos);
    let ss = socios;
    console.log(ss.length, ss);
    for (let i = 0; i < ss.length; i++) {
      console.log(ss[i].length, ss[i]);
      for (let j = 0; j < ss[i].length; j++) {
        if (pagos[i][j]) {
          ss[i][j].pagoPorPorcentaje = pagos[i][j];
        } else {
          ss[i][j].pagoPorPorcentaje = 0;
        }
        if (aportes[i][j]) {
          ss[i][j].aporteComision = aportes[i][j];
        } else {
          ss[i][j].aporteComision = 0;
        }
        console.log(ss[i][j]);
      }
    }
    return ss;
  };

  const controlDatosSocios = (socios) => {
    //console.log('socios en control', socios);
    let existe = false;
    let i = 0;
    let j = 0;
    while (i < socios.length && !existe) {
      while (
        j < socios[i].length &&
        (socios[i][j].pagoPorPorcentaje === 0 ||
          socios[i][j].aporteComision === 0)
      ) {
        existe =
          existe ||
          socios[i][j].pagoPorPorcentaje === 0 ||
          socios[i][j].aporteComision === 0;
        j++;
      }
      i++;
    }
    if (!existe) return true;
    // si no existe es porque está todo bien, no hay campos en 0
    else return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.totalSeniaFinalReserva) {
      alert("Por favor complete la RESERVA del lote");
      return false;
    }
    if (!values.totalEntregadoFinalReserva) {
      alert("Por favor complete la ENTREGA del lote");
      return false;
    }
    values.totalComisionesFinalReserva = sumaComisiones(comisiones);
    values.comisionGastos = comisionGastos;
    values.gananciaFinalReserva = parseInt(totalPagoPrimerSocio) + parseInt(totalAporteSociosSumaTabletas) - parseInt(totalComisiones) + parseInt(comisionGastos);
    if (values.gananciaFinalReserva) {
      values.dineroDisponibleFinalReserva = parseInt(totalPagoPrimerSocio) + totalAporteSociosSumaTabletas - parseInt(totalComisiones) + parseInt(comisionGastos) - parseInt(values.totalSeniaFinalReserva)
    }
    values.socios = unirDatosSocios(asociadosXLote, aporteSocios, pagoSocios);
    values.usuarios = usuarios;
    values.lotes = itemsComprados;
    values.codigo = crearCodigo();
    values.fechaVencimiento = selectedDateVencimiento;
    values.listaSociosTranferencias = listaSociosUnica(asociadosXLote, pagoSocios);
    //console.log("Values.socios: ", values.socios);
    //console.log('values: ', values);
    //console.log('CONTROL: ', controlDatosSocios(values.socios), values.socios)
    if (!controlDatosSocios(values.socios)) {
      if (!window.confirm("Detectado campo 0 en socio. ¿Continuar?")) {
        return false;
      }
    } else {
      console.log('Pasa a addOrEdit...', values);
      if (validate()) {
        setGuardando(true);
        values.vendido = true;
        values.procesado = true;
        procesar(values, resetForm).then((e) => {
          if (e === false) {
            setGuardando(false);
            alert(
              "No se pudo completar la operación, revise los datos e intente nuevamente"
            );
          }
        });
      }
    }
  };

  // const DiaMes = (diames) => {
  //   if (diames < 10) {
  //     return "0" + diames;
  //   } else {
  //     return diames;
  //   }
  // };

  useEffect(() => {
    let vectorAportes = [];
    let vectorPagos = [];
    let vectorCantidad = [];
    if (!cargando) {
      setCargando(true);
      if (itemsComprados && itemsComprados.length) {
        for (let i = 0; i < asociadosXLote.length; i++) {
          console.log('Items en useEffect: ', itemsComprados, asociadosXLote);
          vectorAportes[i] = asociadosXLote[i].map((v) => v.aporteComision ? v.aporteComision : 0);
          vectorPagos[i] = asociadosXLote[i].map((v) => v.pagoPorPorcentaje ? v.pagoPorPorcentaje : 0);
          vectorCantidad.push(asociadosXLote[i].length);
        }
        //console.log("Vector Socios: ", vectorSocios);
        setAporteSocios(vectorAportes);
        setPagoSocios(vectorPagos);
        console.log("Vector aportes: ", vectorAportes);
        console.log("Vector pagos: ", vectorPagos);
        //console.log(recordForEdit, recordForEdit != null);
      }
    }
  }, [recordForEdit, itemsComprados]); // eslint-disable-line react-hooks/exhaustive-deps

  const initialFValues = {
    id: recordForEdit.id,
    totalSeniaFinalReserva: "",
    totalEntregadoFinalReserva: "",
    obsFinalReserva: "",
    totalComisionesFinalReserva: comisiones,
    comisionGastos: comisionGastos,
    gananciaFinalReserva:
      parseInt(totalPagoPrimerSocio) +
      parseInt(totalAporteSociosSumaTabletas) -
      parseInt(totalComisiones) +
      parseInt(comisionGastos),
    socio: asociadosXLote,
    aporteSocios: aporteSocios,
    pagoSocios: pagoSocios,
    usuarios: usuarios,
    importeReserva1: "",
    importeReserva2: "",
    importeReserva3: "",
    importeReserva4: "",
    formaPagoReservaId1: "",
    formaPagoReservaId2: "",
    formaPagoReservaId3: "",
    formaPagoReservaId4: "",
    fechaPactada1: fc,
    fechaPactada2: fc,
    fechaPactada3: fc,
    fechaPactada4: fc,
    codigo: crearCodigo(),
    fechaVencimiento: fc,
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid
        container
        style={{
          borderRadius: 20,
          backgroundColor: "#f3f3f3",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <h3>Loteo: {loteo.nombre}</h3>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <h3>Cliente: {cliente}</h3>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <h3>Fecha: {fc}</h3>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <h3>Vendedor: {vendedor}</h3>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ width: "80%", marginLeft: "10%" }}>
                <hr />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/*------------------------------------Reserva------------------------------------*/}
      <Grid container>
        <Grid item xs={4}>
          <Grid
            container
            style={{
              backgroundColor: "#DDEDD5",
              padding: "10px 20px",
              marginTop: "20px",
              marginBottom: "20px",
              marginRight: "10px",
              //border: "1px solid",
              borderRadius: 10,
            }}
          >
            <Grid item xs={12}>
              {/* <h2>Entrega: $ {recordForEdit.entrega}</h2> */}
              <h2>Reserva:</h2>
              {recordForEdit.importe1 && (
                <h4 style={{ marginLeft: "15%" }}>
                  -{" "}
                  {
                    listaFormaPago.find(
                      (pago) => pago.id === recordForEdit.FormaPagoId1
                    ).nombre
                  }
                  ,{" "}
                  {
                    listaMoneda.find(
                      (moneda) => moneda.id === recordForEdit.MonedaId1
                    ).nombre
                  }
                  : $ {new Intl.NumberFormat().format(recordForEdit.importe1)}
                </h4>
              )}
              {recordForEdit.importe2 && (
                <h4 style={{ marginLeft: "15%" }}>
                  -{" "}
                  {
                    listaFormaPago.find(
                      (pago) => pago.id === recordForEdit.FormaPagoId2
                    ).nombre
                  }
                  ,{" "}
                  {
                    listaMoneda.find(
                      (moneda) => moneda.id === recordForEdit.MonedaId2
                    ).nombre
                  }
                  : $ {new Intl.NumberFormat().format(recordForEdit.importe2)}
                </h4>
              )}
              {recordForEdit.importe3 && (
                <h4 style={{ marginLeft: "15%" }}>
                  -{" "}
                  {
                    listaFormaPago.find(
                      (pago) => pago.id === recordForEdit.FormaPagoId3
                    ).nombre
                  }
                  ,{" "}
                  {
                    listaMoneda.find(
                      (moneda) => moneda.id === recordForEdit.MonedaId3
                    ).nombre
                  }
                  : $ {new Intl.NumberFormat().format(recordForEdit.importe3)}
                </h4>
              )}
              {recordForEdit.importe4 && (
                <h4 style={{ marginLeft: "15%" }}>
                  -{" "}
                  {
                    listaFormaPago.find(
                      (pago) => pago.id === recordForEdit.FormaPagoId4
                    ).nombre
                  }
                  ,{" "}
                  {
                    listaMoneda.find(
                      (moneda) => moneda.id === recordForEdit.MonedaId4
                    ).nombre
                  }
                  : $ {new Intl.NumberFormat().format(recordForEdit.importe4)}
                </h4>
              )}
              <Controls.InputMask
                name="totalSeniaFinalReserva"
                label="Suma de los importes de la Reserva"
                value={values.totalSeniaFinalReserva}
                onChange={handleInputChange}
                variant="outlined"
                prefix="$ "
              />
              <Controls.InputMask
                name="comisionGastos"
                label="Comision y Gastos Administrativos"
                value={values.comisionGastos}
                onChange={handleInputChange}
                variant="outlined"
                prefix="$ "
              />
              <Controls.InputMask
                name="totalEntregadoFinalReserva"
                label="Ingrese el monto total de Entrega"
                value={values.totalEntregadoFinalReserva}
                onChange={handleInputChangeEntrega}
                variant="outlined"
                prefix="$ "
              />
              <div style={{ marginLeft: 30 }}>
                {parseInt(totalPagoSociosSumaTabletas) ===
                parseInt(values.totalEntregadoFinalReserva) ? (
                  <CheckCircleIcon />
                ) : (
                  <PermScanWifiIcon />
                )}
                Total : ${" "}
                {totalPagoSociosSumaTabletas ? new Intl.NumberFormat().format(totalPagoSociosSumaTabletas) : 0}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1}></Grid>

        {/*------------------------------------Entrega------------------------------------*/}
        <Grid item xs={7}>
          <Grid
            container
            style={{
              backgroundColor: "#DDEDD5",
              padding: "10px 20px",
              marginTop: "20px",
              marginBottom: "20px",
              marginRight: "10px",
              //border: "1px solid",
              borderRadius: 10,
            }}
          >
            <Grid item xs={12}>
              <h2>
                Entrega Pactada: ${" "}
                {new Intl.NumberFormat().format(recordForEdit.entrega)}
              </h2>
            </Grid>
            <Grid item xs={3}>
              <Controls.InputMask
                name="importeReserva1"
                label="Importe"
                value={values.importeReserva1}
                variant="outlined"
                onChange={handleInputChange}
                prefix="$ "
              />
            </Grid>
            <Grid item xs={3} style={{marginTop: 5}}>
              <Controls.Select
                name="FormaPagoReservaId1"
                label="Forma de Pago"
                value={values.FormaPagoReservaId1 || ""}
                onChange={handleInputChange}
                options={listaFormaPago.map((item) => {return { id: item.id, title: item.nombre };})}
              />
            </Grid>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechaPactada1"
                  //maxDate={new Date()}
                  format="dd/MM/yyyy"
                  value={selectedDate1}
                  onChange={(e) => handleDateChange1(e)}
                  helperText="Fecha Pactada"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2}>
              <Controls.ActionButton
                style={{ marginTop: 15 }}
                color="primary"
                onClick={() => {
                  setVisibleFPago2(true);
                }}
              >
                <AddCircleIcon fontSize="small" />
              </Controls.ActionButton>
            </Grid>
            {buscarObjeto(values.FormaPagoReservaId1, listaFormaPago).esBancarizado &&
              <>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <h2>Usuarios:</h2>
                    <Paper>
                      {usuarios && usuarios.length ?
                        <>
                          {usuarios.map((user) => 
                            <Grid container>
                              <Grid item xs={7} style={{ paddingLeft: 5 }}>
                                <h3>{buscarObjeto(user.id, listaUsuarios).nombre} {buscarObjeto(user.id, listaUsuarios).apellido}</h3>
                              </Grid>
                              <Grid item xs={5}>
                                <Controls.InputMask
                                  name={"usuario" + user.id}
                                  label="Comisión"
                                  value={user.comision}
                                  variant="outlined"
                                  onChange={handleInputChange}
                                  prefix="$ "
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                        :
                        <></>
                      }
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <h2>Socios:</h2>
                    <Paper>
                      {asociadosXLote && asociadosXLote.length &&
                        <>
                          {listaSociosUnica(asociadosXLote, pagoSocios).map((socio) => 
                            <Grid container>
                              {console.log('Pago Socios: ', pagoSocios)}
                              <Grid item xs={7} style={{ paddingLeft: 5 }}>
                                <h3>{socio.nombre} {socio.apellido}</h3>
                              </Grid>
                              <Grid item xs={5}>
                                <Controls.InputMask
                                  name={"socio" + socio.id}
                                  label="Pago"
                                  value={socio.pago}
                                  variant="outlined"
                                  onChange={handleInputChange}
                                  prefix="$ "
                                />
                              </Grid>
                              {console.log('Resultado de socio: ', socio)}
                            </Grid>
                          )}
                        </>
                      }
                    </Paper>
                  </Grid>
                </Grid>
              </>
            }
            {visible_FPago2 && (
              <>
                <Grid item xs={3}>
                  <Controls.InputMask
                    name="importeReserva2"
                    label="Importe"
                    value={values.importeReserva2}
                    variant="outlined"
                    onChange={handleInputChange}
                    prefix="$ "
                  />
                </Grid>
                <Grid item xs={3} style={{marginTop: 5}}>
                  <Controls.Select
                    name="FormaPagoReservaId2"
                    label="Forma de Pago"
                    value={values.FormaPagoReservaId2 || ""}
                    onChange={handleInputChange}
                    options={listaFormaPago.map((item) => {return { id: item.id, title: item.nombre };})}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaPactada2"
                      //maxDate={new Date()}
                      format="dd/MM/yyyy"
                      value={selectedDate2}
                      onChange={handleDateChange2}
                      helperText="Fecha Pactada"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                  <Controls.ActionButton
                    style={{ marginTop: 15 }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago3(true);
                    }}
                  >
                    <AddCircleIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    style={{ marginTop: 15, backgroundColor: "red" }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago2(false);
                    }}
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </Controls.ActionButton>
                </Grid>
                {buscarObjeto(values.FormaPagoReservaId2, listaFormaPago).esBancarizado &&
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <h2>Usuarios:</h2>
                      <Paper>
                        {usuarios && usuarios.length ?
                          <>
                            {usuarios.map((user) => 
                              <Grid container>
                                <Grid item xs={7} style={{ paddingLeft: 5 }}>
                                  <h3>{buscarObjeto(user.id, listaUsuarios).nombre} {buscarObjeto(user.id, listaUsuarios).apellido}</h3>
                                </Grid>
                                <Grid item xs={5}>
                                  <Controls.InputMask
                                    name={"usuario" + user.id}
                                    label="Comisión"
                                    value={user.comision}
                                    variant="outlined"
                                    onChange={handleInputChange}
                                    prefix="$ "
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </>
                          :
                          <></>
                        }
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <h2>Socios:</h2>
                      <Paper>
                        {asociadosXLote && asociadosXLote.length &&
                          <>
                            {listaSociosUnica(asociadosXLote, pagoSocios).map((socio) => 
                              <Grid container>
                                {console.log('Pago Socios: ', pagoSocios)}
                                <Grid item xs={7} style={{ paddingLeft: 5 }}>
                                  <h3>{socio.nombre} {socio.apellido}</h3>
                                </Grid>
                                <Grid item xs={5}>
                                  <Controls.InputMask
                                    name={"socio" + socio.id}
                                    label="Pago"
                                    value={socio.pago}
                                    variant="outlined"
                                    onChange={handleInputChange}
                                    prefix="$ "
                                  />
                                </Grid>
                                {console.log('Resultado de socio: ', socio)}
                              </Grid>
                            )}
                          </>
                        }
                      </Paper>
                    </Grid>
                  </Grid>
                </>
              }
              </>
            )}
            {visible_FPago3 && (
              <>
                <Grid item xs={3}>
                  <Controls.InputMask
                    name="importeReserva3"
                    label="Importe"
                    value={values.importeReserva3}
                    variant="outlined"
                    onChange={handleInputChange}
                    prefix="$ "
                  />
                </Grid>
                <Grid item xs={3} style={{marginTop: 5}}>
                  <Controls.Select
                    name="FormaPagoReservaId3"
                    label="Forma de Pago"
                    value={values.FormaPagoReservaId3 || ""}
                    onChange={handleInputChange}
                    options={listaFormaPago.map((item) => {return { id: item.id, title: item.nombre };})}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaPactada3"
                      //maxDate={new Date()}
                      format="dd/MM/yyyy"
                      value={selectedDate3}
                      onChange={handleDateChange3}
                      helperText="Fecha Pactada"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                  <Controls.ActionButton
                    style={{ marginTop: 15 }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago4(true);
                    }}
                  >
                    <AddCircleIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    style={{ marginTop: 15, backgroundColor: "red" }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago3(false);
                    }}
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </Controls.ActionButton>
                </Grid>
                {buscarObjeto(values.FormaPagoReservaId3, listaFormaPago).esBancarizado &&
              <>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <h2>Usuarios:</h2>
                    <Paper>
                      {usuarios && usuarios.length ?
                        <>
                          {usuarios.map((user) => 
                            <Grid container>
                              <Grid item xs={7} style={{ paddingLeft: 5 }}>
                                <h3>{buscarObjeto(user.id, listaUsuarios).nombre} {buscarObjeto(user.id, listaUsuarios).apellido}</h3>
                              </Grid>
                              <Grid item xs={5}>
                                <Controls.InputMask
                                  name={"usuario" + user.id}
                                  label="Comisión"
                                  value={user.comision}
                                  variant="outlined"
                                  onChange={handleInputChange}
                                  prefix="$ "
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                        :
                        <></>
                      }
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <h2>Socios:</h2>
                    <Paper>
                      {asociadosXLote && asociadosXLote.length &&
                        <>
                          {listaSociosUnica(asociadosXLote, pagoSocios).map((socio) => 
                            <Grid container>
                              {console.log('Pago Socios: ', pagoSocios)}
                              <Grid item xs={7} style={{ paddingLeft: 5 }}>
                                <h3>{socio.nombre} {socio.apellido}</h3>
                              </Grid>
                              <Grid item xs={5}>
                                <Controls.InputMask
                                  name={"socio" + socio.id}
                                  label="Pago"
                                  value={socio.pago}
                                  variant="outlined"
                                  onChange={handleInputChange}
                                  prefix="$ "
                                />
                              </Grid>
                              {console.log('Resultado de socio: ', socio)}
                            </Grid>
                          )}
                        </>
                      }
                    </Paper>
                  </Grid>
                </Grid>
              </>
            }
              </>
            )}
            {visible_FPago4 && (
              <>
                <Grid item xs={3}>
                  <Controls.InputMask
                    name="importeReserva4"
                    label="Importe"
                    value={values.importeReserva4}
                    variant="outlined"
                    onChange={handleInputChange}
                    prefix="$ "
                  />
                </Grid>
                <Grid item xs={3} style={{marginTop: 5}}>
                  <Controls.Select
                    name="FormaPagoReservaId4"
                    label="Forma de Pago"
                    value={values.FormaPagoReservaId4 || ""}
                    onChange={handleInputChange}
                    options={listaFormaPago.map((item) => {return { id: item.id, title: item.nombre };})}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaPactada4"
                      //maxDate={new Date()}
                      format="dd/MM/yyyy"
                      value={selectedDate4}
                      onChange={handleDateChange4}
                      helperText="Fecha Pactada"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                  <Controls.ActionButton
                    style={{ marginTop: 15, backgroundColor: "red" }}
                    color="primary"
                    onClick={() => {
                      setVisibleFPago4(false);
                    }}
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </Controls.ActionButton>
                </Grid>
                {buscarObjeto(values.FormaPagoReservaId4, listaFormaPago).esBancarizado &&
              <>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <h2>Usuarios:</h2>
                    <Paper>
                      {usuarios && usuarios.length ?
                        <>
                          {usuarios.map((user) => 
                            <Grid container>
                              <Grid item xs={7} style={{ paddingLeft: 5 }}>
                                <h3>{buscarObjeto(user.id, listaUsuarios).nombre} {buscarObjeto(user.id, listaUsuarios).apellido}</h3>
                              </Grid>
                              <Grid item xs={5}>
                                <Controls.InputMask
                                  name={"usuario" + user.id}
                                  label="Comisión"
                                  value={user.comision}
                                  variant="outlined"
                                  onChange={handleInputChange}
                                  prefix="$ "
                                />
                              </Grid>
                            </Grid>
                          )}
                        </>
                        :
                        <></>
                      }
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <h2>Socios:</h2>
                    <Paper>
                      {asociadosXLote && asociadosXLote.length &&
                        <>
                          {listaSociosUnica(asociadosXLote, pagoSocios).map((socio) => 
                            <Grid container>
                              {console.log('Pago Socios: ', pagoSocios)}
                              <Grid item xs={7} style={{ paddingLeft: 5 }}>
                                <h3>{socio.nombre} {socio.apellido}</h3>
                              </Grid>
                              <Grid item xs={5}>
                                <Controls.InputMask
                                  name={"socio" + socio.id}
                                  label="Pago"
                                  value={socio.pago}
                                  variant="outlined"
                                  onChange={handleInputChange}
                                  prefix="$ "
                                />
                              </Grid>
                              {console.log('Resultado de socio: ', socio)}
                            </Grid>
                          )}
                        </>
                      }
                    </Paper>
                  </Grid>
                </Grid>
              </>
            }
              </>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <h3>
                Diferencia: $ {new Intl.NumberFormat().format(diferenciaEntrega(values.entrega, values))}
              </h3>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            width: "100%",
            borderRadius: 10,
            padding: "10px 20px",
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "#dddddd",
          }}
        >
          <h3>Observaciones de la Reserva: </h3>
          <p>{recordForEdit.observaciones}</p>
        </div>
      </Grid>

      {/*------------------------------------Discriminacion de lotes------------------------------------*/}
      <Grid container style={{ marginTop: 40 }}>
        {values.totalEntregadoFinalReserva > 0 ? (
          <>
            {lotes.length ? (
              <>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    textColor="white"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                  >
                    {lotes.map((lote, index) => {
                      return <Tab label={lote.manzana + " - " + lote.lote} />;
                    })}
                  </Tabs>
                </AppBar>

                {lotes.map((lote, index) => {
                  return (
                    <>
                      <TabPanel
                        value={value}
                        index={index}
                        style={{ width: "100%" }}
                      >
                        {asociadosXLote[index] ? (
                          <Panel
                            listaSocios={asociadosXLote[index]}
                            ptoducto={lote}
                            indice={index}
                            entrega={entrega}
                            aporteSocios={aporteSocios[index]}
                            setAporteSocios={setearArrayAportesSocios}
                            pagoSocios={pagoSocios[index]}
                            setPagoSocios={setearArrayPagosSocios}
                          />
                        ) : (
                          <>ERROR en el LOTE. No tiene socios definidos</>
                        )}
                      </TabPanel>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
      <div>
        <hr />
      </div>

      {/*----------------------------------------------------DETALLE-------------------------------------*/}
      <Grid item xs={12} sm={12}>
        <div
          style={{
            borderRadius: 20,
            backgroundColor: "#E3CEF2",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "10px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <h2>Detalle</h2>
        </div>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6}>
          {/*------------------------------------Selector de Usuarios------------------------------------*/}
          <Grid container>
            <Grid item xs={12} sm={10}>
              <Controls.Select
                name="UsuarioId"
                label="Usuario"
                value={usuarioSeleccionado}
                onChange={(item) => {
                  setUsuarioSeleccionado(item.target.value);
                }}
                options={listaUsuarios.map((item) => {
                  return {
                    id: item.id,
                    title: item.apellido + " " + item.nombre,
                  };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.Button
                text="+"
                color="default"
                onClick={() => agregarUsuario()}
              />
            </Grid>

            {/*------------------------------------Seleccionados------------------------------------*/}
            {usuarios.length > 0 ? (
              <>
                <div style={{ height: "5%" }}></div>
                {usuarios.map((user) => {
                  return (
                    <>
                      <Grid container>
                        <Grid item xs={12} sm={5}>
                          <h3
                            style={{
                              background: "lightGray",
                              padding: 5,
                              borderRadius: 10,
                              marginBottom: 13,
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            {obtenerUsuario(user.id)}
                          </h3>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Controls.InputMask
                            label="Comision"
                            value={comisiones[user.id]}
                            name={user.id}
                            variant="outlined"
                            prefix="$ "
                            onChange={(e) => {
                              handleInputComisionChange(e, user.id);
                            }}
                          />
                          <div style={{ color: "transparent" }}>
                            {
                              (totalComisiones =
                                totalComisiones + parseInt(comisiones[user.id]))
                            }
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={1} style={{marginTop: 5}}>
                          <Controls.Button
                            text="-"
                            color="secondary"
                            onClick={() => eliminarUsuario(user.id)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={1}></Grid>
                      </Grid>
                    </>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/*------------------------------------Comisiones------------------------------------*/}
          <Grid
            container
            style={{
              borderRadius: 20,
              backgroundColor: "#dddddd",
              marginBottom: 10,
            }}
          >
            <Grid item xs={12} sm={5}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>Total Comisiones:</h3>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>$ {new Intl.NumberFormat().format(sumaComisiones(comisiones))}</h3>
              </div>
            </Grid>
          </Grid>

          {/*----------------------------------------------------GANANCIAS-------------------------------------*/}
          <Grid
            container
            style={{
              borderRadius: 20,
              backgroundColor: "#dddddd",
              marginBottom: 10,
            }}
          >
            <Grid item xs={12} sm={5}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>Ganancias:</h3>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>
                  $ {parseInt(totalPagoPrimerSocio) +
                  totalAporteSociosSumaTabletas -
                  parseInt(totalComisiones) +
                  parseInt(comisionGastos) ? (
                    <>
                      {new Intl.NumberFormat().format(parseInt(totalPagoPrimerSocio) +
                        totalAporteSociosSumaTabletas -
                        parseInt(totalComisiones) +
                        parseInt(comisionGastos))}
                    </>
                  ) : (
                    <>0</>
                  )}
                </h3>
              </div>
            </Grid>
          </Grid>

          {/*----------------------------------------------------Reserva-------------------------------------*/}
          <Grid
            container
            style={{
              borderRadius: 20,
              backgroundColor: "#dddddd",
              marginBottom: 10,
            }}
          >
            <Grid item xs={12} sm={5}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>Reserva:</h3>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>
                  $ {values.totalSeniaFinalReserva
                    ? new Intl.NumberFormat().format(values.totalSeniaFinalReserva)
                    : 0}
                </h3>
              </div>
            </Grid>
          </Grid>

          {/*----------------------------------------------------Dinero Disponible-------------------------------------*/}
          <Grid
            container
            style={{
              borderRadius: 20,
              backgroundColor: "#dddddd",
              marginBottom: 10,
            }}
          >
            <Grid item xs={12} sm={5}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>Dinero Disponible:</h3>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <h3>
                  $ {parseInt(totalPagoPrimerSocio) +
                  totalAporteSociosSumaTabletas -
                  parseInt(totalComisiones) +
                  parseInt(comisionGastos) -
                  parseInt(values.totalSeniaFinalReserva) ? (
                    <>
                      {new Intl.NumberFormat().format(parseInt(totalPagoPrimerSocio) +
                        totalAporteSociosSumaTabletas -
                        parseInt(totalComisiones) +
                        parseInt(comisionGastos) -
                        parseInt(values.totalSeniaFinalReserva))}
                    </>
                  ) : (
                    <>0</>
                  )}
                </h3>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div>
        <hr />
      </div>

      {/*----------------------------------------Detalles de pago--------------------------------------*/}
      <Grid container>
        <>
          <Grid
            item
            xs={6}
            sm={6}
            style={{
              borderRadius: 20,
              backgroundColor: "lightblue",
              marginBottom: 10,
            }}
          >
            <div style={{ paddingRight: "10px", marginTop: "10px" }}>
              {recordForEdit.cantidadCuotas === 1 ? (
                <>
                  <h3 style={{ textAlign: "left", paddingLeft: "10px" }}>
                    Operación realizada de Contado
                  </h3>
                  <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                    - Precio de la Propiedad: $ {new Intl.NumberFormat().format(recordForEdit.Lotesxloteoprecio)}
                  </h4>
                  <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                    - Entrega: $ {new Intl.NumberFormat().format(importeReserva())}
                  </h4>
                  <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                    - Total: $ {new Intl.NumberFormat().format(importeReserva())}
                  </h4>
                </>
              ) : (
                <>
                  <h3 style={{ textAlign: "left", paddingLeft: "10px" }}>
                    Operación realizada en {recordForEdit.cantidadCuotas} cuotas
                  </h3>
                  <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                    - Precio de la Propiedad: $ {new Intl.NumberFormat().format(recordForEdit.Lotesxloteo.precio)}
                  </h4>
                  <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                    - Entrega: $ {new Intl.NumberFormat().format(importeReserva())}
                  </h4>
                  {recordForEdit.cantidadCuotas === 12 && (
                    <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                      - Cuotas 1-12: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio1)}
                    </h4>
                  )}
                  {recordForEdit.cantidadCuotas === 24 && (
                    <>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 1-12: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio1)}
                      </h4>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 13-24: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio2)}
                      </h4>
                    </>
                  )}
                  {recordForEdit.cantidadCuotas === 36 && (
                    <>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 1-12: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio1)}
                      </h4>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 13-24: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio2)}
                      </h4>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 24-36: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio3)}
                      </h4>
                    </>
                  )}
                  {recordForEdit.cantidadCuotas === 48 && (
                    <>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 1-12: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio1)}
                      </h4>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 13-24: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio2)}
                      </h4>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 25-36: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio3)}
                      </h4>
                      <h4 style={{ textAlign: "left", paddingLeft: "30%" }}>
                        - Cuotas 37-48: $ {new Intl.NumberFormat().format(recordForEdit.importeAnio4)}
                      </h4>
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
        </>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={5}>
          <Grid container style={{ marginTop: 40 }}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechaVencimiento"
                  //maxDate={new Date()}
                  format="dd/MM/yyyy"
                  value={selectedDateVencimiento}
                  onChange={handleDateChangeVencimiento}
                  helperText="Vencimiento de Primera Cuota"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 40, textAlign: 'center' }}>
              <Controls.Input
                name="codigo"
                label="codigo"
                value={codigo}
                onChange={() => setCodigo()}
                error={errors.codigo}
              />
            </Grid>
        </Grid>
      </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="observacionFinalReserva"
            label="Observaciones Finales"
            value={values.observacionFinalReserva}
            onChange={handleInputChange}
            multiline
            //error={errors.observaciones}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!guardando ? (
          <div style={{ marginTop: 25, textAlign: "right" }}>
            <Controls.Button type="submit" text="Guardar" />
            <Controls.Button
              text="Limpiar"
              color="default"
              onClick={resetForm}
            />
          </div>
        ) : (
          <div style={{ margin: 25, textAlign: "right" }}>
            <CircularProgress size={26} />
          </div>
        )}
      </Grid>
    </Form>
  );
}
