import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";
import Notification from "../../components/tablas/Notification";

import * as HistorialReservaServices from "../../services/venta/historialReserva";

import tinycolor from "tinycolor2";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const initialFValues = {
  id: 0,
  nota: "",
  ReservaId: undefined,
};

export default function HistorialReservaLoteLista(props) {

  const { recordForEdit, puedeAgregar, tipoProducto, token } = props;

  const [guardando, setGuardando] = React.useState(false);
  const [registros, setRegistros] = useState({});
  const [cargando, setCargando] = useState(false);
  const [nuevo, setNuevo] = useState(false);
  const [value, setValue] = React.useState(0);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("descripcion" in fieldValues)
      temp.descripcion = fieldValues.descripcion ? "" : "Dato requerido";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const nuevoForm = () => {
    setNuevo(!nuevo);
  };

  const addOrEdit = async (registroItem, resetForm) => {
    try {
      await HistorialReservaServices.insert(registroItem, token, tipoProducto);
    } catch (err) {
      return false;
    }

    setCargando(false);
    setNuevo(false);
    setGuardando(false);
    resetForm();
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.nota) {
      if (values.nota.length > 4) {
        if (validate()) {
          setGuardando(true);
          addOrEdit(values, resetForm).then((e) => {
            if (e === false) {
              setGuardando(false);
              alert(
                "No se pudo completar la operación, revise los datos e intente nuevamente"
              );
            }
          });
        }
      } else {
        alert("La descripción debe contener un mínimo de 5 letras");
      }
    } else {
      alert("Complete una descripción válida");
    }
  };

  const stringToColour = (str) => {
    var hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (let i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return tinycolor(colour).lighten(20).toString();
  };

  const convertirFechaToStr = (fechaTurno) => {
    const texto =
      fechaTurno.substr(8, 2) +
      "/" +
      fechaTurno.substr(5, 2) +
      "/" +
      fechaTurno.substr(0, 4);
    return texto;
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        reservaId: recordForEdit.id,
      });
    }

    if (!cargando) {
      setCargando(true);
      HistorialReservaServices.getAllItems(
        props.token,
        recordForEdit.id,
        tipoProducto
      ).then((res) => {
        setRegistros(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}></Grid>
        {puedeAgregar && (
          <Grid style={{ textAlign: "right" }} item xs={6}>
            <Controls.Button
              text={nuevo ? "-" : "+"}
              color="primary"
              onClick={nuevoForm}
            />
          </Grid>
        )}
      </Grid>
      {nuevo && (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <h3>NUEVO MENSAJE</h3>
                <Grid item xs={12}>
                  <Controls.Input
                    name="nota"
                    helperText="Descripción de la acción realizada"
                    value={values.nota}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 254);
                    }}
                    rowsMax={4}
                    error={errors.descripcion}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: 25, textAlign: "right" }}>
                {!guardando ? (
                  <Controls.Button type="submit" text="Guardar" />
                ) : (
                  <CircularProgress size={26} />
                )}
                <br /> <br />
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
      {registros ? (
        <>
          {registros.length ? (
            <>
              <AppBar position="static">
                <Tabs
                  value={value}
                  indicatorColor="secondary"
                  textColor="white"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                >
                  {<Tab label="HISTORIAL DE COMUNICACIÓN" />}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {registros.map((item, index) => (
                  <div>
                    {item.Usuario.Id !== item.UsuarioId ? (
                      <div
                        style={{
                          backgroundColor: stringToColour(
                            item.Usuario.apellido + item.Usuario.nombre
                          ),
                          padding: 10,
                          borderRadius: 10,
                          margin: 2,
                        }}
                      >
                        {convertirFechaToStr(item.updatedAt)} <br />
                        {item.nota} <br />
                        <div style={{ textAlign: "right", fontSize: 11 }}>
                          {item.Usuario.nombre} {item.Usuario.apellido} -
                          {convertirFechaToStr(item.createdAt)}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </TabPanel>
            </>
          ) : (
            <> Sin registros de seguimiento</>
          )}
        </>
      ) : (
        <>Sin registros de seguimiento</>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
