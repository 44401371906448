import GestorAxios from "../../config/axios";

export async function getAllItems(token) {
  let data = {};

  await GestorAxios.get("/fuente", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function insert(data, token) {
  let dataReturn = {};

  await GestorAxios.post("/fuente/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/fuente/actualizar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function paraGraficos(token, datos) {
  let data = {};
  console.log('En services: ', datos);
  await GestorAxios.get(`/fuente/paragraficos/${datos.fechaDesde}/${datos.fechaHasta}/${datos.filtro}/${datos.incremento}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
