import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import ColorPicker from "material-ui-color-picker";

const initialFValues = {
  id: 0,
  nombre: "",
  color: "",
  esDisponible: false,
  esReservado: false,
  esVendido: false,
};

export default function EstadoForm(props) {

  const { addOrEdit, recordForEdit } = props;

  const [cambiarColor, setCambiarColor] = useState("#000000");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, cambiarColor]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Controls.Input
            name="nombre"
            label="Nombre Estado"
            value={values.nombre}
            onChange={handleInputChange}
            error={errors.nombre}
          />
          <Controls.Checkbox
            name="esDisponible"
            label="¿Es disponible?"
            value={values.esDisponible}
            onChange={handleInputChange}
          />
          <Controls.Checkbox
            name="esReservado"
            label="¿Es reservado?"
            value={values.esReservado}
            onChange={handleInputChange}
          />
          <Controls.Checkbox
            name="esVendido"
            label="¿Es vendido?"
            value={values.esVendido}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Controls.Button
            style={{
              backgroundColor: cambiarColor,
              borderColor: "black",
              height: 50,
            }}
            name="color"
            text=""
            value="Color"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={3} style={{ height: 300 }}>
          <ColorPicker
            name="colorHexa"
            value={cambiarColor}
            label={cambiarColor}
            onChange={(color) => {
              values.color = color;
              setCambiarColor(color);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: 25, textAlign: "right" }}>
            <Controls.Button type="submit" text="Guardar" />
            <Controls.Button
              text="Limpiar"
              color="default"
              onClick={resetForm}
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
