import React, { useState, useEffect } from "react";

import { Paper, Grid } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";

import { useUserState } from "../../../context/UserContext";
import BarraDoble from "./BarraDoble";
import BarraSimple from "./BarraSimple";
//import Rosca from "./Rosca";

//import * as ConfigDataServices from "../../../services/utiles/configData";
import * as UsuariosServices from "../../../services/usuario/usuario";
//import * as ClienteServices from "../../../services/cliente/cliente";
import * as FuenteServices from "../../../services/cliente/fuente";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const seleccion = [
  { id: 1, title: "Entre Fechas"},
  { id: 2, title: "Por Meses"},
  { id: 3, title: "Por Años"},
];

export default function BaseGraficos(props) {

  const { token } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [datosGrafico, setDatosGrafico] = useState({});
  const [datosFuente, setDatosFuente] = useState({});

  const [fechaDesdeVendedor, setFechaDesdeVendedor] = useState(new Date());
  const [fechaHastaVendedor, setFechaHastaVendedor] = useState(new Date());
  const [opcionFiltroVendedor, setOpcionFiltroVendedor] = useState(2);
  const [opcionMesAnioVendedor, setOpcionMesAnioVendedor] = useState(0);
  const [opcionesVendedor, setOpcionesVendedor] = useState([]);

  const [fechaDesdeFuente, setFechaDesdeFuente] = useState(new Date());
  const [fechaHastaFuente, setFechaHastaFuente] = useState(new Date());
  const [opcionFiltroFuente, setOpcionFiltroFuente] = useState(2);
  const [opcionMesAnioFuente, setOpcionMesAnioFuente] = useState(0);
  const [opcionesFuente, setOpcionesFuente] = useState([]);

  function diferenciaMeses(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }

  function obtenerMes(num){
    switch (num) {
      case 0: return "Enero ";
      case 1: return "Febrero ";
      case 2: return "Marzo ";
      case 3: return "Abril ";
      case 4: return "Mayo ";
      case 5: return "Junio ";
      case 6: return "Julio ";
      case 7: return "Agosto ";
      case 8: return "Septiembre ";
      case 9: return "Octubre ";
      case 10: return "Noviembre ";
      case 11: return "Diciembre ";
      default: break;
    }
  }

  function diferenciaAnios(dateFrom, dateTo) {
    return (dateTo.getFullYear() - dateFrom.getFullYear());
  }

  function filtroChange(value, fechaDesde, fechaHasta, origen) {
    let mesesOanios = [];
    let diferencia = 0;
    let mesInicio = 0;
    let anioInicio = 0;
    switch (value) {
      case 1:
        return [];
      case 2:
        diferencia = diferenciaMeses(fechaDesde, fechaHasta);
        mesInicio = fechaDesde.getMonth();
        anioInicio = fechaDesde.getFullYear();
        let incrementoAnio = 0;
        for(let i = 0; i <= diferencia; i++) {
          mesesOanios.push({id: i, title: obtenerMes((mesInicio + i) % 12) + (anioInicio + incrementoAnio).toString()});
          if ((mesInicio + i) % 12 === 11) {
            incrementoAnio++;
          }
        }
        return mesesOanios;
      case 3:
        diferencia = diferenciaAnios(fechaDesde, fechaHasta);
        anioInicio = fechaDesde.getFullYear();
        for(let i = 0; i <= diferencia; i++) {
          mesesOanios.push({id: i, title: anioInicio + i})
        }
        return mesesOanios;
        default:
          break;
      }
      //setCargando(false);
    }

  const handleDateDesdeVendedorChange = (date) => {
    setFechaDesdeVendedor(date);
    setCargando(false);
  };

  const handleDateHastaVendedorChange = (date) => {
    setFechaHastaVendedor(date);
    setCargando(false);
  };

  const handleDateDesdeFuenteChange = (date) => {
    setFechaDesdeFuente(date);
    setCargando2(false);
  };

  const handleDateHastaFuenteChange = (date) => {
    setFechaHastaFuente(date);
    setCargando2(false);
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      let dato = {
        fechaDesde: fechaDesdeVendedor,
        fechaHasta: fechaHastaVendedor,
        incremento: opcionMesAnioVendedor ? opcionMesAnioVendedor : 0,
      }
      console.log('En useEffect opciones: ', filtroChange(opcionFiltroVendedor, fechaDesdeVendedor, fechaHastaVendedor, "vendedor"), opcionFiltroVendedor);
      let fil = filtroChange(opcionFiltroVendedor, fechaDesdeVendedor, fechaHastaVendedor, "vendedor");
      setOpcionesVendedor(fil);
      switch (opcionFiltroVendedor) {
        case 1:
          dato.filtro = "fecha";
          break;
        case 2:
          dato.filtro = "mes";
          break;
        case 3:
          dato.filtro = "anio";
          break;
        default:
          dato.filtro = "mes";
          break;
      }
      console.log('Datos iniciales: ', dato);
      UsuariosServices.paraGraficos(token, dato).then((res) => {
        console.log('Datos para graficos: ', res);
        setDatosGrafico(res);
      });
    }
  }, [cargando]); // eslint-disable-line

  useEffect(() => {
    if (!cargando2) {
      setCargando2(true);
      let dato = {
        fechaDesde: fechaDesdeFuente,
        fechaHasta: fechaHastaFuente,
        incremento: opcionMesAnioFuente ? opcionMesAnioFuente : 0,
      }
      console.log('En useEffect opciones: ', filtroChange(opcionFiltroFuente, fechaDesdeFuente, fechaHastaFuente, "fuente"), opcionFiltroFuente);
      let fil = filtroChange(opcionFiltroFuente, fechaDesdeFuente, fechaHastaFuente, "fuente")
      setOpcionesFuente(fil);
      switch (opcionFiltroFuente) {
        case 1:
          dato.filtro = "fecha";
          break;
        case 2:
          dato.filtro = "mes";
          break;
        case 3:
          dato.filtro = "anio";
          break;
        default:
          dato.filtro = "mes";
          break;
      }
      console.log('Datos iniciales: ', dato);
      FuenteServices.paraGraficos(token, dato).then((res) => {
        console.log('Datos de Fuentes: ', res);
        setDatosFuente(res);
      })
    }
  }, [cargando2]); // eslint-disable-line

  return (
    <>
      <Paper style={{ width: '100%', padding: 10}}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Paper elevation={3}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <h2>CLIENTES POR VENDEDORES</h2>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaDesde"
                      maxDate={new Date()}
                      format="dd/MM/yyyy"
                      value={fechaDesdeVendedor}
                      onChange={handleDateDesdeVendedorChange}
                      helperText="Fecha Desde"
                      //disabled={true}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaHasta"
                      maxDate={new Date()}
                      minDate={fechaDesdeVendedor}
                      format="dd/MM/yyyy"
                      value={fechaHastaVendedor}
                      onChange={handleDateHastaVendedorChange}
                      helperText="Fecha Hasta"
                      //disabled={true}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center', marginBottom: 20 }}>
                  <Controls.Select
                    name="selectorFiltro"
                    label="Filtro"
                    value={opcionFiltroVendedor}
                    options={seleccion.map((item) => {
                      return { id: item.id, title: item.title };
                    })}
                    onChange={(e) => {
                      setOpcionesVendedor(filtroChange(e.target.value, fechaDesdeVendedor, fechaHastaVendedor, "vendedor"));
                      setOpcionFiltroVendedor(e.target.value);
                      setOpcionMesAnioVendedor(0);
                      setCargando(false);
                    }}
                    disabled={true}
                    />
                </Grid>
                {opcionesVendedor && opcionesVendedor.length ?
                  <Grid item xs={6} style={{ textAlign: 'center', marginBottom: 20 }}>
                    <Controls.Select
                      name="selectorMesAnio"
                      label="Mes / Año"
                      value={opcionMesAnioVendedor}
                      options={opcionesVendedor.map((item) => {
                        return { id: item.id, title: item.title };
                      })}
                      onChange={(e) => {
                        setOpcionMesAnioVendedor(e.target.value);
                        setCargando(false);
                      }}
                    />
                  </Grid>
                  :
                  <></>
                }
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    {datosGrafico && datosGrafico.vendedores && datosGrafico.vendedores.length &&
                      <BarraDoble vendedores={datosGrafico.vendedores} asignados={datosGrafico.asignados} propios={datosGrafico.propios} />
                    }
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <h2>FUENTES</h2>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaDesde"
                      maxDate={new Date()}
                      format="dd/MM/yyyy"
                      value={fechaDesdeFuente}
                      onChange={handleDateDesdeFuenteChange}
                      helperText="Fecha Desde"
                      //disabled={true}
                      />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaHasta"
                      maxDate={new Date()}
                      minDate={fechaDesdeFuente}
                      format="dd/MM/yyyy"
                      value={fechaHastaFuente}
                      onChange={handleDateHastaFuenteChange}
                      helperText="Fecha Hasta"
                      //disabled={true}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'center', marginBottom: 20 }}>
                  <Controls.Select
                    name="selectorFiltro"
                    label="Filtro"
                    value={opcionFiltroFuente}
                    options={seleccion.map((item) => {
                      return { id: item.id, title: item.title };
                    })}
                    onChange={(e) => {
                      setOpcionesFuente(filtroChange(e.target.value, fechaDesdeFuente, fechaHastaFuente, "fuente"));
                      setOpcionFiltroFuente(e.target.value);
                      setOpcionMesAnioFuente(0);
                      setCargando2(false);
                    }}
                    //disabled={true}
                    />
                </Grid>
                {opcionesFuente && opcionesFuente.length ?
                  <Grid item xs={6} style={{ textAlign: 'center', marginBottom: 20 }}>
                    <Controls.Select
                      name="selectorMesAnio"
                      label="Mes / Año"
                      value={opcionMesAnioFuente}
                      options={opcionesFuente.map((item) => {
                        return { id: item.id, title: item.title };
                      })}
                      onChange={(e) => {
                        setOpcionMesAnioFuente(e.target.value);
                        setCargando2(false);
                      }}
                    />
                  </Grid>
                  :
                  <></>
                }
                <Grid item xs={12}>
                  <Paper elevation={2}>
                  {datosFuente && datosFuente.fuentes && datosFuente.fuentes.length &&
                    <BarraSimple fuentes={datosFuente.fuentes} cantidad={datosFuente.cantidad} />
                  }
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
