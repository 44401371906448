import React, { useState, useEffect } from "react";
//import ReactToPrint from "react-to-print";

import { useUserState } from "../../../context/UserContext";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";
import PageHeader from "../../../components/tablas/PageHeader";
import Popup from "../../../components/tablas/Popup";
import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";

import * as LotesXLoteoServices from "../../../services/producto/lotesXLoteo";
import * as ReservaLotesXLoteoServices from "../../../services/venta/reservaLotesXLoteo";
import * as FormaPagoServices from "../../../services/venta/formaPago";
import * as MonedaServices from "../../../services/venta/moneda";
import * as UsuarioServices from "../../../services/usuario/usuario";
import * as EstadoServices from "../../../services/producto/estado";
import * as ComisionesUsuariosServices from "../../../services/contable/comisionesUsuarios";
import * as AsociadoxLoteServices from "../../../services/producto/asociadosXLote";

import HistorialReservaLista from "../../venta/HistorialReservaLista";
import DetalleReserva from "../../venta/DetalleReserva";
import DetalleBaja from "../../venta/loteos/DetalleBajaLote";
//import DetalleVenta from "./DetalleVentaLote";
import LotesReservoVisor from "./ReservasLotesVisor";
import EventosReservas from "../../venta/EventosLista";
import ConfirmacionVentaLote from "../../venta/loteos/ConfirmacionVentaLote";
import LotexLoteoReservaEdicion from "../../producto/loteos/LoteXLoteoReservaEdicion";

import Permisos from "../../../config/permisos";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import RoomIcon from "@material-ui/icons/Room";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Search } from "@material-ui/icons";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "fechaEstimadaFirma", label: "Fecha de firma" },
  { id: "cliente", label: "Cliente" },
  { id: "vendedor", label: "Vendedor" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function PropiedadesVenta() {
  const { token, rolCodigo } = useUserState();

  const [registros, setRegistros] = useState({});
  const [registroEstados, setRegistroEstados] = useState({});
  const [openPopupHistorial, setOpenPopupHistorial] = useState(false);
  //const [registroProvincias, setRegistroProvincias] = useState({});
  //const [registroLocalidades, setRegistroLocalidades] = useState({});
  const [openPopupDetalle, setOpenPopupDetalle] = useState(false);
  const [openPopupBaja, setOpenPopupBaja] = useState(false);
  const [openPopupVenta, setOpenPopupVenta] = useState(false);
  const [openPopupEventos, setOpenPopupEventos] = useState(false);
  const [openPopupEditar, setOpenPopupEditar] = useState(false);
  const [registroFormaPago, setRegistroFormaPago] = useState({});
  const [registroMoneda, setRegistroMoneda] = useState({});
  const [registroUsuarios, setRegistroUsuarios] = useState({});
  const [verPendientes, setVerPendientes] = useState(true);
  const [textoBuscar, setTextoBuscar] = useState("");
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  //const [openPopupVer, setOpenPopupVer] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  //const [recordForVer, setRecordForVer] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [registroLotes, setRegistroLotes] = useState({});

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    handleSearch();
  }, [textoBuscar]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cargando) {
      setCargando(true);

      if (verPendientes) {
        ReservaLotesXLoteoServices.getAllActivos(token, rolCodigo).then(
          (res) => {
            console.log('Activos: ', res);
            setRegistros(res);
            handleSearch();
          }
        );
      } else {
        ReservaLotesXLoteoServices.getCompleto(token, rolCodigo).then((res) => {
          console.log('Completos: ', res);
          setRegistros(res);
          handleSearch();
        });
      }

      FormaPagoServices.getAllItems(token).then((res) => {
        setRegistroFormaPago(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setRegistroMoneda(res);
      });
      UsuarioServices.getAllItems(token).then((res) => {
        setRegistroUsuarios(res);
      });
      EstadoServices.getAllItems(token).then((res) => {
        setRegistroEstados(res);
      });
    }
  }, [cargando, registros, verPendientes]); // eslint-disable-line react-hooks/exhaustive-deps

  const convertirFechaToStr = (fechaTurno) => {
    const texto =
      fechaTurno.substr(8, 2) +
      "/" +
      fechaTurno.substr(5, 2) +
      "/" +
      fechaTurno.substr(0, 4);
    return texto;
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInPopupVenta = async (item) => {
    //console.log('Item: ', item);
    await LotesXLoteoServices.getAllItems(token, item.Lotesxloteo.LoteoId).then(
      (res) => {
        setRegistroLotes(res);
      }
    );
    setRecordForEdit(item);
    setOpenPopupVenta(true);
  };

  const handleSearch = () => {
    let target = textoBuscar ? textoBuscar : "";
    target = target.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.length === 0) return items;
        else
          return items.filter((x) =>
            x.Lotesxloteo.nombre.toLowerCase().includes(target)
          );
      },
    });
  };

  const addOrEditVentaBaja = async (registroItem, resetForm) => {
    //console.log('En addOrEditVentaBaja: ', registroItem);
    const id = registroItem.id;
    const itemsComprados = registroItem.ReservasItemComprados;
    const usuarios = registroItem.usuarios;
    const socios = registroItem.socios;
    let estado = 0;
    let cambio = {};
    let loteId = 0;
    if (registroItem.vendido) {
      console.log("Entra en venta: ", registroItem);
      try {
        estado = registroEstados.find((estado) => estado.esVendido === true);
        cambio.EstadoId = estado.id;
        for (let i = 0; i < itemsComprados.length; i++) {
          //console.log('Item: ', itemsComprados[i]);
          loteId = itemsComprados[i].LotesxloteoId;
          await LotesXLoteoServices.updateReserva(cambio, loteId, token);
          //console.log('Estado: ', estado.nombre);
          if (socios[i] && socios[i].length > 0) {
            for (let j = 0; j < socios[i].length; j++) {
              //console.log('Socio Update: ', socios[i][j]);
              await AsociadoxLoteServices.update(socios[i][j], token);
            }
          }
        }
        if (usuarios && usuarios.length > 0) {
          for (let i = 0; i < usuarios.length; i++) {
            let data = {
              ReservasLoteId: registroItem.id,
              UsuarioId: usuarios[i].id,
              importe: usuarios[i].comision,
            };
            //console.log('Comisiones: ', data)
            await ComisionesUsuariosServices.insert(data, token);
          }
        }
        await ReservaLotesXLoteoServices.confirmaVenta(registroItem, id, token);
      } catch (err) {
        return false;
      }
    } else {
      console.log("Entra en baja: ", registroItem);
      try {
        estado = registroEstados.find((estado) => estado.esDisponible === true);
        cambio.EstadoId = estado.id;
        for (let i = 0; i < itemsComprados.length; i++) {
          //console.log('Item: ', itemsComprados[i]);
          loteId = itemsComprados[i].LotesxloteoId;
          await LotesXLoteoServices.updateReserva(cambio, loteId, token);
          console.log("Estado: ", estado.nombre, loteId);
        }
        await ReservaLotesXLoteoServices.confirmaBaja(
          registroItem,
          registroItem.id,
          token
        );
      } catch (err) {
        return false;
      }
    }

    resetForm();
    //setRecordForEdit(null);
    setOpenPopupVenta(false);
    setOpenPopupBaja(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    //setRegistroPropiedades(PropiedadVentaServices.getAllItems(token));
  };

  const addOrEditCambioLote = async (registroItem, resetForm) => {
    console.log("En addOrEdit: ", registroItem);
    const id = registroItem.id;
    const eliminar = registroItem.lotesEliminar;
    const guardar = registroItem.lotesGuardar;
    const primeros = registroItem.lotesPrimeros;
    let estado = 0;
    let cambio = {};
    let loteId = 0;
    try {
      console.log(eliminar);
      if (eliminar && eliminar.length) {
        estado = registroEstados.find((estado) => estado.esDisponible === true);
        cambio.EstadoId = estado.id;
        for (let i = 0; i < eliminar.length; i++) {
          console.log('1 - Eliminado: ', eliminar[i]);
          loteId = eliminar[i].id;
          await LotesXLoteoServices.updateReserva(cambio, loteId, token);
          console.log("1 - Estado: ", estado.nombre, loteId);
        }
      }
      if (guardar && guardar.length) {
        estado = registroEstados.find((estado) => estado.esVendido === true);
        cambio.EstadoId = estado.id;
        for (let i = 0; i < guardar.length; i++) {
          console.log('2 - Guardado: ', guardar[i]);
          loteId = guardar[i].id;
          await LotesXLoteoServices.updateReserva(cambio, loteId, token);
          console.log('2 - Estado: ', estado.nombre);
        }
      }
      if (guardar.length === 0 && eliminar.length === 0) {
        estado = registroEstados.find((estado) => estado.esVendido === true);
        cambio.EstadoId = estado.id;
        for (let i = 0; i < primeros.length; i++) {
          console.log('3 - lotesPrimeros: ', primeros[i].LotesxloteoId);
          loteId = primeros[i].LotesxloteoId;
          await LotesXLoteoServices.updateReserva(cambio, loteId, token);
          console.log('3 - Estado: ', estado.nombre);
        }
      }
      if (guardar.length > 0 && eliminar.length === 0) {
        estado = registroEstados.find((estado) => estado.esVendido === true);
        cambio.EstadoId = estado.id;
        for (let i = 0; i < primeros.length; i++) {
          console.log('4 - guardar y primeros: ', primeros[i]);
          loteId = primeros[i].LotesxloteoId;
          await LotesXLoteoServices.updateReserva(cambio, loteId, token);
          console.log('4 - Estado: ', estado.nombre);
        }
      }
      if (guardar.length === 0 && eliminar.length > 0) {
        estado = registroEstados.find((estado) => estado.esVendido === true);
        cambio.EstadoId = estado.id;
        const elim = eliminar.map((lote) => lote.id)
        const nuevo = primeros.filter((lote) => !elim.includes(lote.LotesxloteoId));
        for (let i = 0; i < nuevo.length; i++) {
          console.log('5 - eliminar y primeros: ', nuevo[i]);
          loteId = nuevo[i].LotesxloteoId;
          await LotesXLoteoServices.updateReserva(cambio, loteId, token);
          console.log('5 - Estado: ', estado.nombre);
        }
      }
      //     await ReservaLotesXLoteoServices.confirmaVenta(registroItem, id, token);
      let reserva = {
        id: id,
        //LotesxloteoId: guardar[0].id,
        vendido: true,
        eliminar: eliminar,
        guardar: guardar,
      };
      if (guardar && guardar.length) reserva.LotesxloteoId = guardar[0].id; 
      console.log('Reserva: ', reserva)
      await ReservaLotesXLoteoServices.confirmaVenta(reserva, id, token);
    } catch (err) {
      console.log('Error: ', err.message);
      return false;
    }

    resetForm();
    //setRecordForEdit(null);
    setOpenPopupVenta(false);
    setOpenPopupBaja(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
  };

  const addOrEdit = async (registroItem, resetForm, id) => {
    if (id === 0) {
      try {
        await LotesXLoteoServices.insert(registroItem, token);
      } catch (err) {
        return false;
      }
    } else {
      try {
        await LotesXLoteoServices.update(registroItem, id, token);
      } catch (err) {
        return false;
      }
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    //setRegistroPropiedades(LotesXLoteoServices.getAllItems(token));
  };

  return (
    <>
      <PageHeader
        title="RESERVAS DE LOTES EN VENTA"
        subTitle="Gestión de propiedes"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            value={textoBuscar}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setTextoBuscar(e.target.value);
            }}
          />
          <Controls.Checkbox
            name="verPendientes"
            label="Ver pendientes"
            value={verPendientes}
            onChange={() => {
              setVerPendientes(!verPendientes);
              setCargando(false);
              handleSearch();
            }}
          />
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <>
                  {item.Lotesxloteo && (
                    <TableRow key={item.id}>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.Lotesxloteo.Loteo.nombre} - [
                        {item.Lotesxloteo.manzana}-{item.Lotesxloteo.nombre}]
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {convertirFechaToStr(item.fechaEstimadaFirma)}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.Cliente.apellido} {item.Cliente.nombre}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.Usuario.apellido} {item.Usuario.nombre}
                      </TableCell>
                      <TableCell>
                        <Controls.ActionButton
                          color="primary"
                          onClick={() => {
                            openInPopup(item);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </Controls.ActionButton>
                        {Permisos(rolCodigo, "VENTAS.RESERVAS.HISTORIAL") && (
                          <>
                            <Controls.ActionButton
                              style={{ color: "white" }}
                              color="secondary"
                              onClick={() => {
                                setRecordForEdit(item);
                                setOpenPopupEventos(true);
                              }}
                            >
                              <AccessAlarmIcon fontSize="small" />
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              style={{
                                backgroundColor: "orange",
                                color: "white",
                              }}
                              onClick={() => {
                                setRecordForEdit(item);
                                setOpenPopupHistorial(true);
                              }}
                            >
                              <MenuBookIcon fontSize="small" />
                            </Controls.ActionButton>
                          </>
                        )}

                        {Permisos(
                          rolCodigo,
                          "VENTAS.RESERVAS.CONFIRMACION"
                        ) && (
                          <>
                            {!item.vendido && !item.cancelado && (
                              <>
                                <Controls.ActionButton
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    setRecordForEdit(item);
                                    setOpenPopupBaja(true);
                                  }}
                                >
                                  <ThumbDownIcon fontSize="small" />
                                </Controls.ActionButton>
                                <Controls.ActionButton
                                  style={{
                                    backgroundColor: "green",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    openInPopupVenta(item);
                                  }}
                                >
                                  <ThumbUpIcon fontSize="small" />
                                </Controls.ActionButton>
                              </>
                            )}
                            <Controls.ActionButton
                              color="primary"
                              onClick={() => {
                                setRecordForEdit(item);
                                //console.log('Desde reserva para editar: ', item)
                                setOpenPopupEditar(true);
                              }}
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </Controls.ActionButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="VISOR DE RESERVA"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <LotesReservoVisor
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Popup
        title="VISOR DE EVENTOS - LOTEOS"
        openPopup={openPopupEventos}
        setOpenPopup={setOpenPopupEventos}
      >
        <EventosReservas
          token={token}
          puedeAgregar={Permisos(rolCodigo, "VENTAS.EVENTOS.AGREGAR")}
          puedeVerGestion={Permisos(rolCodigo, "VENTAS.EVENTOS.VER")}
          recordForEdit={recordForEdit}
          tipoEvento="lotes"
        />
      </Popup>
      <Popup
        title="VISOR DE HISTORIAL - LOTES"
        openPopup={openPopupHistorial}
        setOpenPopup={setOpenPopupHistorial}
      >
        <HistorialReservaLista
          token={token}
          puedeAgregar={Permisos(rolCodigo, "VENTAS.EVENTOS.AGREGAR")}
          puedeVerGestion={Permisos(rolCodigo, "VENTAS.EVENTOS.VER")}
          recordForEdit={recordForEdit}
          tipoProducto="lotes"
        />
      </Popup>
      <Popup
        title="DETALLE DE RESERVA"
        openPopup={openPopupDetalle}
        setOpenPopup={setOpenPopupDetalle}
      >
        <DetalleReserva
          token={token}
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          listaUsuarios={registroUsuarios}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Popup
        title="DETALLE DE BAJA"
        openPopup={openPopupBaja}
        setOpenPopup={setOpenPopupBaja}
      >
        <DetalleBaja
          token={token}
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          listaUsuarios={registroUsuarios}
          recordForEdit={recordForEdit}
          addOrEditVentaBaja={addOrEditVentaBaja}
        />
      </Popup>
      <Popup
        title="EDITAR RESERVA"
        openPopup={openPopupEditar}
        setOpenPopup={setOpenPopupEditar}
      >
        <LotexLoteoReservaEdicion
          token={token}
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          recordForEdit={recordForEdit}
          listaUsuarios={registroUsuarios}
          setOpenPopupEditar={setOpenPopupEditar}
          setCargandoGeneral={setCargando}
          />
      </Popup>
      <Popup
        title="DETALLE DE VENTA"
        openPopup={openPopupVenta}
        setOpenPopup={setOpenPopupVenta}
      >
        <ConfirmacionVentaLote
          token={token}
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          listaUsuarios={registroUsuarios}
          recordForEdit={recordForEdit}
          lotesListaCompleta={registroLotes}
          setLotesListaCompleta={setRegistroLotes}
          addOrEdit={addOrEditCambioLote}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
