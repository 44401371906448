import GestorAxios from "../../config/axios";
import Permisos from "../../config/permisos";

export async function getAllItems(token, rolCodigo) {
  let data = {};

  await GestorAxios.get("/ventas/reservaPropiedadVenta", {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      data = res.data;
    })
    .catch((e) => {
      console.log("Error: ", e.message);
    });

  return data;
}
/*
export async function getAllNoVistoGerente(token) {
  let data = {};

  await GestorAxios.get("/ventas/reservaPropiedadVenta/novistogerente", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function getAllNoVistoSecretaria(token) {
  let data = {};

  await GestorAxios.get("/ventas/reservaPropiedadVenta/novistosecretaria", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
*/
export async function updateObs(data, token) {
  let dataReturn = {};

  await GestorAxios.put(
    `/ventas/reservaPropiedadVenta/actualizarobs/${data.id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => {
      dataReturn = res;
    })
    .catch((error) => console.log("Error: ", error.message));

  return dataReturn;
}

export async function filtrarSimilares(token, dataBuscar) {
  let data = {};

  await GestorAxios.post(
    "/ventas/reservaPropiedadVenta/filtrarSimilares",
    dataBuscar,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    data = res.data;
  });

  return data;
}

export async function insert(data, token) {
  let dataReturn = {};

  await GestorAxios.post("/ventas/reservaPropiedadVenta/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(
    `/ventas/reservaPropiedadVenta/actualizar/${data.id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function getCompleto(token, rolCodigo) {
  let data = {};
  if (Permisos(rolCodigo, "VENTAS.RESERVAS.VERTODAS")) {
    await GestorAxios.get("/ventas/reservaPropiedadVenta/completo", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        data = res.data;
      })
      .catch((e) => {
        console.log("Error: ", e.message);
      });
  } else {
    await GestorAxios.get("/ventas/reservaPropiedadVenta/completo/vendedor", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        data = res.data;
      })
      .catch((e) => {
        console.log("Error: ", e.message);
      });
  }
  return data;
}

export async function getAllActivos(token) {
  let data = {};

  await GestorAxios.get("/ventas/reservaPropiedadVenta/activos", {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      data = res.data;
    })
    .catch((e) => {
      console.log("Error: ", e.message);
    });

  return data;
}

export async function confirmaVenta(data, id, token) {
  let dataReturn = {};
  //console.log('En services venta: ', data, id);

  await GestorAxios.put(
    `/ventas/reservaPropiedadVenta/confirmaventa/${id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function confirmaBaja(data, id, token) {
  let dataReturn = {};
  console.log('En services baja: ', id, data);
  await GestorAxios.put(
    `/ventas/reservaPropiedadVenta/confirmabaja/${id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function getNoProcesadoVenta(token) {
  let data = {};

  await GestorAxios.get("/ventas/reservaPropiedadVenta/noprocesadoventa", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function getNoProcesadoBaja(token) {
  let data = {};

  await GestorAxios.get("/ventas/reservaPropiedadVenta/noprocesadobaja", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function procesar(datos, id, token) {
  let data = {};

  await GestorAxios.put(`/ventas/reservaPropiedadVenta/procesado/${id}/`, datos, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
