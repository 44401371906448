import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import Widget from "../../components/Widget";
import Notification from "../../components/tablas/Notification";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

import { useUserState } from "../../context/UserContext";

import * as EventosServices from "../../services/venta/eventosReserva";

const moment = require("moment");

export default function EventosListaDashboars() {

  const { token } = useUserState();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [registros, setRegistros] = useState({});
  const [ver, setVer] = useState(false);
  const [cargando, setCargando] = useState(false);

  const convertirFechaToStr = (fechaTurno) => {
    const texto =
      fechaTurno.substr(8, 2) +
      "/" +
      fechaTurno.substr(5, 2) +
      "/" +
      fechaTurno.substr(0, 4);
    return texto;
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      EventosServices.getProximosEventos(token).then((res) => {
        setRegistros(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  const obtenerColor = (fecha) => {
    const f = moment(fecha);
    const fa = moment(new Date());
    const cantDias = f.diff(fa, "days");
    if (cantDias < 3) {
      return "#EC9A9A";
    } else if (cantDias < 7) {
      return "#F9FE84";
    }
    return "#9AECA4";
  };

  const obtenerNombreManzana = (manzana, id) => {
    const manzanas = manzana.split(",");
    return manzanas[id];
  };

  return (
    <div>
      {registros ? (
        <>
          {registros.length ? (
            <>
              <div style={{ margin: 10 }}>
                <Widget
                  disableWidgetMenu={true}
                  title="Próximos eventos"
                  upperTitle
                >
                  <Grid container item alignItems={"left"}>
                    <Grid item xs={8}>
                      <div style={{ textAlign: "left", fontSize: 33 }}>
                        {" "}
                        {registros.length}{" "}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ textAlign: "left", fontSize: 33 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setVer(!ver)}
                        >
                          {!ver ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  ></Grid>
                </Widget>
              </div>
              {ver && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2
                      style={{ marginLeft: 30, marginTop: 30, marginBottom: 0 }}
                    >
                      {" "}
                      <AccessAlarmIcon
                        style={{ marginBottom: -5, fontSize: 33 }}
                      />{" "}
                      EVENTOS ASOCIADOS A RESERVAS
                    </h2>{" "}
                  </Grid>
                  {registros.map((item, index) => (
                    <Grid item xs={3}>
                      <Paper
                        style={{
                          backgroundColor: obtenerColor(item.fecha),
                          padding: 10,
                          borderRadius: 10,
                          margin: 5,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            float: "right",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        >
                          {" "}
                          {item.Usuario.apellido} {item.Usuario.nombre}{" "}
                        </div>
                        <AccessAlarmIcon
                          style={{ marginBottom: -5, marginRight: 5 }}
                        />
                        <b> {convertirFechaToStr(item.fecha)} </b>
                        <br />
                        {item.ReservasPropiedadVentum ? (
                          <>
                            <h4>
                              P.VENTA{" "}
                              {
                                item.ReservasPropiedadVentum.PropiedadesVentum
                                  .nombre
                              }
                            </h4>
                          </>
                        ) : (
                          <>
                            <h4>
                              LOTE {item.ReservasLote.Lotesxloteo.Loteo.nombre}{" "}
                              -{" "}
                              {obtenerNombreManzana(
                                item.ReservasLote.Lotesxloteo.Loteo
                                  .cantManzanas,
                                item.ReservasLote.Lotesxloteo.manzana
                              )}{" "}
                              - {item.ReservasLote.Lotesxloteo.nombre}
                            </h4>
                          </>
                        )}
                        {item.observaciones} <br />
                      </Paper>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <> </>
          )}
        </>
      ) : (
        <> </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
