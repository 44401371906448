import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

let prefijo = "";

function NumberFormatCustom(props) {
  const { inputRef, onChange, prefix, suffix, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      prefix={prefijo}
      suffix={suffix}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      isNumericString
      decimalScale={2}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function InputMask(props) {
  const classes = useStyles();
  const { value, onChange, label, name, prefix, suffix, error=null, ...other } = props;
    //console.log('en format: ', label, name, prefix);
  prefijo = prefix;
  return (
    <div className={classes.root}>
      <TextField
        style={{ backgroundColor: '#F0F0F0'  }}
        label={label}
        value={value}
        onChange={onChange}
        name={name}
        id="formatted-numberformat-input"
        variant="filled"
        prefix={prefix}
        suffix={suffix}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        {...other}
        {...(error && {error:true,helperText:error})}
  />
    </div>
  );
}
