import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Widget from "../../components/Widget/Widget";
import Notification from "../../components/tablas/Notification";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useUserState } from "../../context/UserContext";

import AccessAlarmIcon from "@material-ui/icons/Attachment";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import * as HisotorialReservasServices from "../../services/venta/historialReserva";

const moment = require("moment");

export default function EventosForm() {
  const { token } = useUserState();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [registros, setRegistros] = useState({});
  const [ver, setVer] = useState(false);
  const [cargando, setCargando] = useState(false);

  const convertirFechaToStr = (fechaTurno) => {
    const texto =
      fechaTurno.substr(8, 2) +
      "/" +
      fechaTurno.substr(5, 2) +
      "/" +
      fechaTurno.substr(0, 4);
    return texto;
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      HisotorialReservasServices.getUltimosMensajes(token).then((res) => {
        setRegistros(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  const obtenerColor = (fecha) => {
    const f = moment(fecha);
    const fa = moment(new Date());
    const cantDias = fa.diff(f, "days");
    if (cantDias === 0) {
      return "#EC9A9A";
    } else if (cantDias === 1) {
      return "#F9FE84";
    } else {
      return "#9AECA4";
    }
  };

  const obtenerNombreManzana = (manzana, id) => {
    const manzanas = manzana.split(",");
    return manzanas[id];
  };

  const esCambioDeReserva = (index) => {
    if (index > 0) {
      const itemAnterior = registros[index - 1];
      const itemActual = registros[index];

      if (itemActual.ReservasPropiedadVentumId) {
        return (
          itemActual.ReservasPropiedadVentumId ===
          itemAnterior.ReservasPropiedadVentumId
        );
      }
      if (itemActual.ReservasLoteId) {
        return itemActual.ReservasLoteId === itemAnterior.ReservasLoteId;
      }
    }
    return false;
  };

  return (
    <div>
      {registros ? (
        <>
          {registros.length ? (
            <>
              <div style={{ margin: 10 }}>
                <Widget
                  disableWidgetMenu={true}
                  title="Mensajes recientes"
                  upperTitle
                >
                  <Grid container item alignItems={"left"}>
                    <Grid item xs={8}>
                      <div style={{ textAlign: "left", fontSize: 33 }}>
                        {" "}
                        {registros.length}{" "}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ textAlign: "left", fontSize: 33 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setVer(!ver)}
                        >
                          {!ver ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  ></Grid>
                </Widget>
              </div>
              {ver && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2
                      style={{ marginLeft: 30, marginTop: 30, marginBottom: 0 }}
                    >
                      {" "}
                      <MenuBookIcon
                        style={{
                          marginBottom: -5,
                          marginRight: 10,
                          fontSize: 33,
                        }}
                      />{" "}
                      MENSAJES ASOCIADOS A RESERVAS
                    </h2>
                    <br />
                  </Grid>
                  {registros.map((item, index) => (
                    <>
                      {!esCambioDeReserva(index) ? (
                        <div>
                          {" "}
                          <br />{" "}
                        </div>
                      ) : (
                        <></>
                      )}
                      <Grid
                        style={{
                          backgroundColor: obtenerColor(item.createdAt),
                          padding: 10,
                          borderRadius: 20,
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                        item
                        xs={12}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            float: "right",
                            marginRight: 10,
                            marginTop: 5,
                          }}
                        >
                          {item.Usuario.apellido} {item.Usuario.nombre}{" "}
                        </div>
                        {!esCambioDeReserva(index) && (
                          <>
                            {item.ReservasPropiedadVentum ? (
                              <>
                                <h4 style={{ marginTop: 5 }}>
                                  P.VENTA{" "}
                                  {
                                    item.ReservasPropiedadVentum
                                      .PropiedadesVentum.nombre
                                  }
                                </h4>
                              </>
                            ) : (
                              <>
                                <h4 style={{ marginTop: 5 }}>
                                  LOTE{" "}
                                  {item.ReservasLote.Lotesxloteo.Loteo.nombre} -{" "}
                                  {obtenerNombreManzana(
                                    item.ReservasLote.Lotesxloteo.Loteo
                                      .cantManzanas,
                                    item.ReservasLote.Lotesxloteo.manzana
                                  )}{" "}
                                  - {item.ReservasLote.Lotesxloteo.nombre}
                                </h4>
                              </>
                            )}
                          </>
                        )}
                        <AccessAlarmIcon
                          style={{ marginBottom: -5, marginRight: 5 }}
                        />
                        <b> {convertirFechaToStr(item.createdAt)} </b>
                        <br />
                        {item.nota} <br />
                      </Grid>
                    </>
                  ))}
                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <> </>
          )}
        </>
      ) : (
        <> </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
