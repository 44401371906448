
let GestorUrls;

if (process.env.NODE_ENV !== 'production') {

    GestorUrls = {
        drive: 'http://127.0.0.1:5500/uploads/',
        api: 'http://localhost:3000'
    };

} else {

    GestorUrls = {
        drive: 'https://drive.grupodelvalleinmobiliaria.com/',
        api: 'https://api-ssl.grupodelvalleinmobiliaria.com'
    };
}

export default GestorUrls;



