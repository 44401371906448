import GestorAxios from "../../config/axios";

export async function agregarAGaleria(data, token, setProgress) {
  //console.log('Llega a services: ', data.get('imagen'));

  let dataReturn = {};
  await GestorAxios.post(`/web/galeria/agregaragaleria/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
    onUploadProgress: data => {
      //Set the progress value to show the progress bar
      setProgress(Math.round((100 * data.loaded) / data.total));
      //console.log(data);
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cargarGaleria(path, token) {
  let dataReturn = {};
  await GestorAxios.post('/web/galeria/cargargaleria/', path, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function guardarGaleria(data, token) {
  let dataReturn = {};
  await GestorAxios.post(`/web/galeria/guardargaleria/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}
