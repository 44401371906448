import React, { useEffect, useState } from "react";

import { Grid, CircularProgress, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Controls from "../../components/tablas/controls/Controls";
import { useForm } from "../../components/tablas/useForm";

import * as LocalidadServices from "../../services/ubicacion/localidad";
import * as PaisServices from "../../services/ubicacion/pais";
import * as ProvinciaServices from "../../services/ubicacion/provincia";
import * as ClienteServices from "../../services/cliente/cliente";
import * as EstadoCivilServices from "../../services/cliente/estadoCivil";

import ForwardIcon from "@material-ui/icons/Forward";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import { useUserState } from "../../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    backgroundColor: "lightgray",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const initialFValues = {
  //cliente
  nombre: "",
  apellido: "",
  DNI: "",
  telefono: "",
  email: "",
  //busqueda
  b_nombre: "",
  b_apellido: "",
  b_DNI: "",
  b_telefono: "",
  b_email: "",
};

export default function LotexLoteoReserva(props) {
  const { token } = useUserState();

  const { setClienteEncontrado, datos, continuar, setContinuar } = props; // Props datos es para marcar la cantidad de campos a mostrar

  const classes = useStyles();

  const [cargando, setCargando] = useState(false);
  const [registro, setRegistro] = useState({});
  const [confirma, setConfirma] = React.useState(false);

  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});
  const [registroLocalidades, setRegistroLocalidades] = useState({});
  const [registroEstadoCivil, setRegistroEstadoCivil] = useState({});

  const [seleccionado, setSeleccionado] = useState(false);
  const [guardando, setGuardando] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = // eslint-disable-line
    useForm(initialFValues, true, validate);

  const telefonoCoincide = (telefono) => {
    let siNo = false;
    if (telefono) {
      if (values.b_telefono) {
        if (values.b_telefono.length > 0) {
          if (telefono.includes(values.b_telefono)) {
            siNo = true;
          }
        }
      }
    }
    return siNo;
  };

  const buscarSimilares = () => {
    const datosBuscar = {
      nombre: values.b_nombre,
      apellido: values.b_apellido,
      DNI: values.b_DNI,
      telefono: values.b_telefono,
      email: values.b_email,
    };
    ClienteServices.filtrarSimilares(token, datosBuscar).then((res) => {
      setRegistro(res);
    });
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidades(res);
      });
      EstadoCivilServices.getAllItems(token).then((res) => {
        setRegistroEstadoCivil(res);
      });
    }
  }, [cargando]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper elevation={0}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {!seleccionado ? (
              <>
                <Grid
                  style={{ backgroundColor: "lightgreen" }}
                  item
                  xs={12}
                  sm={12}
                >
                  <h2 style={{ paddingLeft: 10 }}>
                    {" "}
                    BUSQUE Y SELECCIONE EL CLIENTE
                  </h2>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="b_nombre"
                    label="Nombre / Alias"
                    value={values.b_nombre}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="b_apellido"
                    label="Apellido"
                    value={values.b_apellido}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="b_DNI"
                    label="DNI"
                    value={values.b_DNI}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    name="b_telefono"
                    label="Teléfono"
                    value={values.b_telefono}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    name="b_email"
                    label="Email"
                    type="email"
                    value={values.b_email}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!guardando}
                  <div style={{ marginTop: 25, textAlign: "right" }}>
                    <Controls.Button onClick={buscarSimilares} text="Buscar" />
                  </div>
                </Grid>
                {!confirma ? (
                  <>
                    {continuar &&
                      <div>
                        <h4>CLIENTES ENCONTRADOS</h4>
                      </div>
                    }
                    <Grid item xs={12} sm={12}></Grid>
                    {registro.length ? (
                      <>
                        {registro.map((item) => (
                          <Grid item xs={12} sm={4}>
                            <Card className={classes.root}>
                              <CardContent>
                                <Typography
                                  style={{ textAlign: "center" }}
                                  className={classes.title}
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  <AccountCircleIcon
                                    style={{ fontSize: 40, color: "white" }}
                                  />
                                </Typography>
                                <Typography variant="h5" component="h2">
                                  {item.apellido} {item.nombre}
                                </Typography>
                                <Typography
                                  className={classes.pos}
                                  color="textSecondary"
                                >
                                  DNI: {item.DNI}
                                </Typography>
                                <Typography variant="body2" component="p">
                                  {telefonoCoincide(item.telefono) && (
                                    <>
                                      <PhoneAndroidIcon
                                        style={{ marginBottom: -5 }}
                                      />{" "}
                                      Coincide con TELEFONO PRINCIPAL
                                    </>
                                  )}
                                  {telefonoCoincide(item.asociadoTelefono) && (
                                    <>
                                      <PhoneAndroidIcon
                                        style={{ marginBottom: -5 }}
                                      />{" "}
                                      Coincide con TELEFONO DEL ASOCIADO
                                    </>
                                  )}
                                  {telefonoCoincide(
                                    item.telefonoAlternativo
                                  ) && (
                                    <>
                                      <PhoneAndroidIcon
                                        style={{ marginBottom: -5 }}
                                      />{" "}
                                      Coincide con TELEFONO ALTERNATIVO
                                    </>
                                  )}
                                  <br />
                                  <EmailIcon
                                    style={{ marginBottom: -5 }}
                                  />{" "}
                                  {item.email}
                                  <br />
                                  {item.Usuario ? (
                                    <>
                                      VENDEDOR:{" "}
                                      <b>
                                        {item.Usuario.nombre}{" "}
                                        {item.Usuario.apellido}
                                      </b>
                                    </>
                                  ) : (
                                    <>NO HAY VENDEDOR ASIGNADO</>
                                  )}
                                </Typography>
                                <Typography
                                  className={classes.pos}
                                  color="textSecondary"
                                >
                                  <Controls.ActionButton
                                    color="primary"
                                    onClick={() => {
                                      setSeleccionado(true);
                                      setConfirma(true);
                                      let paisId = 0;
                                      let provinciaId = 0;
                                      try {
                                        const prov = registroLocalidades.find(
                                          (itemLoc) =>
                                            itemLoc.id === item.LocalidadId
                                        );
                                        const pais = registroProvincias.find(
                                          (itemProv) =>
                                            itemProv.id === prov.ProvinciumId
                                        );
                                        if (pais) {
                                          paisId = pais.PaiId;
                                        }
                                        if (prov) {
                                          provinciaId = prov.ProvinciumId;
                                        }
                                      } catch (e) {}
                                      item.PaiId = paisId;
                                      item.ProvinciumId = provinciaId;
                                      item.b_nombre = values.b_nombre;
                                      item.b_apellido = values.b_apellido;
                                      item.b_DNI = values.b_DNI;
                                      item.b_telefono = values.b_telefono;
                                      item.b_email = values.b_email;
                                      item.DNI = item.DNI ? item.DNI : "";
                                      setValues(item);
                                      setClienteEncontrado(item);
                                    }}
                                  >
                                    <ForwardIcon fontSize="small" />
                                  </Controls.ActionButton>
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </>
                    ) : (
                      <>
                        {continuar &&
                          <> No se encontraron registros</>
                        }
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {!continuar ? (
                  <>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={9}
                    >
                      <h2 style={{ paddingLeft: 10 }}>CLIENTE SELECCIONADO</h2>
                    </Grid>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <Controls.ActionButton
                        style={{ marginTop: 15 }}
                        color="primary"
                        onClick={() => {
                          setSeleccionado(false);
                          setConfirma(false);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" /> CAMBIAR CLIENTE
                      </Controls.ActionButton>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="nombre"
                        label="Nombre / Alias"
                        value={values.nombre}
                        onChange={handleInputChange}
                        error={errors.nombre}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="apellido"
                        label="Apellido"
                        value={values.apellido}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="DNI"
                        label="DNI"
                        value={values.DNI}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="telefono"
                        label="Teléfono"
                        value={values.telefono}
                        onChange={handleInputChange}
                        error={errors.telefono}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="email"
                        label="email"
                        type="email"
                        value={values.email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    {datos === "completo" && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Controls.Input
                            name="direccion"
                            label="Dirección"
                            value={values.direccion}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controls.Input
                            name="telefonoAlternativo"
                            label="Teléfono Alternativo"
                            value={values.telefonoAlternativo}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Select
                            name="PaiId"
                            label="País"
                            value={values.PaiId}
                            onChange={handleInputChange}
                            options={registroPaises.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          {values.PaiId ? (
                            <div>
                              {registroProvincias.find(
                                (item) => item.PaiId === values.PaiId
                              ) ? (
                                <Controls.Select
                                  name="ProvinciumId"
                                  label="Provincia"
                                  value={values.ProvinciumId}
                                  onChange={handleInputChange}
                                  options={registroProvincias.map((item) => {
                                    if (item.PaiId === values.PaiId) {
                                      return {
                                        id: item.id,
                                        title: item.nombre,
                                      };
                                    } else {
                                      return false; // Línea Modificada
                                    }
                                  })}
                                />
                              ) : (
                                <>
                                  {" "}
                                  No hay provincias creadas en este pais. Por
                                  favor completar antes de continuar.
                                </>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          {values.ProvinciumId ? (
                            <div>
                              {registroLocalidades.find(
                                (item) =>
                                  item.ProvinciumId === values.ProvinciumId
                              ) ? (
                                <>
                                  <Controls.Select
                                    name="LocalidadId"
                                    label="Localidad"
                                    value={values.LocalidadId}
                                    onChange={handleInputChange}
                                    options={registroLocalidades.map((item) => {
                                      if (
                                        item.ProvinciumId ===
                                        values.ProvinciumId
                                      ) {
                                        return {
                                          id: item.id,
                                          title: item.nombre,
                                        };
                                      } else {
                                        return false; // Línea Modificada
                                      }
                                    })}
                                  />
                                </>
                              ) : (
                                <>
                                  {" "}
                                  No hay localidades creadas en esta provincia.
                                  Por favor completar antes de continuar.
                                </>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Select
                            name="EstadoCivilId"
                            label="Estado Civil"
                            value={values.EstadoCivilId}
                            onChange={handleInputChange}
                            options={registroEstadoCivil.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controls.Input
                            name="observaciones"
                            label="Observaciones"
                            value={values.observaciones}
                            onChange={handleInputChange}
                            multiline
                            rowsMax={4}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Input
                            name="asociadoNombre"
                            label="Nombre asociado"
                            value={values.asociadoNombre}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Input
                            name="asociadoDNI"
                            label="DNI asociado"
                            value={values.asociadoDNI}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Input
                            name="asociadoTelefono"
                            label="Teléfono asociado"
                            value={values.asociadoTelefono}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      {!guardando ? (
                        <div style={{ marginTop: 25, textAlign: "right" }}>
                          <Controls.Button
                            onClick={() => setContinuar(true)}
                            text="Continuar"
                          />
                        </div>
                      ) : (
                        <div style={{ margin: 25, textAlign: "right" }}>
                          <CircularProgress size={26} />
                        </div>
                      )}
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
