import GestorAxios from "../../config/axios";

export async function getAllItems(token) {
  let data = {};

  await GestorAxios.get("/usuario", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function filtrarItems(token, key, value) {
  let data = {};

  await GestorAxios.get(`/usuario/filtrar/${key}/${value}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function insert(data, token) {
  let dataReturn = {};

  await GestorAxios.post("/usuario/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/usuario/actualizar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function paraGraficos(token, datos) {
  let data = {};

  await GestorAxios.get(`/usuario/paragraficos/${datos.fechaDesde}/${datos.fechaHasta}/${datos.filtro}/${datos.incremento}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function bloquear(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/usuario/bloquear/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function activos(token) {
  let dataReturn = {};

  await GestorAxios.get("/usuario/activos/", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res.data;
  });

  return dataReturn;
}

export async function recuperar(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/usuario/recuperar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function inActivos(token) {
  let dataReturn = {};

  await GestorAxios.get("/usuario/inactivos/", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res.data;
  });

  return dataReturn;
}
