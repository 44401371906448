import React from "react";
import gestorAxios from '../config/axios';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        userId: action.userId,
        email: action.email,
        rolId: action.rolId,
        rolCodigo: action.rolCodigo
      };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "VERIFY_TOKEN_IN_PROCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {


  const token = localStorage.getItem("token");

  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!token,
    token: token,
    userId: 0,
    email: undefined,
    rolId: 0,
    rolCodigo: undefined
  });

  /*
  console.log("EN CONTEXT ", state.rolCodigo);
  if (state.rolCodigo === undefined) {

    dispatch({ type: "VERIFY_TOKEN_IN_PROCESS" });

  }
*/
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}



// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {

    const urlLogin = '/usuario/login';
    const dataLogin = {
      email: login,
      password: password
    }

    gestorAxios.post(urlLogin, dataLogin).then(res => {

      if (res.status === 200) {

        //console.log("LOGIN CORRECTO", res.data);
        localStorage.setItem('token', res.data.data.token);
        setError(null)
        setIsLoading(false)
        dispatch({
          type: 'LOGIN_SUCCESS',
          isAuthenticated: true,
          token: res.data.data.token,
          userId: res.data.data.id,
          email: res.data.data.email,
          rolId: res.data.data.rol,
          rolCodigo: res.data.data.rolCodigo
        })

        history.push('/app/dashboard')

      } else {
        //"LOGIN NO 200", res.data);
        dispatch({ type: "LOGIN_FAILURE" });
        setError(true);
        setIsLoading(false);
      }

    }).catch(error => {
      //console.log("ERROR Y CATCH");
      setError(true);
      setIsLoading(false);
    })



  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

// ###########################################################

function verifyToken(dispatch, history, verificaEnCadaAcceso) {


  const token = localStorage.getItem("token");

  gestorAxios.post('/usuario/verifyToken/', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res) => {

    if (res.status === 200) {

      //console.log("VERIFY TOKER CORRECTO", res.data);
      localStorage.setItem('token', res.data.data.token);
      if (!verificaEnCadaAcceso) {
        dispatch({
          type: 'LOGIN_SUCCESS',
          isAuthenticated: true,
          token: res.data.data.token,
          userId: res.data.data.id,
          email: res.data.data.email,
          rolId: res.data.data.rol,
          rolCodigo: res.data.data.rolCodigo
        })

        history.push('/app/dashboard');
      }

    } else {
      //"LOGIN NO 200", res.data);
      dispatch({ type: "LOGIN_FAILURE" });
    }

  }).catch(error => {
    //console.log("ERROR Y CATCH");

  })




}

function signOut(dispatch, history) {
  localStorage.removeItem("token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}


export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, verifyToken };




