import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, } from "@material-ui/core";

import * as LocalidadServices from "../../../services/ubicacion/localidad";
import * as PaisServices from "../../../services/ubicacion/pais";
import * as ProvinciaServices from "../../../services/ubicacion/provincia";
import * as PropietarioServices from "../../../services/producto/propietario";

import { useUserState } from "../../../context/UserContext";

import Permisos from "../../../config/permisos";

import PropietarioForm from "./PropietarioForm";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";
import PageHeader from "../../../components/tablas/PageHeader";
import Popup from "../../../components/tablas/Popup";
import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";

import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Search } from "@material-ui/icons";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "direccion", label: "Dirección" },
  { id: "telefono", label: "Teléfono" },
  { id: "email", label: "Email" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function Propietario() {

  const { token, rolCodigo, } = useUserState();

  const puedeAgregar = Permisos(rolCodigo, "PRODUCTOS.PROPIETARIOS.AGREGAR");

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => { return items; }, });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", });

  const [registros, setRegistros] = useState({});
  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});
  const [registroLocalidad, setRegistroLocalidad] = useState({});

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      PropietarioServices.getAllItems(token, rolCodigo).then((res) => {
        setRegistros(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidad(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
    }
  }, [cargando, registros, registroPaises]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopup = (item) => {
    let paisId = 0;
    let provinciaId = 0;
    try {
      const prov = registroLocalidad.find(
        (itemLoc) => itemLoc.id === item.LocalidadId
      );
      const pais = registroProvincias.find(
        (itemProv) => itemProv.id === prov.ProvinciumId
      );
      if (pais) {
        paisId = pais.PaiId;
      }
      if (prov) {
        provinciaId = prov.ProvinciumId;
      }
    } catch (e) {
    }
    item.PaiId = paisId;
    item.ProvinciumId = provinciaId;
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) => {
            if (x.nombre)
              if (x.nombre.toLowerCase().includes(target.value)) return x;
            if (x.apellido)
              if (x.apellido.toLowerCase().includes(target.value)) return x;
            if (x.telefono)
              if (x.telefono.toLowerCase().includes(target.value)) return x;
            return false; // Línea Modificada
          });
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm) => {
    if (registroItem.id === 0) {
      await PropietarioServices.insert(registroItem, token);
    } else await PropietarioServices.update(registroItem, token);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    setRegistros(PropietarioServices.getAllItems(token));
  };

  return (
    <>
      <PageHeader
        title="PROPIETARIOS"
        subTitle="Gestión de propietarios"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          {puedeAgregar && (
            <Controls.Button
              text="Agregar"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          )}
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell style={{ fontWeight: "500" }}>
                    {" "}
                    {item.apellido} - {item.nombre}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "500" }}>
                    {" "}
                    {item.direccion}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "500" }}>
                    {" "}
                    {item.telefono}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "500" }}>
                    {" "}
                    {item.email}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "500" }}>
                    {Permisos(rolCodigo, "PRODUCTOS.PROPIETARIOS.EDITAR") && (
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="Gestión propietarios"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <PropietarioForm
          token={token}
          listaLocalidades={registroLocalidad}
          listaProvincias={registroProvincias}
          listaPaises={registroPaises}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
