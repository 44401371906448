import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, } from "@material-ui/core";

import * as ClienteServices from "../../services/cliente/cliente";
import * as HistorialServices from "../../services/cliente/historial";

import { useUserState } from "../../context/UserContext";

import Permisos from "../../config/permisos";

import HistorialForm from "./HistorialForm";
import HistorialLista from "./HistorialLista";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import PageHeader from "../../components/tablas/PageHeader";
import Popup from "../../components/tablas/Popup";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";

import RoomIcon from "@material-ui/icons/Room";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Search } from "@material-ui/icons";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "direccion", label: "Dirección" },
  { id: "telefono", label: "Teléfono" },
  { id: "email", label: "Email" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function Historial() {

  const { token, rolCodigo } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => { return items; }, });
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupLista, setOpenPopupLista] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", });
  const [registros, setRegistros] = useState({});

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ClienteServices.getAllItems(token, rolCodigo).then((res) => {
        setRegistros(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInPopupLista = (item) => {
    setRecordForEdit(item);
    setOpenPopupLista(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) => {
            if (x.nombre)
              if (x.nombre.toLowerCase().includes(target.value)) return x;
            if (x.apellido)
              if (x.apellido.toLowerCase().includes(target.value)) return x;
            if (x.telefono)
              if (x.telefono.toLowerCase().includes(target.value)) return x;
            return false; // Línea Modificada
          });
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm) => {
    try {
      await HistorialServices.insert(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setOpenPopupLista(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
  };

  return (
    <>
      <PageHeader
        title="HISTORIAL DE CLIENTES"
        subTitle="Gestión de seguimiento de clientes"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell style={{ fontWeight: "500" }}>
                    {" "}
                    {item.apellido} - {item.nombre}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "500" }}>
                    {" "}
                    {item.direccion}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "500" }}>
                    {" "}
                    {item.telefono}{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "500" }}>
                    {" "}
                    {item.email}{" "}
                  </TableCell>
                  <TableCell>
                    {Permisos(rolCodigo, "CLIENTES.HISTORIAL.AGREGAR") && (
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                    )}
                    <Controls.ActionButton
                      color="primary"
                      style={{ backgroundColor: "#1F6D14" }}
                      onClick={() => {
                        openInPopupLista(item);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="Seguimiento de cliente"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <HistorialForm
          token={token}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Popup
        title="Historial de seguimiento"
        openPopup={openPopupLista}
        setOpenPopup={setOpenPopupLista}
      >
        <HistorialLista
          token={token}
          puedeAgregar={Permisos(rolCodigo, "CLIENTES.HISTORIAL.AGREGAR")}
          puedeVerGestion={Permisos(
            rolCodigo,
            "CLIENTES.HISTORIAL.VERPESTAÑAGESTION"
          )}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
