import React from "react";
import {
  Route,
  Switch,
//  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";

// PAGINAS
import Pais from '../../pages/ubicacion/Pais';
import Provincia from '../../pages/ubicacion/Provincia';
import Localidad from '../../pages/ubicacion/Localidad';

import Fuente from '../../pages/cliente/Fuente';
import Cliente from '../../pages/cliente/Cliente';
import Historial from '../../pages/cliente/Historial';
import EstadoCivil from '../../pages/cliente/EstadoCivil';
import ConsultaWeb from '../../pages/web/ConsultaWebGeneral';

import Usuario from '../../pages/usuario/Usuario';
import RecuperarUsuario from '../../pages/usuario/RecuperarUsuario';
import Perfil from '../../pages/perfil/index';

// PRODUCTOS
import Estado from '../../pages/producto/Estado';
import Propietario from '../../pages/producto/propiedades/Propietario';
import Asociado from '../../pages/venta/loteos/Asociado';
import PropiedadVenta from '../../pages/producto/propiedades/PropiedadVenta';
import Loteos from '../../pages/producto/loteos/Loteos';

// VENTAS
import FormaPago from '../../pages/venta/FormaPago';
import Moneda from '../../pages/venta/Moneda';
import PropiedadResevada from '../../pages/reserva/propiedades/ReservasPropiedades';
import LotesResevado from '../../pages/reserva/loteos/ReservasLotes';
import VentasLotes from '../../pages/venta/loteos/VentasLote';

// CONTABILIDAD
import Cuotas from '../../pages/contable/Cuotas';
import Banco from '../../pages/contable/Banco';
import BancoSucursal from '../../pages/contable/BancoSucursal';
import Cheques from '../../pages/contable/Cheque';
import Transferencias from '../../pages/contable/Transferencia';
import TarjetaCredito from '../../pages/contable/TarjetaCredito';

// CAJA
import Origen from "../../pages/caja/Origen";
import CajaDiaria from "../../pages/caja/CajaDiaria";
//import MercadoPago from "../../pages/caja/MercadoPago";

// UTILES
import Calculadora from '../../pages/utiles/CalculadoraDeCuotas';

// context
import { useLayoutState } from "../../context/LayoutContext";
import { 
//  useUserState, 
  useUserDispatch, 
  verifyToken 
} from "../../context/UserContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var userDispatch = useUserDispatch();
  //var { isAuthenticated, rolCodigo } = useUserState();
  //console.log("PASA POR LAYOUT ->", isAuthenticated, rolCodigo);

  // CONTROLA TOKEN VALIDO
    
  verifyToken(
    userDispatch,      
    props.history,
    true
    
  )
  
  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
                                         
              <Route path="/app/tablas/ubicacion/pais" component={Pais} />
              <Route path="/app/tablas/ubicacion/provincia" component={Provincia} />
              <Route path="/app/tablas/ubicacion/localidad" component={Localidad} />

              <Route path="/app/tablas/cliente/fuente" component={Fuente} />
              <Route path="/app/tablas/cliente/estadocivil" component={EstadoCivil} />
              <Route path="/app/cliente/cliente" component={Cliente} />
              <Route path="/app/web/consultaweb" component={ConsultaWeb} />
              <Route path="/app/cliente/historial" component={Historial} />
              
              <Route path="/app/usuario" component={Usuario} />
              <Route path="/app/recuperar" component={RecuperarUsuario} />
              <Route path="/app/perfil" component={Perfil} />

              <Route path="/app/productos/pventa" component={PropiedadVenta} />
              <Route path="/app/productos/loteos" component={Loteos} />
              <Route path="/app/tablas/producto/estado" component={Estado} />
              <Route path="/app/producto/propietario" component={Propietario} />

              <Route path="/app/venta/asociado" component={Asociado} />

              <Route path="/app/tablas/venta/fpago" component={FormaPago} />
              <Route path="/app/tablas/venta/moneda" component={Moneda} />
              <Route path="/app/tablas/contable/banco" component={Banco} />
              <Route path="/app/tablas/contable/bancosuc" component={BancoSucursal} />
              <Route path="/app/tablas/contable/tarjetacredito" component={TarjetaCredito} />
              <Route path="/app/tablas/caja/origen" component={Origen} />

              <Route path="/app/venta/propreservada" component={PropiedadResevada} />
              <Route path="/app/venta/lotesreservado" component={LotesResevado} />
              <Route path="/app/venta/ventaslotes" component={VentasLotes} />

              <Route path="/app/contable/cuotas" component={Cuotas} />
              <Route path="/app/contable/cheque" component={Cheques} />
              <Route path="/app/contable/transferencia" component={Transferencias} />

              <Route path="/app/caja/cajadiaria" component={CajaDiaria} />
              {/* <Route path="/app/caja/mercadopago" component={MercadoPago} /> */}

              <Route path="/app/utiles/calculadora" component={Calculadora} />
              {/*<Route path="/app/config/calculadora" component={CalculadoraForm} />*/}

            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://grupodelvalleinmobiliaria.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Grupo del Valle
                </Link>
                <Link
                  color={'primary'}
                  href={'https://grupodelvalleinmobiliaria.com/villa-quillinzo/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Loteos
                </Link>
                <Link
                  color={'primary'}
                  href={'https://grupodelvalleinmobiliaria.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Contactar
                </Link>
              </div>
              <div>
                <Link
                  href={'https://www.facebook.com/'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
