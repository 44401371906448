import React from 'react'
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(6),
        backgroundColor: 'white',
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

export default function PopupPrint(props) {

    const { children, openPopup } = props;
    const classes = useStyles();

    return (
        <Dialog fullWidth={true} open={openPopup} maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}
