import React from "react";
import {
  Grid,

  Button
} from "@material-ui/core";
import { Link } from 'react-router-dom';

//import { useTheme } from "@material-ui/styles";
 

// styles
//import useStyles from "./styles";

// components

//import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
//import { Typography } from "../../components/Wrappers";

// AVISOS
import ClientesConError from '../../pages/cliente/ClienteConError'
import ClientesSinContactar from '../../pages/cliente/ClienteSinContactar'
import ClienteSinAsignarVendedor from '../../pages/cliente/ClienteSinAsignarVendedor'
import ConsultaWeb from "../../pages/web/ConsultaWeb";
import BoletinWeb from "../../pages/web/BoletinWeb";
import EventosProximos from '../../pages/venta/EventosListaDashboard';
import HistorialNuevosMensajes from '../../pages/venta/HistorialListaDashboard';
import AvisoVentaProp from "../../pages/venta/propiedades/AvisoVentaProp";
import AvisoBajaProp from "../../pages/venta/propiedades/AvisoBajaProp";
import AvisoVentaLote from "../../pages/venta/loteos/AvisoVentaLote";
import AvisoBajaLote from "../../pages/venta/loteos/AvisoBajaLote";
import AvisoPreJudicial from "../../pages/venta/loteos/AvisoPreJudicial";
import NotificacionIngresoEgreso from "../caja/NotificacionIngresoEgreso";
//import Imprimir from "../utiles/Imprimir";
import BaseGraficos from "../utiles/graficos/BaseGraficos";

import { useUserState } from '../../context/UserContext';
import Permisos from '../../config/permisos';

export default function Dashboard(props) {
  //var classes = useStyles();
  //var theme = useTheme();

  const { rolCodigo } = useUserState();

  return (
    <>
      <PageTitle title="Mesa de entrada" button={
      <Link  style={{ textDecoration : 'none'}} to="/app/utiles/calculadora">
      <Button
     
        variant="contained"
        size="medium"
        color="primary"        
      >
        CALCULADORA DE CUOTAS
    </Button>
    </Link>
    } />
      <Grid container spacing={4}>
        
      {Permisos(rolCodigo, 'GRAFICO.CLIENTESXVENDEDORES') &&
        <BaseGraficos />
      }

        <EventosProximos />
        <HistorialNuevosMensajes />

        <Grid item xs={12}>
          <ClientesConError />
        </Grid>

        {Permisos(rolCodigo, 'CLIENTES.ASIGNAR') &&
          <Grid item xs={12}>
            <ClienteSinAsignarVendedor />
          </Grid>
        }

        {Permisos(rolCodigo, 'CLIENTES.PRIMERCONTACTO') &&
          <Grid item xs={12}>
            <ClientesSinContactar />
          </Grid>
        }

        {Permisos(rolCodigo, 'WEB.POTENCIALCLIENTE') &&
          <Grid item xs={12}>
            <ConsultaWeb />
          </Grid>
        }

        {Permisos(rolCodigo, 'WEB.BOLETIN') &&
          <Grid item xs={12}>
            <BoletinWeb />
          </Grid>
        }

        {Permisos(rolCodigo, 'RESERVA.VENTA') &&
          <Grid item xs={12}>
            {console.log(rolCodigo)}
            <AvisoVentaProp />
          </Grid>
        }

        {Permisos(rolCodigo, 'RESERVA.VENTA') &&
          <Grid item xs={12}>
            {console.log(rolCodigo)}
            <AvisoBajaProp />
          </Grid>
        }

        {Permisos(rolCodigo, 'RESERVA.VENTA') &&
          <Grid item xs={12}>
            {console.log(rolCodigo)}
            <AvisoVentaLote />
          </Grid>
        }

        {Permisos(rolCodigo, 'RESERVA.VENTA') &&
          <Grid item xs={12}>
            {console.log(rolCodigo)}
            <AvisoBajaLote />
          </Grid>
        }

        {Permisos(rolCodigo, 'VENTAS.PREJUDICIAL') &&
          <Grid item xs={12}>
            {console.log(rolCodigo)}
            <AvisoPreJudicial />
          </Grid>
        }

        {Permisos(rolCodigo, 'CAJA.GENERAL') &&
          <Grid item xs={12}>
            {console.log(rolCodigo)}
            <NotificacionIngresoEgreso />
          </Grid>
        }

          {/* <Grid item xs={12}>
            <Imprimir titulo="Prueba"/>
          </Grid> */}

      </Grid>
    </>
  );
}

// #######################################################################
/*
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}
*/
/*
function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
*/
