import React, { useState, useEffect } from "react";
//import ReactToPrint from 'react-to-print';
import { CircularProgress, Grid, Tabs, Tab, Box } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { useUserState } from "../../context/UserContext";

import CuotasForm from "./CuotasForm";
import ListadoCuotas from "./ListadoCuotas";
import VentasLoteForm from "../venta/loteos/VentasLoteForm";

import * as FormaPagoServices from "../../services/venta/formaPago";
import * as VentasLotesServices from "../../services/venta/ventasLote";
import * as AsociadosxloteServices from "../../services/producto/asociadosXLote";
import * as AsociadosService from "../../services/venta/asociado";
import * as MonedaServices from "../../services/venta/moneda";
import * as CuotasxVentasLoteServices from "../../services/contable/cuotasxVentasLote";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import PageHeader from "../../components/tablas/PageHeader";
import Popup from "../../components/tablas/Popup";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";

import RoomIcon from "@material-ui/icons/Room";
import ReceiptIcon from '@material-ui/icons/Receipt';
import GavelIcon from '@material-ui/icons/Gavel';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const cabecera = [
  { id: "cliente", label: "Cliente" },
  { id: "codigo", label: "Código" },
  { id: "loteo", label: "Loteo" },
  { id: "cuota", label: "Cuota" },
  { id: "vencimiento", label: "Vencimiento" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

function TabPanel(props) {

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function FormaPago() {

  const { token } = useUserState();

  //const fa = new Date();
  
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => { return items; }, });
  const [registros, setRegistros] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupEditar, setOpenPopupEditar] = useState(false);
  const [openPopupListadoCuotas, setOpenPopupListadoCuotas] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", });
  const [asociadosxLote, setAsociadosxLote] = useState({});
  const [asociados, setAsociados] = useState({});
  const [formaPago, setFormaPago] = useState({});
  const [moneda, setMoneda] = useState({});
  const [cuotas, setCuotas] = useState({});
  const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
  const [selectedDateTo, setSelectedDateTo] = useState(new Date());
  const [vigentes, setVigentes] = useState({});
  const [adeudadas, setAdeudadas] = useState({});
  const [pagadas, setPagadas] = useState({});
  const [judiciales, setJudiciales] = useState({})
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const calcularTotales = (cuotas) => {
    let acumulador = 0;
    for (let i = 0; i < cuotas.length; i++) {
      acumulador += parseInt(cuotas[i].importe);
    }
    return acumulador;
  }

  const handleChange = async (event, newValue) => {
    switch (newValue) {
      case 0:
        setCuotas(vigentes);
        break;
      case 1:
        setCuotas(adeudadas);
        break;
      case 2:
        setCuotas(pagadas);
        break;
      case 3:
        setCuotas(judiciales);
        break;
        default:
        break;
    }
    setValue(newValue);
  };

  const handleChangeDateFrom = (date) => {
    console.log('Date: ', (date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear()));
    setSelectedDateFrom(date);
  };

  const handleChangeDateTo = (date) => {
    console.log('Date: ', date);
    setSelectedDateTo(date);
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(cuotas, cabecera, filterFn);

  useEffect(() => {
    let data = {
      fechaDesde: `${selectedDateFrom.getFullYear()}-${selectedDateFrom.getMonth() + 1}-${selectedDateFrom.getDate()}`,
      fechaHasta: `${selectedDateTo.getFullYear()}-${selectedDateTo.getMonth() + 1}-${selectedDateTo.getDate()}`,
    }
    console.log('Datos: ', data);
    CuotasxVentasLoteServices.cuotasPagadas(data, token).then((res) => {
      console.log('Cuotas en función pagadas: ', res.data);
      setPagadas(res.data);
    });
    CuotasxVentasLoteServices.cuotasAdeudadas(data, token).then((res) => {
      console.log('Cuotas en función adeudadas: ', res.data);
      setAdeudadas(res.data);
    });
    CuotasxVentasLoteServices.cuotasVigentes(data, token).then((res) => {
      console.log('Cuotas en función vigentes: ', res.data);
      setVigentes(res.data);
      setCuotas(res.data);
    });
    CuotasxVentasLoteServices.cuotasParaJudicializar(token).then((res) => {
      console.log('Cuotas en función judiciales: ', res.data);
      setJudiciales(res.data);
      setCuotas(res.data);
    });
  }, [selectedDateFrom, selectedDateTo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      VentasLotesServices.getAllItems(token).then((res) => {
        console.log('Ventas: ', res);
        setRegistros(res);
      });
      AsociadosxloteServices.getAllItems(token).then((res) => {
        setAsociadosxLote(res);
      });
      AsociadosService.getAllItems(token).then((res) => {
        setAsociados(res);
      });
      FormaPagoServices.getAllItems(token).then((res) => {
        setFormaPago(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setMoneda(res);
      });/*
      let data = {
        fechaDesde: `${selectedDateFrom.getDate()}-${selectedDateTo.getMonth() + 1}-${selectedDateFrom.getFullYear()}`,
        fechaHasta: `${selectedDateTo.getDate()}-${selectedDateTo.getMonth() + 1}-${selectedDateTo.getFullYear()}`,
      }
      CuotasxVentasLoteServices.cuotasVigentes(data, token).then((res) => {
        setCuotas(res.data);
      });*/
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps


  const openInPopup = (item) => {
    setRecordForEdit(registros.find((reg) => reg.id === item.VentasLoteId));
    setOpenPopup(true);
  };

  const prejudicializar = async (item) => {
    let datos = {
      id: item.id,
      preJudicial: true,
    };
    await CuotasxVentasLoteServices.update(datos, token)
    await CuotasxVentasLoteServices.cuotasParaJudicializar(token).then((res) => {
      console.log('Cuotas en función judiciales: ', res.data);
      setJudiciales(res.data);
      setCuotas(res.data);
    });
    //setRecordForEdit(item);
    //setOpenPopupEditar(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) => {  // eslint-disable-line
            if (x.VentasLote.Cliente.nombre)
              if (x.VentasLote.Cliente.nombre.toLowerCase().includes(target.value)) return x;
            if (x.VentasLote.Cliente.apellido)
              if (x.VentasLote.Cliente.apellido.toLowerCase().includes(target.value)) return x;
            if (x.VentasLote.codigo)
              if (x.VentasLote.codigo.toLowerCase().includes(target.value)) return x;
            }
          );
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm) => {
    console.log("RegistroItem", registroItem);
    try {
      let datoVenta = {
        id: registroItem.id,
        codigo: registroItem.codigo,
      }
      await VentasLotesServices.update(datoVenta, token);
      let datoCuotas = {
        VentasLoteId: registroItem.id,
        fechaVencimiento: registroItem.fechaVencimiento,
      }
      await CuotasxVentasLoteServices.updateFechaVencimiento(datoCuotas, token);
      } catch (err) {
      return false;
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopupEditar(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
  };

  return (
    <>
      <PageHeader
        title="CUOTAS"
        subTitle="Gestión de cuotas"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Grid container>
            <Grid item xs={4}>
              <Controls.Input
                label="Buscar"
                className={classes.searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechaDesde"
                  //maxDate={new Date()}
                  format="dd/MM/yyyy"
                  value={selectedDateFrom}
                  onChange={handleChangeDateFrom}
                  helperText="Desde"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="fechaHasta"
                  minDate={selectedDateFrom}
                  format="dd/MM/yyyy"
                  value={selectedDateTo}
                  onChange={handleChangeDateTo}
                  helperText="Hasta"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2}>
              {cuotas && cuotas.length ?
                <div style={{ borderRadius: 5, backgroundColor: "lightYellow", paddingTop: 5, paddingBottom: 5, paddingLeft: 10 }}>
                  <h3>Cantidad: {cuotas.length}</h3>
                  <h3>Total: ${new Intl.NumberFormat().format(calcularTotales(cuotas))}</h3>
                </div>
                :
                <></>
              }
            </Grid>
          </Grid>
        </Toolbar>
        <Box sx={{ width: '100%' }}>
          <Tabs
            onChange={handleChange}
            value={value}
            aria-label="Tabs where each tab needs to be selected manually"
          >
            <Tab label="Vigentes" />
            <Tab label="Adeudadas" />
            <Tab label="Pagadas" />
            <Tab label="Judicializadas" />
          </Tabs>
        </Box>
        {cuotas.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((cuota) => (
                <>
                  {cuota && cuota.id ?
                  <>
                  <TabPanel value={value} />
                    <TableRow key={cuota.id}>
                      <TableCell style={{ fontWeight: "500" }}>
                        {cuota.VentasLote.Cliente.nombre} {cuota.VentasLote.Cliente.apellido}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {cuota.VentasLote.codigo}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {cuota.VentasLote.ReservasLote.Lotesxloteo.Loteo.nombre}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {cuota.cuota}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {cuota.fechaVencimiento}
                      </TableCell>
                      <TableCell>
                        {value !== 2 && !cuota.preJudicial &&
                          
                          <Controls.ActionButton
                            color="default"
                            onClick={() => {
                              openInPopup(cuota);
                            }}
                          >
                            <ReceiptIcon fontSize="small" />
                          </Controls.ActionButton>
                        }
                        {value === 3 &&
                        <>
                        {!cuota.judicial ?
                          <Controls.ActionButton
                            color="default"
                            onClick={() => {
                              prejudicializar(cuota);
                            }}
                          >
                            <GavelIcon fontSize="small" />
                          </Controls.ActionButton>
                          :
                          <Controls.ActionButton
                          color={cuota.preJudicial && !cuota.judicial ? "yellow" : "red"}
                          // onClick={() => {
                          //   prejudicializar(cuota);
                          // }}
                        >
                          <GavelIcon fontSize="small" />
                        </Controls.ActionButton>
                        }
                        </>
                      }
                      </TableCell>
                    </TableRow>
                    </>
                    :
                    <></>
                  }
                </>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="Edición"
        openPopup={openPopupEditar}
        setOpenPopup={setOpenPopupEditar}
      >
        <VentasLoteForm 
          recordForEdit={recordForEdit} 
          addOrEdit={addOrEdit} 
          listaAsociados={asociados}
          listaAsociadosxLote={asociadosxLote}
          listaFormaPago={formaPago}
          listaMoneda={moneda}
        />
      </Popup>
      <Popup
        title="Pago de cuotas"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CuotasForm
          recordForEdit={recordForEdit} 
          addOrEdit={addOrEdit} 
          setOpenPopup={setOpenPopup}
          listaAsociados={asociados}
          listaAsociadosxLote={asociadosxLote}
          listaFormaPago={formaPago}
          listaMoneda={moneda}
        />
      </Popup>
      <Popup
        title="Listado de cuotas"
        openPopup={openPopupListadoCuotas}
        setOpenPopup={setOpenPopupListadoCuotas}
      >
        <ListadoCuotas 
          recordForEdit={recordForEdit}
          venta = {recordForEdit}
          addOrEdit={addOrEdit} 
          listaAsociados={asociados}
          listaAsociadosxLote={asociadosxLote}
          listaFormaPago={formaPago}
          listaMoneda={moneda}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
