import React, { useState, useEffect } from "react";

import { Grid, } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";

import * as ConfigDataServices from "../../../services/utiles/configData";

export default function ConfirmacionVentaProp(props) {
  const {
    token,
    addOrEdit,
    recordForEdit,
  } = props;

  console.log('Record For Edit: ', recordForEdit);
  //console.log('Usuarios: ', listaUsuarios);

  const propiedad = recordForEdit.PropiedadesVentum;
  const cliente = recordForEdit.Cliente;
  const vendedor = recordForEdit.Usuario;
  const propietario = recordForEdit.PropiedadesVentum.Propietario;

  
  const [cargando, setCargando] = useState(false);
  const [codigo, setCodigo] = useState("");
  const [ultimoCodigo, setUltimoCodigo] = useState(0);
  
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
      
      setErrors({
        ...temp,
      });
      
      if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    };
    
    const DiaMes = (diames) => {
      if (diames < 10) {
        return "0" + diames;
      } else {
        return diames;
      }
    };
    
    const fa = new Date();
    const fc =
    DiaMes(fa.getDate()) +
    "/" +
    DiaMes(fa.getMonth() + 1) +
    "/" +
    fa.getFullYear();
    
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validate()) {
        values.vendido = true;
        values.fechaVenta = fa;
        //console.log('Values Venta: ', values);
        addOrEdit(values, resetForm, values.id);
      }  
    };  
    
    useEffect(() => {
      if (!cargando) {
        ConfigDataServices.getAllItems(token).then((res) => {
          console.log('Codigos: ', res);
          setCodigo(res[0].ultimoCodigoVentaPropiedad)
          setUltimoCodigo(res);
        });
        setCargando(true);
        if (recordForEdit != null) {
          setValues({
            ...recordForEdit,
          });
        }
      }
    }, [cargando, recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const initialFValues = {
    id: recordForEdit.id,
    vendido: false,
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
  useForm(initialFValues, true, validate);

  return (
    <Form onSubmit={handleSubmit}>
      {/*------------------------------------ENCABEZADO------------------------------------*/}
      <Grid container style={{ borderRadius: 20, backgroundColor: "#3CE685", textAlign: "center", }} >
        <Grid item xs={12} sm={12} >
          <div>
            <h1>{propiedad.nombre}</h1>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ width: '80%', marginLeft: '10%'}} >
            <hr />
          </div>
        </Grid>
        {/*------------------------------------Fecha------------------------------------*/}
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4} >
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h2>Fecha:</h2>
              </div>
            </Grid>
            <Grid item xs={8} >
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                }}
              >
                <h2>{fc}</h2>
              </div>
            </Grid>
            {/*------------------------------------Vendedor------------------------------------*/}
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h2>Vendedor:</h2>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                <h2>
                  {vendedor.nombre} {vendedor.apellido}
                </h2>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/*------------------------------------Cliente------------------------------------*/}
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <h2>Cliente:</h2>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                <h2>
                  {cliente.nombre} {cliente.apellido}
                </h2>
              </div>
            </Grid>
            {/*------------------------------------Propietario------------------------------------*/}
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "10px",
                  marginLeft: "10px",
                }}
                >
                <h2>Propietario:</h2>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
                >
                <h2>
                  {propietario.nombre} {propietario.apellido}
                </h2>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
          <Grid container style={{ marginTop: 40, textAlign: 'center' }}>
            <Grid item xs={3}>
              <Controls.Input
                name="codigo"
                label="codigo"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
                error={errors.codigo}
              />
            </Grid>
        <Grid item xs={9}>
          <Controls.Input
            name="observacionFinalReserva"
            label="Observaciones"
            value={values.observaciones}
            onChange={handleInputChange}
            multiline
            //error={errors.observaciones}
            />
        </Grid>
            </Grid>

      <div style={{ marginTop: 25, textAlign: "right" }}>
        <Controls.Button type="submit" text="Confirmar" />
      </div>
    </Form>
  );
}
