import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
//import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "../../logo-blanco.png";
import logoVerde from "../../logo-verde.png";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
 
function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(undefined);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");


  function enterPresionado (event) {
    if (event.keyCode === 13 && loginValue !== "" && passwordValue !== "") {
      // console.log("Enter Presionado...");
      loginUser(
        userDispatch,
        loginValue,
        passwordValue,
        props.history,
        setIsLoading,
        setError,
      );
    }
  }

  useEffect(function () {
     
  }, [error]);

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />

        <div style={{ color: 'white', marginTop: -20 }}>Sistema de gestión inmobiliaria</div>
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>

          <div className={classes.logoMBContainer}>
            <img src={logoVerde} alt="logo" className={classes.logoMBtypeImage} />
          </div>

          <React.Fragment>
            <Typography variant="h1" className={classes.greeting}>
              Bienvenido
              </Typography>



            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Usuario o contraseña erronea
                </Typography>
            </Fade>

         

            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={e => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Email Adress"
              type="email"
              fullWidth
              onKeyDown={(e) => { enterPresionado(e) }}
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={e => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth
              onKeyDown={(e) => { enterPresionado(e) }}
              />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Entrar
                  </Button>
                )}

            </div>
          </React.Fragment>

        </div>
        <Typography color="primary" className={classes.copyright}>
          © {new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.grupodelvalleinmobiliaria.com" rel="noopener noreferrer" target="_blank">Grupo del Valle Inmobiliaria</a>
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
