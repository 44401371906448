import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";

import * as LocalidadServices from "../../services/ubicacion/localidad";
import * as PaisServices from "../../services/ubicacion/pais";
import * as ProvinciaServices from "../../services/ubicacion/provincia";
import * as ClienteServices from "../../services/cliente/cliente";
import * as FuenteServices from "../../services/cliente/fuente";
import * as HistorialServices from "../../services/cliente/historial";
import * as EstadoCivilServices from "../../services/cliente/estadoCivil";

import { useUserState } from "../../context/UserContext";

import Permisos from "../../config/permisos";
import ClienteForm from "./ClienteForm";
import HistorialLista from "./HistorialLista";
import ClienteConErrorForm from "./ClienteConErrorForm";
import AsignaAVendedor from "./AsignaAVendedor";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import PageHeader from "../../components/tablas/PageHeader";
import Popup from "../../components/tablas/Popup";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";

import VisibilityIcon from "@material-ui/icons/Visibility";
import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function Cliente() {
  
  const { token, rolCodigo, userId } = useUserState();
  
  const puedeAgregar = Permisos(rolCodigo, "CLIENTES.CLIENTES.AGREGAR");
  const autoAsignaComoVendedor = Permisos(
    rolCodigo,
    "CLIENTES.AUTO_SIGNAR_ALCREAR"
    );
    
    const [cargando, setCargando] = useState(false);
    const [filterFn, setFilterFn] = useState({
      fn: (items) => {
        return items;
      },
    });
    const [openPopupLista, setOpenPopupLista] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupError, setOpenPopupError] = useState(false);
    const [openPopupAsigna, setOpenPopupAsigna] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [notify, setNotify] = useState({
      isOpen: false,
      message: "",
      type: "",
    });
    const [confirmDialog, setConfirmDialog] = useState({
      isOpen: false,
      title: "",
      subTitle: "",
    });
    
    const [registros, setRegistros] = useState({});
    const [registroPaises, setRegistroPaises] = useState({});
    const [registroProvincias, setRegistroProvincias] = useState({});
    const [registroLocalidad, setRegistroLocalidad] = useState({});
    const [registroFuente, setRegistroFuente] = useState({});
    const [registroEstadoCivil, setRegistroEstadoCivil] = useState({});
    
    const classes = useStyles();
    
    const armarCabecera = (rol) => {
      let cab = [      
        { id: "creado", label: "Creado" },
        { id: "nombre", label: "Nombre" },
        { id: "observaciones ", label: "Observaciones" },
        { id: "telefono", label: "Teléfono" },
        { id: "email", label: "Email" },
        { id: "actions", label: "acciones", disableSorting: true },
        ];
      if (rol === 'ADM' || rol === 'GAD' || rol === 'MKT') {
        cab.splice(3, 0, { id: "fuente", label: "Fuente" });
      }
      return cab;
    }

    const cabecera = armarCabecera(rolCodigo);
    
    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);
    
  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ClienteServices.getAllItems(token, rolCodigo).then((res) => {
        console.log('Clientes con espacios: ', res.filter((item) => item.telefono.includes(" ")));
        setRegistros(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidad(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
      FuenteServices.getAllItems(token).then((res) => {
        console.log(res);
        setRegistroFuente(res);
      });
      EstadoCivilServices.getAllItems(token).then((res) => {
        console.log(res);
        setRegistroEstadoCivil(res);
      });
    }
  }, [cargando, registros, registroPaises]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopupLista = (item) => {
    setRecordForEdit(item);
    setOpenPopupLista(true);
  };

  const openInPopup = (item) => {
    let paisId = 0;
    let provinciaId = 0;
    try {
      const prov = registroLocalidad.find(
        (itemLoc) => itemLoc.id === item.LocalidadId
      );
      const pais = registroProvincias.find(
        (itemProv) => itemProv.id === prov.ProvinciumId
      );
      if (pais) {
        paisId = pais.PaiId;
      }
      if (prov) {
        provinciaId = prov.ProvinciumId;
      }
    } catch (e) {}
    item.PaiId = paisId;
    item.ProvinciumId = provinciaId;
    setRecordForEdit(item);
    setOpenPopup(true);
  };
  
  const openInPopupError = (item) => {
    setRecordForEdit(item);
    setOpenPopupError(true);
  };
  
  const openInPopupAsigna = (item) => {
    setRecordForEdit(item);
    setOpenPopupAsigna(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) => {
            if (x.nombre)
              if (x.nombre.toLowerCase().includes(target.value)) return x;
            if (x.apellido)
              if (x.apellido.toLowerCase().includes(target.value)) return x;
            if (x.telefono)
              if (x.telefono.toLowerCase().includes(target.value)) return x;
            return false; // Línea Modificada
          });
      },
    });
  };

  const addOrEditHistorial = async (registroItem, resetForm) => {
    try {
      await HistorialServices.insert(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setOpenPopupLista(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
  };

  const addOrEditError = async (registroItem, resetForm) => {
    try {
      await ClienteServices.updateConError(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopupError(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    setRegistros(ClienteServices.getAllItems(token));
  };

  const addOrEditAsigna = async (registroItem, resetForm) => {
    try {
      await ClienteServices.updateAsignaVendedor(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopupAsigna(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    setRegistros(ClienteServices.getAllItems(token));
  };
  
  const addOrEdit = async (registroItem, resetForm) => {
    if (registroItem.id === 0) {
      if (autoAsignaComoVendedor) {
        registroItem.vendedorId = userId;
      }
      try {
        await ClienteServices.insert(registroItem, token);
      } catch (err) {
        return false;
      }
    } else {
      try {
        await ClienteServices.update(registroItem, token);
      } catch (err) {
        return false;
      }
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    setRegistros(ClienteServices.getAllItems(token));
  };
  
  return (
    <>
      <PageHeader
        title="CLIENTES"
        subTitle="Gestión de clientes"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          {puedeAgregar && (
            <Controls.Button
              text="Agregar"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          )}
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <>
                {item && item.id ?
                  <>
                  {console.log('Item: ', item)}
                    <TableRow key={item.id}>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.createdAt.toString().split("T")[0] + " " + item.createdAt.toString().split("T")[1].split(".")[0]}{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.apellido ? item.apellido : "" } - {item.nombre ? item.nombre : "" }{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.observaciones}{" "}
                      </TableCell>
                      {Permisos(rolCodigo, "CLIENTES.CLIENTES.FUENTES") && 
                        <TableCell style={{ fontWeight: "500" }}>
                          {item.FuenteId ?
                            <>
                              {registroFuente.find((fuente) => fuente.id === item.FuenteId).nombre}{" "}
                            </>
                            :
                            <>
                            </>
                          }
                          {console.log(item)}
                        </TableCell>
                      }
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.telefono ? item.telefono : "" }{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {" "}
                        {item.email}{" "}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {Permisos(rolCodigo, "CLIENTES.CLIENTES.EDITAR") && (
                          <Controls.ActionButton
                            color="primary"
                            onClick={() => {
                              openInPopup(item);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </Controls.ActionButton>
                        )}
                        {userId !== item.usuarioCreadorId &&
                          !item.fechaPrimerContacto && (
                            <>
                              {Permisos(
                                rolCodigo,
                                "CLIENTES.CLIENTES.REPORTAR"
                              ) && (
                                <Controls.ActionButton
                                  style={{ backgroundColor: "#E3E703" }}
                                  onClick={() => {
                                    openInPopupError(item);
                                  }}
                                >
                                  <ErrorOutlineIcon fontSize="small" />
                                </Controls.ActionButton>
                              )}
                            </>
                          )}
                        {Permisos(rolCodigo, "CLIENTES.ASIGNAR") && (
                          <Controls.ActionButton
                            color="primary"
                            style={{ backgroundColor: "#B40000" }}
                            onClick={() => {
                              openInPopupAsigna(item);
                            }}
                          >
                            <DoubleArrowIcon fontSize="small" />
                          </Controls.ActionButton>
                        )}
                        {Permisos(rolCodigo, "CLIENTES.HISTORIAL") && (
                          <Controls.ActionButton
                            color="primary"
                            style={{ backgroundColor: "#1F6D14" }}
                            onClick={() => {
                              openInPopupLista(item);
                            }}
                          >
                            <VisibilityIcon fontSize="small" />
                          </Controls.ActionButton>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                  :
                  <>
                  </>
                }
                </>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="Gestión clientes"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ClienteForm
          token={token}
          listaFuentes={registroFuente}
          listaLocalidades={registroLocalidad}
          listaProvincias={registroProvincias}
          listaPaises={registroPaises}
          listaEstadoCivil={registroEstadoCivil}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        title="Cliente con error en datos"
        openPopup={openPopupError}
        setOpenPopup={setOpenPopupError}
      >
        <ClienteConErrorForm
          token={token}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditError}
        />
      </Popup>
      <Popup
        title="Asignación de cliente"
        openPopup={openPopupAsigna}
        setOpenPopup={setOpenPopupAsigna}
      >
        <AsignaAVendedor
          token={token}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditAsigna}
        />
      </Popup>
      <Popup
        title="Historial de seguimiento"
        openPopup={openPopupLista}
        setOpenPopup={setOpenPopupLista}
      >
        <HistorialLista
          token={token}
          puedeAgregar={Permisos(rolCodigo, "CLIENTES.HISTORIAL.AGREGAR")}
          puedeVerGestion={Permisos(
            rolCodigo,
            "CLIENTES.HISTORIAL.VERPESTAÑAGESTION"
          )}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditHistorial}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
