import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { useUserState } from "../../../context/UserContext";

import CuotasForm from "../../contable/CuotasForm";
import ListadoCuotas from "../../contable/ListadoCuotas";
import VentasLoteForm from "./VentasLoteForm";

import * as FormaPagoServices from "../../../services/venta/formaPago";
import * as VentasLotesServices from "../../../services/venta/ventasLote";
import * as AsociadosxloteServices from "../../../services/producto/asociadosXLote";
import * as AsociadosService from "../../../services/venta/asociado";
import * as MonedaServices from "../../../services/venta/moneda";
import * as CuotasxVentasLoteServices from "../../../services/contable/cuotasxVentasLote";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";
import PageHeader from "../../../components/tablas/PageHeader";
import Popup from "../../../components/tablas/Popup";
import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";

import RoomIcon from "@material-ui/icons/Room";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const cabecera = [
  { id: "codigo", label: "Código" },
  { id: "cliente", label: "Cliente" },
  { id: "loteo", label: "Loteo" },
  { id: "cantidadCuotas", label: "Cuotas" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function FormaPago() {

  const { token } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => { return items; }, });
  const [registros, setRegistros] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupEditar, setOpenPopupEditar] = useState(false);
  const [openPopupListadoCuotas, setOpenPopupListadoCuotas] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", });
  const [asociadosxLote, setAsociadosxLote] = useState({});
  const [asociados, setAsociados] = useState({});
  const [formaPago, setFormaPago] = useState({});
  const [moneda, setMoneda] = useState({});

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      VentasLotesServices.getAllItems(token).then((res) => {
        //console.log('Ventas: ', res, typeof res[0].ReservasLote.fechaEstimadaFirma);
        console.log('Ventas: ', res)
        setRegistros(res);
      });
      AsociadosxloteServices.getAllItems(token).then((res) => {
        setAsociadosxLote(res);
      });
      AsociadosService.getAllItems(token).then((res) => {
        setAsociados(res);
      });
      FormaPagoServices.getAllItems(token).then((res) => {
        setFormaPago(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setMoneda(res);
      })
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInPopupEditar = (item) => {
    setRecordForEdit(item);
    setOpenPopupEditar(true);
  };

  const openInPopupListadoCuotas = (item) => {
    setRecordForEdit(item);
    setOpenPopupListadoCuotas(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) => { // eslint-disable-line
            if (x.Cliente.nombre)
              if (x.Cliente.nombre.toLowerCase().includes(target.value)) return x;
            if (x.Cliente.apellido)
              if (x.Cliente.apellido.toLowerCase().includes(target.value)) return x;
            if (x.ReservasLote.Lotesxloteo.Loteo.nombre)
              if (x.ReservasLote.Lotesxloteo.Loteo.nombre.toLowerCase().includes(target.value)) return x;
            if (x.codigo)
              if (x.codigo.toLowerCase().includes(target.value)) return x;
          }
        );
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm) => {
    console.log("RegistroItem", registroItem);
    try {
      let datoVenta = {
        id: registroItem.id,
        codigo: registroItem.codigo,
      }
      await VentasLotesServices.update(datoVenta, token);
      //console.log("Socios", itemSocios);
      let datoCuotas = {
        VentasLoteId: registroItem.id,
        fechaVencimiento: registroItem.fechaVencimiento,
      }
      await CuotasxVentasLoteServices.updateFechaVencimiento(datoCuotas, token);
      } catch (err) {
      return false;
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopupEditar(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
  };

  return (
    <>
      <PageHeader
        title="VENTAS"
        subTitle="Gestión de ventas"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
        <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <>
                  {item.codigo ?
                    <TableRow key={item.id}>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.codigo}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.Cliente.nombre} {item.Cliente.apellido}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.ReservasLote.Lotesxloteo.Loteo.nombre}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.ReservasLote.cantidadCuotas === 1 ? "Contado" : item.ReservasLote.cantidadCuotas}
                      </TableCell>
                      <TableCell>
                        {item.ReservasLote.cantidadCuotas !== 1 &&
                          <>
                            <Controls.ActionButton
                              color="primary"
                              onClick={() => {
                                openInPopupEditar(item);
                              }}
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              color="default"
                              onClick={() => {
                                openInPopup(item);
                              }}
                            >
                              <ReceiptIcon fontSize="small" />
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              color="secondary"
                              onClick={() => {
                                openInPopupListadoCuotas(item);
                              }}
                            >
                              <FormatListNumberedIcon fontSize="small" />
                            </Controls.ActionButton>
                          </>
                        }
                      </TableCell>
                    </TableRow>
                    :
                    <></>
                  }
                </>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="Edición"
        openPopup={openPopupEditar}
        setOpenPopup={setOpenPopupEditar}
      >
        <VentasLoteForm 
          recordForEdit={recordForEdit} 
          addOrEdit={addOrEdit} 
          listaAsociados={asociados}
          listaAsociadosxLote={asociadosxLote}
          listaFormaPago={formaPago}
          listaMoneda={moneda}
        />
      </Popup>
      <Popup
        title="Pago de cuotas"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CuotasForm
          recordForEdit={recordForEdit} 
          addOrEdit={addOrEdit} 
          setOpenPopup={setOpenPopup}
          listaAsociados={asociados}
          listaAsociadosxLote={asociadosxLote}
          listaFormaPago={formaPago}
          listaMoneda={moneda}
        />
      </Popup>
      <Popup
        title="Listado de cuotas"
        openPopup={openPopupListadoCuotas}
        setOpenPopup={setOpenPopupListadoCuotas}
      >
        <ListadoCuotas 
          recordForEdit={recordForEdit}
          venta = {recordForEdit}
          addOrEdit={addOrEdit} 
          listaAsociados={asociados}
          listaAsociadosxLote={asociadosxLote}
          listaFormaPago={formaPago}
          listaMoneda={moneda}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
