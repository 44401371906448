import React, { useState, useEffect } from "react";
import RichTextEditor from "mui-rte";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import { stateToHTML } from "draft-js-export-html";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        RichTextEditor: {
            root: {
                marginTop: 20,
                width: "80%"
            },
            editor: {
                border: "1px solid gray" 
            }
        }
    }
});

export default function LotexLoteoForm(props) {

  const { addOrEdit, loteo, recordForEdit, listaAsociados, listaEstados, closeForm, listaManzanas, lotesxLoteo, listaModendas } = props;

  //console.log('Lotes en form: ', lotesxLoteo);
  //console.log('Loteo en form: ', loteo);
  //console.log('recordForEdit en form: ', recordForEdit);
  //console.log('listaAsociados en form: ', listaAsociados);
  //console.log('listaMonedas en form: ', listaModendas);

  let HTML = "";

  const initialFValues = {
    id: 0,
    nombre: "",
    linkDocumentacion: "",
    precio: "",
    precioWeb: "",
    superficieTotal: 0,
    observaciones: "",
    descripcionWeb:"",
    EstadoId: '',
    manzana: '',
    MonedaId: '',
    amojonado: false,
    destacado: false,
    socios: [],
  };

  if (recordForEdit) {
    initialFValues.PaiId = recordForEdit.PaiId;
    initialFValues.ProvinciumId = recordForEdit.ProvinciumId;
    initialFValues.LocalidadId = recordForEdit.LocalidadId;
    if (recordForEdit.descripcionWeb) {
      // 1. Convert the HTML
      const contentHTML = convertFromHTML(recordForEdit.descripcionWeb)
      // 2. Create the ContentState object
      const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
      // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
      HTML = JSON.stringify(convertToRaw(state))
    }
  } else {
    initialFValues.PaiId = "";
    initialFValues.ProvinciumId = "";
    initialFValues.LocalidadId = "";
  }

  const [guardando, setGuardando] = React.useState(false);
  const [sociosCargados, setSociosCargados] = React.useState(false);
  const [socios, setSocios] = React.useState([]);
  const [porcentajes, setPorcentajes] = React.useState([]);
  const [socioSeleccionado, setSocioSeleccionado] = useState("");
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const obtenerColorEstado = (id) => {
    let color = "white";
    if (id) {
      const item = listaEstados.find((item) => item.id === id);
      if (item.color) {
        color = item.color;
      }
    }
    return color;
  };

  const agregarSocio = () => {
    if (socioSeleccionado) {
      let tempSocios = socios;
      const existe = tempSocios.find((s) => s.id === socioSeleccionado);
      if (!existe) {
        tempSocios.push({
          id: socioSeleccionado,
          porcentaje: 0,
        });
      } else {
        alert("Ya esta ingresado el socio seleccionado");
      }
      setSocios(tempSocios);
      validate();
    } else {
      alert("Seleccione un socio");
    }
  };

  const cambiaPorcentajeSocio = (value, socioId) => {
    let tempSocios = socios;
    tempSocios.forEach((s) => {
      if (s.id === socioId) {
        s.porcentaje = value;
      }
    });
    setSocios(tempSocios);
    validate();
  };

  const handleInputPorcentajeSoloNumerosChange = (e, socioId) => {
    // const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    // if (onlyNums.length < 10) {
    //   e.target.value = onlyNums;
    // } else if (onlyNums.length === 10) {
    //   const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    //   e.target.value = number;
    // }
    let p = porcentajes;
    p[socioId] = e.target.value;
    setPorcentajes(p);
    cambiaPorcentajeSocio(e.target.value, socioId);
    handleInputChange(e);
  };

  const handleManzanaChange = (e) => {
    const elemento = lotesxLoteo.find((lote) => lote.manzana === e.target.value.toString());
    console.log('Elemento encontrado: ', elemento, e.target.value, typeof e.target.value);
    if (elemento) {
      values.precio = elemento.precio;
      values.precioWeb = elemento.precioWeb;
      values.MonedaId = elemento.MonedaId;
    } else {
      values.precio = 0;
      values.precioWeb = 0;
      values.MonedaId = '';
    }
    handleInputChange(e);
  }

  const handleEditor = (editorState) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    console.log('ROW: ', convertToRaw(editorState.getCurrentContent()));
    console.log('HTML: ', stateToHTML(editorState.getCurrentContent()));
    HTML = stateToHTML(editorState.getCurrentContent())
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    values.descripcionWeb = HTML;
    if (!values.nombre) {
      alert("Por favor complete el NOMBRE del lote");
      return false;
    }
    if (!values.EstadoId) {
      alert("Por favor seleccione el estado de la propiedad");
      return false;
    }
    if (values.manzana === undefined) {
      alert("Por favor seleccione la manzana");
      return false;
    } 
    if (validate()) {
      values.socios = socios;
      values.LoteoId = loteo.id;
      values.cambiarPrecio = cambiarPrecio;
      console.log('Values: ', values);
      setGuardando(true);
      addOrEdit(values, resetForm, values.id).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
      setGuardando(false);
    }
  };

  const eliminarSocio = (id) => {
    let tempSocios = socios;
    const ss = tempSocios.filter((s) => s.id !== id);
    setSocios(ss);
  };

  const obtenerSocio = (id) => {
    let tempSocios = listaAsociados;
    const ss = tempSocios.find((s) => s.id === id);
    if (ss) {
      return ss.apellido + " " + ss.nombre;
    }
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
      if (!sociosCargados) {
        setSociosCargados(true);
        if (recordForEdit.AsociadosXLotes) {
          if (recordForEdit.AsociadosXLotes.length > 0) {
            let p = porcentajes;
            let tempSocios = socios;
            recordForEdit.AsociadosXLotes.map((soc) => {  // eslint-disable-line
              const existe = tempSocios.find((s) => s.id === soc.AsociadoId);
              if (!existe) {
                tempSocios.push({
                  id: soc.AsociadoId,
                  porcentaje: soc.porcentaje,
                });
                p[soc.AsociadoId] = soc.porcentaje;
              } else {
                return false; // Línea Modificada
              }
            });
            setPorcentajes(p);
            setSocios(tempSocios);
          }
        }
      }
    }
  }, [recordForEdit, socios, porcentajes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginBottom: 30, textAlign: "right" }}>
              <Controls.Button type="submit" text="Guardar" />
              <Controls.Button
                text="Cancelar"
                color="default"
                onClick={closeForm}
              />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Controls.Input
                name="nombre"
                label="Nombre"
                value={values.nombre}
                onChange={handleInputChange}
                error={errors.nombre}
                focused={true}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controls.Select
                name="manzana"
                label="Manzana"
                value={values.manzana}
                onChange={handleManzanaChange}
                options={listaManzanas.map((item, index) => {
                  return { id: index, title: item };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <div
                style={{
                  backgroundColor: obtenerColorEstado(values.EstadoId),
                  borderRadius: 10,
                  color: "white",
                }}
              >
                <Controls.Select
                  name="EstadoId"
                  label="Estado"
                  value={values.EstadoId}
                  onChange={handleInputChange}
                  options={listaEstados.map((item) => {
                    return { id: item.id, title: item.nombre };
                  })}                        //setRecordForEdit(null);

                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#FEE4B0" }} item xs={12} sm={12}>
              <Controls.Input
                name="linkDocumentacion"
                label="Link Documentacion"
                value={values.linkDocumentacion}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#FC9D9D" }} item xs={12} sm={3}>
              <Controls.InputMask
                name="precio"
                label="Precio desde"
                value={values.precio}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#BFFFE3" }} item xs={12} sm={3}>
              <Controls.InputMask
                name="precioWeb"
                label="Precio web"
                value={values.precioWeb}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#BFFFE3" }} item xs={12} sm={3}>
              <Controls.Select
                  name="MonedaId"
                  label="Moneda para WEB"
                  value={values.MonedaId}
                  onChange={handleInputChange}
                  options={listaModendas.map((item) => {
                    return { id: item.id, title: item.nombre };
                  })}
                />
            </Grid>
            {recordForEdit &&
            <>
              {recordForEdit.id &&
                <>
                  <Grid style={{ backgroundColor: "#E8CC0C" }} item xs={12} sm={3}>
                    <Controls.Checkbox
                      name="cambioPrecioLotes"
                      label="¿Cambiar en toda la manzana?"
                      value={cambiarPrecio}
                      onChange={() => setCambiarPrecio(!cambiarPrecio)}
                    />
                  </Grid>
                </>
              }
            </>
            }
            <Grid style={{ backgroundColor: "lightBlue" }} item xs={12} sm={2}>
              <Controls.InputMask
                name="frente"
                label="Frente"
                value={values.frente}
                onChange={handleInputChange}
                //prefix="$"
              />
            </Grid>
            <Grid style={{ backgroundColor: "lightBlue" }} item xs={12} sm={2}>
              <Controls.InputMask
                name="fondo"
                label="Fondo"
                value={values.fondo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "lightBlue" }} item xs={12} sm={2}>
              <Controls.InputMask
                name="lateral"
                label="Lateral"
                value={values.lateral}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "lightBlue" }} item xs={12} sm={2}>
              <Controls.InputMask
                name="opuesto"
                label="Opuesto"
                value={values.opuesto}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "lightBlue" }} item xs={12} sm={2}>
              <Controls.InputMask
                name="superficieTotal"
                label="Superficie total"
                value={values.superficieTotal}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controls.Input
                name="observaciones"
                helperText="Observaciones"
                value={values.observaciones}
                onChange={handleInputChange}
                multiline
                rows={4}
                rowsMax={4}
                error={errors.descripcion}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingBottom: 40, paddingLeft: 20 }} >
              <MuiThemeProvider theme={defaultTheme}>
                <RichTextEditor
                  label="Descripción web aquí..."
                  value={HTML}
                  //onSave={save}
                  onChange={handleEditor}
                  inlineToolbar={true}
                  controls={[
                    "title", 
                    "bold",
                    "italic",
                    "underline",
                    "highlight", 
                    "link", 
                    "bulletList", 
                  ]}
                  toolbarButtonSize="small"
                />
              </MuiThemeProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                style={{
                  backgroundColor: "violet",
                  color: "black",
                  borderRadius: 10,
                  margin: 5,
                }}
                item
                xs={12}
                sm={3}
              >
                <Controls.Checkbox
                  name="destacado"
                  label="Destacado" 
                  value={values.destacado} 
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                style={{
                  backgroundColor: "orange",
                  color: "black",
                  borderRadius: 10,
                  margin: 5,
                }}
                item
                xs={12}
                sm={3}
              >
                <Controls.Checkbox
                  name="amojonado"
                  label="Amojonado" 
                  value={values.amojonado} 
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <h2>Socios </h2>
              <hr />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Select
                name="AsociadoId"
                label="Socio"
                value={socioSeleccionado}
                onChange={(item) => {
                  setSocioSeleccionado(item.target.value);
                }}
                options={listaAsociados.map((item) => {
                  return {
                    id: item.id,
                    title: item.apellido + " " + item.nombre,
                  };
                })}
              />
              <Controls.Button
                text="+"
                color="default"
                onClick={() => agregarSocio()}
              />
            </Grid>
          </Grid>
        </Grid>
        {socios.length > 0 ? (
          <>
            {socios.map((socio) => {
              return (
                <>
                  <Grid item xs={12} sm={2}>
                    <h3
                      style={{
                        background: "lightGray",
                        padding: 5,
                        borderRadius: 10,
                        marginBottom: 13,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {obtenerSocio(socio.id)}
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Controls.InputMask
                      label="Porcentaje"
                      value={porcentajes[socio.id]}
                      name={socio.id}
                      onChange={(e) => {
                        handleInputPorcentajeSoloNumerosChange(e, socio.id);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Controls.Button
                      text="-"
                      color="secondary"
                      onClick={() => eliminarSocio(socio.id)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}></Grid>
                </>
              );
            })}
          </>
        ) : (
          <div>No hay socios asignados a este lote</div>
        )}
        <Grid item xs={12} sm={12}>
          <hr />
        </Grid>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginTop: 25, textAlign: "right" }}>
              <Controls.Button type="submit" text="Guardar" />
              <Controls.Button
                text="Cancelar"
                color="default"
                onClick={closeForm}
              />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
