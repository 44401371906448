import React, { useState, useEffect } from "react";

import * as ReservaPropiedadVentaService from "../../../services/venta/reservaPropiedadVenta";
import * as FormaPagoService from "../../../services/venta/formaPago";
import * as MonedaService from "../../../services/venta/moneda";
import * as UsuarioService from "../../../services/usuario/usuario";
import * as ComicionesUsuariosServices from "../../../services/contable/comisionesUsuarios";

import { useUserState } from "../../../context/UserContext";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import {
  Paper,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
  Collapse,
  IconButton,
  Box,
} from "@material-ui/core";

import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";
import Tarjeta from "../../utiles/Tarjeta";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "propietario", label: "Propietario" },
  { id: "cliente", label: "Cliente" },
  { id: "vendedor", label: "Vendedor" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#DBCFDE",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

// function Fila(props) {
//   const { item, formaPago, moneda, procesar, token } = props;
//   const [open, setOpen] = useState(false);
//   const [comisiones, setComisiones] = useState({});

//   const importeReserva = (item) => {
//     let res = 0;
//     if (item.importe1) {
//       res = item.importe1;
//     }
//     if (item.importe2) {
//       res = res + item.importe2;
//     }
//     if (item.importe3) {
//       res = res + item.importe3;
//     }
//     if (item.importe4) {
//       res = res + item.importe4;
//     }
//     return res;
//   };

//   const obtenerComisiones = async (bool, id) => {
//     if (bool) {
//       await ComicionesUsuariosServices.getAllItems(id, token).then((res) => {
//         setComisiones(res);
//       });
//     }
//   };

//   return (
//     <React.Fragment>
//       <TableRow key={item.id}>
//         <TableCell style={{ fontWeight: "bold" }}>
//           {item.PropiedadesVentum.nombre}
//         </TableCell>
//         <TableCell style={{ fontWeight: "bold" }}>
//           {item.PropiedadesVentum.Propietario.nombre}{" "}
//           {item.PropiedadesVentum.Propietario.apellido}
//         </TableCell>
//         <TableCell style={{ fontWeight: "bold" }}>
//           {item.Cliente.nombre} {item.Cliente.apellido}
//         </TableCell>
//         <TableCell style={{ fontWeight: "bold" }}>
//           {item.Usuario.nombre} {item.Usuario.apellido}
//         </TableCell>
//         <TableCell>
//           <Controls.ActionButton
//             aria-label="expand row"
//             size="small"
//             color="secondary"
//             onClick={() => {
//               setOpen(!open);
//               obtenerComisiones(open, item.id);
//             }}
//           >
//             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//           </Controls.ActionButton>
//           <Controls.ActionButton
//             color="primary"
//             style={{ backgroundColor: "#121ADE" }}
//             onClick={() => {
//               procesar(item.id);
//             }}
//           >
//             <CheckIcon fontSize="small" />
//           </Controls.ActionButton>
//         </TableCell>
//       </TableRow>
//       <TableRow>
//         <TableCell
//           style={{ paddingBottom: 0, paddingTop: 0, borderRadius: 10 }}
//           colSpan={6}
//         >
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <Grid container style={{ borderRadius: 10 }}>
//               <Grid item xs={12} sm={4}>
//                 <div
//                   style={{
//                     padding: "10px 20px",
//                     marginTop: "20px",
//                     marginBottom: "20px",
//                     marginRight: "10px",
//                     borderRadius: 10,
//                   }}
//                 >
//                   <h2>
//                     Entrega: $ {new Intl.NumberFormat().format(item.entrega)}
//                   </h2>
//                   <h2>Reserva:</h2>
//                   {formaPago && formaPago.length && (
//                     <>
//                       {moneda && moneda.length && (
//                         <>
//                           {item.importe1 && (
//                             <h4 style={{ marginLeft: "15%" }}>
//                               -{" "}
//                               {
//                                 formaPago.find(
//                                   (pago) => pago.id === item.FormaPagoId1
//                                 ).nombre
//                               }
//                               ,{" "}
//                               {
//                                 moneda.find(
//                                   (moneda) => moneda.id === item.MonedaId1
//                                 ).nombre
//                               }
//                               : ${new Intl.NumberFormat().format(item.importe1)}
//                             </h4>
//                           )}
//                           {item.importe2 && (
//                             <h4 style={{ marginLeft: "15%" }}>
//                               -{" "}
//                               {
//                                 formaPago.find(
//                                   (pago) => pago.id === item.FormaPagoId2
//                                 ).nombre
//                               }
//                               ,{" "}
//                               {
//                                 moneda.find(
//                                   (moneda) => moneda.id === item.MonedaId2
//                                 ).nombre
//                               }
//                               : ${new Intl.NumberFormat().format(item.importe2)}
//                             </h4>
//                           )}
//                           {item.importe3 && (
//                             <h4 style={{ marginLeft: "15%" }}>
//                               -{" "}
//                               {
//                                 formaPago.find(
//                                   (pago) => pago.id === item.FormaPagoId3
//                                 ).nombre
//                               }
//                               ,{" "}
//                               {
//                                 moneda.find(
//                                   (moneda) => moneda.id === item.MonedaId3
//                                 ).nombre
//                               }
//                               : ${new Intl.NumberFormat().format(item.importe3)}
//                             </h4>
//                           )}
//                           {item.importe4 && (
//                             <h4 style={{ marginLeft: "15%" }}>
//                               -{" "}
//                               {
//                                 formaPago.find(
//                                   (pago) => pago.id === item.FormaPagoId4
//                                 ).nombre
//                               }
//                               ,{" "}
//                               {
//                                 moneda.find(
//                                   (moneda) => moneda.id === item.MonedaId4
//                                 ).nombre
//                               }
//                               : ${new Intl.NumberFormat().format(item.importe4)}
//                             </h4>
//                           )}
//                         </>
//                       )}
//                     </>
//                   )}
//                   <h3>
//                     Total (Entrega + Reserva): $
//                     {new Intl.NumberFormat().format(
//                       item.totalSeniaFinalReserva
//                     )}
//                   </h3>
//                   <h3>
//                     Comisión y Gastos: $
//                     {new Intl.NumberFormat().format(item.comisionGastos)}
//                   </h3>
//                   <h3>
//                     Total Entrega: $
//                     {new Intl.NumberFormat().format(
//                       item.totalEntregadoFinalReserva
//                     )}
//                   </h3>
//                 </div>
//               </Grid>
//               <Grid item xs={6} sm={4}>
//                 <div
//                   style={{
//                     padding: "10px 20px",
//                     marginTop: "20px",
//                     marginBottom: "20px",
//                     marginRight: "10px",
//                     borderRadius: 10,
//                   }}
//                 >
//                   {item.cantidadCuotas === 1 ? (
//                     <>
//                       <h3
//                         style={{
//                           textAlign: "left",
//                           paddingLeft: "10px",
//                         }}
//                       >
//                         Operación realizada de Contado
//                       </h3>
//                       <h4
//                         style={{
//                           textAlign: "left",
//                           paddingLeft: "10%",
//                         }}
//                       >
//                         - Precio de la Propiedad: $
//                         {new Intl.NumberFormat().format(
//                           item.PropiedadesVentum.precio
//                         )}
//                       </h4>
//                       <h4
//                         style={{
//                           textAlign: "left",
//                           paddingLeft: "10%",
//                         }}
//                       >
//                         - Entrega: $
//                         {new Intl.NumberFormat().format(importeReserva(item))}
//                       </h4>
//                       <h4
//                         style={{
//                           textAlign: "left",
//                           paddingLeft: "10%",
//                         }}
//                       >
//                         - Total: $
//                         {new Intl.NumberFormat().format(importeReserva(item))}
//                       </h4>
//                     </>
//                   ) : (
//                     <>
//                       <h3
//                         style={{
//                           textAlign: "left",
//                           paddingLeft: "10px",
//                         }}
//                       >
//                         Operación realizada en {item.cantidadCuotas} cuotas
//                       </h3>
//                       <h4
//                         style={{
//                           textAlign: "left",
//                           paddingLeft: "10%",
//                         }}
//                       >
//                         - Precio de la Propiedad: $
//                         {new Intl.NumberFormat().format(
//                           item.PropiedadesVentum.precio
//                         )}
//                       </h4>
//                       <h4
//                         style={{
//                           textAlign: "left",
//                           paddingLeft: "10%",
//                         }}
//                       >
//                         - Entrega: $
//                         {new Intl.NumberFormat().format(importeReserva(item))}
//                       </h4>
//                       {item.cantidadCuotas === 12 && (
//                         <h4
//                           style={{
//                             textAlign: "left",
//                             paddingLeft: "10%",
//                           }}
//                         >
//                           - Cuotas 1-12: $
//                           {new Intl.NumberFormat().format(item.importeAnio1)}
//                         </h4>
//                       )}
//                       {item.cantidadCuotas === 24 && (
//                         <>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 1-12: $
//                             {new Intl.NumberFormat().format(item.importeAnio1)}
//                           </h4>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 13-24: $
//                             {new Intl.NumberFormat().format(item.importeAnio2)}
//                           </h4>
//                         </>
//                       )}
//                       {item.cantidadCuotas === 36 && (
//                         <>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 1-12: $
//                             {new Intl.NumberFormat().format(item.importeAnio1)}
//                           </h4>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 13-24: $
//                             {new Intl.NumberFormat().format(item.importeAnio2)}
//                           </h4>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 24-36: $
//                             {new Intl.NumberFormat().format(item.importeAnio3)}
//                           </h4>
//                         </>
//                       )}
//                       {item.cantidadCuotas === 48 && (
//                         <>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 1-12: $
//                             {new Intl.NumberFormat().format(item.importeAnio1)}
//                           </h4>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 13-24: $
//                             {new Intl.NumberFormat().format(item.importeAnio2)}
//                           </h4>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 25-36: $
//                             {new Intl.NumberFormat().format(item.importeAnio3)}
//                           </h4>
//                           <h4
//                             style={{
//                               textAlign: "left",
//                               paddingLeft: "10%",
//                             }}
//                           >
//                             - Cuotas 37-48: $
//                             {new Intl.NumberFormat().format(item.importeAnio4)}
//                           </h4>
//                         </>
//                       )}
//                     </>
//                   )}
//                 </div>
//               </Grid>
//               <Grid item xs={6} sm={4}>
//                 <div
//                   style={{
//                     padding: "10px 20px",
//                     marginTop: "20px",
//                     marginBottom: "20px",
//                     marginRight: "10px",
//                     borderRadius: 10,
//                   }}
//                 >
//                   {comisiones && comisiones.length ? (
//                     <>
//                       <h3>Comisiones:</h3>
//                       {comisiones.map((com) => {
//                         //console.log('Com.......', com);
//                         return (
//                           <div>
//                             <h4>
//                               {com.Usuario.nombre} {com.Usuario.apellido}: $
//                               {new Intl.NumberFormat().format(com.importe)}
//                             </h4>
//                           </div>
//                         );
//                       })}
//                     </>
//                   ) : (
//                     <>
//                       <h3>Comisiones no declaradas</h3>
//                     </>
//                   )}
//                 </div>
//               </Grid>
//             </Grid>
//           </Collapse>
//         </TableCell>
//       </TableRow>
//     </React.Fragment>
//   );
// }

export default function AvisoVentaProp(props) {
  const { token } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [reservas, setReservas] = useState({});
  const [formaPago, setFormaPago] = useState({});
  const [moneda, setMoneda] = useState({});
  const [usuario, setUsuario] = useState({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(reservas, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ReservaPropiedadVentaService.getNoProcesadoVenta(token).then((res) => {
        console.log("Reservas Propiedades Venta: ", res);
        setReservas(res);
      });
      FormaPagoService.getAllItems(token).then((res) => {
        setFormaPago(res);
      });
      MonedaService.getAllItems(token).then((res) => {
        setMoneda(res);
      });
      UsuarioService.getAllItems(token).then((res) => {
        setUsuario(res);
      });
    }
  }, [cargando, reservas, formaPago, moneda, usuario]); // eslint-disable-line react-hooks/exhaustive-deps

  const procesar = async (id) => {
    let data = {
      procesado: true,
    };
    await ReservaPropiedadVentaService.procesar(data, id, token);
    await ReservaPropiedadVentaService.getNoProcesadoVenta(token).then(
      (res) => {
        setReservas(res);
      }
    );
  };

  return (
    <>
      {/* {reservas && reservas.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="PROPIEDAD VENDIDA"
                icon={
                  <ThumbUpIcon
                    style={{ fontSize: 40, color: "green", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={reservas}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#DBCFDE" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <h2>
                        <ThumbUpIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                        PROPIEDAD VENDIDA{" "}
                        <ThumbUpIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                      </h2>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: 10 }}>
                      <Controls.Button
                        text="Ocultar"
                        onClick={() => setVisible(!visible)}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <TblContainer>
                  <Table aria-label="collapsible table">
                    <TblHead />
                    <TableBody>
                      {recordsAfterPagingAndSorting().map((item) => (
                        <>
                          <Fila
                            item={item}
                            formaPago={formaPago}
                            moneda={moneda}
                            procesar={procesar}
                            token={token}
                          />
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      /> */}
    </>
  );
}
