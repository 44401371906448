import React from "react";

import { Grid, Paper, } from "@material-ui/core";

export default function TransferenciaForm(props) {
  
  const { recordForEdit, listaTarjetas } = props;
  
  const tarjeta = listaTarjetas.find((tarjeta) => recordForEdit.TarjetaCreditoId === tarjeta.id)

  return (
    <Paper elevation={0}>
      <Grid container>
        {recordForEdit && recordForEdit.id ? (
          <Grid item xs={4}>
            <h3 style={{ marginLeft: 10 }}>
              CLIENTE: {recordForEdit.Cliente.nombre} {recordForEdit.Cliente.apellido}
            </h3>
          </Grid>
        ) : (
          <>
          </>
        )}
        <Grid item xs={4}>
          <h3>Importe: {recordForEdit.ingreso}</h3>
        </Grid>
        <Grid item xs={4}>
          <h3>Tarjeta: {tarjeta.nombre}</h3>
        </Grid>
      </Grid>
    </Paper>
  );
}
