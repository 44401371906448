import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';

export default function Barra(props) {

  const { fuentes, cantidad } = props;
  //console.log('Datos a tabla: ', fuentes, cantidad);
  const data = {
    labels: fuentes,
    datasets: [
      {
        label: 'Cantidad',
        data: cantidad,
        borderColor: "blue",
        backgroundColor: "blue",
      },
    ]
  };

  return (
    <Grid container >
      <Grid item xs={12} >
        <Bar
          data={data}
          width={50}
          height={25}
        />
      </Grid>
    </Grid>
  );
}
