import React, { useState, useEffect } from "react";

import * as LocalidadServices from "../../services/ubicacion/localidad";
import * as PaisServices from "../../services/ubicacion/pais";
import * as ProvinciaServices from "../../services/ubicacion/provincia";
import * as ClienteServices from "../../services/cliente/cliente";
import * as FuenteServices from "../../services/cliente/fuente";

import { useUserState } from "../../context/UserContext";
import ClienteForm from "./ClienteForm";
import Tarjeta from "../utiles/Tarjeta";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import AnnouncementIcon from "@material-ui/icons/Announcement";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import Popup from "../../components/tablas/Popup";

import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "textoErrorDatos", label: "Error detectado" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function ClienteConError() {
  const { token } = useUserState();
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({  // eslint-disable-line
    fn: (items) => {
      return items;
    },
  }); // eslint-disable-line react-hooks/exhaustive-deps
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [registros, setRegistros] = useState({});
  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});
  const [registroLocalidad, setRegistroLocalidad] = useState({});
  const [registroFuente, setRegistroFuente] = useState({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ClienteServices.getItemsRevision(token).then((res) => {
        setRegistros(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidad(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
      FuenteServices.getAllItems(token).then((res) => {
        setRegistroFuente(res);
      });
    }
  }, [cargando, registros, registroPaises]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopup = (item) => {
    let paisId = 0;
    let provinciaId = 0;
    try {
      const prov = registroLocalidad.find(
        (itemLoc) => itemLoc.id === item.LocalidadId
      );
      const pais = registroProvincias.find(
        (itemProv) => itemProv.id === prov.ProvinciumId
      );
      if (pais) {
        paisId = pais.PaiId;
      }
      if (prov) {
        provinciaId = prov.ProvinciumId;
      }
    } catch (e) {
      //console.log("ERROR:", e.message)
    }
    item.PaiId = paisId;
    item.ProvinciumId = provinciaId;
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addOrEdit = async (registroItem, resetForm) => {
    if (registroItem.id === 0) {
      try {
        await ClienteServices.insert(registroItem, token);
      } catch (err) {
        return false;
      }
    } else {
      try {
        await ClienteServices.update(registroItem, token);
      } catch (err) {
        return false;
      }
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    setRegistros(ClienteServices.getAllItems(token));
  };

  return (
    <>
      {registros.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="CLIENTES PARA REVISIÓN"
                icon={
                  <AnnouncementIcon
                    style={{ fontSize: 40, color: "red", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={registros}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#FFE4E3" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <h2>
                    {" "}
                    CLIENTES PARA REVISIÓN{" "}
                    <AnnouncementIcon
                      style={{ fontSize: 40, color: "red" }}
                    />{" "}
                  </h2>
                </Toolbar>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.apellido} - {item.nombre}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.textoErrorDatos}{" "}
                        </TableCell>
                        <TableCell>
                          <Controls.ActionButton
                            color="primary"
                            onClick={() => {
                              openInPopup(item);
                            }}
                          >
                            <EditOutlinedIcon fontSize="small" />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Popup
        title="Gestión clientes"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ClienteForm
          token={token}
          listaFuentes={registroFuente}
          listaLocalidades={registroLocalidad}
          listaProvincias={registroProvincias}
          listaPaises={registroPaises}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
