import RichTextEditor from "mui-rte";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import { stateToHTML } from "draft-js-export-html";
import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";
import Popup from "../../../components/tablas/Popup";

import PropietarioForm from "../propiedades/PropietarioForm";

import * as PropietarioService from "../../../services/producto/propietario";
import { useUserState } from "../../../context/UserContext";

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        RichTextEditor: {
            root: {
                marginTop: 20,
                width: "80%"
            },
            editor: {
                border: "1px solid gray" 
            }
        }
    }
});

export default function PropiedadVentaForm(props) {
  
  const { 
    addOrEdit,
    recordForEdit,
    //setRegistroPropietario,
    nuevoPropietario,
    setNuevoPropietario,
    listaPropietarios,
  } = props;

  const { token } = useUserState();

  let HTML = "";

  const initialFValues = {
    id: 0,
    nombre: "",
    latitud: "",
    longitud: "",
    linkVideo: "",
    linkPlano: "",
    linkDocumentacion: "",
    fotoPrincipal: "",
    precio: 0,
    precioWeb: 0,
    superficieTotal: 0,
    superficieCubierta: 0,
    cantHabitaciones: 0,
    cantBanios: 0,
    cantCocheras: 0,
    pileta: 0,
    calefaccion: 0,
    servLuz: 0,
    servAgua: 0,
    servGas: 0,
    servTelefono: 0,
    visibleWeb: 0,
    direccion: "",
    observaciones: "",
    descripcionWeb:"",
    reservado: 0,
    vendido: 0,
    PropietarioId: 0,
    EstadoId: '',
    nomenclatura: "",
    numCuenta: "",
    amojonado: false,
    destacado:false,
    MonedaId: '',
  };
  if (recordForEdit) {
    initialFValues.PaiId = recordForEdit.PaiId;
    initialFValues.ProvinciumId = recordForEdit.ProvinciumId;
    initialFValues.LocalidadId = recordForEdit.LocalidadId;

    if (recordForEdit.descripcionWeb) {
      // 1. Convert the HTML
      const contentHTML = convertFromHTML(recordForEdit.descripcionWeb)
      // 2. Create the ContentState object
      const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
      // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
      HTML = JSON.stringify(convertToRaw(state))
    }
  } else {
    initialFValues.PaiId = "";
    initialFValues.ProvinciumId = "";
    initialFValues.LocalidadId = "";
  }

  const [guardando, setGuardando] = React.useState(false);
  const [archivo, guardarArchivo] = useState("");
  const [openPopupPropietario, setOpenPopupPropietario] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const addOrEditPropietario = async (registroItem, resetForm) => {
    try {
      const propietario = await PropietarioService.insert(registroItem, token);
      //setRegistroPropietario((prevState) => ({ ...prevState, propietario }));
      values.PropietarioId = propietario.data.id;
      //console.log('Propietario: ', propietario.data, propietario.data.id);
    } catch (err) {
      return false;
    }
    resetForm();
    setNuevoPropietario(false);
    setOpenPopupPropietario(false);
  };

  const handleInputSoloNumerosChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      e.target.value = onlyNums;
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      e.target.value = number;
    }
    handleInputChange(e);
  };

  const verificaYagrega = (campos, objeto) => {
    campos.forEach((campo) => {
      if (values[campo] || values[campo] === false)
        objeto.append(campo, values[campo]);
    });
    return objeto;
  };

  const handleEditor = (editorState) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    //console.log('ROW: ', convertToRaw(editorState.getCurrentContent()));
    //console.log('HTML: ', stateToHTML(editorState.getCurrentContent()));
    HTML = stateToHTML(editorState.getCurrentContent())
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nuevoPropietario) {
      values.descripcionWeb = HTML;
      if (values.PropietarioId === 0) {
        //console.log('Entró en handleSubmit: ', nuevoPropietario);
        if (!nuevoPropietario) {
          alert("Por favor seleccione el propietario para continuar");
        }
        return false;
      }
      if (values.EstadoId === 0) {
        alert("Por favor seleccione el estado de la propiedad");
        return false;
      }
      if (validate()) {
        let formData = new FormData();
        formData = verificaYagrega(
          [
            "nombre",
            "latitud",
            "longitud",
            "linkVideo",
            "linkPlano",
            "linkDocumentacion",
            "precio",
            "precioWeb",
            "superficieTotal",
            "superficieCubierta",
            "cantHabitaciones",
            "cantBanios",
            "cantCocheras",
            "pileta",
            "calefaccion",
            "servLuz",
            "servAgua",
            "servGas",
            "servTelefono",
            "visibleWeb",
            "direccion",
            "observaciones",
            "descripcionWeb",
            "LocalidadId",
            "PropietarioId",
            "EstadoId",
            "nomenclatura",
            "numCuenta",
            "amojonado",
            "MonedaId",
            "destacado"
          ],
          formData
        );
        if (archivo) {
          formData.append("fotoPrincipal", archivo);
        }
        setGuardando(true);
        addOrEdit(formData, resetForm, values.id).then((e) => {
          if (e === false) {
            setGuardando(false);
            alert(
              "No se pudo completar la operación, revise los datos e intente nuevamente"
            );
          }
        });
      }
    }
    setNuevoPropietario(false);
  };

  const obtenerColorEstado = (id) => {
    let color = "white";
    if (id) {
      const item = props.listaEstados.find((item) => item.id === id);
      if (item.color) {
        color = item.color;
      }
    }
    return color;
  };

  const leerArchivo = (e) => {
    guardarArchivo(e.target.files[0]);
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Controls.Input
                name="nombre"
                label="Nombre"
                value={values.nombre}
                onChange={handleInputChange}
                error={errors.nombre}
                focused={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Input
                name="direccion"
                label="Direccion"
                value={values.direccion}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12} sm={8}>
                  {listaPropietarios && listaPropietarios.length &&
                    <Controls.Select
                      name="PropietarioId"
                      label="Propietario"
                      value={values.PropietarioId}
                      onChange={handleInputChange}
                      options={listaPropietarios.map((item) => {
                        return {
                          id: item.id,
                          title:
                            item.apellido +
                            " " +
                            item.nombre +
                            (item.telefono ? " (" + item.telefono + ")" : "") +
                            (item.DNI ? " - [" + item.DNI + "]" : ""),
                        };
                      })}
                    />
                  }
                </Grid>
                {!values.PropietarioId && 
                  <Grid item xs={12} sm={4}>
                    <Controls.Button
                      name="AgregarPropietario"
                      text="Agregar Propietario"
                      onClick={() => {
                        setOpenPopupPropietario(true);
                        //setNuevoPropietario(true);
                      }}      
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div
                style={{
                  backgroundColor: obtenerColorEstado(values.EstadoId),
                  borderRadius: 10,
                  color: "white",
                }}
              >
                <Controls.Select
                  name="EstadoId"
                  label="Estado"
                  value={values.EstadoId}
                  onChange={handleInputChange}
                  options={props.listaEstados.map((item) => {
                    return { id: item.id, title: item.nombre };
                  })}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#B4FEB0" }} item xs={12} sm={3}>
              <Controls.Input
                name="latitud"
                label="Latitud"
                value={values.latitud}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#B4FEB0" }} item xs={12} sm={3}>
              <Controls.Input
                name="longitud"
                label="Longitud"
                value={values.longitud}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controls.Input
                name="nomenclatura"
                label="Nomenclatura"
                value={values.nomenclatura}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controls.Input
                name="numCuenta"
                label="Número de cuenta"
                value={values.numCuenta}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#FEE4B0" }} item xs={12} sm={4}>
              <Controls.Input
                name="linkVideo"
                label="Link Video"
                value={values.linkVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FEE4B0" }} item xs={12} sm={4}>
              <Controls.Input
                name="linkPlano"
                label="Link Plano"
                value={values.linkPlano}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FEE4B0" }} item xs={12} sm={4}>
              <Controls.Input
                name="linkDocumentacion"
                label="Link Documentacion"
                value={values.linkDocumentacion}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                name="imagen"
                helperText="Seleccione una imagen como principal de la propiedad - No debe superar los 500kb"
                value={values.imagen}
                type="file"
                onChange={leerArchivo}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Controls.Select
                name="PaiId"
                label="País"
                value={values.PaiId}
                onChange={handleInputChange}
                options={props.listaPaises.map((item) => {
                  return { id: item.id, title: item.nombre };
                })}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              {values.PaiId ? (
                <div>
                  {props.listaProvincias.find(
                    (item) => item.PaiId === values.PaiId
                  ) ? (
                    <Controls.Select
                      name="ProvinciumId"
                      label="Provincia"
                      value={values.ProvinciumId}
                      onChange={handleInputChange}
                      options={props.listaProvincias.map((item) => {
                        if (item.PaiId === values.PaiId) {
                          return { id: item.id, title: item.nombre };
                        } else {
                          return false // Línea Modificada
                        }
                      })}
                    />
                  ) : (
                    <>
                      {" "}
                      No hay provincias creadas en este pais. Por favor
                      completar antes de continuar.
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={6} sm={4}>
              {values.ProvinciumId ? (
                <div>
                  {props.listaLocalidades.find(
                    (item) => item.ProvinciumId === values.ProvinciumId
                  ) ? (
                    <>
                      <Controls.Select
                        name="LocalidadId"
                        label="Localidad"
                        value={values.LocalidadId}
                        onChange={handleInputChange}
                        options={props.listaLocalidades.map((item) => {
                          if (item.ProvinciumId === values.ProvinciumId) {
                            return { id: item.id, title: item.nombre };
                          } else {
                            return false // Línea Modificada
                          }
                        })}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      No hay localidades creadas en esta provincia. Por favor
                      completar antes de continuar.
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#FC9D9D" }} item xs={12} sm={3}>
              <Controls.InputMask
                name="precio"
                label="Precio"
                value={values.precio}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#BFFFE3" }} item xs={12} sm={3}>
              <Controls.InputMask
                name="precioWeb"
                label="Precio web"
                value={values.precioWeb}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#BFFFE3" }} item xs={12} sm={3}>
               <Controls.Select
                  name="MonedaId"
                  label="Moneda para WEB"
                  value={values.MonedaId}
                  onChange={handleInputChange}
                  options={props.listaModendas.map((item) => {
                    return { id: item.id, title: item.nombre };
                  })}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.InputMask
                name="superficieTotal"
                label="Superficie total"
                value={values.superficieTotal}
                onChange={handleInputSoloNumerosChange}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.InputMask
                name="superficieCubierta"
                label="Superficie cubierta"
                value={values.superficieCubierta}
                onChange={handleInputSoloNumerosChange}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.InputMask
                name="cantHabitaciones"
                label="Cant. habitaciones"
                value={values.cantHabitaciones}
                onChange={handleInputSoloNumerosChange}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.InputMask
                name="cantBanios"
                onChange={handleInputSoloNumerosChange}
                label="Cant. baños"
                value={values.cantBanios}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.InputMask
                name="cantCocheras"
                onChange={handleInputSoloNumerosChange}
                label="Cant. cocheras"
                value={values.cantCocheras}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="pileta"
                label="Pileta"
                value={values.pileta}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="calefaccion"
                label="Calefacción"
                value={values.calefaccion}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="servLuz"
                label="Luz"
                value={values.servLuz}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="servAgua"
                label="Agua"
                value={values.servAgua}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="servGas"
                label="Gas"
                value={values.servGas}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="servTelefono"
                label="Teléfono"
                value={values.servTelefono}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid
                style={{
                  backgroundColor: "lightblue",
                  color: "black",
                  borderRadius: 10,
                  margin: 5,
                }}
                item
                xs={12}
                sm={8}
              >
                <Controls.Checkbox
                  name="visibleWeb"
                  label="Visible en Web"
                  value={values.visibleWeb}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid
                style={{
                  backgroundColor: "violet",
                  color: "black",
                  borderRadius: 10,
                  margin: 5,
                }}
                item
                xs={12}
                sm={8}
              >
                <Controls.Checkbox
                  name="destacado"
                  label="Destacado"
                  value={values.destacado}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid
                style={{
                  backgroundColor: "orange",
                  color: "black",
                  borderRadius: 10,
                  margin: 5,
                }}
                item
                xs={12}
                sm={8}
              >
                <Controls.Checkbox
                  name="amojonado"
                  label="Amojonado"
                  value={values.amojonado}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controls.Input
                name="observaciones"
                helperText="Observaciones"
                value={values.observaciones}
                onChange={handleInputChange}
                multiline
                rows={4}
                rowsMax={4}
                error={errors.descripcion}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingBottom: 40, paddingLeft: 20 }} >
              <MuiThemeProvider theme={defaultTheme}>
                <RichTextEditor
                  label="Descripción web aquí..."
                  value={HTML}
                  //onSave={save}
                  onChange={handleEditor}
                  inlineToolbar={true}
                  controls={[
                    "title", 
                    "bold",
                    "italic",
                    "underline",
                    "highlight", 
                    "link", 
                    "bulletList", 
                  ]}
                  toolbarButtonSize="small"
                />
              </MuiThemeProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginTop: 25, textAlign: "right" }}>
              <Controls.Button type="submit" text="Guardar" />
              <Controls.Button
                text="Limpiar"
                color="default"
                onClick={resetForm}
              />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>
      </Grid>
      <Popup
      title="Vista de propiedad en venta"
      openPopup={openPopupPropietario}
      setOpenPopup={setOpenPopupPropietario}
    >
      <PropietarioForm
        token={token}
        listaPaises={props.listaPaises}
        listaProvincias={props.listaProvincias}
        listaLocalidades={props.listaLocalidades}
        listaPropietarios={props.listaPropietarios}
        addOrEdit={addOrEditPropietario}
        //setNuevoPropietario={setNuevoPropietario}
      />
    </Popup>
    </Form>
  
  );
}
