
export const stringToColour = (index) => {
  /*  var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }*/

  var colour = "#E8E8E8";
  if (index % 2 === 0) colour = "#DDDEDD";

  return colour;
};

export const convertirFecha = (fechaTurno) => {
  return <div>{convertirFechaToStr(fechaTurno)}</div>;
};

export const convertirFechaToStr = (fechaTurno) => {
  const texto =
    fechaTurno.substr(8, 2) +
    "/" +
    fechaTurno.substr(5, 2) +
    "/" +
    fechaTurno.substr(0, 4);
  return texto;
};

export const verificaYagrega = (campos, objeto, values) => {
  campos.forEach((campo) => {
    if (values[campo] || values[campo] === false)
      objeto.append(campo, values[campo]);
  });

  return objeto;
};

export const handleInputSoloNumerosChange = (e, handleInputChange) => {
  const onlyNums = e.target.value.replace(/[^0-9]/g, "");
  if (onlyNums.length < 10) {
    e.target.value = onlyNums;
  } else if (onlyNums.length === 10) {
    const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    e.target.value = number;
  }
  handleInputChange(e);
};

export function numberWithCommas(x) {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return "";
};

export function obtenerMes(num){
  switch (num) {
    case 0: return "Enero ";
    case 1: return "Febrero ";
    case 2: return "Marzo ";
    case 3: return "Abril ";
    case 4: return "Mayo ";
    case 5: return "Junio ";
    case 6: return "Julio ";
    case 7: return "Agosto ";
    case 8: return "Septiembre ";
    case 9: return "Octubre ";
    case 10: return "Noviembre ";
    case 11: return "Diciembre ";
    default: break;
  }
};

function centenas(num) {
  let numeroEscrito = "";
  if (num[0] !== '0' || num[1] !== '0' || num[2] !== '0') {
    if (num[0] === '1' && num[1] === '0' && num[2] === '0') {
      numeroEscrito = "cien ";
    } else {
      switch (num[0]) {
        case '1': numeroEscrito = "ciento "; break;
        case '2': numeroEscrito = "doscientos "; break;
        case '3': numeroEscrito = "trescientos "; break;
        case '4': numeroEscrito = "cuatrocientos "; break;
        case '5': numeroEscrito = "quinientos "; break;
        case '6': numeroEscrito = "seiscientos "; break;
        case '7': numeroEscrito = "setecientos "; break;
        case '8': numeroEscrito = "ochocientos "; break;
        case '9': numeroEscrito = "novecientos "; break;
        default: break;
      }
      numeroEscrito = numeroEscrito + decenas(num[1] + num[2])
    }
  }
  return numeroEscrito;
}

function unidades(num) {
  switch (num) {
    case '1': return "un ";
    case '2': return "dos ";
    case '3': return "tres ";
    case '4': return "cuatro ";
    case '5': return "cinco ";
    case '6': return "seis ";
    case '7': return "siete ";
    case '8': return "ocho ";
    case '9': return "nueve ";
    default: return "";
  }
}

function decenas(num) {
  switch (num[0]) {
    case '0': return unidades(num[1]);
    case '1': 
      switch (num[1]) {
        case '0': return "diez";
        case '1': return "once ";
        case '2': return "doce ";
        case '3': return "trece ";
        case '4': return "catorce ";
        case '5': return "quince ";
        default: return ("dieci" + unidades(num[1]));
      }  
    case '2': 
      if (num[1] === '0') return "veinte ";
      else return ("veinti" + unidades(num[1]));
    case '3':
      if (num[1] === '0') return "treinta ";
      else return ("treinta y " + unidades(num[1]));
    case '4':
      if (num[1] === '0') return "cuarenta ";
      else return ("cuarenta y " + unidades(num[1]));
    case '5':
      if (num[1] === '0') return "cincuenta ";
      else return ("cincuenta y " + unidades(num[1]));
    case '6':
      if (num[1] === '0') return "sesenta ";
      else return ("sesenta y " + unidades(num[1]));
    case '7':
      if (num[1] === '0') return "setenta ";
      else return ("setenta y " + unidades(num[1]));
    case '8':
      if (num[1] === '0') return "ochenta ";
      else return ("ochenta y " + unidades(num[1]));
    case '9':
      if (num[1] === '0') return "noventa ";
      else return ("noventa y " + unidades(num[1]));
    default: return "";
  }
}

export function convertirNumeroaTexto(num) {
  let texto = num.toString();
  let completo = texto.split(".");
  let entero = completo[0];
  let numeros = [];
  let escrito = "";
  let sufijo = ["", "mil ", "millones "];
  while (entero.length > 0) {
    numeros.push(entero.slice(-3));
    entero = entero.slice(0, -3);
  }
  for (let i = numeros.length - 1; i >= 0; i--) {
    switch (numeros[i].length) {
      case 3: escrito = escrito + centenas(numeros[i]); break;
      case 2: escrito = escrito + decenas(numeros[i]); break;
      case 1: escrito = escrito + unidades(numeros[i]); break;
      default: break;
    }
    if (numeros[i][0] !== '0' || numeros[i][1] !== '0' || numeros[i][2] !== '0') {
      escrito = escrito + sufijo[i];
    }
  }
  if (completo.length === 2) {
    escrito = escrito + "con " + decenas(completo[1]);
  }
  return escrito;
};

