import React, { useState, useEffect } from "react";

import * as ReservaLotesxLoteoService from "../../../services/venta/reservaLotesXLoteo";
import * as FormaPagoService from "../../../services/venta/formaPago";
import * as MonedaService from "../../../services/venta/moneda";

import { useUserState } from "../../../context/UserContext";

//import DetalleBajaProp from "./DetalleBajaProp";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
//import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
} from "@material-ui/core";

//import Popup from "../../components/tablas/Popup";

import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";
import Tarjeta from "../../utiles/Tarjeta";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "cliente", label: "Cliente" },
  { id: "vendedor", label: "Vendedor" },
  { id: "devolucion", label: "Devolución" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#FAC96B",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function AvisoBajaProp() {
  const { token } = useUserState();
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({  // eslint-disable-line
    fn: (items) => {
      return items;
    },
  });

  //const [openPopup, setOpenPopup] = useState(false);

  //const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [reservas, setReservas] = useState({});
  const [formaPago, setFormaPago] = useState({});
  const [moneda, setMoneda] = useState({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(reservas, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ReservaLotesxLoteoService.getNoProcesadoBaja(token).then((res) => {
        console.log("Reservas: ", res);
        setReservas(res);
      });
      FormaPagoService.getAllItems(token).then((res) => {
        setFormaPago(res);
      });
      MonedaService.getAllItems(token).then((res) => {
        setMoneda(res);
      });
    }
  }, [cargando, reservas, formaPago, moneda]); // eslint-disable-line react-hooks/exhaustive-deps

  /*  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };
*/
  const procesar = async (id) => {
    let data = {
      procesado: true,
    };
    await ReservaLotesxLoteoService.procesar(data, id, token);
    await ReservaLotesxLoteoService.getNoProcesadoBaja(token).then((res) => {
      setReservas(res);
    });
  };

  /*const addOrEditAsigna = async (registroItem, resetForm) => {
    
    try {
      await ClienteServices.updateAsignaVendedor(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopupAsigna(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    setReservas(ClienteServices.getAllItems(token));
  };*/

  return (
    <>
      {reservas && reservas.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="RESERVA CANCELADA"
                icon={
                  <ThumbDownIcon
                    style={{ fontSize: 40, color: "red", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={reservas}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#FAC96B" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <h2>
                        <ThumbDownIcon
                          style={{
                            fontSize: 40,
                            color: "red",
                            marginBottom: -10,
                          }}
                        />{" "}
                        RESERVA CANCELADA{" "}
                        <ThumbDownIcon
                          style={{
                            fontSize: 40,
                            color: "red",
                            marginBottom: -10,
                          }}
                        />{" "}
                      </h2>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: 10 }}>
                      <Controls.Button
                        text="Ocultar"
                        onClick={() => setVisible(!visible)}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.Lotesxloteo.Loteo.nombre}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.Cliente.nombre} {item.Cliente.apellido}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {item.Usuario.nombre} {item.Usuario.apellido}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          $
                          {new Intl.NumberFormat().format(
                            item.importeDevolucion
                          )}
                        </TableCell>
                        <TableCell>
                          {/*<Controls.ActionButton
                      color="primary"
                      style={{ backgroundColor: "#FFA114" }}
                      onClick={() => {
                        openInPopup(item);
                      }}
                      <VisibilityIcon fontSize="small" />
                    </Controls.ActionButton>
                    >*/}
                          <Controls.ActionButton
                            color="primary"
                            style={{ backgroundColor: "#121ADE" }}
                            onClick={() => {
                              procesar(item.id);
                            }}
                          >
                            <CheckIcon fontSize="small" />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      {/*<Popup
        title="Asignación de cliente"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <DetalleBajaProp
          token={token}
          recordFormaPago={formaPago}
          recordMoneda={moneda}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditAsigna}
        />
      </Popup>*/}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
