import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

import * as UsuarioServices from "../../services/usuario/usuario";
import { useUserState } from "../../context/UserContext";

const initialFValues = {
  id: 0,
  vendedorId: undefined,
};

export default function ClienteForm(props) {

  const { addOrEdit, recordForEdit } = props;

  const { userId } = useUserState();

  //console.log('Record for edit: ', recordForEdit);

  const [guardando, setGuardando] = React.useState(false);
  const [registros, setRegistros] = useState({});
  const [cargando, setCargando] = React.useState(false);

  const validate = (fieldValues = values) => {

    let temp = { ...errors };

    if ("vendedorId" in fieldValues)
      temp.vendedorId =
        fieldValues.vendedorId !== undefined ? "" : "Dato requerido";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setGuardando(true);
     
      let data = {
        vendedorId: values.vendedorId,
        usuarioCreadorId: userId
      }

      if (recordForEdit.nombre) {
        data.nombre = recordForEdit.nombre
      } else {
        data.nombre = ""
      }

      if (recordForEdit.telefono) {
        data.telefono = recordForEdit.telefono
      }

      if (recordForEdit.email) {
        data.email = recordForEdit.email
      }

      data.observaciones = "Consulta por: ";

      if (recordForEdit.LotesxloteoId) {
        data.observaciones = data.observaciones + "Loteo " + recordForEdit.Lotesxloteo.Loteo.nombre
      }

      if (recordForEdit.PropiedadesVentumId) {
        data.observaciones = data.observaciones + "Propiedad " + recordForEdit.PropiedadesVentum.nombre
      }

      if (recordForEdit.consulta) {
        data.observaciones = data.observaciones + " - " + recordForEdit.consulta
      }

      addOrEdit(data, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      UsuarioServices.getAllItems(props.token).then((res) => {
        setRegistros(res);
      });
    }
    if (recordForEdit != null) {
      setValues({
        id: recordForEdit.id,
      });
    }
  }, [recordForEdit, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          {recordForEdit && (
            <>
              <h2>
                {" "}
                <EmojiPeopleIcon
                  style={{ fontSize: 40, color: "green", marginBottom: -10 }}
                />{" "}
                {recordForEdit.nombre ? recordForEdit.nombre : "Desde la web"}
              </h2>
              {recordForEdit.Usuario ? (
                <h3
                  style={{
                    backgroundColor: "yellow",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  {" "}
                  <ReportProblemIcon style={{ marginBottom: -5 }} /> Asignado
                  actualmente a {recordForEdit.Usuario.nombre}{" "}
                  {recordForEdit.Usuario.apellido}
                </h3>
              ) : (
                <h3>[sin asignar]</h3>
              )}
            </>
          )}
          <Grid container spacing={1}>
            {registros.length && (
              <Grid item xs={12} sm={6}>
                <Controls.Select
                  name="vendedorId"
                  label="Seleccione el usuario que desea asignar"
                  value={values.vendedorId}
                  onChange={handleInputChange}
                  options={registros.map((item) => {
                    return {
                      id: item.id,
                      title: item.nombre + " " + item.apellido,
                    };
                  })}
                  error={errors.vendedorId}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <div style={{ marginTop: 25, textAlign: "right" }}>
          {!guardando ? (
            <Controls.Button type="submit" text="Guardar" />
          ) : (
            <CircularProgress size={26} />
          )}
        </div>
      </Grid>
    </Form>
  );
}
