import React, { useState, useEffect, Fragment } from "react";

import { CircularProgress } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, } from "@material-ui/core";

import { useUserState } from "../../../context/UserContext";

import LoteoForm from "./LoteosForm";
import LoteoVisor from "./LoteosVisor";
import LotesXLoteo from "./LotesXLoteo";
import Galeria from "../../utiles/Galeria";
//import DescripcionWebForm from "./DescripcionWebForm";

import * as LoteosServices from "../../../services/producto/loteos";
import * as LocalidadServices from "../../../services/ubicacion/localidad";
import * as PaisServices from "../../../services/ubicacion/pais";
import * as ProvinciaServices from "../../../services/ubicacion/provincia";
import * as EstadosServices from "../../../services/producto/estado";
import * as AsociadoServices from "../../../services/venta/asociado";
import * as MonedaServices from "../../../services/venta/moneda";
import * as LotesXLoteoServices from "../../../services/producto/lotesXLoteo";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";
import PageHeader from "../../../components/tablas/PageHeader";
import Popup from "../../../components/tablas/Popup";
import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";

import GestorUrls from "../../../config/urls";
import Permisos from "../../../config/permisos";

import AccountTreeIcon from "@material-ui/icons/AccountTree";
import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
//import ListIcon from '@material-ui/icons/List';
import { Search } from "@material-ui/icons";

const cabecera = [
  { id: "nombre", label: "nombre" },
  { id: "foto", label: "Foto" },
  { id: "Estado", label: "Estado" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function Loteos() {
  const { token, rolCodigo } = useUserState();

  const urlDrive = GestorUrls.drive;

  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});
  const [registroLocalidad, setRegistroLocalidad] = useState({});
  const [registroEstados, setRegistroEstados] = useState({});
  const [registroAsociados, setRegistroAsociados] = useState({});
  const [registroMonedas, setRegistroMonedas] = useState({});

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [registros, setRegistros] = useState({});

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupVer, setOpenPopupVer] = useState(false);

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForVer, setRecordForVer] = useState(null);
  
  const [recordForLoteo, setRecordForLoteo] = useState(null);
  const [openPopupLoteo, setOpenPopupLoteo] = useState(false);

  const [recordForGaleria, setRecordForGaleria] = useState(null);
  const [openPopupGaleria, setOpenPopupGaleria] = useState(false);
 
  //const [openPopupWeb, setOpenPopupWeb] = useState(false);
  //const [recordForWeb, setRecordForWeb] = useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      LoteosServices.getAllItems(token).then((res) => {
        setRegistros(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidad(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        setRegistroPaises(res);
      });
      EstadosServices.getAllItems(token).then((res) => {
        setRegistroEstados(res);
      });
      AsociadoServices.getAllItems(token).then((res) => {
        setRegistroAsociados(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setRegistroMonedas(res);
      });
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopupLotes = (item) => {
    setRecordForLoteo(item);
    setOpenPopupLoteo(true);
  };

/*  const openInPopupWeb = (item) => {
    const data = {
      id: item.id,
      descripcionWeb: item.descripcionWeb,
    }
    setRecordForWeb(data);
    setOpenPopupWeb(true);
  };
*/
  const openInPopupGaleria = (id) => {
    setRecordForGaleria("productos/loteos/" + id + "/galeria/");
    setOpenPopupGaleria(true);
  };

  const openInPopupVer = (item) => {
    try {
      const prov = registroLocalidad.find(
        (itemLoc) => itemLoc.id === item.LocalidadId
      );
      const pais = registroProvincias.find(
        (itemProv) => itemProv.id === prov.ProvinciumId
      );
      if (pais) {
        item.PaiId = pais.PaiId;
        item.Pais_nombre = pais.nombre;
      }
      if (prov) {
        item.ProvinciumId = prov.ProvinciumId;
        item.Provincia_nombre = prov.nombre;
      }
      if (item.latitud) {
        item.urlMapa =
          "https://www.google.com/maps/search/?api=1&query=" +
          item.latitud +
          "," +
          item.longitud;
      }
    } catch (e) {}
    setRecordForVer(item);
    setOpenPopupVer(true);
  };

  const openInPopup = (item) => {
    let paisId = 0;
    let provinciaId = 0;
    try {
      const prov = registroLocalidad.find(
        (itemLoc) => itemLoc.id === item.LocalidadId
      );
      const pais = registroProvincias.find(
        (itemProv) => itemProv.id === prov.ProvinciumId
      );
      if (pais) {
        paisId = pais.PaiId;
      }
      if (prov) {
        provinciaId = prov.ProvinciumId;
      }
    } catch (e) {}
    item.PaiId = paisId;
    item.ProvinciumId = provinciaId;
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const handleSearch = (e) => {
    let target = e.target;
    target.value = target.value.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm, id) => {
    if (id === 0) {
      try {
        await LoteosServices.insert(registroItem, token);
      } catch (err) {
        return false;
      }
    } else {
      try {
        //console.log('Modificaciones de loteo: ', registroItem.get('cambiarPrecio'));
        console.log('registroItem; ', registroItem.get('descripcionWeb'));
        await LoteosServices.update(registroItem, id, token);
        if (registroItem.get('descripcionWeb') !== "" && registroItem.get('descripcionWeb') === undefined) {
          console.log('registroItem.get(descripcionWeb)', registroItem.get('descripcionWeb'));
          await LoteosServices.updateDescripcionWeb(registroItem, id, token);
        }
        if (registroItem.get('cambiarPrecio')) {
          console.log('Cambiar precio y moneda en todos los lotes del loteo...');
          let data = {
            id: id,
            precio: registroItem.get('precio'),
            precioWeb: registroItem.get('precioWeb'),
            MonedaId: registroItem.get('MonedaId'),
          };
          console.log('Data: ', data);
          await LotesXLoteoServices.updatePreciosLoteo(data, id, token);
        }
      } catch (err) {
        return false;
      }
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    //setOpenPopupWeb(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    setRegistros(LoteosServices.getAllItems(token));
  };

  return (
    <>
      <PageHeader
        title="GESTIÓN DE LOTEOS"
        subTitle="Gestión de loteos"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          {Permisos(rolCodigo, "PRODUCTOS.PROP_VENTA_AGREGA_EDITA") && (
            <Controls.Button
              text="Agregar"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          )}
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <Fragment key={item.id}>
                  {item.nombre ? (
                    <TableRow key={item.id}>
                      <TableCell key={"nombre" + item.id} style={{ fontWeight: "500" }}>
                        {item.nombre}{" "}
                      </TableCell>
                      <TableCell key={"fotoPrincipal" + item.id}>
                        {item.fotoPrincipal ? (
                          <img
                            style={{ width: 200, borderRadius: 10 }}
                            alt=""
                            src={
                              urlDrive +
                              "productos/loteos/" +
                              item.id +
                              "/" +
                              item.fotoPrincipal
                            }
                          />
                        ) : (
                          <> </>
                        )}
                      </TableCell>
                      {item.Estado ? (
                        <TableCell key={"estado" + item.id} style={{ fontWeight: "500" }}>
                          <div
                            style={{
                              backgroundColor: item.Estado.color,
                              padding: 10,
                              borderRadius: 10,
                              textAlign: "center",
                              color: "white",
                            }}
                          >
                            {item.Estado.nombre}{" "}
                          </div>{" "}
                        </TableCell>
                      ) : (
                        <TableCell key={"estadoo" + item.id} style={{ fontWeight: "500" }}>
                          <div
                            style={{
                              backgroundColor: "lightgray",
                              padding: 10,
                              borderRadius: 10,
                              textAlign: "center",
                              color: "black",
                            }}
                          >
                            No asignado
                          </div>{" "}
                        </TableCell>
                      )}
                      <TableCell key={"botones" + item.id}>
                        {Permisos(
                          rolCodigo,
                          "PRODUCTOS.LOTES_AGREGA_EDITA"
                        ) && (
                          <>
                            <Controls.ActionButton
                              key={"editar" + item.id} 
                              color="primary"
                              onClick={() => {
                                openInPopup(item);
                              }}
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </Controls.ActionButton>
                            {/*<Controls.ActionButton
                            key={"web" + item.id} 
                            color="primary"
                            onClick={() => {
                              openInPopupWeb(item);
                            }}
                          >
                            <ListIcon fontSize="small" />
                          </Controls.ActionButton>*/}
                        </>
                      )}
                        <Controls.ActionButton
                          key={"ver" + item.id} 
                          color="primary"
                          style={{ backgroundColor: "#1F6D14" }}
                          onClick={() => {
                            openInPopupVer(item);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          key={"lotes" + item.id} 
                          color="primary"
                          style={{ backgroundColor: "blue" }}
                          onClick={() => {
                            openInPopupLotes(item);
                          }}
                        >
                          <AccountTreeIcon fontSize="small" />
                        </Controls.ActionButton>
                        {Permisos(rolCodigo, "GALERIA.CRUD"
                        ) && 
                          <Controls.ActionButton
                            key={"galeria" + item.id} 
                            color="primary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              openInPopupGaleria(item.id);
                            }}
                          >
                            <PhotoCameraIcon fontSize="small" />
                          </Controls.ActionButton>
                        }
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />{" "}
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="FORMULARIO LOTEO"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <LoteoForm
          listaLocalidades={registroLocalidad}
          listaProvincias={registroProvincias}
          listaPaises={registroPaises}
          listaEstados={registroEstados}
          listaMonedas={registroMonedas}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      {/*<Popup
        title="DESCRIPCIÓN EN WEB"
        openPopup={openPopupWeb}
        setOpenPopup={setOpenPopupWeb}
      >
        <DescripcionWebForm
          recordForEdit={recordForWeb}
          addOrEdit={addOrEdit}
          setOpenPopupWeb={setOpenPopupWeb}
        />
      </Popup>*/}
      <Popup
        title="VISOR LOTEO"
        openPopup={openPopupVer}
        setOpenPopup={setOpenPopupVer}
      >
        <LoteoVisor recordForVer={recordForVer} />
      </Popup>
      <Popup
        title="LOTES POR LOTEO"
        openPopup={openPopupLoteo}
        setOpenPopup={setOpenPopupLoteo}
      >
        <LotesXLoteo
          token={token}
          rolCodigo={rolCodigo}
          listaAsociados={registroAsociados}
          listaEstados={registroEstados}
          recordForLoteo={recordForLoteo}
        />
      </Popup>
      <Popup
        title="GALERIA DE FOTOS"
        openPopup={openPopupGaleria}
        setOpenPopup={setOpenPopupGaleria}
      >
        <Galeria
          token={token}
          path={recordForGaleria}
          setOpenPopup={setOpenPopupGaleria}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
