import React, { useState, useEffect } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Grid, CircularProgress } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';

import GestorUrls from "../../config/urls";

import * as GaleriaServices from "../../services/utiles/galeria";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export default function Galeria(props) {

  const { token, path, setOpenPopup } = props;

  const urlDrive = GestorUrls.drive;

  const initialFValues = {};

  const [guardando, setGuardando] = React.useState(false);
  const [archivos, guardarArchivos] = useState({});
  const [cargando, setCargando] = useState(false);
  const [galeria, setGaleria] = useState([]);
  const [agregado, setAgregado] = useState(false);
  const [primeraCarga, setPrimeraCarga] = useState(true);
  // const [indice, setIndice] = useState(0);
  const [progress, setProgress] = useState(0);
  const [cantValida, setCantValida] = useState(true);
  const [notify, setNotify] = useState({ // eslint-disable-line
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const eliminarImagen = (elem) => {
    let rutas = galeria.slice();
    let i = rutas.indexOf(elem);
    if (i !== -1) {
      rutas.splice(i, 1);
    }
    setGaleria(rutas);
  };

  const handleAgregar = (e) => {
    //setAgregarUno(true);
    let formData = new FormData();
    //console.log(archivos);
    if (archivos) {
      for (let i = 0; i < archivos.length; i++) {
        formData.append("imagen", archivos[i]);
        //console.log(archivos[i]);
      }
      formData.append("indice", galeria.length + 1);
      formData.append("path", path);
      //console.log('Imagenes: ', archivos, galeria.length + 1, path);
      //console.log('Agregar: ', formData.get('imagen'));
      addOrEditAgregaUno(formData).then((e) => {
        if (e === false) {
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!agregado) {
      try {
        let datos = {
          path: path,
          galeria: galeria
        }
        GaleriaServices.guardarGaleria(datos, token).then((res => {
          setGuardando(true);
        }));
        setAgregado(true);
      } catch (error) {}
      resetForm();

      //setGuardando(false);
      setOpenPopup(false);
    }
  };

  // coloca la imagen en el state
  const leerArchivo = (e) => {
    let tamanio = 0;
    for (let i = 0; i < e.target.files.length; i++) {
      tamanio = tamanio + e.target.files[i].size;
    }
    if (tamanio > 10000000) {
      
      setCantValida(false);
    } else {
      guardarArchivos(e.target.files);
      setCantValida(true);
    }
    //console.log('en leer: ', e.target.files, tamanio);
  };

  const addOrEditAgregaUno = async (registroItem) => {
    //console.log('add: ', registroItem.get('imagen'));
    try {
      await GaleriaServices.agregarAGaleria(registroItem, token, setProgress
    ).then((res) => {
          let rutas = galeria.slice();
          for (let i = 0; i < res.data.length; i++) {
            let elemento = {
              id: galeria.length + i,
              foto: urlDrive + res.data[i],
              //console.log('Respuesta: ', res.data);
            };
            rutas.push(elemento);
            //console.log("Rutas: ", rutas);  
          }
          setGaleria(rutas);
        }
      );
    } catch (err) {
      return false;
    }

    resetForm();
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    //setCargando(false);
    setGuardando(false);
  };

  useEffect(() => {
    if (!cargando) {
      if (primeraCarga) {
        setPrimeraCarga(false);
        let cargar = {path: path}
        GaleriaServices.cargarGaleria(cargar, token).then((res) => {
          let rutas = [];
          // setIndice(res.data.length);
          for (let i = 0; i < res.data.length; i++) {
            rutas[i] = {
              id: i,
              foto: urlDrive + path + res.data[i],
            };
          }
          //orden = rutas;
          setGaleria(rutas); 
        });
        if (galeria && galeria.length) {
          if (!primeraCarga) {
            setCargando(true);
          }
        }
      }
    }
  }, [cargando, galeria]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid item xs={10}>
            <input
              accept=".jpg,.jpeg"
              id="contained-button-file"
              multiple
              type="file"
              onChange={leerArchivo}
              style={{backgroundColor: "#F1F1F1", height: 40, border: "0.5px solid", borderRadius: 5}}
            />
              <div style={{ margin: 20 }}>
              <LinearProgress  variant="determinate" value={progress} />
             <span style={{ fontSize: 12, fontWeight: 'bold' }}> {progress} % </span> 
              </div>
    
          </Grid>
          <Grid item xs={2}>
              <div style={{ marginTop: 25, textAlign: "right" }}>
                <Controls.Button
                  text="Agregar"
                  color="primary"
                  onClick={() => {
                    if (cantValida) {
                      setGuardando(true);
                      handleAgregar();  
                    }else {
                      alert('Verifique el tamaño de los archivos. La suma no debe superar los 10 MB');
                    }
                  }}
                />
              </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {galeria && galeria.length ? (
            <>
              <DragDropContext
                onDragEnd={(result, param) => {
                  const { source, destination } = result;
                  if (!destination) {
                    return;
                  }
                  if (
                    source.index === destination.index &&
                    source.droppableId === destination.droppableId
                  ) {
                    return;
                  }

                  setGaleria((prev) =>
                    reorder(prev, source.index, destination.index)
                  );
                }}
              >
                <div>
                  <Droppable droppableId="galeria">
                    {(droppableProvided) => (
                      <div
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                        //className="task-container"
                      >
                        {galeria.map((foto, index) => (
                          <Draggable
                            key={foto.id}
                            draggableId={foto.foto}
                            index={index}
                          >
                            {(draggableProvided) => (
                              <div
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.dragHandleProps}
                              >
                                <img
                                  style={{ width: 150, borderRadius: 10 }}
                                  alt=""
                                  src={foto.foto}
                                />
                                <Controls.ActionButton
                                  style={{ backgroundColor: "lightgrey" }}
                                  onClick={() => {
                                    eliminarImagen(foto);
                                  }}
                                >
                                  <DeleteForeverIcon fontSize="small" />
                                </Controls.ActionButton>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <div style={{ textAlign: "center" }}>
        {!guardando ?
        <Controls.Button
        type="submit"
        text="Finalizar"
        style={{ marginTop: 50 }}
      />
          :
          <CircularProgress />
      }
      </div>
    </Form>
  );
}
