import React, { useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import DateFnsUtils from "@date-io/date-fns";

import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  id: 0,
  fecha: fc,
  descripcion: "",
  ClienteId: undefined,
};

export default function HistorialForm(props) {
  const { addOrEdit, recordForEdit } = props;

  const [guardando, setGuardando] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [confirma, setConfirma] = React.useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fecha =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("descripcion" in fieldValues)
      temp.descripcion = fieldValues.descripcion ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.descripcion) {
      if (values.descripcion.length > 4) {
        if (validate()) {
          setGuardando(true);
          addOrEdit(values, resetForm).then((e) => {
            if (e === false) {
              setGuardando(false);
              alert(
                "No se pudo completar la operación, revise los datos e intente nuevamente"
              );
            }
          });
        }
      } else {
        alert("La descripción debe contener un mínimo de 5 letras");
      }
    } else {
      alert("Complete una descripción válida");
    }
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ClienteId: recordForEdit.id,
      });
      setConfirma(true);
    }
  }, [recordForEdit, confirma]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      {recordForEdit && (
        <h2>
          {" "}
          {recordForEdit.nombre} {recordForEdit.apellido} -
          {recordForEdit.telefono}{" "}
        </h2>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="fecha"
                  maxDate={new Date()}
                  format="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  helperText="Fecha"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                name="descripcion"
                helperText="Descripción de la acción realizada"
                value={values.descripcion}
                onChange={handleInputChange}
                multiline
                rows={4}
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 254);
                }}
                rowsMax={4}
                error={errors.descripcion}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginTop: 25, textAlign: "right" }}>
              <Controls.Button type="submit" text="Guardar" />
              <Controls.Button
                text="Limpiar"
                color="default"
                onClick={resetForm}
              />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
