import React from "react";

import { Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

import GestorUrls from "../../../config/urls";

import RoomIcon from "@material-ui/icons/Room";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AssignmentIcon from "@material-ui/icons/Assignment";
import InfoIcon from "@material-ui/icons/Info";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";

export default function LoteosVisor(props) {

  const { recordForVer } = props;

  const urlDrive = GestorUrls.drive;

  function numberWithCommas(x) {
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return "";
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <h1> {recordForVer.nombre}</h1>
            <img
              src={
                urlDrive +
                "productos/loteos/" +
                recordForVer.id +
                "/" +
                recordForVer.fotoPrincipal
              }
              alt=""
              style={{ width: 400, borderRadius: 10 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h2>
              {" "}
              <RoomIcon /> Ubicación{" "}
            </h2>
            {recordForVer.direccion}
            <br />
            {recordForVer.Localidad && (
              <>
                {recordForVer.Localidad.nombre}
                <br />
                {recordForVer.Pais_nombre}
                <br />
              </>
            )}
            {recordForVer.urlMapa && (
              <Link href={recordForVer.urlMapa} target="_blank" color="inherit">
                <br />
                <Button variant="contained" color="primary">
                  VER EN MAPA
                </Button>
              </Link>
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <h2>
              {" "}
              <AssignmentIcon /> Características{" "}
            </h2>
            {recordForVer.superficieTotal ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Superficie total :{" "}
                <b> {recordForVer.superficieTotal}</b>{" "}
              </div>
            ) : (
              <div>
                <CancelIcon /> Superficie Total : No definido
              </div>
            )}
            {recordForVer.cantManzanas ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Cantidad de manzanas :{" "}
                <b>{recordForVer.cantManzanas}</b>{" "}
              </div>
            ) : (
              <div>
                <CancelIcon /> Cantidad de manzanas : No definido
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <h2>
              {" "}
              <SettingsIcon /> Servicios{" "}
            </h2>
            {recordForVer.servLuz ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Luz
              </div>
            ) : (
              <div>
                <CancelIcon /> Luz
              </div>
            )}
            {recordForVer.servAgua ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Agua
              </div>
            ) : (
              <div>
                <CancelIcon /> Agua
              </div>
            )}
            {recordForVer.servGas ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Gas
              </div>
            ) : (
              <div>
                <CancelIcon /> Gas
              </div>
            )}
            {recordForVer.servTelefono ? (
              <div style={{ color: "green" }}>
                <CheckCircleIcon /> Teléfono
              </div>
            ) : (
              <div>
                <CancelIcon /> Teléfono
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <h2>
              {" "}
              <InfoIcon /> Información{" "}
            </h2>
            {recordForVer.linkVideo && (
              <Link
                href={recordForVer.linkVideo}
                target="_blank"
                color="inherit"
              >
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: "red" }}
                >
                  <PlayCircleFilledIcon style={{ marginRight: 5 }} /> VER VIDEO
                </Button>
                <br />
              </Link>
            )}
            {recordForVer.linkPlano && (
              <Link
                href={recordForVer.linkPlano}
                target="_blank"
                color="inherit"
              >
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: "blue" }}
                >
                  <AspectRatioIcon style={{ marginRight: 5 }} /> VER PLANO
                </Button>
                <br />
              </Link>
            )}
            {recordForVer.linkDocumentacion && (
              <Link
                href={recordForVer.linkDocumentacion}
                target="_blank"
                color="inherit"
              >
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: "green" }}
                >
                  <FolderSpecialIcon style={{ marginRight: 5 }} /> VER DOCS
                </Button>
              </Link>
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <h2>
              {" "}
              <MonetizationOnIcon /> Precio desde{" "}
            </h2>
            <div
              style={{
                backgroundColor: "lightgray",
                textAlign: "center",
                padding: 3,
                borderRadius: 10,
              }}
            >
              {recordForVer.precioWeb ? (
                <h1> $ {numberWithCommas(recordForVer.precioWeb)} </h1>
              ) : (
                <h1> Consultar</h1>
              )}
            </div>
            {!recordForVer.Estado.esVendido && recordForVer.Estado.esReservado && (
              <div
                style={{
                  backgroundColor: recordForVer.Estado.color,
                  color: "black",
                  textAlign: "center",
                  padding: 6,
                  borderRadius: 10,
                  margin: 10,
                }}
              >
                {" "}
                <AssignmentLateIcon /> RESERVADO{" "}
              </div>
            )}
            {recordForVer.Estado.esVendido && (
              <div
                style={{
                  backgroundColor: recordForVer.Estado.color,
                  color: "white",
                  textAlign: "center",
                  padding: 6,
                  borderRadius: 10,
                  margin: 10,
                }}
              >
                {" "}
                <AssignmentLateIcon /> VENDIDA{" "}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
