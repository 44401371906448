import React, { useState, useEffect } from "react";
import RichTextEditor from "mui-rte";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import { stateToHTML } from "draft-js-export-html";

import { Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        RichTextEditor: {
            root: {
                marginTop: 20,
                width: "80%"
            },
            editor: {
                border: "1px solid gray" 
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(12),
      height: theme.spacing(6),
      backgroundColor: "lightblue",
      color: "black",
    },
  },
}));

export default function LoteosForm(props) {

  const { addOrEdit, recordForEdit } = props;

  let HTML = "";

  //console.log('Record: ', recordForEdit);

  const classes = useStyles();

  const initialFValues = {
    id: 0,
    nombre: "",
    latitud: "",
    longitud: "",
    linkVideo: "",
    linkPlano: "",
    linkDocumentacion: "",
    fotoPrincipal: "",
    precio: 0,
    precioWeb: 0,
    superficieTotal: 0,
    servLuz: false,
    servAgua: false,
    servGas: false,
    servTelefono: false,
    visibleWeb: false,
    direccion: "",
    observaciones: "",
    descripcionWeb:"",
    EstadoId: '',
    cantManzanas: 0,
    MonedaId: '',
    codigo: '',
  };

  if (recordForEdit) {
    initialFValues.PaiId = recordForEdit.PaiId;
    initialFValues.ProvinciumId = recordForEdit.ProvinciumId;
    initialFValues.LocalidadId = recordForEdit.LocalidadId;
    console.log('Descripcion web: ', recordForEdit.descripcionWeb);
    if (recordForEdit.descripcionWeb) {
      // 1. Convert the HTML
      console.log('HTML: ', HTML);
      const contentHTML = convertFromHTML(recordForEdit.descripcionWeb)
      // 2. Create the ContentState object
      const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
      // 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
      HTML = JSON.stringify(convertToRaw(state))
    }
  } else {
    initialFValues.PaiId = "";
    initialFValues.ProvinciumId = "";
    initialFValues.LocalidadId = "";
  }

  const [guardando, setGuardando] = React.useState(false);
  const [archivo, guardarArchivo] = useState("");
  const [cambiarPrecio, setCambiarPrecio] = useState(false);
  //const [obsWeb, setObsWeb] = useState("");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const obtenerColorEstado = (id) => {
    let color = "white";
    if (id) {
      const item = props.listaEstados.find((item) => item.id === id);
      if (item.color) {
        color = item.color;
      }
    }
    return color;
  };

  const handleInputSoloNumerosChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      e.target.value = onlyNums;
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      e.target.value = number;
    }
    handleInputChange(e);
  };

  const verificaYagrega = (campos, objeto) => {
    campos.forEach((campo) => {
      if (values[campo] || values[campo] === false)
        objeto.append(campo, values[campo]);
    });
    return objeto;
  };

  const handleEditor = (editorState) => {
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    console.log('ROW: ', convertToRaw(editorState.getCurrentContent()));
    console.log('HTML: ', stateToHTML(editorState.getCurrentContent()));
    HTML = stateToHTML(editorState.getCurrentContent());
    //console.log('HTML: ', HTML);
  };
  
  const handleSubmit = (e) => {
    console.log('HTML: ', HTML);
    values.descripcionWeb = HTML;
    e.preventDefault();
    if (values.EstadoId === 0) {
      alert("Por favor seleccione el estado de la propiedad");
      return false;
    }
    if (values.cantManzanas === 0) {
      alert("Por favor ingrese la cantidad de manzanas");
      return false;
    }
    if (validate()) {
      let formData = new FormData();
      formData = verificaYagrega(
        [
          "nombre",
          "latitud",
          "longitud",
          "linkVideo",
          "linkPlano",
          "linkDocumentacion",
          "precio",
          "precioWeb",
          "superficieTotal",
          "servLuz",
          "servAgua",
          "servGas",
          "servTelefono",
          "visibleWeb",
          "direccion",
          "observaciones",
          "descripcionWeb",
          "cantManzanas",
          "LocalidadId",
          "EstadoId",
          "MonedaId",
          "codigo",
        ],
        formData
      );
      if (cambiarPrecio) {
        formData.append("cambiarPrecio", cambiarPrecio);
      }
      if (archivo) {
        formData.append("fotoPrincipal", archivo);
      }
      setGuardando(true);
      addOrEdit(formData, resetForm, values.id).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  const leerArchivo = (e) => {
    guardarArchivo(e.target.files[0]);
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <Controls.Input
                name="nombre"
                label="Nombre"
                value={values.nombre}
                onChange={handleInputChange}
                error={errors.nombre}
                focused={true}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
            </Grid>
            <Grid item xs={12} sm={2}>
              {recordForEdit && recordForEdit.codigo ?
                <h2>
                  Código: {recordForEdit.codigo}
                </h2>
              :
                <Controls.Input
                  name="codigo"
                  label="Código"
                  value={values.codigo}
                  onChange={handleInputChange}
                  error={errors.codigo}
                />
              }
            </Grid>
            <Grid item xs={12} sm={1}>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controls.Input
                name="direccion"
                label="Direccion"
                value={values.direccion}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <div
                style={{
                  backgroundColor: obtenerColorEstado(values.EstadoId),
                  borderRadius: 10,
                  color: "white",
                }}
              >
                <Controls.Select
                  name="EstadoId"
                  label="Estado"
                  value={values.EstadoId}
                  onChange={handleInputChange}
                  options={props.listaEstados.map((item) => {
                    return { id: item.id, title: item.nombre };
                  })}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controls.Input
                name="cantManzanas"
                label="Manzanas (nombres separados por coma)"
                value={values.cantManzanas}
                onChange={handleInputChange}
                multiline
                rows={3}
                rowsMax={3}
              />
              <br />
              {values.cantManzanas.length ? (
                <div className={classes.root}>
                  SIMULACIÓN:
                  {values.cantManzanas.split(",").map((m) => (
                    <Paper
                      key={m}
                      style={{
                        padding: 5,
                        paddingTop: 14,
                        textAlign: "center",
                      }}
                    >
                      {m}
                    </Paper>
                  ))}
                </div>
              ) : (
                <>SIMULACIÓN: {values.cantManzanas}</>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#B4FEB0" }} item xs={12} sm={3}>
              <Controls.Input
                name="latitud"
                label="Latitud"
                value={values.latitud}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#B4FEB0" }} item xs={12} sm={3}>
              <Controls.Input
                name="longitud"
                label="Longitud"
                value={values.longitud}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#FEE4B0" }} item xs={12} sm={4}>
              <Controls.Input
                name="linkVideo"
                label="Link Video"
                value={values.linkVideo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FEE4B0" }} item xs={12} sm={4}>
              <Controls.Input
                name="linkPlano"
                label="Link Plano"
                value={values.linkPlano}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FEE4B0" }} item xs={12} sm={4}>
              <Controls.Input
                name="linkDocumentacion"
                label="Link Documentacion"
                value={values.linkDocumentacion}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Controls.Input
                name="imagen"
                helperText="Seleccione una imagen como principal de la propiedad - No debe superar los 500kb"
                value={values.imagen}
                // onChange={handleInputChange}
                type="file"
                onChange={leerArchivo} 
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Controls.Select
                name="PaiId"
                label="País"
                value={values.PaiId}
                onChange={handleInputChange}
                options={props.listaPaises.map((item) => {
                  return { id: item.id, title: item.nombre };
                })}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              {values.PaiId ? (
                <div>
                  {props.listaProvincias.find(
                    (item) => item.PaiId === values.PaiId
                  ) ? (
                    <Controls.Select
                      name="ProvinciumId"
                      label="Provincia"
                      value={values.ProvinciumId}
                      onChange={handleInputChange}
                      options={props.listaProvincias.map((item) => {
                        if (item.PaiId === values.PaiId) {
                          return { id: item.id, title: item.nombre };
                        } else {
                          return false; // Línea Modificada
                        }
                      })}
                    />
                  ) : (
                    <>
                      {" "}
                      No hay provincias creadas en este pais. Por favor
                      completar antes de continuar.
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={6} sm={4}>
              {values.ProvinciumId ? (
                <div>
                  {props.listaLocalidades.find(
                    (item) => item.ProvinciumId === values.ProvinciumId
                  ) ? (
                    <>
                      <Controls.Select
                        name="LocalidadId"
                        label="Localidad"
                        value={values.LocalidadId}
                        onChange={handleInputChange}
                        options={props.listaLocalidades.map((item) => {
                          if (item.ProvinciumId === values.ProvinciumId) {
                            return { id: item.id, title: item.nombre };
                          } else {
                            return false; // Línea Modificada
                          }
                        })}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      No hay localidades creadas en esta provincia. Por favor
                      completar antes de continuar.
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#FC9D9D" }} item xs={12} sm={3}>
              <Controls.Input
                name="precio"
                label="Precio desde"
                //value={new Intl.NumberFormat().format(values.precio)}
                value={values.precio}
                //onChange={handleInputChange}
                onChange={handleInputSoloNumerosChange}
                prefix="$"
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FC9D9D" }} item xs={12} sm={3}>
              <Controls.Input
                name="precioWeb"
                label="Precio desde web"
                value={values.precioWeb}
                onChange={handleInputSoloNumerosChange}
                prefix="$"
              />
            </Grid>
            <Grid style={{ backgroundColor: "#BFFFE3" }} item xs={12} sm={3}>
               <Controls.Select
                  name="MonedaId"
                  label="Moneda para WEB"
                  value={values.MonedaId}
                  onChange={handleInputChange}
                  options={props.listaMonedas.map((item) => {
                    return { id: item.id, title: item.nombre };
                  })}
                />
            </Grid>
            {recordForEdit &&
              <>
                <Grid style={{ backgroundColor: "#E8CC0C" }} item xs={12} sm={3}>
                  <Controls.Checkbox
                    name="cambioPrecioLotes"
                    label="¿Cambiar en todo el loteo?"
                    value={cambiarPrecio}
                    onChange={() => {
                      console.log('Cambiar precio: ', cambiarPrecio, typeof cambiarPrecio);
                      setCambiarPrecio(!cambiarPrecio);
                    }}
                  />
                </Grid>
              </>
            }
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.Input
                name="superficieTotal"
                label="Superficie total"
                value={values.superficieTotal}
                onChange={handleInputSoloNumerosChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="servLuz"
                label="Luz"
                value={values.servLuz}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="servAgua"
                label="Agua"
                value={values.servAgua}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="servGas"
                label="Gas"
                value={values.servGas}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid style={{ backgroundColor: "#FCF89D" }} item xs={12} sm={2}>
              <Controls.Checkbox
                name="servTelefono"
                label="Teléfono"
                value={values.servTelefono}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                style={{
                  backgroundColor: "lightblue",
                  color: "black",
                  borderRadius: 10,
                  margin: 5,
                }}
                item
                xs={12}
                sm={3}
              >
                <Controls.Checkbox
                  name="visibleWeb"
                  label="Visible en Web"
                  value={values.visibleWeb}
                  onChange={handleInputChange}
                />
              </Grid>
              <Controls.Input
                name="observaciones"
                helperText="Observaciones"
                value={values.observaciones}
                onChange={handleInputChange}
                multiline
                rows={4}
                rowsMax={4}
                error={errors.descripcion}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingBottom: 40, paddingLeft: 20 }} >
              <MuiThemeProvider theme={defaultTheme}>
                <RichTextEditor
                  label="Descripción web aquí..."
                  value={HTML}
                  //onSave={save}
                  onChange={handleEditor}
                  inlineToolbar={true}
                  controls={[
                    "title", 
                    "bold",
                    "italic",
                    "underline",
                    "highlight", 
                    "link", 
                    "bulletList", 
                  ]}
                  toolbarButtonSize="small"
                />
              </MuiThemeProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginTop: 25, textAlign: "right" }}>
              <Controls.Button type="submit" text="Guardar" />
              <Controls.Button
                text="Limpiar"
                color="default"
                onClick={resetForm}
              />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
