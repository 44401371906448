import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, InputAdornment, } from "@material-ui/core";

import { useUserState } from "../../../context/UserContext";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";
import PageHeader from "../../../components/tablas/PageHeader";
import Popup from "../../../components/tablas/Popup";
import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";

import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import RoomIcon from "@material-ui/icons/Room";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Search } from "@material-ui/icons";

import * as PropiedadVentaServices from "../../../services/producto/propiedadVenta";
import * as ReservaPropiedadVentaServices from "../../../services/venta/reservaPropiedadVenta";
import * as FormaPagoServices from "../../../services/venta/formaPago";
import * as MonedaServices from "../../../services/venta/moneda";
import * as UsuarioServices from "../../../services/usuario/usuario";
import * as EstadoServices from "../../../services/producto/estado";
//import * as ComisionesUsuariosService from "../../../services/contable/comisionesUsuarios";
//import * as VentasPropiedadesServices from "../../../services/venta/ventasPropiedades"

import Permisos from "../../../config/permisos";

import PropiedadReservaVisor from "../../reserva/propiedades/ReservasPropiedadesVisor";
import EventosReservas from "../../venta/EventosLista";
import HistorialReservaLista from "../../venta/HistorialReservaLista";
import DetalleBaja from "../../venta/propiedades/DetalleBajaProp";
//import DetalleVenta from "../../venta/propiedades/DetalleVentaProp";
import ConfirmacionVentaProp from "../../venta/propiedades/ConfirmacionVentaProp";


const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "propietario", label: "Propietario" },
  { id: "cliente", label: "Cliente" },
  { id: "vendedor", label: "Vendedor" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function PropiedadesVenta() {
  
  const { token, rolCodigo } = useUserState();

  const [registros, setRegistros] = useState({});
  //const [registroPropiedades, setRegistroPropiedades] = useState({});
  //const [registroProvincias, setRegistroProvincias] = useState({});
  //const [registroLocalidades, setRegistroLocalidades] = useState({});
  const [registroEstado, setRegistroEstado] = useState({});
  const [registroFormaPago, setRegistroFormaPago] = useState({});
  const [registroMoneda, setRegistroMoneda] = useState({});
  const [registroUsuarios, setRegistroUsuarios] = useState({});
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => { return items; }, });
  const [verPendientes, setVerPendientes] = useState(true);
  const [textoBuscar, setTextoBuscar] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupEventos, setOpenPopupEventos] = useState(false);
  const [openPopupHistorial, setOpenPopupHistorial] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "", });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", });
  const [openPopupBaja, setOpenPopupBaja] = useState(false);
  const [openPopupVenta, setOpenPopupVenta] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    handleSearch();
  }, [textoBuscar]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cargando) {
      setCargando(true);

      if (verPendientes) {
        ReservaPropiedadVentaServices.getAllActivos(token, rolCodigo).then(
          (res) => {
            setRegistros(res);
            handleSearch();
          }
        );
      } else {
        ReservaPropiedadVentaServices.getCompleto(token, rolCodigo).then(
          (res) => {
            setRegistros(res);
            handleSearch();
          }
        );
      }
      FormaPagoServices.getAllItems(token).then((res) => {
        setRegistroFormaPago(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setRegistroMoneda(res);
      });
      UsuarioServices.getAllItems(token).then((res) => {
        //console.log('Usuarios: ', res);
        setRegistroUsuarios(res);
      })
      EstadoServices.getAllItems(token).then((res) => {
        setRegistroEstado(res);
      });
    } else {
      handleSearch();
    }
  }, [cargando, registros, verPendientes]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const handleSearch = () => {
    let target = textoBuscar ? textoBuscar : "";
    target = target.toLowerCase();
    setFilterFn({
      fn: (items) => {
        if (target === "") return items;
        else
          return items.filter((x) =>
            x.PropiedadesVentum.nombre.toLowerCase().includes(target)
          );
      },
    });
  };

  const addOrEdit = async (registroItem, resetForm, id) => {
    console.log('Registro en addrEdit: ', registroItem);
    if (id === 0) {
      try {
        await PropiedadVentaServices.insert(registroItem, token);
      } catch (err) {
        return false;
      }
    } else {
      console.log('Llega a update', registroItem);
      try {
        await PropiedadVentaServices.update(registroItem, id, token);
      } catch (err) {
        return false;
      }
    }
    if (registroItem.vendido) {
      console.log('Llega a Venta...........', registroItem);
      // try {
      //   await VentasPropiedadesServices.insert(registroItem, token);
      // } catch (err) {
      //   return false;
      // }
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    //setRegistroPropiedades(PropiedadVentaServices.getAllItems(token));
  };

  const addOrEditVentaBaja = async (registroItem, resetForm, id) => {
    let estado = 0;
    let cambio = {};
    let PropId = 0;
    if (registroItem.vendido) {
      console.log('Entra en venta: ', id, registroItem);
      try {
        PropId = registroItem.PropiedadesVentumId;
        estado = registroEstado.find((estado) => estado.esVendido === true);
        cambio.EstadoId = estado.id;
        await PropiedadVentaServices.updateReserva(cambio, PropId, token)
        await ReservaPropiedadVentaServices.confirmaVenta(registroItem, id, token);
        // let usuarios = registroItem.usuarios;
        // if (usuarios && usuarios.length > 0) {
        //   for (let i=0; i<usuarios.length; i++) {
        //     let data = {
        //       ReservasPropiedadVentumId: id,
        //       UsuarioId: usuarios[i].id,
        //       importe: usuarios[i].comision,
        //     }
        //     await ComisionesUsuariosService.insert(data, token);
        //   }
        // }
      } catch (err) {
        return false;
      }
    } else {
      //console.log('Entra en baja: ', id, registroItem);
      try {
        estado = registroEstado.find((estado) => estado.esDisponible === true);
        cambio.EstadoId = estado.id;
        PropId = registroItem.PropiedadId;
        console.log('Datos: ', registroItem, PropId, cambio);
        await PropiedadVentaServices.updateReserva(cambio, PropId, token)
        await ReservaPropiedadVentaServices.confirmaBaja(registroItem, id, token);
      } catch (err) {
        return false;
      }
    }

    resetForm();
    //setRecordForEdit(null);
    setOpenPopupVenta(false);
    setOpenPopupBaja(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
    //setRegistroPropiedades(PropiedadVentaServices.getAllItems(token));
  };

  return (
    <>
      <PageHeader
        title="RESERVAS DE PROPIEDADES EN VENTA"
        subTitle="Gestión de propiedes"
        icon={<RoomIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Buscar"
            value={textoBuscar}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setTextoBuscar(e.target.value);
            }}
          />
          <Controls.Checkbox
            name="verPendientes"
            label="Ver pendientes"
            value={verPendientes}
            onChange={() => {
              setVerPendientes(!verPendientes);
              setCargando(false);
              handleSearch();
            }}
          />
        </Toolbar>
        {registros.length ? (
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <>
                  {item.PropiedadesVentum && (
                    <TableRow key={item.id}>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.PropiedadesVentum.nombre}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.PropiedadesVentum.Propietario.nombre}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.Cliente.nombre} {item.Cliente.apellido}
                      </TableCell>
                      <TableCell style={{ fontWeight: "500" }}>
                        {item.Usuario.nombre} {item.Usuario.apellido}
                      </TableCell>
                      <TableCell>
                        <Controls.ActionButton
                          color="primary"
                          onClick={() => {
                            openInPopup(item);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            setRecordForEdit(item);
                            setOpenPopupEventos(true);
                          }}
                        >
                          <AccessAlarmIcon fontSize="small" />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          style={{ backgroundColor: "orange" }}
                          onClick={() => {
                            setRecordForEdit(item);
                            setOpenPopupHistorial(true);
                          }}
                        >
                          <MenuBookIcon fontSize="small" />
                        </Controls.ActionButton>
                        {(!item.vendido && !item.cancelado) &&
                          <>
                            <Controls.ActionButton
                              style={{ backgroundColor: "red", color: "white" }}
                              onClick={() => {
                                setRecordForEdit(item);
                                setOpenPopupBaja(true);
                              }}
                            >
                              <ThumbDownIcon fontSize="small" />
                            </Controls.ActionButton>
                            <Controls.ActionButton
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                              onClick={() => {
                                setRecordForEdit(item);
                                setOpenPopupVenta(true);
                              }}
                            >
                              <ThumbUpIcon fontSize="small" />
                            </Controls.ActionButton>
                          </>
                        }
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <div style={{ margin: 30 }}>
            {" "}
            <CircularProgress size={26} className={classes.loginLoader} />  <h2>No hay registros</h2>
          </div>
        )}
        <TblPagination />
      </Paper>
      <Popup
        title="VISOR DE RESERVA"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <PropiedadReservaVisor
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
        />
      </Popup>
      <Popup
        title="VISOR DE EVENTOS - PROPIEDADES"
        openPopup={openPopupEventos}
        setOpenPopup={setOpenPopupEventos}
      >
        <EventosReservas
          token={token}
          puedeAgregar={Permisos(rolCodigo, "VENTAS.EVENTOS.AGREGAR")}
          puedeVerGestion={Permisos(rolCodigo, "VENTAS.EVENTOS.VER")}
          recordForEdit={recordForEdit}
          tipoEvento="pventa"
        />
      </Popup>
      <Popup
        title="VISOR DE HISTORIAL - PROPIEDADES"
        openPopup={openPopupHistorial}
        setOpenPopup={setOpenPopupHistorial}
      >
        <HistorialReservaLista
          token={token}
          puedeAgregar={Permisos(rolCodigo, "VENTAS.EVENTOS.AGREGAR")}
          puedeVerGestion={Permisos(rolCodigo, "VENTAS.EVENTOS.VER")}
          recordForEdit={recordForEdit}
          tipoProducto="pventa"
        />
      </Popup>
      <Popup
        title="DETALLE DE BAJA"
        openPopup={openPopupBaja}
        setOpenPopup={setOpenPopupBaja}
      >
        <DetalleBaja
          token={token}
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          listaUsuarios={registroUsuarios}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditVentaBaja}
        />
      </Popup>
      {/* <Popup
        title="DETALLE DE VENTA"
        openPopup={openPopupVenta}
        setOpenPopup={setOpenPopupVenta}
      >
        <DetalleVenta
          token={token}
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          listaUsuarios={registroUsuarios}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditVentaBaja}
        />
      </Popup>       */}
      <Popup
        title="CONFIRMACIÓN DE VENTA"
        openPopup={openPopupVenta}
        setOpenPopup={setOpenPopupVenta}
      >
        <ConfirmacionVentaProp
          token={token}
          recordFormaPago={registroFormaPago}
          recordMoneda={registroMoneda}
          listaUsuarios={registroUsuarios}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditVentaBaja}
        />
      </Popup>      
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
