import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem, } from "@material-ui/core";
import { Menu as MenuIcon, Person as AccountIcon, ArrowBack as ArrowBackIcon, } from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";
import { Link } from 'react-router-dom';

// components
import {  Typography } from "../Wrappers";
//import Notification from "../Notification/Notification";
//import { email } from '../../context/UserContext';

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar, } from "../../context/LayoutContext";
import { useUserDispatch, signOut, useUserState } from "../../context/UserContext";

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var { email } = useUserState();
  // local
 
  //var [notificationsMenu, setNotificationsMenu] = useState(null);
 
  var [profileMenu, setProfileMenu] = useState(null);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          SISTEMA DE GESTIÓN INMOBILIARIA
        </Typography>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
             {email}
            </Typography>
            
          </div>
          <Link to="/app/perfil" className={classes.profileMenuItem}> 
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Editar datos personales
          </MenuItem>
          </Link>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Salir
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
