/*
ADMIN : ADM
MARKETING  : MKT
VENDEDOR: VEN
GERENTE: GER
GERENTE ADM : GAD
SECREATARIA: SEC
CONTABILIDAD: CON
*/

const data = [
  {
    nombre: "ADMIN",
    acceso: ["ADM"],
  },
  {
    nombre: "TABLAS",
    acceso: ["ADM", "GAD", "CON"],
  },
  {
    nombre: "CONFIG",
    acceso: ["ADM", "GAD", "GER"],
  },
  {
    nombre: "CLIENTES.CLIENTES",
    acceso: ["ADM", "MKT", "VEN", "GER", "GAD", "SEC", "CON"],
  },
  {
    nombre: "CLIENTES.CLIENTES.AGREGAR",
    acceso: ["ADM", "VEN", "GER", "GAD", "SEC", "MKT"],
  },
  {
    nombre: "CLIENTES.CLIENTES.EDITAR",
    acceso: ["ADM", "VEN", "GER", "GAD", "MKT"],
  },
  {
    nombre: "CLIENTES.CLIENTES.FUENTES",
    acceso: ["ADM", "GAD", "MKT"],
  },
  {
    nombre: "CLIENTES.CLIENTES.REPORTAR",
    acceso: ["ADM", "VEN", "GER", "GAD"],
  },
  {
    nombre: "CLIENTES.ASIGNAR",
    acceso: ["ADM", "GAD", "MKT"],
  },
  {
    nombre: "CLIENTES.AUTO_SIGNAR_ALCREAR",
    acceso: ["VEN", "GER"],
  },
  {
    nombre: "CLIENTES.VERTODOS",
    acceso: ["ADM", "GAD", "SEC", "CON"],
  },
  {
    nombre: "CLIENTES.FUENTE",
    acceso: ["ADM", "GAD"],
  },
  {
    nombre: "CLIENTES.HISTORIAL",
    acceso: ["ADM", "VEN", "GER", "GAD", "SEC", "CON"],
  },
  {
    nombre: "CLIENTES.PRIMERCONTACTO",
    acceso: ["ADM", "VEN", "GER", "GAD"],
  },
  {
    nombre: "CLIENTES.HISTORIAL.VERPESTAÑAGESTION",
    acceso: ["ADM", "GER", "GAD", "SEC"],
  },
  {
    nombre: "CLIENTES.HISTORIAL.AGREGAR",
    acceso: ["ADM", "VEN", "GER", "GAD", "SEC", "CON"],
  },

  {
    nombre: "CLIENTES.COINCIDENCIA.TELEFONO",
    acceso: ["ADM", "GAD", "MKT"],
  },

  {
    nombre: "UBICACION",
    acceso: ["ADM", "GAD"],
  },
  {
    nombre: "USUARIOS",
    acceso: ["ADM", "GAD"],
  },
  //----------------------------PRODUCTOS-------------------------------------
  {
    nombre: "PRODUCTOS.PROP_VENTA",
    acceso: ["ADM", "VEN", "GER", "GAD", "SEC", "CON"],
  },
  {
    nombre: "PRODUCTOS.PROP_VENTA_AGREGA_EDITA",
    acceso: ["ADM", "GER", "GAD"],
  },
  {
    nombre: "PRODUCTOS.LOTES_AGREGA_EDITA",
    acceso: ["ADM", "GER", "GAD"],
  },
  {
    nombre: "PRODUCTOS.ESTADO",
    acceso: ["ADM", "GER"],
  },
  {
    nombre: "PRODUCTOS.PROPIETARIOS",
    acceso: ["ADM", "GER", "GAD"],
  },
  {
    nombre: "PRODUCTOS.PROPIETARIOS.CREAR",
    acceso: ["ADM", "GER", "GAD"],
  },
  {
    nombre: "PRODUCTOS.PROPIETARIOS.VERTODOS",
    acceso: ["ADM", "GER", "GAD", "SEC", "CON"],
  },
  {
    nombre: "PRODUCTOS.PROPIETARIOS.AGREGAR",
    acceso: ["ADM", "GER", "GAD"],
  },
  {
    nombre: "PRODUCTOS.PROPIETARIOS.EDITAR",
    acceso: ["ADM", "GER", "GAD"],
  },
  //----------------------------VENTAS-------------------------------------
  {
    nombre: "VENTAS.ASOCIADOS",
    acceso: ["ADM", "GER", "GAD", "CON"],
  },
  {
    nombre: "VENTAS.ASOCIADOS.AGREGAR",
    acceso: ["ADM", "GER", "GAD"],
  },
  {
    nombre: "VENTAS.ASOCIADOS.EDITAR",
    acceso: ["ADM", "GER"],
  },
  {
    nombre: "VENTAS.ASOCIADOS.ABONAR",
    acceso: ["ADM", "CON"],
  },
  {
    nombre: "VENTAS.RESERVAS.OBSGERENTE",
    acceso: ["ADM", "GER"],
  },
  {
    nombre: "VENTAS.RESERVAS.OBSSECRETARIA",
    acceso: ["ADM", "SEC"],
  },
  {
    nombre: "VENTAS.RESERVAS",
    acceso: ["ADM", "GER", "GAD", "SEC", "CON", "VEN"],
  },
  {
    nombre: "VENTAS.RESERVAS.VERTODAS",
    acceso: ["ADM", "GER", "GAD", "SEC", "CON"],
  },
  {
    nombre: "VENTAS.RESERVAS.CONFIRMACION",
    acceso: ["ADM", "GER", "GAD", "SEC" ],
  },
  {
    nombre: "VENTAS.RESERVAS.HISTORIAL",
    acceso: ["ADM", "GER", "GAD", "SEC" ],
  },
  {
    nombre: "VENTAS.EVENTOS.VER",
    acceso: ["ADM", "GER", "GAD", "SEC", "CON"],
  },
  {
    nombre: "VENTAS.EVENTOS.AGREGAR",
    acceso: ["ADM", "GER", "GAD", "SEC", "CON"],
  },
  {
    nombre: "VENTAS.VENTAS",
    acceso: ["ADM", "SEC", "CON", "GAD"],
  },
  {
    nombre: "VENTAS.PREJUDICIAL",
    acceso: ["ADM", "GAD"],
  },
  {
    nombre: "UTILES.CALCULADORA.INTERES",
    acceso: ["ADM", "GER", "GAD"],
  },
  {
    nombre: "UTILES.CALCULADORA.CENTRO",
    acceso: ["ADM", "GER", "GAD"],
  },
  //----------------------------GALERIA-------------------------------------
  {
    nombre: "GALERIA.CRUD",
    acceso: ["ADM", "GER", "GAD", "MKT"],
  },
  {
    nombre: "WEB.GENERAL",
    acceso: ["ADM", "GAD", "MKT"],
  },
  {
    nombre: "WEB.POTENCIALCLIENTE",
    acceso: ["ADM", "GAD", "MKT"],
  },
  {
    nombre: "WEB.BOLETIN",
    acceso: ["ADM", "MKT"],
  },
  //----------------------------RESERVA-------------------------------------
  {
    nombre: "RESERVA.VENTA",
    acceso: ["ADM", "CON"],
  },
  //----------------------------CAJA GENERAL-------------------------------------
  {
    nombre: "CAJA.GENERAL",
    acceso: ["ADM", "CON"],
  },
  //----------------------------GRAFICOS-------------------------------------
  {
    nombre: "GRAFICO.CLIENTESXVENDEDORES",
    acceso: ["ADM", "GAD", "CON", "MKT", "GER"],
  },
  
];

function Permisos(rolCodigo, item) {
  const elemento = data.filter((el) => el.nombre === item);
  //console.log("***** ENTONTRO1: ", elemento);
  try {
    const permiso = elemento[0].acceso.filter((el) => el === rolCodigo);
    //console.log("***** ENTONTRO2: ", permiso);
    if (permiso.length > 0) {
      return true;
    }
  } catch {}

  return false;
}

export default Permisos;
