import GestorAxios from "../../config/axios";

export async function getAllItems(token) {
  let data = {};

  await GestorAxios.get("/contables/asociadosxcuotasxventaslotes", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
/*
export async function filtrarSimilares(token, dataBuscar) {
  let data = {};

  await GestorAxios.post("/contables/asociadosxcuotasxventaslotes/filtrarSimilares", dataBuscar, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
*/
export async function insert(data, token) {
  let dataReturn = {};
  //console.log('Datos desde service: ', data);

  await GestorAxios.post("/contables/asociadosxcuotasxventaslotes/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(`/contables/asociadosxcuotasxventaslotes/actualizar/${data.id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cuotasxSocio(AsociadoId, token) {
  let dataReturn = {};

  await GestorAxios.get(`/contables/asociadosxcuotasxventaslotes/cuotasxsocio/${AsociadoId}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}
