import GestorAxios from "../../config/axios";

export async function getAllItems(token) {
  let data = {};

  await GestorAxios.get("/productos/loteos", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

// multipart upload - envia archivo
export async function insert(data, token) {
  let dataReturn = {};

  await GestorAxios.post("/productos/loteos/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, id, token) {
  let dataReturn = {};

  await GestorAxios.put(`/productos/loteos/actualizar/${id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function agregarAGaleria(data, id, token) {
  let dataReturn = {};
  await GestorAxios.post(`/productos/loteos/agregaragaleria/${id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function filtrar(token, id) {
  let data = {};

  await GestorAxios.get("/productos/loteos/filtrar/id/" + id, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}

export async function cargarGaleria(id, token) {
  let dataReturn = {};
  await GestorAxios.get(`/productos/loteos/cargargaleria/${id}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

/*export async function cargarUno(url, token) {
  console.log('En services: ', url);
  let dataReturn = {};
  await GestorAxios.get(`/productos/loteos/cargaruno/${url}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}*/

export async function guardarGaleria(id, galeria, token) {
  let dataReturn = {};
  await GestorAxios.post(`/productos/loteos/guardargaleria/${id}/`, galeria, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}


export async function updateDescripcionWeb(data, id, token) {
  let dataReturn = {};

  await GestorAxios.put(`/productos/loteos/actualizardescripcionweb/${id}/`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function encontrar(token, id) {
  let data = {};

  await GestorAxios.get("/productos/loteos/encontrar/" + id + "/", {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    data = res.data;
  });

  return data;
}
