import React, { useState, useEffect } from "react";

import * as ClienteServices from "../../services/cliente/cliente";
import * as FuenteServices from "../../services/cliente/fuente";

import { useUserState } from "../../context/UserContext";

import AsignaAVendedor from "./AsignaAVendedor";

import Notification from "../../components/tablas/Notification";
import ConfirmDialog from "../../components/tablas/ConfirmDialog";
import Popup from "../../components/tablas/Popup";
import useTable from "../../components/tablas/useTable";
import Controls from "../../components/tablas/controls/Controls";
import Tarjeta from "../utiles/Tarjeta";

import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
} from "@material-ui/core";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const cabecera = [
  { id: "nombre", label: "Nombre" },
  { id: "observaciones", label: "Observaciones" },
  { id: "fuente", label: "Fuente" },
  { id: "telefono", label: "Teléfono" },
  { id: "email", label: "Email" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#E3FFE0",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    pageContent: {
      margin: theme.spacing(5),
      padding: theme.spacing(3),
    },
    searchInput: {
      width: "75%",
    },
    newButton: {
      position: "absolute",
      right: "10px",
    },
  },
}));

export default function ClienteSinAsignarVendedor() {
  const { token } = useUserState();
  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({  // eslint-disable-line
    fn: (items) => {
      return items;
    },
  });

  const [openPopupAsigna, setOpenPopupAsigna] = useState(false);

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [registros, setRegistros] = useState({});
  const [fuentes, setFuentes] = useState({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(registros, cabecera, filterFn);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      ClienteServices.getAllItemsSinAsignar(token).then((res) => {
        setRegistros(res);
      });
      FuenteServices.getAllItems(token).then((res) => {
        setFuentes(res);
      });
    }
  }, [cargando, registros, fuentes]); // eslint-disable-line react-hooks/exhaustive-deps

  const openInPopupAsigna = (item) => {
    setRecordForEdit(item);
    setOpenPopupAsigna(true);
  };

  const addOrEditAsigna = async (registroItem, resetForm) => {
    try {
      await ClienteServices.updateAsignaVendedor(registroItem, token);
    } catch (err) {
      return false;
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopupAsigna(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    setRegistros(ClienteServices.getAllItems(token));
  };

  return (
    <>
      {registros.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="CLIENTES SIN ASIGNAR"
                icon={
                  <DoubleArrowIcon
                    style={{ fontSize: 40, color: "red", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={registros}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#E3FFE0" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <h2>
                        <DoubleArrowIcon
                          style={{
                            fontSize: 40,
                            color: "red",
                            marginBottom: -10,
                          }}
                        />{" "}
                        CLIENTES SIN ASIGNAR{" "}
                        <DoubleArrowIcon
                          style={{
                            fontSize: 40,
                            color: "red",
                            marginBottom: -10,
                          }}
                        />{" "}
                      </h2>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: 10 }}>
                      <Controls.Button
                        text="Ocultar"
                        onClick={() => setVisible(!visible)}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                      <TableRow key={item.id}>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.apellido} - {item.nombre}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.observaciones}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "500" }}>
                          {item.FuenteId && fuentes && fuentes.length ? (
                            <>
                              {
                                fuentes.find(
                                  (fuente) => fuente.id === item.FuenteId
                                ).nombre
                              }{" "}
                            </>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.telefono}{" "}
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {" "}
                          {item.email}{" "}
                        </TableCell>
                        <TableCell>
                          <Controls.ActionButton
                            color="primary"
                            style={{ backgroundColor: "#B40000" }}
                            onClick={() => {
                              openInPopupAsigna(item);
                            }}
                          >
                            <DoubleArrowIcon fontSize="small" />
                          </Controls.ActionButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Popup
        title="Asignación de cliente"
        openPopup={openPopupAsigna}
        setOpenPopup={setOpenPopupAsigna}
      >
        <AsignaAVendedor
          token={token}
          recordForEdit={recordForEdit}
          addOrEdit={addOrEditAsigna}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
