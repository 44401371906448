import React from "react";

import { Grid, Paper, Typography, Button } from "@material-ui/core";

import { Link } from "react-router-dom";

import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "../../logo-blanco.png";

export default function Error() {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={logo} alt="logo" />
        <Typography variant="h3" color="white" className={classes.logotypeText}>
          Grupo del Valle Inmobiliaria
        </Typography>
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant="h1"
          color="primary"
          className={classnames(classes.textRow, classes.errorCode)}
        >
          404
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          Oops. Pagina no encontrada, vuelva hacia una dirección válida
        </Typography>
        <Typography
          variant="h6"
          color="text"
          colorBrightness="secondary"
          className={classnames(classes.textRow, classes.safetyText)}
        >
          La dirección a la que intenta acceder no existe. Si el problema persiste contacte con soporte técnico.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          size="large"
          className={classes.backButton}
        >
          Volver
        </Button>
      </Paper>
    </Grid>
  );
}
