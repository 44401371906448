import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import { useUserState } from "../../context/UserContext";

import * as LotesXLoteoServices from "../../services/producto/lotesXLoteo";
import * as LoteosServices from "../../services/producto/loteos";

import { obtenerMes, convertirNumeroaTexto } from "../utiles/Miscelaneas";

import fondo from "../../assets/imagenes";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 20,
    margin: 20,
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  rotulo:{
     backgroundColor: 'lightgray', padding: 5, borderRadius: 10,
     textDecoration: "none",
     fontWeight: 'bold',
     margin: 5, 
  },
  page:{
      fontSize:  'auto',   /* auto is the initial value */
      margin: '0mm',  /* this affects the margin in the printer settings */
  },
  html:{
      backgroundColor: '#FFFFFF',
      margin: 0,  /* this affects the margin on the html before sending to printer */
  },
  body:{
      border: "solid 1px blue",
      margin: "10mm 15mm 10mm 15mm" /* margin you want for the content */
  }
}));

function ReservaParaImprimir(props) {
  const { 
    itemsImprimir,
    productoSeleccionado,
    lotesComprados,
    listaFormasPago,
    listaMonedas,
    listaLocalidades,
    listaNacionalidades,
    listaProvincias,
    listaEstadoCivil,
    listaLoteos,
    listaUsuarios,
    usuario,
    setOpenPopup
} = props;

  console.log('Para Imprimir: ', itemsImprimir, listaNacionalidades);

  const { token } = useUserState();

  const classes = useStyles();

  const [isPrinting, setIsPrinting] = useState(false);
  const [manzanas, setManzanas] = useState([]);
  const [lotes, setLotes] = useState([]);
  
  const fecha = new Date();
  const fechaEstimadaFirma = new Date(itemsImprimir.fechaEstimadaFirma);
  
  const buscarObjeto = (id, datos) => {
    let ret = {};
    if (datos.length) {
      if (id === 0) {
        ret = undefined;
      } else {
        const itemEncontrado = datos.find((item) => item.id === id);
        if (itemEncontrado) {
          ret = itemEncontrado;
        }
      }
    }
    return ret;
  };

  const loteo = buscarObjeto(productoSeleccionado.LoteoId, listaLoteos);
  const localidadLoteos = buscarObjeto(loteo.LocalidadId, listaLocalidades);
  const provinciaLoteos = buscarObjeto(localidadLoteos.ProvinciumId, listaProvincias);
  const vendedor = buscarObjeto(usuario, listaUsuarios);
  
  const descripcionLote = (producto, resto) => {
    let resultado = "";
    let cantidad = resto && resto.length ? resto.length : 0;
    console.log('Cantidad de lotes: ', cantidad);
    resultado = `el lote "${producto.nombre}" de la manzana "${manzanas[parseInt(producto.manzana)]}" que mide ${convertirNumeroaTexto(producto.frente)} (${producto.frente}) metros de frente, ${convertirNumeroaTexto(producto.fondo)} (${producto.fondo}) metros de fondo, ${convertirNumeroaTexto(producto.lateral)} (${producto.lateral}) metros de lado y ${convertirNumeroaTexto(producto.opuesto)} (${producto.opuesto}) metros en el lado opuesto, lo que hace una superficie total de ${convertirNumeroaTexto(producto.superficieTotal)} (${producto.superficieTotal}) mts2, `;
    for (let i = 0; i < cantidad; i++) {
      if (i + 1 === cantidad) {
        resultado = resultado + ' y ';
      }
      resultado = resultado + `el lote "${resto[i].nombre}" de la manzana "${manzanas[parseInt(resto[i].manzana)]}" que mide ${resto[i].frente? convertirNumeroaTexto(resto[i].frente) : 0} (${resto[i].frente? resto[i].frente : 0}) metros de frente, ${resto[i].fondo ? convertirNumeroaTexto(resto[i].fondo) : 0} (${resto[i].fondo ? resto[i].fondo: 0}) metros de fondo, ${resto[i].lateral ? convertirNumeroaTexto(resto[i].lateral): 0} (${resto[i].lateral ? resto[i].lateral: 0}) de lado y ${resto[i].opuesto ? convertirNumeroaTexto(resto[i].opuesto): 0} (${resto[i].opuesto ? resto[i].opuesto : 0}) en el lado opuesto, lo que hace una superficie total de ${resto[i].superficieTotal ? convertirNumeroaTexto(resto[i].superficieTotal) : 0} (${resto[i].superficieTotal ? resto[i].superficieTotal : 0}) mts2 `;    
    }
    resultado += 'lote'
    if (cantidad > 0) {
      resultado += 's todos'
    }
    resultado += ` de la localidad de ${localidadLoteos.nombre}, Provincia de ${provinciaLoteos.nombre}`
    return resultado;
  }

  const todasFormaPago = (item) => {
    let resultado = "";
    if (item.FormaPagoId1) {
      resultado = `${buscarObjeto(item.FormaPagoId1, listaFormasPago).nombre} la cantidad de ${buscarObjeto(item.MonedaId1, listaMonedas).simbolo}${new Intl.NumberFormat().format(item.importe1)}`;
      if (item.FormaPagoId3) {
        resultado += ', ';
      } else if (item.FormaPagoId2) {
        resultado += ' y '
      } 
      if (item.FormaPagoId2) {
        resultado += `${buscarObjeto(item.FormaPagoId2, listaFormasPago).nombre} la cantidad de ${buscarObjeto(item.MonedaId2, listaMonedas).simbolo}${new Intl.NumberFormat().format(item.importe2)}`;
        if (item.FormaPagoId4) {
          resultado += ', ';
        } else if (item.FormaPagoId3) {
          resultado += ' y '
        } 
        if (item.FormaPagoId3) {
          resultado += `${buscarObjeto(item.FormaPagoId3, listaFormasPago).nombre} la cantidad de ${buscarObjeto(item.MonedaId3, listaMonedas).simbolo}${new Intl.NumberFormat().format(item.importe3)}`;
          if (item.FormaPagoId4) {
            resultado += ` y ${buscarObjeto(item.FormaPagoId4, listaFormasPago).nombre} la cantidad de ${buscarObjeto(item.MonedaId4, listaMonedas).simbolo}${new Intl.NumberFormat().format(item.importe4)}`;
          }
        }
      }
    }
    return resultado;
  }

  const cuotas = (item) => {
    let resultado = "";
    if (item.importeAnio1) {
      resultado = `1 a la 12, pesos ${convertirNumeroaTexto(item.importeAnio1)} ($${new Intl.NumberFormat().format(item.importeAnio1)})`;
      if (item.importeAnio3) {
        resultado += ', ';
      } else if (item.importeAnio2) {
        resultado += ' y '
      } 
      if (item.importeAnio2) {
        resultado += `13 a la 24, pesos ${convertirNumeroaTexto(item.importeAnio2)} ($${new Intl.NumberFormat().format(item.importeAnio2)})`;
        if (item.importeAnio4) {
          resultado += ', ';
        } else if (item.importeAnio3) {
          resultado += ' y '
        }
        if (item.importeAnio3) {
          resultado += `25 a la 36, pesos ${convertirNumeroaTexto(item.importeAnio3)} ($${new Intl.NumberFormat().format(item.importeAnio3)})`;
          if (item.importeAnio4) {
            resultado += ` y 37 a la 48, pesos ${convertirNumeroaTexto(item.importeAnio4)} ($${new Intl.NumberFormat().format(item.importeAnio4)})`;
          }
        }
      }
    }
    return resultado;
  }

  const asociado = (item) => {
    let resultado = "";
    if (item.asociadoNombre || item.asociadoApellido) {
      resultado = ` – y ${item.asociadoSexo === 'H' ? "el Sr." : item.asociadoSexo === 'M' ? "la Sra." : "la Srita."} ${item.asociadoApellido}, ${item.asociadoNombre}, D.N.I.: ${item.asociadoDNI}, nacionalidad ${buscarObjeto(item.asociadoNacionalidad, listaNacionalidades).nombre}, domiciliad${item.asociadoSexo === 'H' ? "o" : "a"} en ${item.asociadoDireccion} – ${buscarObjeto(item.AsociadoLocalidadId, listaLocalidades).nombre} – ${buscarObjeto(item.AsociadoProvinciumId, listaProvincias).nombre}, quien dice ser ${buscarObjeto(item.AsociadoEstadoCivilId, listaEstadoCivil).nombre}`
    }
    return resultado;
  }

  // function printpage() {
  //   let oldStr = window.document.body.innerHTML; // Obtener el contenido del cuerpo
  //   let start = "<! - startprint ->"; // Iniciar la impresión del logotipo, 17 caracteres
  //   let end = "<! - endprint ->"; // fin de imprimir logo
  //   let newStr = oldStr.substr (oldStr.indexOf (start) + 17); // interceptar el contenido después de comenzar a imprimir el logotipo
  //   newStr = newStr.substring (0, newStr.indexOf (end)); // Interceptar el contenido entre la marca de inicio de impresión y la marca de finalización de impresión
  //   window.document.body.innerHTML = newStr; // Asignar el contenido especificado que debe imprimirse en el cuerpo
  //   window.print (); // Llame a la función de impresión del navegador para imprimir el área especificada
  //   window.document.body.innerHTML = oldStr; // reemplaza el cuerpo con el contenido original
  // }

  useEffect(() => {
    LoteosServices.encontrar(token, productoSeleccionado.LoteoId).then((res) => {
      setManzanas(res.cantManzanas.split(","));
    });
    if (lotesComprados) {
      let datos = {};
      let ids = [];
      for (let i = 0; i < lotesComprados.length; i++) {
        ids.push(lotesComprados[i].id);
      }
      datos.ids = ids;
      LotesXLoteoServices.encontrar(token, datos).then((res) => {
        setLotes(res);
      });  
    }
  }, []); // eslint-disable-line

  useEffect(
    function () {
      if (isPrinting) {
        window.print();
        setIsPrinting(false);
      }
    },
    [isPrinting],
  );

  const printItems = () => {
    setIsPrinting(true);
  };

  return (
    <div className={classes.html}>
      {itemsImprimir && (
        <>
          <div>
            {!isPrinting && (
              <>
                <Button
                  variant="contained"
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    printItems();
                  }}
                >
                  IMPRIMIR
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenPopup(false);
                  }}
                >
                  CERRAR
                </Button>
              </>
            )}
            <Grid container>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <h2>RESERVA</h2>
              </Grid>
            </Grid>
          </div>
          <h4>{`En el presente acto ${itemsImprimir.sexo === 'H' ? "el Sr." : itemsImprimir.sexo === 'M' ? "la Sra." : "la Srita."} ${itemsImprimir.apellido}, ${itemsImprimir.nombre}, D.N.I.: ${itemsImprimir.DNI}, nacionalidad ${buscarObjeto(itemsImprimir.nacionalidad, listaNacionalidades).nombre}, domiciliad${itemsImprimir.sexo === 'H' ? "o" : "a"} en ${itemsImprimir.direccion} – ${buscarObjeto(itemsImprimir.LocalidadId, listaLocalidades).nombre} – ${buscarObjeto(itemsImprimir.ProvinciumId, listaProvincias).nombre}, quien dice ser ${buscarObjeto(itemsImprimir.EstadoCivilId, listaEstadoCivil).nombre} ${asociado(itemsImprimir)}.`}</h4>
          <h4>{`Entrega en concepto de reserva ${todasFormaPago(itemsImprimir)}, por ${descripcionLote(productoSeleccionado, lotes)}, a el Sr./la Sra. ${vendedor.nombre} ${vendedor.apellido} por orden y cuenta del dueño de la Inmobiliaria Grupo del Valle, el Sr. Lucas Matías Mella. Av. Giagetto 771 y manifiesta que ha dado la misma para asegurar la concreción de la compra, el inmueble  quedaría por reservado.`}</h4>
          <h4>{`Faltaría por entregar la suma de pesos ${convertirNumeroaTexto(itemsImprimir.entrega)} ($${new Intl.NumberFormat().format(itemsImprimir.entrega)}) en el momento de la firma; cuotas de la siguiente manera, ${cuotas(itemsImprimir)}.`}</h4> 
          <h4>{`Los gastos administrativos y de comisión inmobiliaria (honorarios) serán de pesos ${convertirNumeroaTexto(itemsImprimir.comisionGastos)} ($${new Intl.NumberFormat().format(itemsImprimir.comisionGastos)}) que se abonarán al momento de la firma. La fecha estimada de firma será el ${fechaEstimadaFirma.getDate()} del mes de ${obtenerMes(fechaEstimadaFirma.getMonth())}del año ${fechaEstimadaFirma.getFullYear()}.`}</h4> 
          <h4>{`A los ${fecha.getDate()} días del mes de ${obtenerMes(fecha.getMonth())}del año ${fecha.getFullYear()} en plena conformidad se firman dos ejemplares de un mismo tenor y a un solo efecto.`}</h4>
        </>
      )}
      {/* endprint */}
    </div>
  );
}
 

export default ReservaParaImprimir;
