import GestorAxios from "../../config/axios";
//import Permisos from "../../config/permisos";

export async function getAllItems(token, rolCodigo) {
  let data = {};

  await GestorAxios.get("/contables/cuotasxventaslotes", {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      data = res.data;
    })
    .catch((e) => {
      console.log("Error: ", e.message);
    });

  return data;
}

export async function filtrar(data, token) {
  let datos = {};

  await GestorAxios.post(
    `/contables/cuotasxventaslotes/filtrar/${data.key}/${data.value}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    datos = res.data;
  });

  return datos;
}

export async function insert(data, token) {
  let dataReturn = {};
  console.log('En services ventas: ', data);

  await GestorAxios.post("/contables/ventaslocuotasxventaslotestes/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(
    `/contables/cuotasxventaslotes/actualizar/${data.id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function updateFechaVencimiento(data, token) {
  let dataReturn = {};

  await GestorAxios.put(
    `/contables/cuotasxventaslotes//actualizarfechavencimiento/${data.VentasLoteId}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cuotasxVenta(id, token) {
  let dataReturn = {};
  //console.log(id, token);
  await GestorAxios.get(
    `/contables/cuotasxventaslotes/cuotasxventa/${id}/`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cuotasxFecha(fecha, token) {
  let dataReturn = {};
  //console.log(fecha, token);
  await GestorAxios.get(
    `/contables/cuotasxventaslotes/cuotasxfecha/${fecha.dia}/${fecha.mes}/${fecha.anio}/`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cuotasPagadas(data, token) {
  let dataReturn = {};
  //console.log(data, token);
  await GestorAxios.get(
    `/contables/cuotasxventaslotes/cuotaspagadas/${data.fechaDesde}/${data.fechaHasta}/`, 
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cuotasAdeudadas(data, token) {
  let dataReturn = {};
  //console.log(data, token);
  await GestorAxios.get(
    `/contables/cuotasxventaslotes/cuotasadeudadas/${data.fechaDesde}/${data.fechaHasta}/`, 
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cuotasVigentes(data, token) {
  let dataReturn = {};
  //console.log(data, token);
  await GestorAxios.get(
    `/contables/cuotasxventaslotes/cuotasvigentes/${data.fechaDesde}/${data.fechaHasta}/`, 
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cuotasParaJudicializar(token) {
  let dataReturn = {};
  //console.log(data, token);
  await GestorAxios.get(
    `/contables/cuotasxventaslotes/cuotasparajudicializar/`, 
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}

export async function cuotasPreJudicializadas(token) {
  let dataReturn = {};
  //console.log(data, token);
  await GestorAxios.get(
    `/contables/cuotasxventaslotes/cuotasprejudicializadas/`, 
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  ).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}
