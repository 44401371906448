import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import DashboardIcon from "@material-ui/icons/Dashboard";
import CreateIcon from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/Close";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";

import Popup from "../../../components/tablas/Popup";
import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";

import LoteForm from "./LoteXLoteoForm";
import LoteVisor from "./LoteXLoteoVisor";

import Permisos from "../../../config/permisos";

import Controls from "../../../components/tablas/controls/Controls";

import * as LoteXLoteoServices from "../../../services/producto/lotesXLoteo";
import * as MonedaServices from "../../../services/venta/moneda";


export default function LotesXLoteo(props) {
  const { recordForLoteo, listaEstados, listaAsociados, token, rolCodigo } = props;

  const [cargando, setCargando] = useState(false);
  const [registros, setRegistros] = useState({});
  const [cantManzanas, setCantManzanas] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openForm, setOpenForm] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [openPopupVer, setOpenPopupVer] = useState(false);
  const [recordForVer, setRecordForVer] = useState(null);
  const [registroMoneda, setRegistroMoneda] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirm, setConfirm] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const reservaGeneradaOk = () => {
    setOpenPopupVer(false);
    setNotify({
      isOpen: true,
      message: "Reserva generada correctamente",
      type: "success",
    });
    setCargando(false);
  };

  const abrirPopup = (item) => {
    setRecordForEdit(item);
    setOpenForm(true);
  };

  const confirmaEliminarLote = (item) => {
    setConfirm({
      isOpen: true,
      title: "¿Confirma eliminar el lote " + item.nombre + " ?",
      subTitle: "Ésta acción no podrá revertirse",
      onConfirm: () => {
        eliminarLote(item);
      },
    });
  };

  const eliminarLote = async (item) => {
    setConfirm({
      isOpen: false,
      title: "¿Confirma eliminar el lote " + item.nombre + " ?",
      subTitle: "Ésta acción no podrá revertirse",
      onConfirm: () => {},
    });
    const ok = await LoteXLoteoServices.borrar(item.id, token);
    if (ok) {
      setNotify({
        isOpen: true,
        message: "Borrado correctamente",
        type: "success",
      });
      LoteXLoteoServices.getAllItems(token, recordForLoteo.id).then((res) => {
        setRegistros(res);
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Error al intentar borrar",
        type: "error",
      });
    }
  };

  const abrirClonePopup = (item) => {
    item.id = 0;
    setRecordForEdit(item);
    setOpenForm(true);
  };

  const verLote = (item) => {
    if (!editMode) {
      setRecordForVer(item);
      setOpenPopupVer(true);
    }
  };

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      let tempCantManzanas = recordForLoteo.cantManzanas.split(",");
      setCantManzanas(tempCantManzanas);
      LoteXLoteoServices.getAllItems(token, recordForLoteo.id).then((res) => {
        console.log('Lotes: ', res);
        setRegistros(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setRegistroMoneda(res);
      });
    }
  }, [cargando, registros, cantManzanas]); // eslint-disable-line react-hooks/exhaustive-deps

  const addOrEdit = async (registroItem, resetForm, id) => {
    if (id === undefined || id === 0) {
      try {
        await LoteXLoteoServices.insert(registroItem, token);
      } catch (err) {
        return false;
      }
    } else {
      try {
        await LoteXLoteoServices.update(registroItem, id, token);
        if (registroItem.cambiarPrecio) {
          LoteXLoteoServices.updatePreciosManzana(registroItem, token)
        }
      } catch (err) {
        return false;
      }
    }
    resetForm();
    setRecordForEdit(null);
    setOpenForm(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    setCargando(false);
    setRegistros(LoteXLoteoServices.getAllItems(token, recordForLoteo.id));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <h1> {recordForLoteo.nombre}</h1>
          </Grid>
          {!openForm && (
            <Grid item xs={12} sm={4}>
              {editMode && (
                <Controls.ActionButton
                  color="primary"
                  style={{ backgroundColor: "blue" }}
                  onClick={() => {
                    abrirPopup({});
                  }}
                >
                  <AddToPhotosIcon
                    style={{ marginRight: 10 }}
                    fontSize="small"
                  />{" "}
                  NUEVO
                </Controls.ActionButton>
              )}
              {Permisos(rolCodigo, "PRODUCTOS.LOTES_AGREGA_EDITA") && (
                <Controls.ActionButton
                  color="primary"
                  style={{ backgroundColor: editMode ? "red" : "green" }}
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  <CreateIcon style={{ marginRight: 10 }} fontSize="small" />
                  {!editMode ? <>IR A MODO EDICIÓN</> : <>FINALIZAR EDICIÓN</>}
                </Controls.ActionButton>
              )}
            </Grid>
          )}
          {openForm ? (
            <div>
              <h2>Formulario lote</h2>
              <LoteForm
                closeForm={() => {
                  setOpenForm(false);
                }}
                listaAsociados={listaAsociados}
                listaEstados={listaEstados}
                recordForEdit={recordForEdit}
                loteo={recordForLoteo}
                addOrEdit={addOrEdit}
                listaManzanas={cantManzanas}
                listaModendas={registroMoneda}
                lotesxLoteo={registros}
              />
            </div>
          ) : (
            <>
              {registros.length ? (
                <>
                  {cantManzanas.map((m, index) => (
                    <>
                      <Grid item xs={12} sm={12} key={index} >
                        <div style={{ fontSize: 40 }}>
                          {" "}
                          <DashboardIcon style={{ marginBottom: -2 }} /> {m}
                        </div>
                      </Grid>
                      {registros.map((lote) => {  // eslint-disable-line
                        if (index === parseInt(lote.manzana)) {
                          return (
                            <Grid
                              key={lote.id + lote.nombre + lote.manzana + index}
                              onClick={() => verLote(lote)}
                              style={{
                                backgroundColor: lote.Estado
                                  ? lote.Estado.color
                                  : "gray",
                                margin: 5,
                                padding: 5,
                                borderRadius: 10,
                                textAlign: "center",
                                color: "white",
                                fontSize: 10,
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                              item
                              xs={12}
                              sm={1}
                            >
                              <div>
                                <div style={{ fontSize: 16 }}>
                                  {lote.nombre}{" "}
                                </div>
                                <br />
                                <div style={{ fontSize: 14 }}>
                                  {lote.Moneda ? 
                                    lote.Moneda.simbolo + ' ' + new Intl.NumberFormat().format(lote.precio)
                                    :
                                    's/d ' + new Intl.NumberFormat().format(lote.precio)
                                  }
                                </div>
                              </div>
                              {editMode && (
                                <>
                                  <Controls.ActionButton
                                    color="primary"
                                    style={{ backgroundColor: "green" }}
                                    onClick={() => {
                                      abrirPopup(lote);
                                    }}
                                  >
                                    <CreateIcon style={{}} fontSize="small" />
                                  </Controls.ActionButton>
                                  <Controls.ActionButton
                                    color="primary"
                                    style={{ backgroundColor: "orange" }}
                                    onClick={() => {
                                      abrirClonePopup(lote);
                                    }}
                                  >
                                    <ChromeReaderModeIcon
                                      style={{}}
                                      fontSize="small"
                                    />
                                  </Controls.ActionButton>
                                  <Controls.ActionButton
                                    color="primary"
                                    style={{ backgroundColor: "red" }}
                                    onClick={() => {
                                      confirmaEliminarLote(lote);
                                    }}
                                  >
                                    <CloseIcon style={{}} fontSize="small" />
                                  </Controls.ActionButton>
                                </>
                              )}
                            </Grid>
                          );
                        }
                      })}
                      <Grid item xs={12} sm={12} key={index + "divider"} >
                        <hr />
                      </Grid>
                    </>
                  ))}
                </>
              ) : (
                <>No hay lotes creados</>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Popup
        title="VISOR LOTE"
        openPopup={openPopupVer}
        setOpenPopup={setOpenPopupVer}
      >
        <LoteVisor
          onSave={reservaGeneradaOk}
          recordForVer={recordForLoteo}
          recordLoteForVer={recordForVer}
          cantManzanas={cantManzanas}
          lotesListaCompleta={registros}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirm} setConfirmDialog={setConfirm} />
    </Grid>
  );
}
