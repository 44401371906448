import React, { useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

export default function UsuarioForm(props) {
  const { addOrEdit, recordForEdit } = props;

  const initialFValues = {
    id: 0,
    nombre: "",
    apellido: "",
    telefono: "",
    email: "",
    bloqueado: false,
    password: "",
    password2: "",
  };

  if (recordForEdit) {
    initialFValues.RolId = recordForEdit.RolId;
  } else {
    initialFValues.RolId = undefined;
  }

  const [guardando, setGuardando] = React.useState(false);
  const [confirma, setConfirma] = React.useState(false);
  const [cambiaPassword, setCambiaPassword] = React.useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";

    if ("apellido" in fieldValues)
      temp.apellido = fieldValues.apellido ? "" : "Dato requerido";

    if ("telefono" in fieldValues)
      temp.telefono = fieldValues.telefono ? "" : "Dato requerido";

    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "Dato requerido";

    if ("RolId" in fieldValues)
      temp.RolId = fieldValues.RolId !== undefined ? "" : "Dato requerido";

    if (!recordForEdit || (recordForEdit && cambiaPassword)) {
      if ("password" in fieldValues)
        temp.password = fieldValues.password ? "" : "Dato requerido";

      if (values.password2) {
        if ("password" in fieldValues)
          temp.password = temp.password2 =
            values.password2.length > 4
              ? ""
              : "Mínimo 4 caracteres para la contraseña";
      }

      if ("password" in fieldValues)
        temp.password2 = temp.password2 =
          values.password === values.password2
            ? ""
            : "Las contraseñas deben ser iguales";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      values.cambiaPassword = cambiaPassword;
      setGuardando(true);
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
        }
      });
    }
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
      setConfirma(true);
    }
  }, [recordForEdit, confirma]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4}>
              <Controls.Input
                name="nombre"
                label="Nombre"
                value={values.nombre}
                onChange={handleInputChange}
                error={errors.nombre}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Controls.Input
                name="apellido"
                label="Apellido"
                value={values.apellido}
                onChange={handleInputChange}
                error={errors.apellido}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Controls.Input
                name="telefono"
                label="Teléfono"
                value={values.telefono}
                onChange={handleInputChange}
                error={errors.telefono}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Input
                name="email"
                label="email"
                type="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Select
                name="RolId"
                label="Rol en la empresa"
                value={values.RolId}
                onChange={handleInputChange}
                options={props.listaRoles.map((item) => {
                  return { id: item.id, title: item.nombre };
                })}
                error={errors.RolId}
              />
            </Grid>
            {recordForEdit && (
              <>
                <Controls.Checkbox
                  name="cambiaPass"
                  label="Modifica contraseña ?"
                  value={cambiaPassword}
                  onChange={() => {
                    setCambiaPassword(!cambiaPassword);
                  }}
                />
              </>
            )}
            {(!recordForEdit || (recordForEdit && cambiaPassword)) && (
              <>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    name="password"
                    label="Contraseña"
                    type="password"
                    value={values.password}
                    onChange={handleInputChange}
                    error={errors.password}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    name="password2"
                    label="Repita la contraseña"
                    type="password"
                    value={values.password2}
                    onChange={handleInputChange}
                    error={errors.password2}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!guardando ? (
            <div style={{ marginTop: 25, textAlign: "right" }}>
              <Controls.Button type="submit" text="Guardar" />
              <Controls.Button
                text="Limpiar"
                color="default"
                onClick={resetForm}
              />
            </div>
          ) : (
            <div style={{ margin: 25, textAlign: "right" }}>
              <CircularProgress size={26} />
            </div>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
