import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import * as HistorialServices from "../../services/cliente/historial";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  id: 0,
  fecha: fc,
  descripcion: "",
  ClienteId: undefined,
};

export default function HistorialLista(props) {

  const { addOrEdit, recordForEdit, puedeAgregar, puedeVerGestion } = props;

  const [guardando, setGuardando] = React.useState(false);
  const [registros, setRegistros] = useState({});
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [cargando, setCargando] = useState(false);
  const [nuevo, setNuevo] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fecha =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("descripcion" in fieldValues)
      temp.descripcion = fieldValues.descripcion ? "" : "Dato requerido";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const nuevoForm = () => {
    setNuevo(!nuevo);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.descripcion) {
      if (values.descripcion.length > 4) {
        if (validate()) {
          setGuardando(true);
          addOrEdit(values, resetForm).then((e) => {
            if (e === false) {
              setGuardando(false);
              alert(
                "No se pudo completar la operación, revise los datos e intente nuevamente"
              );
            }
          });
        }
      } else {
        alert("La descripción debe contener un mínimo de 5 letras");
      }
    } else {
      alert("Complete una descripción válida");
    }
  };

  const stringToColour = (index) => {
    /*  var hash = 0;
          for (var i = 0; i < str.length; i++) {
              hash = str.charCodeAt(i) + ((hash << 5) - hash);
          }
          var colour = '#';
          for (var i = 0; i < 3; i++) {
              var value = (hash >> (i * 8)) & 0xFF;
              colour += ('00' + value.toString(16)).substr(-2);
          }*/
    var colour = "#E8E8E8";
    if (index % 2 === 0) colour = "#DDDEDD";
    return colour;
  };

  const convertirFechaToStr = (fechaTurno) => {
    const texto =
      fechaTurno.substr(8, 2) +
      "/" +
      fechaTurno.substr(5, 2) +
      "/" +
      fechaTurno.substr(0, 4);
    return texto;
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ClienteId: recordForEdit.id,
      });
    }

    if (!cargando) {
      setCargando(true);
      HistorialServices.getAllItems(props.token, recordForEdit.id).then(
        (res) => {
          setRegistros(res);
        }
      );
    }
  }, [cargando, registros]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {recordForEdit && (
            <h2>
              {" "}
              {recordForEdit.nombre} {recordForEdit.apellido} -
              {recordForEdit.telefono}{" "}
            </h2>
          )}
        </Grid>
        {puedeAgregar && (
          <Grid style={{ textAlign: "right" }} item xs={6}>
            <Controls.Button
              text={nuevo ? "-" : "+"}
              color="primary"
              onClick={nuevoForm}
            />
          </Grid>
        )}
      </Grid>
      {nuevo && (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fecha"
                      maxDate={new Date()}
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      helperText="Fecha"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    name="descripcion"
                    helperText="Descripción de la acción realizada"
                    value={values.descripcion}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(0, 254);
                    }}
                    rowsMax={4}
                    error={errors.descripcion}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: 25, textAlign: "right" }}>
                {!guardando ? (
                  <Controls.Button type="submit" text="Guardar" />
                ) : (
                  <CircularProgress size={26} />
                )}
                <br /> <br />
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
      {registros ? (
        <>
          {registros.length ? (
            <>
              <AppBar position="static">
                <Tabs
                  value={value}
                  indicatorColor="secondary"
                  textColor="white"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                >
                  <Tab label="VENDEDOR" />
                  {puedeVerGestion && <Tab label="GESTIÓN" />}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {registros.map((item, index) => (
                  <>
                    {item.Cliente.vendedorId === item.UsuarioId ? (
                      <div
                        style={{
                          backgroundColor: stringToColour(index),
                          padding: 10,
                          borderRadius: 10,
                          margin: 2,
                        }}
                      >
                        {convertirFechaToStr(item.fecha)} <br />
                        {item.descripcion} <br />
                        <div style={{ textAlign: "right", fontSize: 11 }}>
                          {item.Usuario.nombre} {item.Usuario.apellido} -
                          {convertirFechaToStr(item.createdAt)}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {registros.map((item, index) => (
                  <>
                    {item.Cliente.vendedorId !== item.UsuarioId ? (
                      <div
                        style={{
                          backgroundColor: stringToColour(index),
                          padding: 10,
                          borderRadius: 10,
                          margin: 2,
                        }}
                      >
                        {convertirFechaToStr(item.fecha)} <br />
                        {item.descripcion} <br />
                        <div style={{ textAlign: "right", fontSize: 11 }}>
                          {item.Usuario.nombre} {item.Usuario.apellido} -
                          {convertirFechaToStr(item.createdAt)}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </TabPanel>
            </>
          ) : (
            <> Sin registros de seguimiento</>
          )}
        </>
      ) : (
        <>Sin registros de seguimiento</>
      )}
    </div>
  );
}
