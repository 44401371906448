import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
//  NotificationsNone as NotificationsIcon,
//  FormatSize as TypographyIcon,
//  FilterNone as UIElementsIcon,
//  BorderAll as TableIcon,
//  QuestionAnswer as SupportIcon,
//  LibraryBooks as LibraryIcon,
//  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

import PaymentIcon from '@material-ui/icons/Payment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
//import DoneIcon from '@material-ui/icons/Done';

import DashboardIcon from '@material-ui/icons/Dashboard';
import HouseIcon from '@material-ui/icons/House';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import RoomIcon from '@material-ui/icons/Room';
import MapIcon from '@material-ui/icons/Map';
import PinDropIcon from '@material-ui/icons/PinDrop';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import HistoryIcon from '@material-ui/icons/History';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BookIcon from '@material-ui/icons/Book';
import CloudIcon from '@material-ui/icons/Cloud';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import RedoIcon from '@material-ui/icons/Redo';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ReplyIcon from '@material-ui/icons/Reply';
import FavoriteIcon from '@material-ui/icons/Favorite';
//import CardMembershipIcon from '@material-ui/icons/CardMembership';
//import SettingsIcon from '@material-ui/icons/Settings';
//import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

import { useUserState } from '../../context/UserContext';
import Permisos from '../../config/permisos';


// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

let structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
];


function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  const { rolCodigo } = useUserState();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  structure = [
    { id: 0, label: "Mesa de entrada", link: "/app/dashboard", icon: <HomeIcon /> },
  ];

  structure.push(
    { id: 2, type: "divider" },
    { id: 4, type: "title", label: "CLIENTE" },
  );

  if (Permisos(rolCodigo, 'CLIENTES.CLIENTES')) {
    structure.push({ id: 10, label: "Clientes", link: "/app/cliente/cliente", icon: <EmojiPeopleIcon /> });

  }

  if (Permisos(rolCodigo, 'WEB.GENERAL')) {
    structure.push(
      { id: 12, label: "Consulta Web", link: "/app/web/consultaweb", icon: <CloudIcon /> })
  }

  if (Permisos(rolCodigo, 'CLIENTES.HISTORIAL')) {
    structure.push(
      { id: 14, label: "Historial", link: "/app/cliente/historial", icon: <HistoryIcon /> })
  }


  if (Permisos(rolCodigo, 'PRODUCTOS.PROP_VENTA')) {
    structure.push(
      { id: 20, type: "divider" },
      { id: 22, type: "title", label: "PRODUCTOS" },
      { id: 24, label: "Loteos", link: "/app/productos/loteos", icon: <DashboardIcon /> },
      { id: 26, label: "Props. en venta", link: "/app/productos/pventa", icon: <HouseIcon /> },

    )
  }

  if (Permisos(rolCodigo, 'PRODUCTOS.PROPIETARIOS')) {
    structure.push(
      { id: 28, label: "Propietarios", link: "/app/producto/propietario", icon: <HomeWorkIcon /> }
    )
  }


  if (Permisos(rolCodigo, 'VENTAS.ASOCIADOS')) {
    structure.push(
      { id: 30, type: "divider" },
      { id: 32, type: "title", label: "VENTAS" },
      { id: 34, label: "Socios", link: "/app/venta/asociado", icon: <SupervisedUserCircleIcon /> }
    )
  }

  if (Permisos(rolCodigo, 'VENTAS.RESERVAS')) {
    structure.push(
      { id: 36, label: "Reservas P.Venta", link: "/app/venta/propreservada", icon: <LibraryBooksIcon /> },
      { id: 38, label: "Reservas Lotes", link: "/app/venta/lotesreservado", icon: <BookIcon /> },
    )
  }

  if (Permisos(rolCodigo, 'VENTAS.VENTAS')) {
    structure.push(
      { id: 40, label: "Lotes Vendidos", link: "/app/venta/ventaslotes", icon: <MonetizationOnIcon /> },
      { id: 42, type: "divider" },
      { id: 44, type: "title", label: "CONTABLE" },
      { id: 46, label: "Cuotas", link: "/app/contable/cuotas", icon: <VerticalSplitIcon /> },
      { id: 48, label: "Cheques", link: "/app/contable/cheque", icon: <AccountBalanceWalletIcon /> },
      { id: 50, label: "Transferencia", link: "/app/contable/transferencia", icon: <RedoIcon /> },
    )
  }

  if (Permisos(rolCodigo, 'CAJA.GENERAL')) {
    structure.push(
      { id: 52, type: "divider" },
      { id: 54, type: "title", label: "CAJA" },
      { id: 56, label: "Caja Diaria", link: "/app/caja/cajadiaria", icon: <AllInboxIcon /> },
      // { id: 58, label: "Mercado Pago", link: "/app/caja/mercadopago", icon: <CardMembershipIcon /> },
      // { id: 50, label: "Transferencia", link: "/app/contable/transferencia", icon: <RedoIcon /> },
    )
  }

  if (Permisos(rolCodigo, 'TABLAS')) {
    structure.push(

      { id: 90, type: "divider" },
      {
        id: 100,
        label: "TABLAS",
        link: "/app/tablas",
        icon: <ClearAllIcon />,
        children: [

          { id: 102, type: "title", label: "ADMIN" },
          { id: 104, label: "Usuarios", link: "/app/usuario", icon: <PeopleAltIcon /> },
          { id: 106, label: "Recuperar", link: "/app/recuperar", icon: <ReplyIcon /> },

          { id: 110, type: "title", label: "CLIENTES" },
          { id: 112, label: "Fuente", link: "/app/tablas/cliente/fuente", icon: <TurnedInNotIcon /> },
          { id: 112, label: "Estado Civil", link: "/app/tablas/cliente/estadocivil", icon: <FavoriteIcon /> },

          { id: 120, type: "title", label: "PRODUCTOS" },
          { id: 122, label: "Estados", link: "/app/tablas/producto/estado", icon: <EqualizerIcon /> },

          { id: 130, type: "title", label: "UBICACIÓN" },
          { id: 132, label: "Pais", link: "/app/tablas/ubicacion/pais", icon: <MapIcon /> },
          { id: 134, label: "Provincia", link: "/app/tablas/ubicacion/provincia", icon: <PinDropIcon /> },
          { id: 136, label: "Localidad", link: "/app/tablas/ubicacion/localidad", icon: <RoomIcon /> },
          { id: 140, type: "title", label: "VENTAS" },
          { id: 142, label: "Forma de Pago", link: "/app/tablas/venta/fpago", icon: <PaymentIcon /> },
          { id: 144, label: "Moneda", link: "/app/tablas/venta/moneda", icon: <AttachMoneyIcon /> },
          { id: 146, type: "title", label: "CONTABLE" },
          { id: 148, label: "Banco", link: "/app/tablas/contable/banco", icon: <AccountBalanceIcon /> },
          { id: 150, label: "Sucursal", link: "/app/tablas/contable/bancosuc", icon: <BusinessIcon /> },
          { id: 152, label: "Tarjeta", link: "/app/tablas/contable/tarjetacredito", icon: <CreditCardIcon /> },
          { id: 154, type: "title", label: "CAJA" },
          { id: 156, label: "Origen", link: "/app/tablas/caja/origen", icon: <TripOriginIcon /> },
        ],
      });
  }
/*
  if (Permisos(rolCodigo, 'CONFIG')) {
    structure.push(

      { id: 150, type: "divider" },
      {
        id: 152,
        label: "CONFIGS",
        link: "/app/config",
        icon: <SettingsIcon />,
        children: [

          { id: 101, type: "title", label: "CALCULADORA" },
          { id: 102, label: "Porcentaje", link: "/app/config/calculadora", icon: <DynamicFeedIcon /> },

        ],
      });
  }
*/


  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
