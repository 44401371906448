import React, { useEffect, useState } from "react";

import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Controls from "../../components/tablas/controls/Controls";
import { useForm } from "../../components/tablas/useForm";

import { useUserState } from "../../context/UserContext";

export default function ChequeContenido(props) {
  const {
    addOrEdit,
    recordForEdit,
    listaBancos,
    listaSucursales,
    listaMonedas,
    cliente,
    numero,
  } = props;

  console.log('Props: ', props);
  const { userId } = useUserState();

  const [guardando, setGuardando] = useState(false);
  const [fechaEmision, setFechaEmision] = useState(new Date());
  const [fechaPago, setFechaPago] = useState(new Date());
  const [tipoCheque, setTipoCheque] = useState("terceros");

  const initialFValues = {
    id: 0,
    numeroCheque: 0,
    fechaEmision: new Date(),
    fechaPago: new Date(),
    destinatario: "",
    librador: "",
    importe: 0,
    tipoCheque: "",
    endosado: false,
    linkComprobante: "",
    observaciones: "",
    CiienteId: "",
    MonedaId: "",
    UsuarioId: "",
    BancoSucursalId: "",
    numero: 0,
};
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("numeroCheque" in fieldValues)
      temp.numeroCheque = fieldValues.numeroCheque ? "" : "Dato requerido";
    
    if ("BancoSucursalId" in fieldValues)
      temp.BancoSucursalId = fieldValues.BancoSucursalId ? "" : "Dato requerido";

    if ("importe" in fieldValues)
      temp.importe = fieldValues.importe ? "" : "Dato requerido";
      
    if ("MonedaId" in fieldValues)
      temp.MonedaId = fieldValues.MonedaId ? "" : "Dato requerido";

    console.log('Temp: ', temp);
      
    setErrors({
      ...temp,
    });
    
    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleDateEmisionChange = (date) => {
    setFechaEmision(date);
    const f = new Date(date);
    values.fechaConsulta =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const handleDatePagoChange = (date) => {
    setFechaPago(date);
    const f = new Date(date);
    values.fechaConsulta =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const handleRadioChange = (event) => {
    setTipoCheque(event.target.value);
  };

  const Guardar = () => {
    //e.preventDefault();
    values.ClienteId = cliente.id; 
    values.numero = numero;
    values.UsuarioId = userId
    console.log('En chequeform: ', values);
    if (validate()) {
      setGuardando(true);
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper elevation={0}>
      <Grid container>
        <Grid item xs={4}>
          <Controls.Input
            name="librador"
            label="Librador"
            value={values.librador}
            onChange={handleInputChange}
            error={errors.librador}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Input
            name="destinatario"
            label="Destinatario"
            value={values.destinatario}
            onChange={handleInputChange}
            error={errors.destinatario}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Input
            name="numeroCheque"
            label="Número Cheque"
            value={values.numeroCheque}
            onChange={handleInputChange}
            error={errors.numeroCheque}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Select
            name="BancoId"
            label="Banco"
            value={values.BancoId}
            onChange={handleInputChange}
            options={listaBancos.map((item) => {
              return { id: item.id, title: item.nombre };
            })}
            error={errors.BancoId}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Select
            name="BancoSucursalId"
            label="Sucursal"
            value={values.BancoSucursalId}
            onChange={handleInputChange}
            options={listaSucursales.map((item) => {
              return { id: item.id, title: item.nombre };
            })}
            error={errors.BancoSucursalId}
          />
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="fechaEmision"
              maxDate={new Date()}
              format="dd/MM/yyyy"
              value={fechaEmision}
              onChange={handleDateEmisionChange}
              helperText="Fecha de emisión"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="fechaPago"
              maxDate={new Date()}
              format="dd/MM/yyyy"
              value={fechaPago}
              onChange={handleDatePagoChange}
              helperText="Fecha de pago"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3}>
          <Controls.Input
            name="importe"
            label="Importe"
            value={values.importe}
            onChange={handleInputChange}
            error={errors.importe}
          />
        </Grid>
        <Grid item xs={3}>
          <Controls.Select
            name="MonedaId"
            label="Moneda"
            value={values.MonedaId}
            onChange={handleInputChange}
            options={listaMonedas.map((item) => {
              return { id: item.id, title: item.nombre };
            })}
            error={errors.BancoId}
          />
        </Grid>
        <Grid item xs={4}>
          <RadioGroup
            aria-label="tipo"
            name="propio"
            value={tipoCheque}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="propio"
              control={<Radio />}
              label="Cheque Propio"
            />
            <FormControlLabel
              value="terceros"
              control={<Radio />}
              label="Cheque Terceros"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={4}>
          <Controls.Checkbox
            name="endosado"
            label="Endosado"
            value={values.endosado}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Input
            name="linkComprobante"
            label="Comprobante"
            value={values.linkComprobante}
            onChange={handleInputChange}
            error={errors.linkComprobante}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="observaciones"
            label="Observaciones"
            value={values.observaciones}
            onChange={handleInputChange}
            error={errors.observaciones}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
            <>
              {!guardando ? (
                <div style={{ marginTop: 25, textAlign: "right" }}>
                  <Controls.Button 
                    text="Guardar"
                    onClick={() => Guardar()}
                  />
                  <Controls.Button
                    text="Limpiar"
                    color="default"
                    onClick={resetForm}
                  />
                </div>
              ) : (
                  <></>
              )}
            </>
        </Grid>
      </Grid>
    </Paper>
  );
}
