import React, { useEffect, useState } from "react";

import { Grid, CircularProgress, } from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import BuscarCliente from "../utiles/BuscarCliente";

export default function TransferenciaForm(props) {
  
  const { addOrEdit, recordForEdit, listaBancos, listaSucursales, cliente, listaMonedas } = props;
  
  const [guardando, setGuardando] = useState(false);
  const [fechaTransaccion, setFechaTransaccion] = useState(false);
  const [clienteEncontrado, setClienteEncontrado] = useState({});
  const [continuar, setContinuar] = useState(false);
  
  const initialFValues = {
    id: 0,
    ClienteId: 0,
    BancoSucursalId: 0,
    MonedaId: 0,
    cuentaOrigen: "",
    cuentaDestino: "",
    fechaEmision: new Date(),
    fechaImpacto: new Date(),
    importe: 0,
    numeroComprobante: 0,
    linkComprobante: "",
    observaciones: "",
  };

  
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("cuentaOrigen" in fieldValues)
      temp.cuentaOrigen = fieldValues.cuentaOrigen ? "" : "Dato requerido";
    
    if ("cuentaDestino" in fieldValues)
      temp.cuentaDestino = fieldValues.cuentaDestino ? "" : "Dato requerido";
      
    if ("BancoSucursalId" in fieldValues)
      temp.BancoSucursalId = fieldValues.BancoSucursalId ? "" : "Dato requerido";

    if ("importe" in fieldValues)
      temp.importe = fieldValues.importe ? "" : "Dato requerido";
      
    if ("MonedaId" in fieldValues)
      temp.MonedaId = fieldValues.MonedaId ? "" : "Dato requerido";

    console.log('Temp: ', temp);
      
    setErrors({
      ...temp,
    });
    
    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
   };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
  useForm(initialFValues, true, validate);
  
  const handleDateEmisionChange = (date) => {
    setFechaTransaccion(date);
    const f = new Date(date);
    values.fechaConsulta =
    f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };
  
  const handleSubmit = (e) => {
    if (cliente && cliente.id) values.ClienteId = cliente.id; 
    if (clienteEncontrado && clienteEncontrado.id) values.ClienteId = clienteEncontrado.id;
    e.preventDefault();
    if (validate()) {
      console.log('Post validation');
      setGuardando(true);
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        {recordForEdit && recordForEdit.id ? (
          <Grid item xs={4}>
            <h3 style={{ marginLeft: 10 }}>
              CLIENTE: {recordForEdit.Cliente.nombre} {recordForEdit.Cliente.apellido}
            </h3>
          </Grid>
        ) : (
          <>
            {!continuar ? (
              <>
                <Grid
                  item
                  xs={12}
                  style={{ border: "1px solid", borderRadius: 10, padding: 20 }}
                >
                  <BuscarCliente
                    setClienteEncontrado={setClienteEncontrado}
                    datos="parciales"
                    continuar={continuar}
                    setContinuar={setContinuar}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item xs={4}>
              {clienteEncontrado && clienteEncontrado.id ? (
                <>
                  <h3 style={{ marginLeft: 10 }}>
                    CLIENTE: {clienteEncontrado.nombre} {clienteEncontrado.apellido}
                  </h3>
                </>
              ) : (
                <>
                  <h2>No hay cliente asignado</h2>
                </>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={4}>
          <Controls.Input
            name="cuentaOrigen"
            label="Cuenta Origen"
            value={values.cuentaOrigen}
            onChange={handleInputChange}
            error={errors.cuentaOrigen}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Input
            name="cuentaDestino"
            label="Cuenta Destino"
            value={values.cuentaDestino}
            onChange={handleInputChange}
            error={errors.cuentaDestino}
          />
        </Grid>
        <Grid item xs={3}>
          <Controls.Select
            name="BancoId"
            label="Banco"
            value={values.BancoId}
            onChange={handleInputChange}
            options={listaBancos.map((item) => {
              return { id: item.id, title: item.nombre };
            })}
            error={errors.BancoId}
          />
        </Grid>
        {values.BancoId ?
          <Grid item xs={3}>
            <Controls.Select
              name="BancoSucursalId"
              label="Sucursal"
              value={values.BancoSucursalId}
              onChange={handleInputChange}
              options={listaSucursales.filter((item) => item.BancoId === values.BancoId).map((item) => {
                return { id: item.id, title: item.nombre };
              })}
              error={errors.BancoSucursalId}
            />
          </Grid>
          :
          <Grid item xs={3}>
          </Grid>
        }
        <Grid item xs={3}>
          <Controls.Input
            name="numeroComprobante"
            label="Número Comprobante"
            value={values.numeroComprobante}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="fechaEmision"
              maxDate={new Date()}
              format="dd/MM/yyyy"
              value={fechaTransaccion}
              onChange={handleDateEmisionChange}
              helperText="Fecha de emisión"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4}>
          <Controls.Input
            name="importe"
            label="Importe"
            value={values.importe}
            onChange={handleInputChange}
            error={errors.importe}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Select
            name="MonedaId"
            label="Moneda"
            value={values.MonedaId}
            onChange={handleInputChange}
            options={listaMonedas.map((item) => {
              return { id: item.id, title: item.nombre };
            })}
            error={errors.MonedaId}
          />
        </Grid>
        <Grid item xs={4}>
          <Controls.Input
            name="linkComprobantePago"
            label="Link al comprobante de Pago"
            value={values.linkComprobantePago}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="observaciones"
            label="Observaciones"
            value={values.observaciones}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          {continuar ?
            <>
              {!guardando ? (
                <div style={{ marginTop: 25, textAlign: "right" }}>
                  <Controls.Button type="submit" text="Guardar" />
                  <Controls.Button
                    text="Limpiar"
                    color="default"
                    onClick={resetForm}
                  />
                </div>
              ) : (
                <div style={{ margin: 25, textAlign: "right" }}>
                  <CircularProgress size={26} />
                </div>
              )}
            </>
            :
            <></>
          }
        </Grid>
      </Grid>
    </Form>
  );
}
