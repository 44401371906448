import React, { useEffect, useState } from "react";

import { Grid, CircularProgress, Card, RadioGroup, FormControlLabel, Radio, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Controls from "../../../components/tablas/controls/Controls";
import { useForm, Form } from "../../../components/tablas/useForm";
import Popup from "../../../components/tablas/Popup";
import PopupPrint from "../../../components/tablas/PopupPrint";

import * as FormaPagoServices from "../../../services/venta/formaPago";
import * as MonedaServices from "../../../services/venta/moneda";
import * as LocalidadServices from "../../../services/ubicacion/localidad";
import * as PaisServices from "../../../services/ubicacion/pais";
import * as ProvinciaServices from "../../../services/ubicacion/provincia";
import * as ClienteServices from "../../../services/cliente/cliente";
import * as EstadoCivilServices from "../../../services/cliente/estadoCivil";
import * as EstadoServices from "../../../services/producto/estado";
import * as ReservaLotesXLoteoServices from "../../../services/venta/reservaLotesXLoteo";
import * as LotesXLoteoServices from "../../../services/producto/lotesXLoteo";
import * as LoteosServices from "../../../services/producto/loteos";
import * as BancoServices from "../../../services/contable/banco";
import * as SucursalServices from "../../../services/contable/bancoSucursal";
import * as ChequeServices from "../../../services/contable/cheque";
import * as TransferenciaServices from "../../../services/contable/transferencia";
import * as CajaGeneralServices from "../../../services/caja/cajaGeneral";
import * as TarjetaCreditoServices from "../../../services/contable/tarjetaCredito";
import * as UsuarioServices from "../../../services/usuario/usuario";

import LabelIcon from "@material-ui/icons/Label";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import ForwardIcon from "@material-ui/icons/Forward";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import Calculadora from "../../utiles/CalculadoraDeCuotas";
import ChequeContenido from "../../contable/ChequeContenido";
import TransferenciaContenido from "../../contable/TransferenciaContenido";
import EfectivoContenido from "../../contable/EfectivoContenido";
import TarjetaContenido from "../../contable/TarjetaContenido";
import ReservaParaImprimir from "../../reserva/ReservaParaImprimirSinImagen";

import { useUserState } from "../../../context/UserContext";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//import { es } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    backgroundColor: "lightgray",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  //propiedad
  id: 0,
  importe1: "",
  MonedaId1: "",
  FormaPagoId1: "",
  importe2: "",
  MonedaId2: "",
  FormaPagoId2: "",
  importe3: "",
  MonedaId3: "",
  FormaPagoId3: "",
  importe4: "",
  MonedaId4: "",
  FormaPagoId4: "",
  cantidadCuotas: 1,
  importeAnio1: 0,
  importeAnio2: 0,
  importeAnio3: 0,
  importeAnio4: 0,
  ClienteId: "",
  vendedorId: "",
  comisionGastos: 0,
  fechaEstimadaFirma: fc,
  //cliente
  nombre: "",
  apellido: "",
  DNI: "",
  telefono: "",
  email: "",
  //busqueda
  b_nombre: "",
  b_apellido: "",
  b_DNI: "",
  b_telefono: "",
  b_email: "",
};

export default function LotexLoteoReserva(props) {
  const { token, userId } = useUserState();

  const { productoSeleccionado, onSave, cantManzanas, lotesListaCompleta } =
    props;

  const classes = useStyles();

  const [cargando, setCargando] = useState(false);
  const [registro, setRegistro] = useState({});
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [confirma, setConfirma] = React.useState(false);

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupImprimir, setOpenPopupImprimir] = useState(false);

  const [registroFormaPago, setRegistroFormaPago] = useState({});
  const [registroMoneda, setRegistroMoneda] = useState({});
  const [registroPaises, setRegistroPaises] = useState({});
  const [registroProvincias, setRegistroProvincias] = useState({});
  const [registroLocalidades, setRegistroLocalidades] = useState({});
  const [registroBancos, setRegistroBancos] = useState({});
  const [registroSucursales, setRegistroSucursales] = useState({});
  const [registroTarjetasCredito, setRegistroTarjetasCredito] = useState({});
  const [registroEstadoCivil, setRegistroEstadoCivil] = useState({});
  const [registroLoteos, setRegistroLoteos] = useState({});
  const [registroUsuarios, setRegistroUsuarios] = useState({});

  const [lotesComprados, setLotesComprados] = useState([]);
  const [registroEstados, setRegistroEstados] = useState({});
  const [pagoParcial, setPagoParcial] = useState("true");

  const [visible_FPago2, setVisibleFPago2] = useState(false);
  const [visible_FPago3, setVisibleFPago3] = useState(false);
  const [visible_FPago4, setVisibleFPago4] = useState(false);
  
  const [seleccionado, setSeleccionado] = useState(false);
  const [guardando, setGuardando] = useState(false);
  
  const [esCheque, setEsCheque] = useState([]);
  const [esBancarizado, setEsBancarizado] = useState([]);
  const [esEfectivo, setEsEfectivo] = useState([]);
  const [esTarjeta, setEsTarjeta] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [insertadoEnCaja, setInsertadoEnCaja] = useState([]);

  const [desactivado1, setDesactivado1] = useState(false);
  const [desactivado2, setDesactivado2] = useState(false);
  const [desactivado3, setDesactivado3] = useState(false);
  const [desactivado4, setDesactivado4] = useState(false);

  const [itemsImprimir, setItemsImprimir] = useState({});
  const [nacionalidades, setNacionalidades] = useState([]);
  const [asociado, setAsociado] = useState(false);

  const sexo = [
    {id: "H", nombre: "Sr"},
    {id: "M", nombre: "Sra"},
    {id: "S", nombre: "Srita"},
  ];

  const addOrEdit = async (registroItem, resetForm) => {
    let loteAReservar = {
      vendedorId: userId,
      LotesxloteoId: productoSeleccionado.id,
      observaciones: registroItem.observacionesReserva,

      FormaPagoId1: registroItem.FormaPagoId1,
      MonedaId1: registroItem.MonedaId1,
      importe1: registroItem.importe1,

      ClienteId: registroItem.ClienteId,
      fechaEstimadaFirma: selectedDate,
      linkComprobantePago: registroItem.linkComprobantePago,
      lotesComprados: lotesComprados,

      comisionGastos: registroItem.comisionGastos,
    };

    if (visible_FPago2) {
      loteAReservar.FormaPagoId2 = registroItem.FormaPagoId2;
      loteAReservar.MonedaId2 = registroItem.MonedaId2;
      loteAReservar.importe2 = registroItem.importe2;
    }
    if (visible_FPago3) {
      loteAReservar.FormaPagoId3 = registroItem.FormaPagoId3;
      loteAReservar.MonedaId3 = registroItem.MonedaId3;
      loteAReservar.importe3 = registroItem.importe3;
    }
    if (visible_FPago4) {
      loteAReservar.FormaPagoId4 = registroItem.FormaPagoId4;
      loteAReservar.MonedaId4 = registroItem.MonedaId4;
      loteAReservar.importe4 = registroItem.importe4;
    }

    try {
      registroItem.cantidadCuotas = parseInt(registroItem.cantidadCuotas);
    } catch (err) {}

    if (registroItem.cantidadCuotas > 1) {
      loteAReservar.cantidadCuotas = registroItem.cantidadCuotas;
      loteAReservar.entrega = registroItem.entrega;
      loteAReservar.importeAnio1 = registroItem.importeAnio1;
      loteAReservar.importeAnio2 = registroItem.importeAnio2;
      loteAReservar.importeAnio3 = registroItem.importeAnio3;
      loteAReservar.importeAnio4 = registroItem.importeAnio4;
    }

    let cliente = {
      id: registroItem.id,
      nombre: registroItem.nombre,
      apellido: registroItem.apellido,
      DNI: registroItem.DNI,
      telefono: registroItem.telefono,
      email: registroItem.email,
      direccion: registroItem.direccion,
      telefonoAlternativo: registroItem.telefonoAlternativo,
      observaciones: registroItem.observaciones,
      LocalidadId: registroItem.LocalidadId,
      EstadoCivilId: registroItem.EstadoCivilId,
      asociadoNombre: registroItem.asociadoNombre,
      asociadoDNI: registroItem.asociadoDNI,
      asociadoTelefono: registroItem.asociadoTelefono,
    };

    try {
      let reservaId = 0;
      await ClienteServices.update(cliente, token);
      await ReservaLotesXLoteoServices.insert(loteAReservar, token).then((res) => {
        console.log('Reserva: ', res);
        reservaId = res.data.id;
      });
      let EstadoId = registroEstados.find(
        (estado) => estado.esReservado === true
      ).id;
      const modificarItem = {
        id: productoSeleccionado.id,
        EstadoId: EstadoId,
      };
      await LotesXLoteoServices.updateReserva(
        modificarItem,
        modificarItem.id,
        token
      );

      // setear todos los lotes restantes como reservados.
      if (lotesComprados) {
        if (lotesComprados.length > 0) {
          for (let i = 0; i < lotesComprados.length; i++) {
            await LotesXLoteoServices.updateReserva(
              {
                id: lotesComprados[i].id,
                EstadoId: EstadoId,
              },
              lotesComprados[i].id,
              token
            );
          }
        }
      }

      if (insertadoEnCaja) {
        console.log('Elementos: ', insertadoEnCaja);
        if (insertadoEnCaja.length > 0) {
          for (let i = 0; i < insertadoEnCaja.length; i++) {
            await CajaGeneralServices.update(
              {
                ReservasLoteId: reservaId,
                id: insertadoEnCaja[i],
              },
              token
            );
          }
        }
      }

      onSave();
    } catch (err) {
      alert("Error al intentar crear la reserva");
      return false;
    }
  };

  const agregarValues = (transaccion) => {
    switch (transaccion.numero) {
      case 1: {
        setDesactivado1(true);
        values.MonedaId1 = transaccion.MonedaId;
        values.importe1 = transaccion.importe;
        values.linkComprobantePago1 = transaccion.linkComprobantePago;
        break;
      }
      case 2: {
        setDesactivado2(true);
        values.MonedaId2 = transaccion.MonedaId;
        values.importe2 = transaccion.importe;
        values.linkComprobantePago2 = transaccion.linkComprobantePago;
        break;
      }
      case 3: {
        setDesactivado3(true);
        values.MonedaId3 = transaccion.MonedaId;
        values.importe3 = transaccion.importe;
        values.linkComprobantePago3 = transaccion.linkComprobantePago;
        break;
      }
      case 4: {
        setDesactivado4(true);
        values.MonedaId4 = transaccion.MonedaId;
        values.importe4 = transaccion.importe;
        values.linkComprobantePago4 = transaccion.linkComprobantePago;
        break;
      }
      default:
        break;
    }
    //console.log('Para agregar: ', insertadoEnCaja);
  }

  const addOrEditCheque = async (transaccion, resetForm) => {
    //console.log("addoreditcheque: ", transaccion, insertadoEnCaja);

    agregarValues(transaccion);
    //console.log('Values despues de funcion: ', values);

    try {
      let chequeId = 0
      await ChequeServices.insert(transaccion, token).then((res) => {
        chequeId = res.data.id;
        //console.log('Devolucion cheque: ', res.data);
      });
      if (chequeId > 0) {
        let caja = {
          ingreso: transaccion.importe,
          ClienteId: transaccion.ClienteId,
          FormaPagoId: transaccion.numero === 1 ? values.FormaPagoId1 : transaccion.numero === 2 ? values.FormaPagoId2 : transaccion.numero === 3 ? values.FormaPagoId3 : values.FormaPagoId4,
          MonedaId: transaccion.MonedaId,
          ChequeId: chequeId,
          UsuarioId: userId,
          OrigenId: 1         // RESERVA DE LOTE
        }
        console.log('En cheque: ', caja);
        await CajaGeneralServices.insert(caja, token).then((res) => {
          //console.log('insertado en caja', res);
          setInsertadoEnCaja(insertadoEnCaja.concat(res.data.id));
        });
      }
    } catch (err) {
      //alert("Error al intentar crear la reserva");
      return false;
    }
  };

  const addOrEditEfectivo = async (transaccion, resetForm) => {
    //console.log("addoreditefectivo: ", transaccion);

    agregarValues(transaccion);
    //console.log('Values despues de funcion: ', values);

    try {
      let caja = {
        ingreso: transaccion.importe,
        ClienteId: transaccion.ClienteId,
        FormaPagoId: transaccion.numero === 1 ? values.FormaPagoId1 : transaccion.numero === 2 ? values.FormaPagoId2 : transaccion.numero === 3 ? values.FormaPagoId3 : values.FormaPagoId4,
        MonedaId: transaccion.MonedaId,
        UsuarioId: userId,
        OrigenId: 1         // RESERVA DE LOTE
      }
      await CajaGeneralServices.insert(caja, token).then((res) => {
        //console.log('insertado en caja', res);
        setInsertadoEnCaja(insertadoEnCaja.concat(res.data.id));
      });
    } catch (err) {
      //alert("Error al intentar crear la reserva");
      return false;
    }
  };

  const addOrEditTarjeta = async (transaccion, resetForm) => {
    //console.log("addoredittarjeta: ", transaccion);

    agregarValues(transaccion);
    //console.log('Values despues de funcion: ', values);

    try {
      let caja = {
        ingreso: transaccion.importe,
        ClienteId: transaccion.ClienteId,
        FormaPagoId: transaccion.numero === 1 ? values.FormaPagoId1 : transaccion.numero === 2 ? values.FormaPagoId2 : transaccion.numero === 3 ? values.FormaPagoId3 : values.FormaPagoId4,
        MonedaId: transaccion.MonedaId,
        TarjetaCreditoId: transaccion.TarjetaCreditoId,
        UsuarioId: userId,
        OrigenId: 1         // RESERVA DE LOTE
      }
      //console.log('Caja: ', caja)
      await CajaGeneralServices.insert(caja, token).then((res) => {
        //console.log('insertado en caja', res);
        setInsertadoEnCaja(insertadoEnCaja.concat(res.data.id));
      });
    } catch (err) {
      //alert("Error al intentar crear la reserva");
      return false;
    }
  };

  const addOrEditTransferencia = async (transaccion, resetForm) => {
    //console.log("addoredittransferencia: ", transaccion);

    agregarValues(transaccion);
    //console.log('Values despues de funcion: ', values);

    try {
      let transfId = 0;
      await TransferenciaServices.insert(transaccion, token).then((res) => {
        //console.log('Transferencia: ',res);
        transfId = res.data.id;
      });
      if (transfId > 0) {
        let caja = {
          ingreso: transaccion.importe,
          ClienteId: transaccion.ClienteId,
          FormaPagoId: transaccion.numero === 1 ? values.FormaPagoId1 : transaccion.numero === 2 ? values.FormaPagoId2 : transaccion.numero === 3 ? values.FormaPagoId3 : values.FormaPagoId4,
          MonedaId: transaccion.MonedaId,
          TransferenciumId: transfId,
          UsuarioId: userId,
          OrigenId: 1         // RESERVA DE LOTE
        }
        //console.log('Caja: ', caja)
        await CajaGeneralServices.insert(caja, token).then((res) => {
          //console.log('insertado en caja', res);
          setInsertadoEnCaja(insertadoEnCaja.concat(res.data.id));
        });
      }
    } catch (err) {
      //alert("Error al intentar crear la reserva");
      return false;
    }
  };

  const handleRadioChange = (event) => {
    setPagoParcial(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fechaConsulta =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const obtenerNombreLote = (item) => {
    const nom = cantManzanas[item.manzana] + " - " + item.nombre;
    return nom;
  };

  const obtenerTotalCompra = () => {
    let total = productoSeleccionado.precio;
    if (lotesComprados.length > 0) {
      for (let i = 0; i < lotesComprados.length; i++) {
        total += lotesComprados[i].precio;
      }
    }
    return total;
  };

  const eliminarLoteComprado = (item) => {
    let lc = lotesComprados.slice().filter((lc) => lc.id !== item.id);
    setLotesComprados(lc);
  };

  const agregarLoteACompra = (item) => {
    if (productoSeleccionado.id === item.target.value) {
      alert("El lote ya esta en la lista de compra");
      return;
    }
    const lote = lotesListaCompleta.find((l) => l.id === item.target.value);
    let lc = lotesComprados.slice();
    const exite = lc.find((l) => l.id === item.target.value);
    if (exite) {
      alert("El lote ya esta en la lista de compra");
      return;
    }
    lc.push({
      id: lote.id,
      nombre: lote.nombre,
      precio: lote.precio,
      monedaId: 1,
    });
    setLotesComprados(lc);
  };

  const validacion = (valores) => {
    let resultado = "";
    let bandera = true;
    if (valores) {
      if (!valores.nombre) {
        resultado += "- NOMBRE - Dato requerido, \n";
        bandera = bandera && false;
      } 
      if (!valores.apellido) {
        resultado += " APELLIDO - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (!valores.DNI) {
        resultado += "- DNI - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (!valores.direccion) {
        resultado += "- DOMICILIO - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (!valores.LocalidadId) {
        resultado += "- LOCALIDAD - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (!valores.EstadoCivilId) {
        resultado += "- ESTADO CIVIL - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (!valores.sexo) {
        resultado += "- SEXO - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (!valores.nacionalidad) {
        resultado += "- NACIONALIDAD - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (!valores.entrega) {
        resultado += "- ENTREGA - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (!valores.comisionGastos) {
        resultado += "- COMISIÓN Y GASTOS - Dato requerido, \n";
        bandera = bandera && false;
      }
      if (resultado !== "") alert(resultado);
      return bandera;
    } else {
      alert("Por favor, complete todos los datos...");
      return false;
    }
  }

  const openInPopupImprimir = (item) => {
    console.log('Items antes de imprimir: ', item);
    if (validacion(item)) {
      item.fechaEstimadaFirma = selectedDate;
      if (item.cantidadCuotas === "1" || item.cantidadCuotas === "12") {
        item.importeAnio2 = 0;
        item.importeAnio3 = 0;
        item.importeAnio4 = 0;
      } else if (item.cantidadCuotas === "24") {
        item.importeAnio3 = 0;
        item.importeAnio4 = 0;
      } else if (item.cantidadCuotas === "36") {
        item.importeAnio4 = 0;
      }
      if (!asociado) {
        item.asociadoApellido = "";
        item.asociadoNombre = "";
      }
      setItemsImprimir(item);
      setOpenPopupImprimir(true);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.importe1 || !values.FormaPagoId1 || !values.MonedaId1) {
      alert("Por favor complete los datos de pago antes de crear la reserva");
      return false;
    }
    if (pagoParcial === "true") {
      if (values.cantidadCuotas < 1 || values.cantidadCuotas === undefined) {
        alert("Por favor complete la cantidad de cuotas");
        return false;
      } else {
        if (values.cantidadCuotas > 48) {
          alert("La cantidad de cuotas ingresada no puede ser mayor a 48");
          return false;
        }
      }
    } else {
      values.cantidadCuotas = 1;
    }
    if (validate()) {
      setGuardando(true);
      values.vendedorId = userId;
      values.ClienteId = values.id;
      values.PropiedadesVentumId = productoSeleccionado.id;
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  const handleInputSoloNumerosChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      e.target.value = onlyNums;
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      e.target.value = number;
    }
    handleInputChange(e);
  };

  const telefonoCoincide = (telefono) => {
    let siNo = false;
    if (telefono) {
      if (values.b_telefono) {
        if (values.b_telefono.length > 0) {
          if (telefono.includes(values.b_telefono)) {
            siNo = true;
          }
        }
      }
    }
    return siNo;
  };

  const buscarSimilares = () => {
    const datosBuscar = {
      nombre: values.b_nombre,
      apellido: values.b_apellido,
      DNI: values.b_DNI,
      telefono: values.b_telefono,
      email: values.b_email,
    };
    ClienteServices.filtrarSimilares(token, datosBuscar).then((res) => {
      setRegistro(res);
    });
  };

//  useEffect(() => {}, []);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      FormaPagoServices.getAllItems(token).then((res) => {
        setEsCheque(
          res.filter((item) => item.esCheque === true).map((item) => item.id)
        );
        setEsBancarizado(
          res
            .filter((item) => item.esBancarizado === true).map((item) => item.id)
        );
        setEsEfectivo(
          res.filter((item) => item.esEfectivo === true).map((item) => item.id)
        );
        setEsTarjeta(
          res.filter((item) => item.esTarjeta === true).map((item) => item.id)
        );
        setRegistroFormaPago(res);
      });
      MonedaServices.getAllItems(token).then((res) => {
        setRegistroMoneda(res);
      });
      ProvinciaServices.getAllItems(token).then((res) => {
        setRegistroProvincias(res);
      });
      PaisServices.getAllItems(token).then((res) => {
        let gentilicio = [];
        let gen = [];
        for (let i = 0; i < res.length; i++) {
          gen = gen.concat(res[i].gentilicio.split(","));
        }
        for (let i = 0; i < gen.length; i++) {
          gentilicio.push({id: i + 1, nombre: gen[i]})
        }
        setNacionalidades(gentilicio);
        setRegistroPaises(res);
      });
      LocalidadServices.getAllItems(token).then((res) => {
        setRegistroLocalidades(res);
      });
      EstadoServices.getAllItems(token).then((res) => {
        setRegistroEstados(res);
      });
      BancoServices.getAllItems(token).then((res) => {
        setRegistroBancos(res);
      });
      SucursalServices.getAllItems(token).then((res) => {
        setRegistroSucursales(res);
      });
      TarjetaCreditoServices.getAllItems(token).then((res) => {
        setRegistroTarjetasCredito(res);
      });
      EstadoCivilServices.getAllItems(token).then((res) => {
        setRegistroEstadoCivil(res);
      });
      LoteosServices.getAllItems(token).then((res) => {
        setRegistroLoteos(res);
      });
      UsuarioServices.getAllItems(token).then((res) => {
        setRegistroUsuarios(res);
      });
    }
  }, [cargando]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {registroFormaPago.length && registroMoneda.length ? (
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {!seleccionado ? (
                  <>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={12}
                    >
                      <h2 style={{ paddingLeft: 10 }}>
                        {" "}
                        BUSQUE Y SELECCIONE EL CLIENTE
                      </h2>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="b_nombre"
                        label="Nombre / Alias"
                        value={values.b_nombre}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="b_apellido"
                        label="Apellido"
                        value={values.b_apellido}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="b_DNI"
                        label="DNI"
                        value={values.b_DNI}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="b_telefono"
                        label="Teléfono"
                        value={values.b_telefono}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="b_email"
                        label="Email"
                        type="email"
                        value={values.b_email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginTop: 25, textAlign: "right" }}>
                        <Controls.Button
                          onClick={buscarSimilares}
                          text="Buscar"
                        />
                      </div>
                    </Grid>
                    {!confirma ? (
                      <>
                        <div>
                          <h4>CLIENTES ENCONTRADOS</h4>
                        </div>
                        <Grid item xs={12} sm={12}></Grid>
                        {registro.length ? (
                          <>
                            {registro.map((item) => (
                              <Grid item xs={12} sm={4}>
                                <Card className={classes.root}>
                                  <CardContent>
                                    <Typography
                                      style={{ textAlign: "center" }}
                                      className={classes.title}
                                      color="textSecondary"
                                      gutterBottom
                                    >
                                      <AccountCircleIcon
                                        style={{ fontSize: 40, color: "white" }}
                                      />
                                    </Typography>
                                    <Typography variant="h5" component="h2">
                                      {item.apellido} {item.nombre}
                                    </Typography>
                                    <Typography
                                      className={classes.pos}
                                      color="textSecondary"
                                    >
                                      DNI: {item.DNI}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                      {telefonoCoincide(item.telefono) && (
                                        <>
                                          <PhoneAndroidIcon
                                            style={{ marginBottom: -5 }}
                                          />{" "}
                                          Coincide con TELEFONO PRINCIPAL
                                        </>
                                      )}
                                      {telefonoCoincide(
                                        item.asociadoTelefono
                                      ) && (
                                        <>
                                          <PhoneAndroidIcon
                                            style={{ marginBottom: -5 }}
                                          />{" "}
                                          Coincide con TELEFONO DEL ASOCIADO
                                        </>
                                      )}
                                      {telefonoCoincide(
                                        item.telefonoAlternativo
                                      ) && (
                                        <>
                                          <PhoneAndroidIcon
                                            style={{ marginBottom: -5 }}
                                          />{" "}
                                          Coincide con TELEFONO ALTERNATIVO
                                        </>
                                      )}
                                      <br />
                                      <EmailIcon
                                        style={{ marginBottom: -5 }}
                                      />{" "}
                                      {item.email}
                                      <br />
                                      {item.Usuario ? (
                                        <>
                                          VENDEDOR:{" "}
                                          <b>
                                            {item.Usuario.nombre}{" "}
                                            {item.Usuario.apellido}
                                          </b>
                                        </>
                                      ) : (
                                        <>NO HAY VENDEDOR ASIGNADO</>
                                      )}
                                    </Typography>
                                    <Typography
                                      className={classes.pos}
                                      color="textSecondary"
                                    >
                                      <Controls.ActionButton
                                        color="primary"
                                        onClick={() => {
                                          setSeleccionado(true);
                                          setConfirma(true);
                                          let paisId = 0;
                                          let provinciaId = 0;
                                          try {
                                            const prov =
                                              registroLocalidades.find(
                                                (itemLoc) =>
                                                  itemLoc.id ===
                                                  item.LocalidadId
                                              );
                                            const pais =
                                              registroProvincias.find(
                                                (itemProv) =>
                                                  itemProv.id ===
                                                  prov.ProvinciumId
                                              );
                                            if (pais) {
                                              paisId = pais.PaiId;
                                            }
                                            if (prov) {
                                              provinciaId = prov.ProvinciumId;
                                            }
                                          } catch (e) {}
                                          item.PaiId = paisId;
                                          item.ProvinciumId = provinciaId;
                                          item.b_nombre = values.b_nombre;
                                          item.b_apellido = values.b_apellido;
                                          item.b_DNI = values.b_DNI;
                                          item.b_telefono = values.b_telefono;
                                          item.b_email = values.b_email;
                                          item.DNI = item.DNI ? item.DNI : "";
                                          setCliente(item);
                                          setValues(item);
                                        }}
                                      >
                                        <ForwardIcon fontSize="small" />
                                      </Controls.ActionButton>
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </>
                        ) : (
                          <> No se encontraron registros</>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={9}
                    >
                      <h2 style={{ paddingLeft: 10 }}>CLIENTE SELECCIONADO</h2>
                    </Grid>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <Controls.ActionButton
                        style={{ marginTop: 15 }}
                        color="primary"
                        onClick={() => {
                          setSeleccionado(false);
                          setConfirma(false);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" /> CAMBIAR CLIENTE
                      </Controls.ActionButton>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="nombre"
                        label="Nombre / Alias"
                        value={values.nombre}
                        onChange={handleInputChange}
                        error={errors.nombre}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="apellido"
                        label="Apellido"
                        value={values.apellido}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Input
                        name="DNI"
                        label="DNI"
                        value={values.DNI}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="telefono"
                        label="Teléfono"
                        value={values.telefono}
                        onChange={handleInputChange}
                        error={errors.telefono}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="email"
                        label="email"
                        type="email"
                        value={values.email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="direccion"
                        label="Dirección"
                        value={values.direccion}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="telefonoAlternativo"
                        label="Teléfono Alternativo"
                        value={values.telefonoAlternativo}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Select
                        name="PaiId"
                        label="País"
                        value={values.PaiId}
                        onChange={handleInputChange}
                        options={registroPaises.map((item) => {
                          return { id: item.id, title: item.nombre };
                        })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      {values.PaiId ? (
                        <div>
                          {registroProvincias.find(
                            (item) => item.PaiId === values.PaiId
                          ) ? (
                            <Controls.Select
                              name="ProvinciumId"
                              label="Provincia"
                              value={values.ProvinciumId}
                              onChange={handleInputChange}
                              options={registroProvincias.map((item) => {
                                if (item.PaiId === values.PaiId) {
                                  return { id: item.id, title: item.nombre };
                                } else {
                                  return false; // Línea Modificada
                                }
                              })}
                            />
                          ) : (
                            <>
                              {" "}
                              No hay provincias creadas en este pais. Por favor
                              completar antes de continuar.
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      {values.ProvinciumId ? (
                        <div>
                          {registroLocalidades.find(
                            (item) => item.ProvinciumId === values.ProvinciumId
                          ) ? (
                            <>
                              <Controls.Select
                                name="LocalidadId"
                                label="Localidad"
                                value={values.LocalidadId}
                                onChange={handleInputChange}
                                options={registroLocalidades.map((item) => {
                                  if (
                                    item.ProvinciumId === values.ProvinciumId
                                  ) {
                                    return { id: item.id, title: item.nombre };
                                  } else {
                                    return false; // Línea Modificada
                                  }
                                })}
                              />
                            </>
                          ) : (
                            <>
                              {" "}
                              No hay localidades creadas en esta provincia. Por
                              favor completar antes de continuar.
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Select
                        name="EstadoCivilId"
                        label="Estado Civil"
                        value={values.EstadoCivilId}
                        onChange={handleInputChange}
                        options={registroEstadoCivil.map((item) => {
                          return { id: item.id, title: item.nombre };
                        })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Select
                        name="sexo"
                        label="Titulo"
                        value={values.sexo}
                        onChange={handleInputChange}
                        options={sexo.map((item) => {
                          return { id: item.id, title: item.nombre };
                        })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Controls.Select
                        name="nacionalidad"
                        label="Nacionalidad"
                        value={values.nacionalidad}
                        onChange={handleInputChange}
                        options={nacionalidades.map((item) => {
                          return { id: item.id, title: item.nombre };
                        })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controls.Input
                        name="observaciones"
                        label="Observaciones"
                        value={values.observaciones}
                        onChange={handleInputChange}
                        multiline
                        rowsMax={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controls.Checkbox
                        name="asociado"
                        label="¿Agregar asociado?"
                        value={asociado}
                        onChange={() => setAsociado(!asociado)}
                      />
                    </Grid>
                    {asociado &&
                      <>
                        <Grid item xs={6} sm={4}>
                          <Controls.Input
                            name="asociadoNombre"
                            label="Nombre asociado"
                            value={values.asociadoNombre}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Input
                            name="asociadoApellido"
                            label="Apellido asociado"
                            value={values.asociadoApellido}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Input
                            name="asociadoDNI"
                            label="DNI asociado"
                            value={values.asociadoDNI}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Select
                            name="AsociadoPaiId"
                            label="País"
                            value={values.AsociadoPaiId}
                            onChange={handleInputChange}
                            options={registroPaises.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          {values.AsociadoPaiId ? (
                            <div>
                              {registroProvincias.find(
                                (item) => item.PaiId === values.AsociadoPaiId
                              ) ? (
                                <Controls.Select
                                  name="AsociadoProvinciumId"
                                  label="Provincia"
                                  value={values.AsociadoProvinciumId}
                                  onChange={handleInputChange}
                                  options={registroProvincias.map((item) => {
                                    if (item.PaiId === values.AsociadoPaiId) {
                                      return { id: item.id, title: item.nombre };
                                    } else {
                                      return false; // Línea Modificada
                                    }
                                  })}
                                />
                              ) : (
                                <>
                                  {" "}
                                  No hay provincias creadas en este pais. Por favor
                                  completar antes de continuar.
                                </>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          {values.AsociadoProvinciumId ? (
                            <div>
                              {registroLocalidades.find(
                                (item) => item.ProvinciumId === values.AsociadoProvinciumId
                              ) ? (
                                <>
                                  <Controls.Select
                                    name="AsociadoLocalidadId"
                                    label="Localidad"
                                    value={values.AsociadoLocalidadId}
                                    onChange={handleInputChange}
                                    options={registroLocalidades.map((item) => {
                                      if (
                                        item.ProvinciumId === values.AsociadoProvinciumId
                                      ) {
                                        return { id: item.id, title: item.nombre };
                                      } else {
                                        return false; // Línea Modificada
                                      }
                                    })}
                                  />
                                </>
                              ) : (
                                <>
                                  {" "}
                                  No hay localidades creadas en esta provincia. Por
                                  favor completar antes de continuar.
                                </>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Input
                            name="asociadoDireccion"
                            label="Domicilio de asociado"
                            value={values.asociadoDireccion}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Select
                            name="asociadoNacionalidad"
                            label="Nacionalidad de asociado"
                            value={values.asociadoNacionalidad}
                            onChange={handleInputChange}
                            options={nacionalidades.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Select
                            name="AsociadoEstadoCivilId"
                            label="Estado Civil de Asociado"
                            value={values.AsociadoEstadoCivilId}
                            onChange={handleInputChange}
                            options={registroEstadoCivil.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Select
                            name="asociadoSexo"
                            label="Sexo"
                            value={values.asociadoSexo}
                            onChange={handleInputChange}
                            options={sexo.map((item) => {
                              return { id: item.id, title: item.nombre };
                            })}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Controls.Input
                            name="asociadoTelefono"
                            label="Teléfono asociado"
                            value={values.asociadoTelefono}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </>
                    }
                    <Grid
                      style={{ backgroundColor: "#E08F8F" }}
                      item
                      xs={12}
                      sm={12}
                    >
                      <hr />
                      <h2 style={{ paddingLeft: 10 }}>
                        LISTA DE PRODUCTOS COMPRADOS{" "}
                      </h2>
                    </Grid>
                    <Grid
                      style={{ textAlign: "right", backgroundColor: "#DADADA" }}
                      item
                      xs={12}
                      sm={1}
                    >
                      <LabelIcon style={{ marginTop: 20 }} />
                    </Grid>
                    <Grid
                      style={{ backgroundColor: "#DADADA" }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <h2> {productoSeleccionado.nombre} </h2>
                    </Grid>
                    <Grid
                      style={{ textAlign: "left", backgroundColor: "#DADADA" }}
                      item
                      xs={12}
                      sm={3}
                    >
                      <h2> $ {productoSeleccionado.precio} </h2>
                    </Grid>
                    <Grid
                      style={{ textAlign: "left", backgroundColor: "#DADADA" }}
                      item
                      xs={12}
                      sm={5}
                    ></Grid>
                    <Grid
                      style={{
                        textAlign: "left",
                        marginTop: -20,
                        backgroundColor: "white",
                      }}
                      item
                      xs={12}
                      sm={12}
                    >
                      <hr />
                    </Grid>
                    {lotesComprados.length > 0 ? (
                      <>
                        {lotesComprados.map((lote) => (
                          <>
                            <Grid
                              style={{
                                textAlign: "right",
                                marginTop: -20,
                                backgroundColor: "#DADADA",
                              }}
                              item
                              xs={12}
                              sm={1}
                            >
                              <LabelIcon style={{ marginTop: 20 }} />
                            </Grid>
                            <Grid
                              style={{
                                marginTop: -20,
                                backgroundColor: "#DADADA",
                              }}
                              item
                              xs={12}
                              sm={3}
                            >
                              <h2> {lote.nombre} </h2>
                            </Grid>
                            <Grid
                              style={{
                                textAlign: "left",
                                marginTop: -20,
                                backgroundColor: "#DADADA",
                              }}
                              item
                              xs={12}
                              sm={3}
                            >
                              <h2>$ {lote.precio} </h2>
                            </Grid>
                            <Grid
                              style={{
                                textAlign: "left",
                                marginTop: -20,
                                backgroundColor: "#DADADA",
                              }}
                              item
                              xs={12}
                              sm={5}
                            >
                              <Controls.ActionButton
                                style={{ marginTop: 7, backgroundColor: "red" }}
                                color="primary"
                                onClick={() => {
                                  eliminarLoteComprado(lote);
                                }}
                              >
                                <CancelRoundedIcon fontSize="small" />
                              </Controls.ActionButton>
                            </Grid>
                            <Grid
                              style={{
                                textAlign: "left",
                                marginTop: -20,
                                backgroundColor: "white",
                              }}
                              item
                              xs={12}
                              sm={12}
                            >
                              <hr />
                            </Grid>
                          </>
                        ))}
                        <Grid
                          style={{
                            textAlign: "left",
                            marginTop: -20,
                            backgroundColor: "white",
                          }}
                          item
                          xs={12}
                          sm={12}
                        >
                          <h2>TOTAL: {obtenerTotalCompra()} </h2>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Controls.Select
                        name="FormaPagoId2"
                        label="Agregar lote a la compra"
                        value={values.loteAgregadoId || ""}
                        onChange={agregarLoteACompra}
                        options={lotesListaCompleta.map((item) => {
                          if (item.Estado)
                            if (item.Estado.esDisponible) {
                              return {
                                id: item.id,
                                title: obtenerNombreLote(item),
                              };
                            } else {
                              return false; // Línea Modificada
                            }
                          return false; // Línea Modificada
                        })}
                      />
                    </Grid>
                    <Grid
                      style={{ backgroundColor: "lightgreen" }}
                      item
                      xs={12}
                      sm={12}
                    >
                      <hr />
                      <h2 style={{ paddingLeft: 10 }}>DATOS DE PAGO </h2>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={pagoParcial}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Pago Total"
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Pago Parcial"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={12}></Grid>
                    {pagoParcial === "true" ? (
                      <>
                        <Grid item xs={12} sm={3}>
                          <Controls.InputMask
                            name="entrega"
                            style={{ backgroundColor: "lightblue" }}
                            label="Entrega"
                            prefix="$ "
                            value={values.entrega}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                          <Controls.Button
                            text="Calculadora de Cuotas"
                            onClick={() => setOpenPopup(true)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Input
                            name="cantidadCuotas"
                            label="Cantidad de Cuotas"
                            value={values.cantidadCuotas}
                            onChange={handleInputSoloNumerosChange}
                          />
                        </Grid>
                        {values.cantidadCuotas > 48 ||
                        values.cantidadCuotas < 1 ? (
                          <>
                            {() =>
                              alert(
                                "Número de cuotas inválido. No pueden superar 48"
                              )
                            }
                          </>
                        ) : (
                          <>
                            {values.cantidadCuotas > 1 && (
                              <>
                                <Grid item xs={12} sm={2}>
                                  <Controls.Input
                                    name="importeAnio1"
                                    label="Primer Año"
                                    value={values.importeAnio1}
                                    onChange={handleInputSoloNumerosChange}
                                  />
                                </Grid>
                              </>
                            )}
                            {values.cantidadCuotas > 12 && (
                              <>
                                <Grid item xs={12} sm={2}>
                                  <Controls.Input
                                    name="importeAnio2"
                                    label="Segundo Año"
                                    value={values.importeAnio2}
                                    onChange={handleInputSoloNumerosChange}
                                  />
                                </Grid>
                              </>
                            )}
                            {values.cantidadCuotas > 24 && (
                              <>
                                <Grid item xs={12} sm={2}>
                                  <Controls.Input
                                    name="importeAnio3"
                                    label="Tercer Año"
                                    value={values.importeAnio3}
                                    onChange={handleInputSoloNumerosChange}
                                  />
                                </Grid>
                              </>
                            )}
                            {values.cantidadCuotas > 36 && (
                              <>
                                <Grid item xs={12} sm={2}>
                                  <Controls.Input
                                    name="importeAnio4"
                                    label="Cuarto Año"
                                    value={values.importeAnio4}
                                    onChange={handleInputSoloNumerosChange}
                                  />
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {pagoParcial === "true" && (
                      <>
                        <Grid item xs={12} sm={12}>
                          {" "}
                          <hr />{" "}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <h2>Reserva:</h2>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={3}>
                      {!desactivado1 ?
                        <Controls.Select
                          name="FormaPagoId1"
                          label="Forma de Pago"
                          value={values.FormaPagoId1 || ""}
                          onChange={handleInputChange}
                          options={registroFormaPago.map((item) => {return { id: item.id, title: item.nombre };})}
                        />
                        :
                        <Controls.Input
                          name="FormaPagoId1"
                          label="Forma de Pago"
                          value={registroFormaPago.find((fp) => fp.id === values.FormaPagoId1).nombre}
                          disabled
                          variant="outlined"
                        />
                      }
                    </Grid>
                    <Grid item xs={2}>
                      <Controls.ActionButton
                        style={{ marginTop: 15 }}
                        color="primary"
                        onClick={() => {
                          setVisibleFPago2(true);
                        }}
                      >
                        <AddCircleIcon fontSize="small" />
                      </Controls.ActionButton>
                    </Grid>
                    <Grid item xs={7}>
                    </Grid>
                    <Grid item xs={12}
                      style={{
                        border: "1px solid",
                        borderRadius: 10,
                        padding: 20,
                        marginBottom: 40,
                      }}
                    >
                      {esEfectivo && esEfectivo.length && esEfectivo.includes(values.FormaPagoId1) ? (
                        <>
                        <EfectivoContenido
                          listaMonedas={registroMoneda}
                          cliente={cliente}
                          addOrEdit={addOrEditEfectivo}
                          numero={1}
                          />
                        </>
                        ) : (
                        <></>
                      )}
                      {esTarjeta && esTarjeta.length && esTarjeta.includes(values.FormaPagoId1) ? (
                        <>
                        <TarjetaContenido
                          listaTarjetas={registroTarjetasCredito}
                          listaMonedas={registroMoneda}
                          cliente={cliente}
                          addOrEdit={addOrEditTarjeta}
                          numero={1}
                          />
                        </>
                        ) : (
                          <></>
                          )}
                      {esCheque && esCheque.length && esCheque.includes(values.FormaPagoId1) ? (
                        <ChequeContenido
                          listaBancos={registroBancos}
                          listaSucursales={registroSucursales}
                          listaMonedas={registroMoneda}
                          cliente={cliente}
                          addOrEdit={addOrEditCheque}
                          numero={1}
                        />
                        ) : (
                          <></>
                      )}
                      {esBancarizado && esBancarizado.length && esBancarizado.includes(values.FormaPagoId1) ? (
                        <TransferenciaContenido
                          listaBancos={registroBancos}
                          listaSucursales={registroSucursales}
                          listaMonedas={registroMoneda}
                          addOrEdit={addOrEditTransferencia}
                          cliente={cliente}
                          numero={1}
                        />
                      ) : (
                        <></>
                      )}
                      {!values.FormaPagoId1 &&
                        <h2>Seleccione Forma de Pago</h2>
                      }
                    </Grid>
                    {visible_FPago2 && (
                      <>
                        <Grid item xs={3}>
                          {!desactivado2 ?
                            <Controls.Select
                              name="FormaPagoId2"
                              label="Forma de Pago"
                              value={values.FormaPagoId2 || ""}
                              onChange={handleInputChange}
                              options={registroFormaPago.map((item) => {return { id: item.id, title: item.nombre };})}
                            />
                            :
                            <Controls.Input
                              name="FormaPagoId2"
                              label="Forma de Pago"
                              value={registroFormaPago.find((fp) => fp.id === values.FormaPagoId2).nombre}
                              disabled
                              variant="outlined"
                            />
                          }
                        </Grid>
                        <Grid item xs={2}>
                          <Controls.ActionButton
                            style={{ marginTop: 15 }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago3(true);
                            }}
                          >
                            <AddCircleIcon fontSize="small" />
                          </Controls.ActionButton>
                          {/* <Controls.ActionButton
                            style={{ marginTop: 15, backgroundColor: "red" }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago2(false);
                            }}
                          >
                            <CancelRoundedIcon fontSize="small" />
                          </Controls.ActionButton> */}
                        </Grid>
                        <Grid item xs={7}>
                        </Grid>
                        <Grid item xs={12}
                          style={{
                            border: "1px solid",
                            borderRadius: 10,
                            padding: 20,
                            marginBottom: 40,
                          }}
                        >
                          {esEfectivo && esEfectivo.length && esEfectivo.includes(values.FormaPagoId2) ? (
                            <>
                            <EfectivoContenido
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditEfectivo}
                              numero={2}
                              />
                            </>
                            ) : (
                            <></>
                          )}
                          {esTarjeta && esTarjeta.length && esTarjeta.includes(values.FormaPagoId2) ? (
                            <>
                            <TarjetaContenido
                              listaTarjetas={registroTarjetasCredito}
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditTarjeta}
                              numero={2}
                              />
                            </>
                            ) : (
                              <></>
                              )}
                          {esCheque && esCheque.length && esCheque.includes(values.FormaPagoId2) ? (
                            <ChequeContenido
                              listaBancos={registroBancos}
                              listaSucursales={registroSucursales}
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditCheque}
                              numero={2}
                            />
                            ) : (
                              <></>
                          )}
                          {esBancarizado && esBancarizado.length && esBancarizado.includes(values.FormaPagoId2) ? (
                            <TransferenciaContenido
                              listaBancos={registroBancos}
                              listaSucursales={registroSucursales}
                              listaMonedas={registroMoneda}
                              addOrEdit={addOrEditTransferencia}
                              cliente={cliente}
                              numero={2}
                            />
                          ) : (
                            <></>
                          )}
                          {!values.FormaPagoId2 &&
                            <h2>Seleccione Forma de Pago</h2>
                          }
                        </Grid>
                      </>
                    )}
                     {visible_FPago3 && (
                      <>
                        <Grid item xs={3}>
                          {!desactivado3 ?
                            <Controls.Select
                              name="FormaPagoId3"
                              label="Forma de Pago"
                              value={values.FormaPagoId3 || ""}
                              onChange={handleInputChange}
                              options={registroFormaPago.map((item) => {return { id: item.id, title: item.nombre };})}
                            />
                            :
                            <Controls.Input
                              name="FormaPagoId3"
                              label="Forma de Pago"
                              value={registroFormaPago.find((fp) => fp.id === values.FormaPagoId3).nombre}
                              disabled
                              variant="outlined"
                            />
                          }
                        </Grid>
                        <Grid item xs={2}>
                          <Controls.ActionButton
                            style={{ marginTop: 15 }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago4(true);
                            }}
                          >
                            <AddCircleIcon fontSize="small" />
                          </Controls.ActionButton>
                          {/* <Controls.ActionButton
                            style={{ marginTop: 15, backgroundColor: "red" }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago3(false);
                            }}
                          >
                            <CancelRoundedIcon fontSize="small" />
                          </Controls.ActionButton> */}
                        </Grid>
                        <Grid item xs={7}>
                        </Grid>
                        <Grid item xs={12}
                          style={{
                            border: "1px solid",
                            borderRadius: 10,
                            padding: 20,
                            marginBottom: 40,
                          }}
                        >
                          {esEfectivo && esEfectivo.length && esEfectivo.includes(values.FormaPagoId3) ? (
                            <>
                            <EfectivoContenido
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditEfectivo}
                              numero={3}
                              />
                            </>
                            ) : (
                            <></>
                          )}
                          {esTarjeta && esTarjeta.length && esTarjeta.includes(values.FormaPagoId3) ? (
                            <>
                            <TarjetaContenido
                              listaTarjetas={registroTarjetasCredito}
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditTarjeta}
                              numero={3}
                              />
                            </>
                            ) : (
                              <></>
                              )}
                          {esCheque && esCheque.length && esCheque.includes(values.FormaPagoId3) ? (
                            <ChequeContenido
                              listaBancos={registroBancos}
                              listaSucursales={registroSucursales}
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditCheque}
                              numero={3}
                            />
                            ) : (
                              <></>
                          )}
                          {esBancarizado && esBancarizado.length && esBancarizado.includes(values.FormaPagoId3) ? (
                            <TransferenciaContenido
                              listaBancos={registroBancos}
                              listaSucursales={registroSucursales}
                              listaMonedas={registroMoneda}
                              addOrEdit={addOrEditTransferencia}
                              cliente={cliente}
                              numero={3}
                            />
                          ) : (
                            <></>
                          )}
                          {!values.FormaPagoId3 &&
                            <h2>Seleccione Forma de Pago</h2>
                          }
                        </Grid>
                      </>
                    )}
                    {visible_FPago4 && (
                      <>
                        <Grid item xs={3}>
                          {!desactivado4 ?
                            <Controls.Select
                              name="FormaPagoId4"
                              label="Forma de Pago"
                              value={values.FormaPagoId4 || ""}
                              onChange={handleInputChange}
                              options={registroFormaPago.map((item) => {return { id: item.id, title: item.nombre };})}
                            />
                            :
                            <Controls.Input
                              name="FormaPagoId3"
                              label="Forma de Pago"
                              value={registroFormaPago.find((fp) => fp.id === values.FormaPagoId4).nombre}
                              disabled
                              variant="outlined"
                            />
                          }
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          {/* <Controls.ActionButton
                            style={{ marginTop: 15, backgroundColor: "red" }}
                            color="primary"
                            onClick={() => {
                              setVisibleFPago4(false);
                            }}
                          >
                            <CancelRoundedIcon fontSize="small" />
                          </Controls.ActionButton> */}
                        </Grid>
                        <Grid item xs={7}>
                        </Grid>
                        <Grid item xs={12}
                          style={{
                            border: "1px solid",
                            borderRadius: 10,
                            padding: 20,
                            marginBottom: 40,
                          }}
                        >
                          {esEfectivo && esEfectivo.length && esEfectivo.includes(values.FormaPagoId4) ? (
                            <>
                            <EfectivoContenido
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditEfectivo}
                              numero={4}
                              />
                            </>
                            ) : (
                            <></>
                          )}
                          {esTarjeta && esTarjeta.length && esTarjeta.includes(values.FormaPagoId4) ? (
                            <>
                            <TarjetaContenido
                              listaTarjetas={registroTarjetasCredito}
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditTarjeta}
                              numero={4}
                              />
                            </>
                            ) : (
                              <></>
                              )}
                          {esCheque && esCheque.length && esCheque.includes(values.FormaPagoId4) ? (
                            <ChequeContenido
                              listaBancos={registroBancos}
                              listaSucursales={registroSucursales}
                              listaMonedas={registroMoneda}
                              cliente={cliente}
                              addOrEdit={addOrEditCheque}
                              numero={4}
                            />
                            ) : (
                              <></>
                          )}
                          {esBancarizado && esBancarizado.length && esBancarizado.includes(values.FormaPagoId4) ? (
                            <TransferenciaContenido
                              listaBancos={registroBancos}
                              listaSucursales={registroSucursales}
                              listaMonedas={registroMoneda}
                              addOrEdit={addOrEditTransferencia}
                              cliente={cliente}
                              numero={4}
                            />
                          ) : (
                            <></>
                          )}
                          {!values.FormaPagoId4 &&
                            <h2>Seleccione Forma de Pago</h2>
                          }
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          //disablePast={true}  Cambiado 29/10/21 para carga de documentación anterior al sistema
                          id="selectedDate"
                          format="dd/MM/yyyy"
                          value={selectedDate}
                          onChange={handleDateChange}
                          helperText="Fecha estimada de firma:"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controls.InputMask
                        name="comisionGastos"
                        label="Comisión y Gastos Administrativos"
                        prefix="$ "
                        value={values.comisionGastos}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controls.Input
                        name="observacionesReserva"
                        label="Observaciones de la reserva"
                        value={values.observacionesReserva}
                        onChange={handleInputChange}
                        multiline
                        rowsMax={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {!guardando ? (
                        <div style={{ marginTop: 25, textAlign: "right" }}>
                          <Controls.Button type="submit" text="Guardar" />
                          <Controls.Button text="Imprimir" onClick={() => openInPopupImprimir(values)} />
                        </div>
                      ) : (
                        <div style={{ margin: 25, textAlign: "right" }}>
                          <CircularProgress size={26} />
                        </div>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Popup
            title="Calculadora de Cuotas"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <Calculadora />
          </Popup>
          <PopupPrint
            title="Imprimir Reserva"
            openPopup={openPopupImprimir}
            setOpenPopup={setOpenPopupImprimir}
          >
            <ReservaParaImprimir 
              itemsImprimir={itemsImprimir}
              productoSeleccionado={productoSeleccionado}
              lotesComprados={lotesComprados}
              listaFormasPago={registroFormaPago}
              listaMonedas={registroMoneda}
              listaLocalidades={registroLocalidades}
              listaPaises={registroPaises}
              listaProvincias={registroProvincias}
              listaEstadoCivil={registroEstadoCivil}
              listaBancos={registroBancos}
              listaSucursales={registroSucursales}
              listaTarjetasCredito={registroTarjetasCredito}
              listaLoteos={registroLoteos}
              listaNacionalidades={nacionalidades}
              listaUsuarios={registroUsuarios}
              usuario={userId}
              setOpenPopup={setOpenPopupImprimir}
          />
          </PopupPrint>
        </Form>
      ) : (
        <>Cargando...</>
      )}
    </>
  );
}
