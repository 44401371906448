import React, { useState, useEffect } from "react";

import * as VentasLotesServices from "../../../services/venta/ventasLote";
import * as ConfigDataServices from "../../../services/utiles/configData";
import * as CuotasxVentaLoteServices from "../../../services/contable/cuotasxVentasLote";

import { useUserState } from "../../../context/UserContext";

import Notification from "../../../components/tablas/Notification";
import ConfirmDialog from "../../../components/tablas/ConfirmDialog";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import GavelIcon from '@material-ui/icons/Gavel';

import Popup from "../../../components/tablas/Popup";

import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Grid,
} from "@material-ui/core";

import useTable from "../../../components/tablas/useTable";
import Controls from "../../../components/tablas/controls/Controls";

import AvisoVentasLoteForm from "./AvisoVentaLoteForm";
import Tarjeta from "../../utiles/Tarjeta";
//import { procesar } from "../../../services/venta/reservaPropiedadVenta";

const cabecera = [
  { id: "codigo", label: "Código" },
  { id: "loteo", label: "Loteo" },
  { id: "cliente", label: "Cliente" },
  { id: "actions", label: "acciones", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "25%",
    minWidth: 275,
    backgroundColor: "#DBCFDE",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

export default function AvisoVentaLote() {
  const { token } = useUserState();

  const [cargando, setCargando] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [cuotas, setCuotas] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [lotes, setLotes] = useState({});
  const [venta, setVenta] = useState({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(cuotas, cabecera, filterFn);

  const openInPopup = (item) => {
    //console.log("Item", item);
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const procesar = async (item, resetForm) => {
    console.log("En procesar: ", item);
    const id = item.id;
    const lotes = item.lotes;
    let data = {
      id: item.id,
      procesado: true,
    };
    
    console.log('confirma: ', procesar);
    let venta = {
      codigo: item.codigo,
      observacionesVenta: item.observaciones,
      ReservasLoteId: id,
      ClienteId: item.ClienteId,
      vendedorId: item.Usuario.id,
      cantidadCuotas: item.cantidadCuotas,
      importeAnio1: item.importeAnio1,
      importeAnio2: item.importeAnio2,
      importeAnio3: item.importeAnio3,
      importeAnio4: item.importeAnio4,
      fechaVencimiento: item.fechaVencimiento,
    }
    console.log('venta: ', venta);

    try {
      await VentasLotesServices.insert(venta, token);
    } catch (err) {
      return false;
    }
    resetForm();
    //setRecordForEdit(null);
    setOpenPopup(false);
    setNotify({
      isOpen: true,
      message: "Guardado correctamente",
      type: "success",
    });
    // fuerza a recargar la lista
    setCargando(false);
  };

  useEffect(() => {
    if (!cargando) {
      CuotasxVentaLoteServices.cuotasPreJudicializadas(token).then((res) => {
        console.log('Cuotas Prejudiciales: ', res.data);
        setCuotas(res.data);
      });
      }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {cuotas && cuotas.length ? (
        <>
          {!visible ? (
            <>
              <Tarjeta
                label="PREJUDICIALIZADAS"
                icon={
                  <GavelIcon
                    style={{ fontSize: 40, color: "green", marginBottom: -10 }}
                  />
                }
                estilos={classes.root}
                registros={cuotas}
                setVisible={setVisible}
                visible={visible}
              />
            </>
          ) : (
            <>
              <Paper
                style={{ backgroundColor: "#DBCFDE" }}
                className={classes.pageContent}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <h2>
                        <GavelIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                        PREJUDICIALIZADAS{" "}
                        <GavelIcon
                          style={{
                            fontSize: 40,
                            color: "green",
                            marginBottom: -10,
                          }}
                        />{" "}
                      </h2>
                    </Grid>
                    <Grid item xs={1} style={{ paddingTop: 10 }}>
                      <Controls.Button
                        text="Ocultar"
                        onClick={() => setVisible(!visible)}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
                <TblContainer>
                  <Table aria-label="collapsible table">
                    <TblHead />
                    <TableBody>
                      {recordsAfterPagingAndSorting().map((item) => (
                        <TableRow key={item.id}>
                          <TableCell style={{ fontWeight: "bold" }}>
                            {item.VentasLote.codigo}
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            {item.VentasLote.ReservasLote.Lotesxloteo.Loteo.nombre}
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            {item.VentasLote.Cliente.nombre} {item.VentasLote.Cliente.apellido}
                          </TableCell>
                          <TableCell>
                            {/* <Controls.ActionButton
                              color="primary"
                              style={{ backgroundColor: "#FFA114" }}
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                openInPopup(item);
                              }}
                            >
                              <AssignmentTurnedInIcon />
                            </Controls.ActionButton> */}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TblContainer>
                <TblPagination />
              </Paper>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <Popup
        title="Venta Confirmada"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AvisoVentasLoteForm
          token={token}
          recordForEdit={recordForEdit}
          procesar={procesar}
          lotes={lotes}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
