import GestorAxios from "../../config/axios";

export async function getUltimosMensajes(token) {
  let data = {};

  await GestorAxios.get("/ventas/historialReserva/ultimos", {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      data = res.data;
    })
    .catch((e) => {
      console.log("Error: ", e.message);
    });

  return data;
}

export async function getAllItems(token, reservaId, tipoProducto) {
  let data = {};

  let filtro = "ReservasLoteId";

  if (tipoProducto === "pventa") {
    filtro = "ReservasPropiedadVentumId";
  }

  await GestorAxios.get(
    "/ventas/historialReserva/filtrar/" + filtro + "/" + reservaId,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => {
      data = res.data;
    })
    .catch((e) => {
      console.log("Error: ", e.message);
    });

  return data;
}

export async function insert(data, token, tipoProducto) {
  let dataReturn = {};
  data.tipoProducto = tipoProducto;

  await GestorAxios.post("/ventas/historialReserva/nuevo/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      dataReturn = res;
    })
    .catch((e) => {
      console.log("Error: ", e.message);
    });

  return dataReturn;
}

export async function update(data, token) {
  let dataReturn = {};

  await GestorAxios.put(
    `/ventas/historialreservalote/actualizar/${data.id}/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => {
      dataReturn = res;
    })
    .catch((e) => {
      console.log("Error: ", e.message);
    });

  return dataReturn;
}
