import React, { useState, useEffect } from "react";

import { Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Controls from "../../components/tablas/controls/Controls";
import { useForm, Form } from "../../components/tablas/useForm";

import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import * as ClienteServices from "../../services/cliente/cliente";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useUserState } from "../../context/UserContext";

import Permisos from "../../config/permisos";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    backgroundColor: "lightgray",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const fa = new Date();
const fc = fa.getDate() + "-" + (fa.getMonth() + 1) + "-" + fa.getFullYear();

const initialFValues = {
  id: 0,
  nombre: "",
  apellido: "",
  DNI: "",
  asociadoNombre: "",
  asociadoDNI: "",
  asociadoTelefono: "",
  direccion: "",
  telefono: "",
  telefonoAlternativo: "",
  email: "",
  fechaConsulta: fc,
  observaciones: "",
  PaiId: undefined,
  ProvinciumId: undefined,
  LocalidadId: undefined,
  FuenteId: undefined,
  EstadoCivilId: undefined,
};

export default function ClienteForm(props) {
  const { addOrEdit, recordForEdit, setOpenPopup, token, listaFuentes, listaLocalidades, listaProvincias, listaPaises, listaEstadoCivil } = props;

  const { rolCodigo } = useUserState();

  const classes = useStyles();
  const [registros, setRegistros] = useState({});
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [confirma, setConfirma] = React.useState(false);
  const [guardando, setGuardando] = React.useState(false);
  const [buscar, setBuscar] = React.useState(false);
  const [coincidencia, setCoincidencia] = useState({});

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const f = new Date(date);
    values.fechaConsulta =
      f.getDate() + "-" + (f.getMonth() + 1) + "-" + f.getFullYear();
  };

  const handleInputSoloNumerosChange = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 12) {
      e.target.value = onlyNums;
    } else if (onlyNums.length === 12) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      e.target.value = number;
    }
    handleInputChange(e);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nombre" in fieldValues)
      temp.nombre = fieldValues.nombre ? "" : "Dato requerido";
    if ("telefono" in fieldValues)
      temp.telefono = fieldValues.telefono ? "" : "Dato requerido";
    if ("FuenteId" in fieldValues)
      temp.FuenteId =
        fieldValues.FuenteId !== undefined ? "" : "Dato requerido";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    //buscarSimilares();
    if (validate()) {
      setGuardando(true);
      addOrEdit(values, resetForm).then((e) => {
        if (e === false) {
          setGuardando(false);
          alert(
            "No se pudo completar la operación, revise los datos e intente nuevamente"
          );
        }
      });
    }
  };

  const telefonoCoincide = (telefono) => {
    let siNo = false;
    if (telefono) {
      if (values.telefono) {
        if (values.telefono.length > 0) {
          if (telefono.includes(values.telefono)) {
            siNo = true;
          }
        }
      }
    }
    return siNo;
  };

  const buscarSimilares = async () => {
    setBuscar(true);
    const datosBuscar = {
      nombre: values.nombre,
      apellido: values.apellido,
      DNI: values.DNI,
      telefono: values.telefono,
      email: values.email,
    };
    const datos = {
      key: "telefono",
      value: values.telefono,
    };
    await ClienteServices.filtrar(token, datos).then((res) => {
      console.log("Coincidencias: ", res);
      setBuscar(false);
      setCoincidencia(res);
    });
    await ClienteServices.filtrarSimilares(token, datosBuscar).then(
      (res) => {
        console.log("Similares: ", res);
        setRegistros(res);
      }
    );
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
      setConfirma(true);
    }
  }, [recordForEdit, registros, confirma]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {confirma ? (
              <>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="nombre"
                    label="Nombre / Alias"
                    value={values.nombre}
                    onChange={handleInputChange}
                    error={errors.nombre}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="apellido"
                    label="Apellido"
                    value={values.apellido}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="DNI"
                    label="DNI"
                    value={values.DNI}
                    onChange={handleInputChange}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={6}>
              <Controls.Input
                name="telefono"
                label="Teléfono"
                value={values.telefono}
                onChange={handleInputSoloNumerosChange}
                error={errors.telefono}
              />
            </Grid>
            {confirma ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    name="email"
                    label="email"
                    type="email"
                    value={values.email}
                    onChange={handleInputChange}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            {!confirma ? (
              <>
                {registros.length ? (
                  <>
                    <Grid item xs={12} style={{ backgroundColor: "green", borderRadius: 10, marginTop: 30, marginBottom: 10 }}>
                      <h4 style={{ textAlign: "center", color: "white" }}>CLIENTES SIMILARES ENCONTRADOS</h4>
                    </Grid>
                    {registros.map((item) => (
                      <Grid item xs={12} sm={4}>
                        <Card className={classes.root}>
                          <CardContent>
                            <Typography
                              style={{ textAlign: "center" }}
                              className={classes.title}
                              color="textSecondary"
                              gutterBottom
                            >
                              <AccountCircleIcon
                                style={{ fontSize: 40, color: "white" }}
                              />
                            </Typography>
                            <Typography variant="h5" component="h2">
                              {item.apellido} {item.nombre}
                            </Typography>
                            <Typography
                              className={classes.pos}
                              color="textSecondary"
                            >
                              DNI: {item.DNI}
                            </Typography>
                            <Typography variant="body2" component="p">
                              {telefonoCoincide(item.telefono) && (
                                <>
                                  <PhoneAndroidIcon
                                    style={{ marginBottom: -5 }}
                                  />{" "}
                                  {Permisos(
                                    rolCodigo,
                                    "CLIENTES.COINCIDENCIA.TELEFONO"
                                  )
                                    ? item.telefono +
                                      " - Coincide con TELEFONO PRINCIPAL"
                                    : "Coincide con TELEFONO PRINCIPAL"}
                                </>
                              )}
                              {telefonoCoincide(item.asociadoTelefono) && (
                                <>
                                  <PhoneAndroidIcon
                                    style={{ marginBottom: -5 }}
                                  />{" "}
                                  {Permisos(
                                    rolCodigo,
                                    "CLIENTES.COINCIDENCIA.TELEFONO"
                                  )
                                    ? item.asociadoTelefono +
                                      " - Coincide con TELEFONO DEL ASOCIADO"
                                    : "Coincide con TELEFONO DEL ASOCIADO"}
                                </>
                              )}
                              {telefonoCoincide(item.telefonoAlternativo) && (
                                <>
                                  <PhoneAndroidIcon
                                    style={{ marginBottom: -5 }}
                                  />{" "}
                                  {Permisos(
                                    rolCodigo,
                                    "CLIENTES.COINCIDENCIA.TELEFONO"
                                  )
                                    ? item.telefonoAlternativo +
                                      " - Coincide con TELEFONO ALTERNATIVO"
                                    : "Coincide con TELEFONO ALTERNATIVO"}
                                </>
                              )}
                              <br />
                              <EmailIcon style={{ marginBottom: -5 }} />{" "}
                              {item.email}
                              <br />
                              {item.Usuario ? (
                                <>
                                  VENDEDOR:{" "}
                                  <b>
                                    {item.Usuario.nombre}{" "}
                                    {item.Usuario.apellido}
                                  </b>
                                </>
                              ) : (
                                <>NO HAY VENDEDOR ASIGNADO</>
                              )}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {/*<div> No hay registros similares </div>
                  <br />*/}
                  </>
                )}
                {coincidencia && coincidencia.length ? (
                  <>
                    <Grid item xs={12} style={{ backgroundColor: "red", borderRadius: 10, marginTop: 30, marginBottom: 10 }}>
                      <h4 style={{ textAlign: "center", color: "white" }}>COINCIDENCIAS ENCONTRADAS</h4>
                    </Grid>
                    {coincidencia.map((item) => (
                      <Grid item xs={12} sm={4}>
                        <Card className={classes.root}>
                          <CardContent>
                            <Typography
                              style={{ textAlign: "center" }}
                              className={classes.title}
                              color="textSecondary"
                              gutterBottom
                            >
                              <AccountCircleIcon
                                style={{ fontSize: 40, color: "white" }}
                              />
                            </Typography>
                            <Typography variant="h5" component="h2">
                              {item.apellido} {item.nombre}
                            </Typography>
                            <Typography
                              className={classes.pos}
                              color="textSecondary"
                            >
                              DNI: {item.DNI}
                            </Typography>
                            <Typography variant="body2" component="p">
                              {telefonoCoincide(item.telefono) && (
                                <>
                                  <PhoneAndroidIcon
                                    style={{ marginBottom: -5 }}
                                  />{" "}
                                  {Permisos(
                                    rolCodigo,
                                    "CLIENTES.COINCIDENCIA.TELEFONO"
                                  )
                                    ? item.telefono +
                                      " - Coincide con TELEFONO PRINCIPAL"
                                    : "Coincide con TELEFONO PRINCIPAL"}
                                </>
                              )}
                              {telefonoCoincide(item.telefonoAlternativo) && (
                                <>
                                  <PhoneAndroidIcon
                                    style={{ marginBottom: -5 }}
                                  />{" "}
                                  {Permisos(
                                    rolCodigo,
                                    "CLIENTES.COINCIDENCIA.TELEFONO"
                                  )
                                    ? item.telefonoAlternativo +
                                      " - Coincide con TELEFONO ALTERNATIVO"
                                    : "Coincide con TELEFONO ALTERNATIVO"}
                                </>
                              )}
                              <br />
                              <EmailIcon style={{ marginBottom: -5 }} />{" "}
                              {item.email}
                              <br />
                              {item.Usuario ? (
                                <>
                                  VENDEDOR:{" "}
                                  <b>
                                    {item.Usuario.nombre}{" "}
                                    {item.Usuario.apellido}
                                  </b>
                                </>
                              ) : (
                                <>NO HAY VENDEDOR ASIGNADO</>
                              )}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {/*<div> No hay coincidencias </div>
                <br />*/}
                  </>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    name="direccion"
                    label="Dirección"
                    value={values.direccion}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    name="telefonoAlternativo"
                    label="Teléfono Alternativo"
                    value={values.telefonoAlternativo}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Select
                    name="PaiId"
                    label="País"
                    value={values.PaiId}
                    onChange={handleInputChange}
                    options={listaPaises.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  {values.PaiId ? (
                    <div>
                      {listaProvincias.find(
                        (item) => item.PaiId === values.PaiId
                      ) ? (
                        <Controls.Select
                          name="ProvinciumId"
                          label="Provincia"
                          value={values.ProvinciumId}
                          onChange={handleInputChange}
                          options={listaProvincias.map((item) => {
                            if (item.PaiId === values.PaiId) {
                              return { id: item.id, title: item.nombre };
                            } else {
                              return false; // Línea Modificada
                            }
                          })}
                        />
                      ) : (
                        <>
                          {" "}
                          No hay provincias creadas en este pais. Por favor
                          completar antes de continuar.
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  {values.ProvinciumId ? (
                    <div>
                      {listaLocalidades.find(
                        (item) => item.ProvinciumId === values.ProvinciumId
                      ) ? (
                        <>
                          <Controls.Select
                            name="LocalidadId"
                            label="Localidad"
                            value={values.LocalidadId}
                            onChange={handleInputChange}
                            options={listaLocalidades.map((item) => {
                              if (item.ProvinciumId === values.ProvinciumId) {
                                return { id: item.id, title: item.nombre };
                              } else {
                                return false; // Línea Modificada
                              }
                            })}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          No hay localidades creadas en esta provincia. Por
                          favor completar antes de continuar.
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Select
                    name="EstadoCivilId"
                    label="Estado Civil"
                    value={values.EstadoCivilId}
                    onChange={handleInputChange}
                    options={listaEstadoCivil.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    name="observaciones"
                    label="Observaciones"
                    value={values.observaciones}
                    onChange={handleInputChange}
                    multiline
                    rowsMax={4}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="asociadoNombre"
                    label="Nombre asociado"
                    value={values.asociadoNombre}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="asociadoDNI"
                    label="DNI asociado"
                    value={values.asociadoDNI}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Controls.Input
                    name="asociadoTelefono"
                    label="Teléfono asociado"
                    value={values.asociadoTelefono}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="fechaConsulta"
                      maxDate={new Date()}
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      helperText="Fecha consulta"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Select
                    name="FuenteId"
                    label="Fuente"
                    value={values.FuenteId}
                    onChange={handleInputChange}
                    options={listaFuentes.map((item) => {
                      return { id: item.id, title: item.nombre };
                    })}
                    error={errors.FuenteId}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {!confirma ? (
          <>
            <Grid item xs={12}>
              <div style={{ marginTop: 25, textAlign: "right" }}>
                {!buscar ?
                <>
                {coincidencia.length ? (
                  <>
                    <Controls.Button
                      text="Cerrar"
                      color="default"
                      onClick={() => setOpenPopup(false)}
                    />
                  </>
                ) : (
                  <>
                  {coincidencia && coincidencia.length === 0 && registros && registros.length === 0 ? (
                    <h2>No hay coincidencias. Puede proceder a cargar el cliente</h2>)
                    : 
                    <></>}
                    <Controls.Button onClick={buscarSimilares} text="Buscar" />
                    <Controls.Button
                      text="Limpiar"
                      color="default"
                      onClick={resetForm}
                    />
                    {coincidencia && coincidencia.length === 0 ? (
                      <Controls.Button
                        color="secondary"
                        onClick={() => {
                          setConfirma(true);
                        }}
                        text="CONTINUAR"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
                </>
                :
                <>
                <div style={{ margin: 25, textAlign: "right" }}>
                  <CircularProgress size={26} />
                </div>
                </>
                }
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              {!guardando ? (
                <div style={{ marginTop: 25, textAlign: "right" }}>
                  <Controls.Button type="submit" text="Guardar" />
                  <Controls.Button
                    text="Limpiar"
                    color="default"
                    onClick={resetForm}
                  />
                </div>
              ) : (
                <div style={{ margin: 25, textAlign: "right" }}>
                  <CircularProgress size={26} />
                </div>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Form>
  );
}
