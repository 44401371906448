import React, { useState, useEffect } from "react";

import { Grid, Paper } from "@material-ui/core";

import Controls from "../../../components/tablas/controls/Controls";
//import { useForm, Form } from "../../../components/tablas/useForm";

//--------------------------------------------PRUEBA-------------------------------------------------------------

export default function Panel(props) {

  const { listaSocios, indice, aporteSocios, setAporteSocios, pagoSocios, setPagoSocios, } = props;

  const [cargando, setCargando] = useState(false);
  const [totalSumaAportes, setTotalSumaAportes] = useState(0);

  let vectorCantidad = [];

  const sumaAporteSocios = (totAportes) => {
    let resultado = 0;
    let i = 0;
    let ap = [];
    for (let i = 0; i < totAportes.length; i++) {
      if (totAportes[i] === '') {
        ap.push(0);
      } else {
        ap.push(parseInt(totAportes[i]));
      }
    }
    for (i = 0; i < ap.length; i++) {
      resultado = resultado + ap[i];
    }
    return resultado;
  };

  const handleInputChangeAporte = (e) => {

    const index = parseInt(e.target.name.substr(4));
    let aport = aporteSocios.slice();
    aport[index] = e.target.value;

    setTotalSumaAportes(sumaAporteSocios(aport));
    setAporteSocios(indice, aport);
  };

  const handleInputChangePago = (e) => {

    const index = parseInt(e.target.name.substr(4));
    let pagos = pagoSocios.slice();
    pagos[index] = e.target.value;
    setPagoSocios(indice, pagos);
  };

  useEffect(() => {
    const setearPagos = () => {
      if (listaSocios) {
        if (listaSocios.length > 0) {
          let v = [];
          for (let i = 0; i < listaSocios.length; i++) {
            v[i] = 0;
          }
          setAporteSocios(indice, v);
          setPagoSocios(indice, v);
        }
      }
    };

    if (!cargando) {
      setCargando(true);
      if (aporteSocios) {
        if (aporteSocios.length > 0) {
          setTotalSumaAportes(sumaAporteSocios(aporteSocios));
        }
      }

      if (pagoSocios) {
        if (!pagoSocios.length) {
          setearPagos();
        }
      } else {
        setearPagos();
      }
    }
  }, [vectorCantidad, aporteSocios]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper elevation={0}>
      {listaSocios !== undefined && (
        <>
          {listaSocios.length > 0 ? (
            <>
              <Grid container>
                {listaSocios.map((socio, index) => (
                  <>
                    <Grid xs={5}
                      key={index}
                      style={{
                        textAlign: "center",
                        backgroundColor: "lightblue",
                        borderRadius: 10,
                        marginBottom: 20,
                      }}
                    >
                      <h2>{socio.Asociado.nombre} {socio.Asociado.apellido}</h2>
                      <h4>{socio.porcentaje}%</h4>
                      {pagoSocios ? (
                        <>
                          {pagoSocios.length && (
                            <>
                              <Controls.InputMask
                                name={"pago" + index}
                                label="Pago por Porcentaje"
                                value={pagoSocios[index] ? pagoSocios[index] : 0}
                                //type="number"
                                onChange={handleInputChangePago}
                                variant="outlined"
                                prefix="$ "
                              />
                              <Controls.InputMask
                                name={"apor" + index}
                                label="Aporte Comisión"
                                value={aporteSocios[index]}
                                //type="number"
                                onChange={handleInputChangeAporte}
                                variant="outlined"
                                prefix="$ "
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid xs={1}></Grid>
                  </>
                ))}
              </Grid>
            </>
          ) : (
            <>No hay</>
          )}

          {/*------------------------------------Aporte de Socios------------------------------------*/}
          <div style={{ width: "50%", marginLeft: "25%" }}>
            <Grid container style={{ borderRadius: 10, textAlign: "right", paddingRight: "10px", marginBottom: "5px", marginTop: "5px", backgroundColor: "#dddddd" }}>
              <Grid item xs={12} sm={6}>
                <div>
                  <h4>$ {totalSumaAportes ? new Intl.NumberFormat().format(totalSumaAportes) : 0}</h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <h4>Aporte</h4>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Paper>
  );
}
